import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  getCreditBalance,
  getInvoiceDetailsByPmtId,
} from '../../blRequestInstruction/components/actions/Instruction';

export interface PaymentBlPaymentDetails {
  pmtReference: string;
  pmtTime: string;
  pmtAmount: string;
  allocatedAmount: any;
  unAllocatedAmount: any;
}

export interface InvoicePmtDetails {
  id: any;
  invAmt: any;
  invNo: any;
  invType: any;
  invStatus: any;
}

interface PaymentSllice {
  data: PaymentBlPaymentDetails[];
  selectedPaymentData?: any;
  invoiceDetailsPmtId?: any;
  initialInvoiceDetailsPmtId?: any;
  validationFlag?: any;
  multiplePmtRef?: any;
  pmtNumberdata?: any;
  pmtReftabledata?: any;
  mstCodeList?: any;
}

const initialState: PaymentSllice = {
  pmtNumberdata: [],
  pmtReftabledata: [],
  multiplePmtRef: false,
  validationFlag: false,
  data: [
    /*  {
      paymentRef: 'PTMNO2366',
      time: '18-02-2023  5:30',
      amount: 'USD 100',
    },
    {
      paymentRef: 'PTMNO2366',
      time: '10-02-2023  1:00',
      amount: 'USD 200',
    },
    {
      paymentRef: 'PTMNO2366',
      time: '11-02-2023  3:30',
      amount: 'USD 800',
    },
    {
      paymentRef: 'PTMNO2366',
      time: '12-02-2023  5:20',
      amount: 'USD 200',
    }, */
  ],
};

export const paymentBlPayment = createSlice({
  name: 'paymentBlPayment',
  initialState,
  reducers: {
    updatePaymentList: (
      state,
      action: PayloadAction<PaymentBlPaymentDetails[]>
    ) => {
      state.data = action.payload;
    },
    selectedPayment: (state, action: PayloadAction<any>) => {
      state.selectedPaymentData = action.payload;
    },
    flagPmtNumber: (state, action: PayloadAction<any>) => {
      state.validationFlag = action.payload;
    },
    multiplePmtRef: (state, action: PayloadAction<any>) => {
      state.multiplePmtRef = action.payload;
    },
    pmtReftabledata: (state, action: PayloadAction<any>) => {
      state.pmtReftabledata = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getInvoiceDetailsByPmtId.pending,
      (state,action)=>{
        state.pmtNumberdata = []
      }
    )
    builder.addCase(
      getInvoiceDetailsByPmtId.fulfilled,
      (state, action) => {
        
        state.invoiceDetailsPmtId = action.payload;
        state.initialInvoiceDetailsPmtId = action.payload;
        state.pmtNumberdata = action.payload?.data
      }
    );

  },
});

export const {
  updatePaymentList,
  selectedPayment,
  flagPmtNumber,
  multiplePmtRef,
  pmtReftabledata,
} = paymentBlPayment.actions;

export default paymentBlPayment.reducer;
