import { useEffect, useRef, useState } from "react";

import moment from "moment";
import queryString from "query-string";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import DatePicker from 'react-datepicker';
import { Dropdown, Form, InputGroup } from "react-bootstrap";
import { matchPath, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

import { AppDispatch, RootState } from "../../store";
import DataTable from "../../common/components/DataTable";
import useDataCountry from "../../../hooks/useDataCountry";
import FilterView from "../../common/components/FilterView";
import OblDetails from "../../common/components/OblDetails";
import { getDateFormat } from "../../../helpers/dateFormat";
import { AESDecrypt, AESEncrypt } from "../../../encrypt-util";
import { IDataTableColumn } from "../../common/types/dataTable";
import { getColumnViews } from "../../common/actions/dataTable";
import { getDashboardData } from "../dashboard/actions/dashboard";
import { updateDashbaordName } from "../../common/slices/navbarTop";
import { toggleColumnSettingsPayDash } from "./beneficiaryreportDashSlice";
import { clearBeneficiaryDashboardList, updateCurrentPagePayment } from "../dashboard/slices/dashboardSlice";
import DashboardContainer from "../../../components/common/DashboardContainer";
import { generateColumnConfig, IConfigsDefinition } from "../../utils/columnConfig";
import { updateDashbaordRefresh, updateDataTableFilterState, updateParams, updateSortStateDataTable } from "../../common/components/DataTable/slices/DataTable";
import { c } from "msw/lib/glossary-de6278a9";
import { getBankListBeneficiary } from "./beneficiaryReportAction";
const gridName = "OPC_INVOICE_PMT_DASHBOARD";
const dashboardName = "OPC_INVOICE_PMT_DASHBOARD";
interface DateTypes {
  startDate: Date | null;
  endDate: Date | null;
}
const BeneficiaryReportDashboard = () => {
  const { blNum } = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const [filterUpdated, setFilterUpdated] = useState(false);
  const [selectedBank, setSelectedBank] = useState<any>();
  const [columns, setColumns] = useState<IDataTableColumn[]>([]);
  // const [callFilterApi, setCallFilterApi] = useState(false);
  const [dates, setDates] = useState<DateTypes>({
    startDate: null,
    endDate: null
  });
  const [configs, setConfigs] = useState<IConfigsDefinition>({
    columns: [],
    staticHiddenCols: [],
    defaultHiddenCols: [],
  });
  const refresh = useSelector(
    (state: RootState) => state.dataTable.refreshDashboard
  );
  // const hasLoaded = useRef(false); // Ref to track if the API has been called


  const linerId = useSelector((state: RootState) => state.navbarTop.linerId);
  const currentPage = useSelector(
    (state: RootState) => state.dashboard.currentPagePayment
  );
  const bankList = useSelector(
    (state: RootState) => state.beneficiaryPayment.bankList
  ) ?? [];

  const filterState = useSelector(
    (state: RootState) => state.dataTable.filterState
  );
  const filterStateLocal = filterState?.["paymentbeneficiary"];

  const data = useSelector(
    (state: RootState) => state.dashboard.beneficiaryRptDashboardList
  );
  const tpoId = useSelector((state: RootState) => state.agentDelegation.tpoId);

  const grids = useSelector(
    (state: RootState) => state.dataTable.beneficiaryDashboardGrids
  );
  const [previousState, setPreviousState] = useState<{
    filterState: string | null;
    search: string | null;
    grids: any[] | null;
  }>({
    filterState: "{}",
    search: "",
    grids: grids?.[0]?.gridId,
  });
  const firstApiCall = useRef(true);
  const [currentState, setCurrentState] = useState<{
    filterState: string | null;
    search: string | null;
    grids: any[] | null;
  }>({
    filterState: "{}",
    search: "",
    grids: grids?.[0]?.gridId,
  });
  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );
  const sortState = useSelector(
    (state: RootState) => state.dataTable.sortState
  );
  const setCurrentPage = (page: number) => {
    dispatch(updateCurrentPagePayment(page));
  };

  const paymentTopSearchState = useSelector(
    (state: RootState) => state.navbarTop.paymentTopSearchState
  );
  const selectedConfigId = useSelector(
    (state: RootState) => state.dataTable.selectedConfigId
  );
  const selectedTopSearchFilter = useSelector(
    (state: RootState) => state.navbarTop.selectedTopSearchFilter
  );
  const searchInputValue = useSelector(
    (state: RootState) => state.navbarTop.searchInputValue
  );

  const FilterViewAdditionalChildren = (() => {
    return (
      <div className="d-flex flex-nowrap gap-3 align-items-center">
        <div className='d-flex flex-nowrap align-items-stretch' style={{ width: 'max-content' }}>
          <InputGroup>
            <InputGroup.Text
              className="rounded-pill-l bg-100 fs--1"
              style={{ fontWeight: 450 }}
            >
              Date Range
            </InputGroup.Text>
            <div style={{ border: 'solid #d8e2ef 1px', maxWidth: "12rem", marginBottom: '0', background: 'white' }} className='rounded-pill-r' >
              <DatePicker
                selected={dates.startDate}
                onChange={(date: any) => {
                  setDates(({
                    startDate: date[0],
                    endDate: date[1]
                  }))
                }}
                selectsRange
                startDate={dates.startDate}
                maxDate={new Date()}
                endDate={dates.endDate}
                className='form-control border-0  fs--1 text-primary form-control-sm rounded-pill-r'
                dateFormat={getDateFormat()}
              />
            </div>
          </InputGroup>
        </div>
        {
          profileDetails?.opcPartnerType === 'PTO' &&
          <div className='d-flex flex-nowrap align-items-stretch' style={{ width: 'max-content' }}>
            <div style={{ width: '17rem' }}>
              <InputGroup>
                <InputGroup.Text
                  className="rounded-pill-l bg-100 fs--1"
                  style={{ fontWeight: 450 }}
                >
                  Select Bank
                </InputGroup.Text>
                <Form.Select
                  aria-label="Config"
                  className="text-primary rounded-pill-r fs--1"
                  size="sm"
                  value={selectedBank}
                  onChange={(e) => {
                    setSelectedBank(e?.target?.value);
                  }}
                >
                  <option value="">
                    Select All
                  </option>
                  {
                    bankList.map(({ value, label }: any) => (
                      <option value={value}>
                        {label}
                      </option>
                    ))
                  }
                </Form.Select>
              </InputGroup>
            </div>
          </div>
        }
      </div>
    )
  })();

  const handleGetBankList = () => {
    dispatch(getBankListBeneficiary());
  }

  const dataCountry = useDataCountry();
  // const linerId = useSelector((state: RootState) => state.navbarTop.linerId);

  function useDebouncedEffect(effect: any, deps: any, delay: any) {
    const callback = useRef<any>();

    useEffect(() => {
      callback.current = effect;
    }, [effect]);

    useEffect(() => {
      const handler = () => {
        if (callback.current) {
          callback.current();
        }
      };

      const timer = setTimeout(handler, delay);

      return () => clearTimeout(timer);
    }, [...deps, delay]);
  }

  useEffect(() => {
    const date = new Date();
    date.setDate(new Date().getDate() - 7);
    date.setHours(0, 0, 0, 0);
    const today = new Date()
    today.setHours(0, 0, 0, 0);
    setDates({
      startDate: date,
      endDate: today
    })
    handleGetBankList();
    // setCallFilterApi(true)
  }, []);

  useEffect(() => {
    if (dates.startDate && dates.endDate) {
      setFilterUpdated(true);
      dispatch(updateDataTableFilterState({
        ...filterState,
        paymentbeneficiary: {
          ...filterStateLocal,
          payment_date: [
            formatDate(dates.startDate) || "",
            formatDate(dates.endDate) || "",
          ],
          bank_name: selectedBank ?? ''
        }
      }));
    }
    // else {
    //   dispatch(updateDataTableFilterState({
    //     ...filterState,
    //     paymentbeneficiary: {
    //       ...filterStateLocal,
    //       payment_date: []
    //     }
    //   }));
    // }
  }, [dates.startDate, dates.endDate, selectedBank]);

  useEffect(() => {
    const paymentDashboardDefinition = grids?.[0]?.columnConfigGrid || "";

    if (!paymentDashboardDefinition) return;

    try {
      const configs = generateColumnConfig(
        JSON.parse(JSON.parse(paymentDashboardDefinition)),
        getParams,
        navigate
      );

      const columns = configs?.columns?.map((c) => c);
      setConfigs(configs);
      setColumns(columns);
    } catch (e) {
      console.error("Column Error: ", e);
    }
  }, [grids, location]);

  useEffect(() => {
    dispatch(updateDashbaordName(gridName));
  }, []);


  const sort = (queryString.parse(location.search)?.sort as string)
    ?.split?.(",")
    ?.join?.(" ");

  useEffect(() => {
    if (location?.pathname && dataCountry && tpoId) {
      dispatch(
        getColumnViews({
          gridName: dashboardName,
          country: dataCountry,
          linerId: tpoId,
        })
      );
    }
    dispatch(updateParams({}));
  }, [dataCountry, tpoId]);

  useEffect(() => {
    const currentState = {
      filterState: JSON.stringify(filterStateLocal),
      search: location.search,
      grids: grids?.[0]?.gridId,
    };
    if (JSON.stringify(previousState) !== JSON.stringify(currentState)) {
      loadPaymentDashboard(true);
      setCurrentState({
        filterState: JSON.stringify(filterStateLocal),
        search: location.search,
        grids: grids?.[0]?.gridId,
      });
    }
  }, [filterStateLocal, location.search, grids]);

  useEffect(() => {
    loadPaymentDashboard(false);
  }, [currentPage]);

  const formatDate = (inputDate: any) => {
    return moment(inputDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
  }

  const loadPaymentDashboard = (filterUpdated: boolean) => {
    if (filterUpdated) setCurrentPage(1);
    if (filterStateLocal && filterStateLocal.payment_date && filterStateLocal.payment_date?.length > 0) {
      if (Object.keys(filterStateLocal)?.length > 0) {
        const filterApiState: any = {};
        const filterKeys = Object.keys(filterStateLocal,);
        for (let k of filterKeys) {
          if (filterStateLocal?.[k]?.from) {
            filterApiState[k] = [
              (filterStateLocal?.[k]?.from) || "",
              (filterStateLocal?.[k]?.to) || "",
            ].filter((i) => i);
          } else if (filterStateLocal?.[k]?.from !== undefined) {
            filterApiState[k] = undefined;
          } else {
            filterApiState[k] = filterStateLocal?.[k]
              ? typeof filterStateLocal?.[k] === "string"
                ? [filterStateLocal?.[k]]
                : filterStateLocal?.[k]
              : undefined || undefined;
          }
        }
        if (Object.keys(filterApiState)?.length > 0) {
          const finalFilterApiState: any = {};
          const currentDate = new Date();

          if (grids[0]) {
            let timeFlag = "";
            let timeDiff = 0;
            let dateRange: any = {};

            const newdata = grids[0].configGroup?.find(
              (c: any) =>
                c.userGridId == selectedConfigId?.["paymentbeneficiary"]
            )?.columnConfigGrid;
            if (newdata) {
              timeFlag = JSON.parse(newdata).find(
                (item: any) => item.accessor == "invdt"
              )?.filterState;
              timeDiff = JSON.parse(newdata).find(
                (item: any) => item.accessor == "invdt"
              )?.timeDiff;
              dateRange = JSON.parse(newdata).find(
                (item: any) => item.accessor == "invdt"
              )?.dateRange;
            }
          }
          for (let i of Object.keys(filterApiState)) {
            if (filterApiState[i]) {
              finalFilterApiState[i] = filterApiState[i];
            }
          }
          if (tpoId) {
            setTimeout(() => {
              dispatch(
                getDashboardData({
                  gridName: "OPC_INVOICE_PMT_DASHBOARD",
                  payload: {
                    fields: [],

                    filters: /*   selectedTopSearchFilter?.where === ''
                          ? instructionTopSearchState
                          :  */ finalFilterApiState ? finalFilterApiState : {},
                    size: 20,
                    sorts: sort ? [sort] : ["identity_no desc"],
                    page: filterUpdated ? 1 : currentPage,
                  },
                  dataCountry: dataCountry,
                  partnerId: profileDetails.partnerId,

                  where: grids?.[0]?.whereClause
                    ? AESEncrypt(grids?.[0]?.whereClause)
                    : null,
                  parameter:
                    selectedTopSearchFilter?.where !== ""
                      ? {
                        searchParameter: searchInputValue,
                        searchWhere: selectedTopSearchFilter?.where,
                      }
                      : {},
                })
              );
            }, 150);
          }
        }
      }
    }
  };

  const showColumnSettings = useSelector(
    (state: RootState) => state.beneficiaryPayment.showColumnSettings
  );

  const getParams = () => {
    return {
      blid: blNum ? AESDecrypt(blNum) : "",
      pmtmode: blNum ? AESDecrypt(blNum) : "",
    };
  };

  useEffect(() => {
    dispatch(updateParams({}));
    dispatch(
      updateSortStateDataTable({
        key: null,
      })
    );
  }, [dataCountry]);

  /*   const sideWidth =
    Object.entries({
      [`/opc/paymentDashboard`]: 0,
      [`/blsliderpayment/:blNum`]: 6,
    }).find(([path, _]) => matchPath(path, location.pathname))?.[1] ?? 9; */

  return (
    <DashboardContainer
      // sideWidth={sideWidth}
      middleChildren={
        location.pathname.split("/")[1] == "blsliderinvoice" ? (
          <OblDetails
            blslider={`/blsliderinvoice/${location.pathname.split("/")[1]}`}
          />
        ) : (
          <div className={`flex-1 h-100 smoothDiv w-100`}>
            <Outlet />
          </div>
        )
      }
    >
      <FilterView
        // loadList={loadPaymentDashboard}
        showUploadButton
        showExport={true}
        showExchangeRate={false}
        additionalChildren={FilterViewAdditionalChildren}
        showColumnSettings={true}
        dataLoading={false}
        toggleColumnSettings={toggleColumnSettingsPayDash}
        columnSettingsPersistKey="@odex/paydash/v1"
        uniqueFilterKey="paymentbeneficiary"
        configViews={
          grids?.find?.((g: any) => g?.gridName === dashboardName)?.configGroup || []
        }
        showRightDiv={
          !!matchPath(location.pathname, "/opc/paymentDashboardReport") && !!dates.startDate && !!dates.endDate
        }
        dashboardName={dashboardName}
        data={data}
        gridDef={grids}
      />
      {columns?.length > 0 && (
        <DataTable
          data={data?.result || null}
          columns={columns}
          dataCountry={dataCountry || ""}
          sortable
          searchable={false}
          showColumnFilter={false}
          selection={false}
          perPage={20}
          pagination
          numberPagination
          maxPage={parseInt(data?.maxPage || "100")}
          hasNext={data?.hasNext}
          currentPage={currentPage}
          gridId={grids?.[0]?.gridId || 0}
          onChangePageNumber={(pageNumber) => setCurrentPage(pageNumber)}
          showExport={false}
          showColumnSettings={showColumnSettings}
          onHide={() => {
            dispatch(toggleColumnSettingsPayDash(false));
          }}
          columnSettingsPersistKey="@odex/paydash/v1"
          customSort={true}
          uniqueFilterKey="paymentbeneficiary"
          staticHiddenCols={configs?.staticHiddenCols || []}
          defaultHiddenCols={[]}
          tableMinHeight={400}
          gridName={dashboardName}
          dataLoading={false}
          configGroup={grids?.[0]?.configGroup || []}
        />
      )}
    </DashboardContainer>
  );
};

export default BeneficiaryReportDashboard;
