import classNames from "classnames";

import { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  Form,
  Row,
  Col,
  Button,
  InputGroup,
  Modal,
  Card,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import CreditBalance from "./CreditBalance";
import FileUpload from "./FileUpload";
import PayableAmount from "./PayableAmount";
import App from "./app";

import {
  paymentLoader,
  resetPaymentLoaders,
  setEditPayment,
  setPaymentStatus,
  updatePaymentMode,
  updatePymtModeOptions,
} from "./slices/blPaymentSlice";
import {
  toggleDocumentHistoryColumnShort,
  updateSelectedEventIdDH,
} from "../blDetails/slices/documentHistorySlice";
import { updateBlDetailsRowColumns } from "../blDetails/slices/blDetailsTable";
import {
  faAdd,
  faCancel,
  faCheck,
  faEdit,
  faEraser,
  faPlus,
  faRedo,
  faSave,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import Alert from "react-bootstrap/Alert";
import { enableBlPayment } from "../standingInstruction/slices/standingInstructionTable";
import {
  acceptPayment,
  addInvoiceToExistingPayment,
  cancelPayment,
  checkDuplicateReferenceNo,
  checkDuplicateReferenceNoByPymtId,
  editBankPymtRefNo,
  getCreditLimit,
  getPaymentBanks,
  getPymtDetailsByPymtNo,
  getPymtModeOptions,
  getPymtRefNo,
  rejectPayment,
  submitAutoPayment,
  submitPaymentAddFund,
  submitPaymentBanks,
  updateBankPymtRefNo,
  updatePaymentReferenceNo,
} from "./actions/payments";
import { toast } from "react-toastify";
import { getCreditBalance } from "../blRequestInstruction/components/actions/Instruction";
import { faArrowAltCircleLeft } from "@fortawesome/free-regular-svg-icons";
import {
  updateSelectedRowsInvoice,
} from "../multiBlPayment/slices/multiBlPaymentSlice";
import Lottie from "lottie-react";
import infiniteLoop from "../../../assets/img/animated-icons/infinite-loop.json";
import { Link } from "react-router-dom";
import { defineCustomElements, PaycargoCheckout } from "@paycargo/js-react";
import { getAppConfig } from "../../common/actions/userDetails";
import PaymentInvoiceList from "./PaymentInvoiceList";
import {
  updateDashbaordRefresh,
  updateSortStateDataTable,
} from "../../common/components/DataTable/slices/DataTable";
import { toggleMainLayout } from "../settings/slices/themeSettings";
import { AESDecrypt } from "../../../encrypt-util";
import { isEmpty } from "lodash";
import { useBackNavigation } from "../../../hooks/BackNavigation";
import { StandaloneInvoiceDetails } from "./slices/standaloneInvoicePaymentSlice";
import axiosInstance from "../../../axiosSetup";
import UploadIndicator from "../../../components/common/UploadIndicator";
import { AxiosResponse } from "axios";
import { response } from "msw";
import { number } from "yup";
import { tpoId } from "../blRequestInstruction/components/instructionSlice/AgentNominationSlice";
import InfoDiv from "../../../components/common/InfoDiv";

defineCustomElements();
const BlPayment = () => {
  const { t } = useTranslation();

  const paymentMode = useSelector(
    (state: RootState) => state.blPayment.paymentMode
  );
  const tpoId = useSelector((state: RootState) => state.agentDelegation.tpoId);

  const standaloneInvoiceList = useSelector(
    (state: RootState) => state.standaloneInvoice.data
  );
  const cancelPaymentLoader = useSelector(
    (state: RootState) => state.blPayment.cancelPaymentLoader
  );
  const [totalCreditLimit, setTotalCreditLimit] = useState();
  const [creditStatusSubtype, setCreditStatusSubtype] = useState<any>();
  const [creditBalance, setCreditBalance] = useState(0);
  const acceptPaymentLoader = useSelector(
    (state: RootState) => state.blPayment.acceptPaymentLoader
  );
  const rejectPaymentLoader = useSelector(
    (state: RootState) => state.blPayment.rejectPaymentLoader
  );
  const selectedRouter = useSelector(
    (state: RootState) => state.documentHistory.selectedRouter
  );
  const [showPaymentConfirm, setShowPaymentConfirm] = useState(false);
  const [platformfee, setPlatformfee] = useState<number>();
  const [platformChargeConfigId, setPlatformChargeConfigId] = useState("");
  const [selectedFileServerPath, setSelectedFileServerPath] = useState<
    Array<string | null>
  >([null, null]);
  const [loadingFileState, setLoadingFileState] = useState(0);

  const [paymentRefFocus, setpaymentRefFocus] = useState(false);
  const [rejectionMsg, setrejectionMsg] = useState<any>("");

  const [rejectValidation, setrejectValidation] = useState<any>(false);

  const [loading, setLoading] = useState<boolean>(false);

  const [showDuplicacy, setshowDuplicacy] = useState(false);

  const [duplicayID, setduplicayID] = useState<any>("");
  const currentDateTime = new Date().toISOString();
  const [showEditDuplicacy, setShowEditDuplicacy] = useState(false);
  const [showPaymentCancel, setPaymentCancel] = useState(false);
  const [editDuplicayID, setEditDuplicayID] = useState<any>("");
  const [editDuplicayMsg, seteditDuplicayMsg] = useState<any>("");
  const [methodName, setMethodName] = useState<any>("");
  const [showInsufficientCreditModal, setShowInsufficientCreditModal] =
    useState(false);

  const [selectedcurrency, setselectedcurrency] = useState<any>("USD");
  const [totalSelectedAmount, settotalSelectedAmount] = useState<any>([]);

  const [newPaymentRef, setNewPaymentRef] = useState(false);
  const [duplicayMsg, setduplicayMsg] = useState<any>("");
  const [selectedBlId, setselectedBlId] = useState<any>("");
  const amountDetails = useSelector(
    (state: RootState) => state.blPayment.amountDetails
  );

  const rowColumns = useSelector(
    (state: RootState) => state.blDetailsTable.rowColumns
  );

  const selecteCredData = useSelector(
    (state: RootState) => state.creditBalance.selectedCreditData
  );

  const linerId = useSelector((state: RootState) => state.navbarTop.linerId);

  const creditBalanceList = useSelector(
    (state: RootState) => state.creditBalanceBlPayment.data
  );

  const multiplePmtRef = useSelector(
    (state: RootState) => state.paymentBlPayment.multiplePmtRef
  );
  const pmtReftabledata = useSelector(
    (state: RootState) => state.paymentBlPayment.pmtReftabledata
  );
  const bankData = useSelector(
    (state: RootState) => state.blPayment.bankOptions
  );

  const pymtModeOptions = useSelector(
    (state: RootState) => state.blPayment.pymtModeOptions
  );
  const initialInvoiceDetailsPmtId = useSelector(
    (state: RootState) => state.paymentBlPayment.initialInvoiceDetailsPmtId
  );
  const tabledata = useSelector(
    (state: RootState) => state.documentHistory.data
  );
  const invoiceDashboardData = useSelector(
    (state: RootState) => state.dashboard.invoiceDashboardList
  );
  const paymentData = useSelector((state: RootState) => state.paymentBlPayment);
  const pmtRefNo = useSelector((state: RootState) => state.payment.pmtRefNo);

  const navigate = useNavigate();
  const location = useLocation();
  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );

  const partnerCountry = profileDetails.partnerCountry;

  const storedUserCountry = JSON.parse(
    window.localStorage.getItem("@user-selected-datacountry") || "{}"
  );

  const linerDataCountry = useSelector(
    (state: RootState) => state.userProfile.linerDataCountry
  );
  const storedCountry =
    storedUserCountry?.[0]?.loginId === profileDetails.email
      ? storedUserCountry?.[0]?.country
      : null;
  const dataCountry =
    storedCountry ?? linerDataCountry ?? profileDetails.partnerCountry ?? null;

  const { blNum } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const onBack = useBackNavigation();
  const validationFlag = useSelector(
    (state: RootState) => state.paymentBlPayment.validationFlag
  );

  const selectedRowsInvoice: any[] = useSelector(
    (state: RootState) => Object.values(state.multiBlPayment.selectedRowsInvoice).filter(x => x != undefined)
  );
  const [hideCredit, setHideCredit] = useState(false);
  const pymtRefNo = useParams()?.blId;
  useEffect(() => {
    dispatch(resetPaymentLoaders());
  }, []);


  useEffect(() => {
    if (location.pathname == "/opc/invoice/payment" && tpoId) {
      dispatch(getCreditLimit(tpoId))
        .unwrap()
        .then((response: any) => {
          /*   if (
            response.data.length !== 0 &&
            response.data.includes('creditLimit') &&
            response.data.includes('creditBalance')
          ) { */

          setTotalCreditLimit(response?.data.creditLimit);
          setCreditStatusSubtype(response?.data.creditStatusSubtype);
          setCreditBalance(response?.data.creditBalance);
          if (
            ((creditStatusSubtype === "Non-Prepaid" &&
              response?.data.statusCode !== 400) ||
              response?.data.creditLimit) &&
            response?.data.creditBalance
          ) {
            //dispatch(updatePaymentMode("2"));
            setHideCredit(true);
          } else if (
            (creditStatusSubtype === "Prepaid" &&
              response?.data.statusCode !== 400) ||
            response?.data.creditLimit ||
            response?.data.creditBalance
          ) {
            //dispatch(updatePaymentMode("4"));
            setHideCredit(true);
          } else {
            //alert(1)
            dispatch(updatePaymentMode("1"));
          }

          //   }
        });
    }
  }, [creditStatusSubtype, tpoId]);

  const getComparisonStatus = () => {
    var totAmt = (
      totalSelectedAmount.find(
        (item: any) => item.currencyCode === selectedcurrency
      ) || { amount: "" }
    ).amount;

    if (typeof totAmt === "undefined") return null;
    if (creditBalance >= totAmt) {
      return <span style={{ color: "green" }}>Sufficient</span>;
    } else {
      //updatePaymentMode("1");
      return (
        <div style={{ width: "5.5rem" }}>
          <InfoDiv
            title={"Click to view steps to credit account"}
            onClick={() => {
              setShowInsufficientCreditModal(true);
            }}
          >
            <span style={{ color: "#e63757" }}>Insufficient</span>
          </InfoDiv>
        </div>
      );
    }
  };
  const matchPth =
    matchPath(
      {
        ///bl/:blNum/payment/:pymtRefNo/:pmtMode
        path: "/bl/:blNum/payment/:pymtRefNo",
        caseSensitive: true,
        end: true,
      },
      location.pathname
    ) ||
    matchPath(
      {
        path: "instruction/bl/:blNum/payment/:pymtRefNo",
        caseSensitive: true,
        end: true,
      },
      location.pathname
    ) ||
    matchPath(
      {
        path: "payment/bl/:blNum/payment/:pymtRefNo",
        caseSensitive: true,
        end: true,
      },
      location.pathname
    );

  function extractValuesFromUrl(url: any) {
    const params = new URLSearchParams(url);
    const sortParam = params.get("sort");

    if (sortParam) {
      const arr: any = sortParam.split("%2C");
      return arr;
    } else {
      return [];
    }
  }

  useEffect(() => {
    if (window.location.pathname.includes("blsliderpayment")) {
      const newval: any = invoiceDashboardData.result.find(
        (item: any) => +item.pmtmode === +AESDecrypt(blNum)
      );
      setselectedBlId(newval?.blid);
    } else {
      setselectedBlId(AESDecrypt(blNum));
    }
  }, [blNum]);

  const [filteredArrayData, setFilteredArrayData] = useState<any>([]);

  useEffect(() => {
    invoiceDashboardData.result?.forEach((d: any, i: any) => {
      if (paymentData.pmtNumberdata.map((v: any) => v.invNo).includes(d.invno))
        filteredArrayData.push(d.pmtreference);
    });
    setFilteredArrayData(filteredArrayData);
  }, [invoiceDashboardData, paymentData]);
  useEffect(() => {
    dispatch(setEditPayment(false));
    dispatch(updatePaymentMode("1"));
    dispatch(setPaymentStatus(""));
  }, []);



  const [resp, setResp] = useState<AxiosResponse<any, any> | null>(null);
  /*   useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(
          `api/invoice-management/v1/payment/getPaymentMethodByConfig?countryCode=SG&currency=SGD&linerId=1&pmtMode=4`
        );
        setResp(response);
      } catch (error) {
        console.error('Error:', error);
      }
    };
    fetchData();
  }, []); */
  useEffect(() => {
    if (modalCloseFocus.current) {
      setTimeout(() => {
        if (modalCloseFocus.current) modalCloseFocus.current.focus();
      }, 500);
      // modalCloseFocus.current.focus();
    }
  }, [showPaymentConfirm]);

  useEffect(() => {
    if (paymentRefFocus)
      if (modalCloseFocus.current) {
        setTimeout(() => {
          if (modalCloseFocus.current) modalCloseFocus.current.focus();
        }, 500);
        // modalCloseFocus.current.focus();
      }
  }, [paymentRefFocus]);

  useEffect(() => {
    dispatch(
      getPymtModeOptions({
        partnerCountry: "TZ",
        selectedLinerId: tpoId,
      })
    );
  }, []);
  const [selectedTransactions, setSelectedTransactions] = useState<any[]>([]);


  const paycargoRef = useRef<any | null>(null);
  const modalCloseFocus = useRef<any>(null);
  const totalAmtVal = useRef<any>(null);

  let paycargoInvoiceList = [{}];

  const [integrationResponse, setIntegrationResponse] = useState("");

  useEffect(() => {
    if (paymentMode == 6) {
      const hideLayout = () => {
        dispatch(toggleMainLayout(false));
        const errorMessage = paycargoRef?.current?.shadowRoot
          ?.querySelectorAll("div")[0]
          ?.getElementsByClassName("hydrated")?.[0]
          ?.shadowRoot?.querySelectorAll("div")?.[0]
          ?.getElementsByClassName("toast error")?.[0]?.innerText;
        if (errorMessage !== undefined) dispatch(toggleMainLayout(true));
      };
      const listener = (event: any) => {
        if (event.detail.success) {
          paycargoInvoiceList = event.detail.data.success.approve.map(
            (item: any) => ({ invNo: item.number, invAmt: item.totalAmount })
          );
          setIntegrationResponse(JSON.stringify(event));
          handleSubmit();
        }
        setTimeout(() => dispatch(toggleMainLayout(true)), 150);
      };

      paycargoRef?.current?.addEventListener("close", listener);
      paycargoRef?.current?.addEventListener("paymentResponse", listener);
      paycargoRef?.current?.addEventListener("click", hideLayout);

      return () => {
        paycargoRef?.current?.removeEventListener("close", listener);
        paycargoRef?.current?.removeEventListener("paymentResponse", listener);
        paycargoRef?.current?.removeEventListener("click", hideLayout);
      };
    }
  }, [paycargoRef, paymentMode]);

  // useEffect(() => {
  //   debugger;
  //   if (paycargoInvoiceList.length > 0 && paymentMode == 6) {
  //     handleSubmit();
  //   }
  // }, [paycargoInvoiceList]);

  const disableFields =
    /*   profileDetails?.partnerType === 'liner' ||
    (matchPth && profileDetails?.partnerType !== 'liner') */
    //||
    location.pathname.includes("blsliderpayment") ? true : false;

  const leftScroll0 = () => {
    setTimeout(() => {
      const simplebarRef = document.getElementsByClassName(
        "simplebar-content-wrapper"
      );

      if (simplebarRef[0]?.scrollLeft > 0)
        simplebarRef[0]?.scrollTo({ left: 0, behavior: "smooth" });
    }, 1500);
  };

  const _onCloseForm = () => {
    onBack();
    /*  setTimeout(() => {
      onBack();
 
      leftScroll0();
    }, 500); */

    // don't use -1, it breaks when you switch between payments and then press back
    //navigate(-1);
  };

  const onChangePaymentMode = (e: ChangeEvent<HTMLSelectElement>) => {
    dispatch(updatePaymentMode(e.target.value));
  };

  const selectedDocHistory: any = useSelector(
    (state: RootState) => state.documentHistory.data
  );

  const [selected, setSelected] = useState(bankData[0]?.value);
  const [hideEditAction, setHideEditAction] = useState(false);
  const [bnkName, setBnkName] = useState(bankData[0]?.bankName || "");
  const [acName, setAcName] = useState(bankData[0]?.accountNo || "");
  const [inputValue, setInputValue] = useState("");
  const [inputValueTemp, setInputValueTemp] = useState<any>("");

  const [radioValue, setRadioValue] = useState("auto");
  const [paymentAmount, setPaymentAmount] = useState("");
  const [pmtId, setPmtId] = useState(0);

  useEffect(() => {
    if (
      !disableFields &&
      radioValue === "auto" &&
      inputValueTemp?.trim() === ""
    ) {
      dispatch(
        getPymtRefNo({
          partnerCountry,
        })
      );
    }
  }, [radioValue, partnerCountry]);
  useEffect(() => {
    if (radioValue === "auto") {
      if (pmtRefNo) setInputValue(pmtRefNo);
      setInputValueTemp(pymtRefNo);
    }
  }, [pmtRefNo, radioValue]);

  useEffect(() => {
    const newval = pmtReftabledata.reduce(
      (sum: any, obj: { invAmt: string }) =>
        sum +
        (typeof obj.invAmt === "string" ? parseFloat(obj.invAmt) : obj.invAmt),
      0
    );
    totalAmtVal.current = newval;
    setselectedTotal(newval);
  }, [pmtReftabledata]);
  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelected(e.target.value);
    setBnkName(
      e.target.value
        ? bankData.find((v: any) => v.value == e.target.value)?.bankName || ""
        : ""
    );
    setAcName(
      e.target.value
        ? bankData.find((v: any) => v.value == e.target.value)?.accountNo || ""
        : ""
    );
  };

  const pymtMode = useSelector((state: RootState) => state.blPayment.pymtMode);
  const paymentLoaderCheck = useSelector(
    (state: RootState) => state.blPayment.paymentSubmitLoader
  );
  const pymtNo = useSelector((state: RootState) => state.blPayment.pymtNo);
  const editPayment: any = useSelector(
    (state: RootState) => state.blPayment.editPayment
  );
  const bnkNm = useSelector((state: RootState) => state.blPayment.bnkNm);
  const accNo = useSelector((state: RootState) => state.blPayment.accNo);
  const pymtAmt = useSelector((state: RootState) => state.blPayment.pymtAmt);
  const pymtCurr = useSelector((state: RootState) => state.blPayment.pymtCurr);
  const localCurr = useSelector((state: RootState) => state.blPayment.localCurr)
  const localAmt = useSelector((state: RootState) => state.blPayment.localAmt)
  const [byteArr, setByteArr] = useState("");
  const [paymentProofFileName, setPaymentProofFileName] = useState("");
  const payorAccountId = useSelector(
    (state: RootState) => state.blPayment.payorAccountId
  );

  const radioVal = useSelector(
    (state: RootState) => state.blPayment.radioValue
  );
  const pmtStatus = useSelector(
    (state: RootState) => state.blPayment.pmtStatus
  );

  const pmtMode = useSelector((state: RootState) => state.blPayment.pymtMode);
  const linerRemarks = useSelector(
    (state: RootState) => state.blPayment.linerRemarks
  );

  const pymtId = useSelector((state: RootState) => state.blPayment.pmtId);
  const fileName = useSelector((state: RootState) => state.blPayment.fileName);

  const selectedLinerId = useSelector(
    (state: RootState) => state.userProfile.selectedLinerId
  );

  useEffect(() => {
    //if (location.pathname.split('/')[1] === 'blsliderpayment'){

    if (location.pathname !== "/opc/invoice/payment") {
      dispatch(
        getPymtDetailsByPymtNo({
          pymtId:
            location.pathname.split("/")[1] === "blsliderpayment"
              ? location.pathname.split("/")[2]
              : pymtRefNo,
        })
      );
    }

    // //} else if(location.pathname.includes('payment')){
    //   dispatch(getPymtDetailsByPymtNo({
    //     pymtNo: location.pathname.split('/')[4]
    //   }));
    // }
  }, [pymtNo, location.pathname]);
  useEffect(() => {
    /*  dispatch(
      getCreditBalance({
        selectedLinerId:
          profileDetails.partnerType === 'liner'
            ? profileDetails?.partnerId
            : selectedLinerId,
      })
    ); */
    dispatch(paymentLoader(false));
  }, []);

  useEffect(() => {
    if (disableFields) {
      pymtMode !== ""
        ? dispatch(updatePaymentMode(pymtMode))
        : dispatch(updatePaymentMode(pymtModeOptions[0].value));
      pymtNo !== "" ? setInputValue(pymtNo) : setInputValue("");
      pymtAmt !== "" ? setPaymentAmount(pymtAmt) : setPaymentAmount("");
      pymtCurr !== "" ? setSelectedCcy(pymtCurr) : setSelectedCcy("USD");
      pymtId ? setPmtId(pymtId) : setPmtId(0);
      if (payorAccountId) {
        setSelected(payorAccountId);
        setBnkName(bnkNm);
        setAcName(accNo);
        setRadioValue(radioVal);
      } else {
        setSelected(bankData[0]?.value);
        setBnkName(bankData[0]?.bankName || "");
        setAcName(bankData[0]?.accountNo || "");
        setRadioValue("auto");
      }
    } else {
      setSelected(bankData[0]?.value);
      setBnkName(bankData[0]?.bankName || "");
      setAcName(bankData[0]?.accountNo || "");
      setRadioValue("auto");
    }
  }, [pymtNo, bankData, pymtCurr]);

  const handleDuplicacy = () => {
    var newSelectedData: any = [];


    dispatch(
      addInvoiceToExistingPayment({
        invoices: newSelectedData,
        pmtId: duplicayID,
        paymentFundings: [],
        paymentMode: paymentMode,
        paymentRefNo: /*  paymentMode == 2 ? '' : */ inputValue,
        currency: "USD",
        payorAccountId: pymtMode == 1 ? selected : "",
        linerId: linerId,
        pmtAmount: amountDetails,
        bankRefNo: radioValue != "auto" ? inputValue : "",
        pmtProofAtt: byteArr,
        paymentProofFileName: paymentProofFileName,
        serverFilePath: selectedFileServerPath[0],
        tempFileName: selectedFileServerPath[1],
      })
    );

    setTimeout(() => {
      navigate("/payment");
      dispatch(updateSelectedRowsInvoice({}));
    }, 500);
  };

  const [showConfirmMsg, setShowConfirmMsg] = useState(false);

  const checkValidationAndUpdate = async () => {
    var filteredArray: any = [];

    const duplicateFlag = await dispatch(
      checkDuplicateReferenceNoByPymtId({
        inputValue,
        selectedCcy: pymtCurr,
        selectedPymtId: pymtId,
        pymtAmt: formatNumber(paymentAmount),
      })
    );

    if (duplicateFlag?.payload == true) {
      if (editPayment) {
        if (pmtReftabledata.length == 0) {
          setShowConfirmMsg(true);
        } else if (pmtNumberdata > pmtReftabledata) {
          setShowConfirmMsg(true);
        } else {
          handleUpdate();
        }
      }
    } else {
      if (duplicateFlag?.payload != undefined) {
        setShowEditDuplicacy(true);
        setpaymentRefFocus(false);
        //setduplicayID(duplicateFlag.payload);
        setEditDuplicayID(Object.keys(duplicateFlag?.payload)[0]);
        seteditDuplicayMsg(Object.values(duplicateFlag?.payload)[0]);
        if (
          Object.values(duplicateFlag?.payload)[0] ==
          "Payment Reference No. already exists."
        ) {
          setMethodName("handleUpdate");
        }
      }
    }
  };

  const handleUpdate = () => {
    var filteredArray: any = [];
    invoiceDashboardData.result?.forEach((d: any, i: any) => {
      if (paymentData.pmtNumberdata.map((v: any) => v.invNo).includes(d.invno))
        filteredArray.push(d.pmtReference);
    });

    dispatch(
      editBankPymtRefNo({
        invoices: pmtReftabledata.map((item: any) => ({
          invId: item.invid ? item.invid : item.invId,
          invNo: item.invNo,
          invAmt: item.invAmt,
        })),

        pmtId: pmtId,
        paymentFundings: [],
        paymentMode: paymentMode,
        paymentRefNo: /*  paymentMode == 2 ? '' : */ inputValue,
        currency: selectedCcy,
        payorAccountId: pymtMode == 1 ? selected : "",
        linerId: linerId,
        pmtAmount: pmtReftabledata.reduce(
          (sum: any, obj: { invAmt: string }) =>
            sum +
            (typeof obj.invAmt === "string"
              ? parseFloat(obj.invAmt)
              : obj.invAmt),
          0
        ),
        bankRefNo: radioValue != "auto" ? inputValue : "",
        pmtProofAtt: byteArr,
        paymentProofFileName: paymentProofFileName,
        refType: radioValue === "auto" ? 1 : 2,
        serverFilePath: selectedFileServerPath[0],
        tempFileName: selectedFileServerPath[1],
      })
    )
      .unwrap()
      .then((response: any) => {
        if (response !== "") {
          setTimeout(() => {
            if (window.location.pathname.includes("blsliderpayment")) {
              //  navigate('/payment');
              navigate("opc/paymentDashboard");
              dispatch(updateSelectedRowsInvoice({}));
            } else {
              navigate("opc/home");
            }
          }, 1500);
        }
      });
    // setTimeout(() => {
    //   navigate(-1);
    // }, 500);
  };
  const initialOptions = {
    clientId:
      "ASNtyCWcwVoRDlSRjWBrdPdJC9zcX-84PrbiSAjoqz037_rlOkTm-2ti8k-TdP4RxcP48XIu60fPxkjE",
    currency: "USD",
    intent: "capture",
  };

  const handleSubmit = async () => {
    //dispatch(updateDashbaordRefresh(true));

    const convertedPayload = selectedRowsInvoice.map((item) => ({
      payee: item.payee,
      payeeName: item.payeeName,
      effectiveDate: item.effectiveDate.split("-").reverse().join("-"),
      currencyExchangeDate: item.currencyExchangeDate,
      invNo: item.draftNo,
      finalInvNo: item.finalNo,
      invCurr: item.ccy,
      invAmt: parseFloat(item.owed),
      paidAmt: parseFloat(item.paid),
      countryCode: profileDetails.partnerCountry,
      localCcy: "TZS",
      localAmt: parseFloat(item.amountLocal),
      discountAmt: parseFloat(item.discounts),
      owed: parseFloat(item.owed),
      credits: parseFloat(item.credits),
      creditTaxes: parseFloat(item.creditTaxes),
      taxes: parseFloat(item.taxes),
    }));

    const modifiedPayload = {
      bankRefNo: "",
      currency: "USD",
      integrationResponse: "",
      invoiceNavisInputs: convertedPayload,
      linerId: tpoId,
      localAmount: (
        totalSelectedAmount.find(
          (item: any) => item.currencyCode === "TZS"
        ) || { amount: "" }
      ).amount,
      localCcy: "TZS",
      paymentFundings: [],
      paymentMode: paymentMode,
      paymentRefNo: inputValue,
      payorAccountId: selected,
      pmtAmount: (
        totalSelectedAmount.find(
          (item: any) => item.currencyCode === "USD"
        ) || { amount: "" }
      ).amount,
      pmtProofAtt: "",
      paymentProofFileName: paymentProofFileName,
      serverFilePath: selectedFileServerPath[0],
      tempFileName: selectedFileServerPath[1],
      refType: radioValue === "auto" ? 1 : 2,
      creditBalance: creditBalance,
    };

    dispatch(submitPaymentBanks(modifiedPayload))
      .unwrap()
      .then((response: any) => {
        if (response?.data?.message) {
          if (response?.data?.success == "true") {
            toast.success(response.data.message, {
              theme: "colored",
              toastId: 'submitPaymentBanksSuccess'
            });
          } else {
            toast.error(response.data.message, {
              theme: "colored",
              toastId: 'submitPaymentBanksError'
            });
          }
          /*  setTimeout(() => {
            navigate('/opc/invoice');
          }, 3000); */
        } else {
          toast.error("Something went wrong", {
            theme: "colored",
            toastId: 'submitPaymentBanksError'
          });
        }
        setTimeout(() => {
          navigate("/opc/home");
          dispatch(updateDashbaordRefresh(true));
          dispatch(updateSelectedRowsInvoice({}));
          dispatch(paymentLoader(false));
        }, 2000);
      })
      .catch((error) => {

        console.error("errorrrs", error.message);
        toast.error(`${Object.values(error?.response?.data?.errors)[0]}`, {
          theme: "colored",
          toastId: 'handleSubmitToastError'
        });
        dispatch(paymentLoader(false));
      });
    // }

  };

  const [bankRefNoLiner, setBankRefNoLiner] = useState("");

  const [selectedCcy, setSelectedCcy] = useState("USD");
  const [selectedTotal, setselectedTotal] = useState("");

  const selectedCreditAmount = selecteCredData
    .map((i: any) => Number(i.unAllocatedAmount))
    .reduce((partialSum: any, a: any) => partialSum + a, 0);
  const totalCreditAmount = creditBalanceList
    ?.map((i: any) => Number(i.unAllocatedAmount))
    .reduce((partialSum: any, a: any) => partialSum + a, 0);

  useEffect(() => {
    if (
      selectedCreditAmount > 0 &&
      amountDetails > 0 &&
      selectedCreditAmount > amountDetails
    ) {
      // toast.error('Selected Invoice amount is greater than the Credit Balance');
    }
  }, [selecteCredData, amountDetails]);


  // const formatNumber = (value: string): string => {
  //   const parsedValue = parseFloat(value);
  //   if (isNaN(parsedValue)) return value; // Return the original value if it's not a valid number

  //   const roundedValue = parseFloat(parsedValue.toFixed(2)); // Round to two decimal places
  //   const [integerPart, decimalPart] = roundedValue.toString().split('.');
  //   const formattedInteger = parseInt(integerPart, 10).toLocaleString();
  //   const formattedDecimal = decimalPart ? `.${decimalPart}` : '';
  //   return `${formattedInteger}${formattedDecimal}`;
  // };

  const formatNumber = (value: string): string => {
    const numericValue = parseFloat(value);

    if (isNaN(numericValue)) {
      return "Invalid Number";
    }

    const trimmedValue = numericValue.toFixed(2);

    const numberWithCommas = trimmedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return numberWithCommas;
  };
  const formatNumber2 = (value: string): string => {
    const parsedValue = parseFloat(value);
    if (isNaN(parsedValue)) return value; // Return the original value if it's not a valid number

    const roundedValue = parseFloat(parsedValue.toFixed(2)); // Round to two decimal places
    const [integerPart, decimalPart] = roundedValue.toString().split(".");
    const formattedInteger = parseInt(integerPart, 10).toLocaleString();
    const formattedDecimal = decimalPart ? `.${decimalPart}` : "";
    return `${formattedInteger}${formattedDecimal}`;
  };
  //const [editPayment, setEditPayment] = useState(false);

  const rbac = useSelector((state: RootState) => state.userProfile);
  const popconfig: any = rbac.rbacDetails.paymentProofAttachment ? {
    "1": {
      auto: 'O',
      generate: 'M'
    }
  } : {}
  const pmtNumberdata = useSelector(
    (state: RootState) => state.paymentBlPayment.pmtNumberdata
  );

  useEffect(() => {
    if (creditBalance) {
      if (creditStatusSubtype == "Non-Prepaid") {
        dispatch(updatePaymentMode("2"));
      }
      if (creditStatusSubtype == "Prepaid") {
        dispatch(updatePaymentMode("4"));
      }
    }
  }, [creditStatusSubtype]);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];

      if (file.size > 10 * 1000000) {
        toast.error(
          `File size is greater than ${Math.round(
            10
          )}MB. Please upload file below ${Math.round(
            10
          )}MB`,
          {
            toastId: 'handleFileChangeToastError'
          }
        );
        e.target.value = "";
        return;
      }
      let fileExt = file.name.slice(-4);

      if (fileExt.toLowerCase() != ".pdf") {
        toast.error("Please upload Only PDF File", { toastId: 'handleFileChangeError' });
        e.target.value = "";
        return;
      }

      const formData = new FormData();
      formData.append("file", file);

      try {
        setSelectedFileServerPath([null, null]);
        setLoadingFileState(0);
        axiosInstance
          .post("/api/opc-management/v1/view/file", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress(progressEvent) {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / (progressEvent.total ?? 1)
              );
              setLoadingFileState(percentCompleted);
            },
          })
          .then((response) => {
            setLoadingFileState(100);
            setSelectedFileServerPath([
              response.data.path,
              response.data.tempFileName,
            ]);
          })
          .catch(() => {
            setLoadingFileState(-1);
          });
      } catch (e) {
        console.error("File Upload Error: ", e);
      }
      setPaymentProofFileName(file.name);
    }
    // const buffer = await file.arrayBuffer();
    // const bytes = new Uint8Array(buffer);
    // setByteArr(bytes.toString());
  };

  // const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  // useEffect(() => {
  //   if (+selectedTotal < 0) {
  //     setDisableSubmitButton(true);
  //   } else {
  //     setDisableSubmitButton(false);
  //   }
  // }, [selectedTotal]);
  interface MyComponentProps {
    paymentMode: number; // Assuming paymentMode is a number, adjust type as needed
    pmtStatus: string; // Assuming pmtStatus is a string, adjust type as needed
  }
  const MyComponent: React.FC<MyComponentProps> = ({
    paymentMode,
    pmtStatus,
  }) => {
    // Define onSuccess function here if needed

    return (
      <>
        {paymentMode === 7 && pmtStatus !== "1" && pmtStatus !== "Paid" && (
          <div style={{ zIndex: 500 }}>
            <p />
            <PayPalScriptProvider deferLoading={true} options={initialOptions}>
              <PayPalButtons style={{ layout: "horizontal" }} />
            </PayPalScriptProvider>
          </div>
        )}
      </>
    );
  };
  const initAmt = paymentAmount;

  // const platAmt = (0.0523 * parseFloat(paymentAmount)).toString();
  /*   useEffect(() => {
    if (
      paymentAmount &&
      profileDetails.partnerCountry !== "" &&
      pymtCurr !== "" &&
      pymtMode !== ""
    )
      try {
        axiosInstance
          .get(
            ` ?pmtAmount=${paymentAmount}&countryCode=US&currency=${selectedCcy}&linerId=1&pmtMode=7`
          )
          .then((response) => {
            setPlatformfee(+response.data.totalPlatformCharge);
            setPlatformChargeConfigId(
              `${response.data?.platformChargeConfigId ?? ""}`
            );
            setTotamt(
              platformfee
                ? parseFloat(initAmt) + platformfee
                : parseFloat(initAmt)
            );
          });
      } catch (error) {
        console.error("Error:", error);
      }
  }, [paymentAmount, platformfee]); */
  return (
    <div className=" h-100">
      <div className="border card" style={{ minHeight: "100%" }}>
        <Card.Header
          className="d-flex align-items-center border-bottom sticky-top bg-white"
          style={{ zIndex: 2 }}
        >
          <FontAwesomeIcon
            style={{ position: "relative", left: "0" }}
            icon={faArrowAltCircleLeft}
            className="cursor-pointer fa-lg "
            onClick={_onCloseForm}
          />
          {window.location.pathname === "/opc/invoice/payment" && (
            <h2 className="fs-0 text-primary ms-2 p-0 m-0">
              {t("resource_2:paymentDetails")}
            </h2>
          )}
          {window.location.pathname !== "/opc/invoice/payment" && (
            <h2 className="fs-0 text-primary ms-2">
              {t("resource_2:paymentDetails")}
            </h2>
          )}
          &nbsp;&nbsp;&nbsp;&nbsp;
        </Card.Header>

        <div className="card-body">
          {disableFields ? (
            <>
              {
                /*  rbac.rbacDetails.showPlatformFees == 'Y'  */ paymentMode ==
                7 && (
                  <>
                    <Form.Label
                      column
                      lg={3}
                      className="fs--1"
                      style={{ marginBottom: "-5rem", fontSize: "22px" }}
                    >
                      {t("Total Amount:")}
                    </Form.Label>
                    <span style={{ fontSize: "14px", marginLeft: "0rem" }}>
                      {window.location.pathname.includes("blsliderpayment")
                        ? `${selectedCcy} ${formatNumber(initAmt)}`
                        : `${selectedCcy} ${formatNumber(initAmt)}`}
                    </span>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "18px",
                      }}
                    >
                      <Form.Label column lg={3} className="fs--1">
                        {t("Platform Fee:")}
                      </Form.Label>
                      <span style={{ fontSize: "14px", marginLeft: "0rem" }}>
                        {window.location.pathname.includes("blsliderpayment")
                          ? `${selectedCcy} ${platformfee}`
                          : `${selectedCcy} ${platformfee}`}
                        <a
                          href={`${process.env.REACT_APP_CLIENT_APP_HOST}/api/opc-management/v1/payment/getInvoicePdf/${pymtId}`}
                          target="_blank"
                          style={{ marginLeft: "0.7rem", fontSize: "12px" }}
                        >
                          Invoice
                        </a>
                      </span>
                    </div>
                  </>
                )
              }
              <Form.Group as={Row} controlId="payableAmount.input">
                <Form.Label column lg={3} className="fs--1">
                  {t("resource_2:payableAmount")}
                </Form.Label>
                {/* <Col lg={7}>
                  <InputGroup>
                    <Col lg={5}>
                      <Form.Control
                        value={selectedCcy}
                        disabled={true}
                      ></Form.Control>
                    </Col>
                    <Col lg={7}>
                      <Form.Control
                        aria-label={t("resource_2:payableAmount")}
                        aria-describedby="payable-amount"
                        value={
                          window.location.pathname.includes("blsliderpayment")
                            ? //totAmt
                              initAmt
                            : //totAmt
                              initAmt
                        }
                        disabled={disableFields}
                      />
                    </Col>
                  </InputGroup>
                </Col> */}
                <Col lg={6}>
                  <div className='d-flex py-1 '>
                    <div className="pe-5">
                      <p className="text-600 fs--1 fw-medium mb-0">
                        {selectedCcy}
                      </p> <h4 className="text-800 mb-0">{initAmt}  </h4>
                    </div>
                    <div className="pe-5">
                      <p className="text-600 fs--1 fw-medium mb-0">
                        {localCurr}
                      </p> <h4 className="text-800 mb-0">{localAmt}  </h4>
                    </div>
                  </div>
                </Col>
              </Form.Group>
            </>
          ) : (
            <>
              <Row style={{ marginTop: "-5px", marginBottom: "10px" }}>
                <Col>
                  <div style={{ color: "#4682B4", fontWeight: "bold" }}>
                    Change the currency in case the invoices to be paid in Local
                    Currency (TZS)
                  </div>
                </Col>
              </Row>
              <PayableAmount
                settotalSelectedAmount={settotalSelectedAmount}
                totalSelectedAmount={totalSelectedAmount}
                setselectedcurrency={setselectedcurrency}
                selectedcurrency={selectedcurrency}
              />
              <br></br>
              {hideCredit &&
                paymentMode === "2" &&
                selectedcurrency === "USD" &&
                creditStatusSubtype == "Non-Prepaid" && (
                  <div>
                    <Form.Group as={Row} controlId="payableAmount.input">
                      <Form.Label
                        column
                        lg={3}
                        className="fs--1"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Total Credit Limit (USD)
                      </Form.Label>

                      <Col lg={2}>
                        {/*   <Form.Text className='form-control-sm text-end'>
                        {totalCreditLimit}
                      </Form.Text> */}
                        <Form.Control
                          value={totalCreditLimit}
                          className="form-control-sm text-end"
                          disabled
                        />
                      </Col>
                    </Form.Group>
                    <div className="mb-2"></div>{" "}
                    {/* Adjust the margin-bottom as needed */}
                    <Form.Group as={Row} controlId="payableAmount.input">
                      <Form.Label
                        column
                        lg={3}
                        className="fs--1"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Credit Balance (USD)
                      </Form.Label>

                      <Col lg={2}>
                        <Form.Control
                          value={creditBalance}
                          className="form-control-sm text-end"
                          disabled
                        />
                        {/*        <Form.Text className='form-control-sm text-end'>
                        {creditBalance}
                      </Form.Text> */}
                        {/*          {getComparisonStatus()} */}
                      </Col>
                      <Col lg={5} /* className='align-self-center' */>
                        {getComparisonStatus()}
                      </Col>
                    </Form.Group>
                  </div>
                )}
              {hideCredit &&
                paymentMode === "4" &&
                selectedcurrency === "USD" &&
                creditStatusSubtype == "Prepaid" && (
                  <div>
                    <Form.Group as={Row} controlId="payableAmount.input">
                      <Form.Label
                        column
                        lg={3}
                        className="fs--1"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Prepaid Balance (USD)
                      </Form.Label>

                      <Col lg={2}>
                        <Form.Control
                          value={creditBalance}
                          className="form-control-sm text-end"
                          disabled
                        />
                        {/*        <Form.Text className='form-control-sm text-end'>
                        {creditBalance}
                      </Form.Text> */}
                        {/*          {getComparisonStatus()} */}
                      </Col>
                      <Col lg={5} /* className='align-self-center' */>
                        {getComparisonStatus()}
                      </Col>
                    </Form.Group>
                  </div>
                )}
            </>
          )}
          <hr />

          <div>
            {pmtId != 0 && (
              <PaymentInvoiceList
                selectedCcy={selectedCcy}
                pmtID={pymtId}
                inputValue={inputValue}
              />
            )}
          </div>

          <Form.Group className="pb-1 pe-1 ps-3" controlId="blPayment.pmtId">
            <Form.Control type="hidden" className="fs--1" value={pmtId} />
          </Form.Group>
          <Form.Group className="pb-3 pe-0 ps-0" controlId="blPayment.input1">
            <Form.Label className="fs--1">
              {t("resource_2:paymentMode")}
            </Form.Label>

            <Form.Select
              aria-label="Payment Mode"
              className={`${classNames({ "text-400": !paymentMode })} fs--1`}
              onChange={onChangePaymentMode}
              value={paymentMode}
              disabled={disableFields}
            >
              {pymtModeOptions
                .filter(
                  ({ value }: any) =>
                    (![2, 4].includes(value) || selectedcurrency == "USD") &&
                    (value != 2 ||
                      (creditStatusSubtype == "Non-Prepaid" && creditBalance)) &&
                    (value != 4 ||
                      (creditStatusSubtype == "Prepaid" && creditBalance)
                    )
                )
                .map((option: any) => (
                  <option
                    className="text-dark"
                    key={option.value}
                    value={option.value}
                  >
                    {option.text}
                  </option>
                ))}
              {disableFields &&
                pymtModeOptions.map((option: any) => (
                  <option
                    className="text-dark"
                    key={option.value}
                    value={option.value}
                  >
                    {option.text}
                    {option.value == 2 && totalCreditAmount
                      ? " -- USD " +
                      formatNumber(totalCreditAmount) +
                      "(" +
                      creditBalanceList.length +
                      ")"
                      : ""}
                  </option>
                ))}

              {/*  <option className='text-dark' value='1'>
                Bank Transfer
              </option>
              <option className='text-dark' value='2'>
                Credit Balance
              </option>
              <option className='text-dark' value='3'>
                PayCargo
              </option> */}
            </Form.Select>
          </Form.Group>

          {paymentMode == 6 && profileDetails.partnerType === "customer" ? (
            <div
              className="co-lg-12"
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                color: "#f5822a",
              }}
            >
              {" "}
              There can be additional charges applicable by Paycargo as a part
              of their platform utilization fee{" "}
            </div>
          ) : (
            ""
          )}

          {paymentMode ? (
            <>
              {/*   added2 */}
              {paymentMode == 1 ||
                paymentMode == 3 ||
                paymentMode == 2 ||
                paymentMode == 4 ? (
                <>
                  <Form className="p-0">
                    {paymentMode == 1 || paymentMode == 3 ? (
                      <>
                        <Row className="mb-3">
                          <div className="pb-0 pe-3 ps-3 mt-3">
                            {bankData.length == 0 && profileDetails.opcPartnerType!='PTO' ? (
                              <span>
                                <p
                                  style={{
                                    fontSize: "12px",
                                    marginBottom: "0px",
                                  }}
                                >
                                  To add Bank Accounts please
                                  <Link to={"/opc/user/profile"}>
                                    {" click here"}
                                  </Link>
                                </p>
                              </span>
                            ) : (
                              <></>
                            )}
                            {!disableFields && (
                              <Form.Group controlId="blPayment.input2">
                                <Form.Select
                                  aria-label="Bank Name"
                                  className="fs--1"
                                  disabled={disableFields}
                                  value={selected}
                                  onChange={handleChange}
                                >
                                  {
                                    /*   {disableFields
                                ? optionsDummy.map((option: any) => (
                                    <option
                                      key={option.value}
                                      value={option.value}>
                                      {option.text}
                                    </option>
                                  ))
                                : */ //}

                                    bankData.map((option: any) => (
                                      <option
                                        key={option.value}
                                        value={option.value}
                                      >
                                        {option.text}
                                      </option>
                                    ))
                                  }
                                </Form.Select>
                              </Form.Group>
                            )}
                          </div>
                          <Col>
                            <Form.Group controlId="blPayment.input2">
                              <Form.Label className="fs--1">
                                {t("resource_2:bankName")}
                              </Form.Label>
                              <Form.Control
                                type="text"
                                className="fs--1"
                                disabled={disableFields}
                                value={bnkName}
                                onChange={(e) => setBnkName(e.target.value)}
                              />
                            </Form.Group>
                          </Col>

                          <Col>
                            <Form.Group controlId="blPayment.input3">
                              <Form.Label
                                className="fs--1"
                                disabled={disableFields}
                              >
                                {t("resource_2:A/CNo")}
                              </Form.Label>
                              <Form.Control
                                type="text"
                                className="fs--1"
                                disabled={disableFields}
                                value={acName}
                                onChange={(e) => setAcName(e.target.value)}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <>
                        {/*  <div style={{ fontWeight: 'bold' }}>
                          <p style={{ fontSize: '12px', marginBottom: '6px' }}>
                            Please use the ODeX Ref Number while transacting on
                            PayCargo. You can visit PayCargo here{' '}
                            <a
                              href='https://paycargo.com '
                              target='_blank'
                              style={{ fontWeight: 'bold' }}
                            >
                              https://paycargo.com{''}
                            </a>
                          </p>
                          <p style={{ fontSize: '12px', marginBottom: '16px' }}>
                            Alternatively you can also enter the PayCargo
                            Payment Ref ID for the set of invoice/s for which
                            you have initiated a payment on PayCargo.
                          </p>
                        </div> */}
                      </>
                    )}
                    <fieldset>
                      {paymentMode == 1 ? (
                        <div className="mb-2" style={{ fontWeight: "bold" }}>
                          <p style={{ fontSize: "12px", marginBottom: "0px" }}>
                            {t(
                              "resource_message:paymentModeMesForBankTransfer"
                            )}
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                      {/* <legend> {t("resource_3:yourReferenceNo")}</legend> */}
                      <hr />
                      <Form.Group className="mb-2" controlId="blPayment.input4">
                        <Form.Label className="fs--1" htmlFor="auto">
                          <input
                            type="radio"
                            id="auto"
                            value="auto"
                            name="refNo"
                            onChange={() => {
                              setInputValue("");
                              setRadioValue("auto");
                              // props.refInput.disabled = 'true';
                            }}
                            checked={radioValue === "auto" ? true : false}
                            disabled={disableFields}
                          />{" "}
                          {t("resource_2:oDeXGenerate")}
                        </Form.Label>
                        <Form.Label className="fs--1" htmlFor="generate">
                          <input
                            type="radio"
                            id="generate"
                            value="generate"
                            name="refNo"
                            onChange={(e) => {
                              setInputValueTemp(inputValue);
                              setInputValue("");
                              setRadioValue("generate");
                            }}
                            style={{ marginLeft: "20px" }}
                            checked={radioValue === "generate" ? true : false}
                            disabled={!editPayment ? disableFields : false}
                          />{" "}
                          {t("resource_5:iHaveMyRefNo")}
                        </Form.Label>

                        <Form.Control
                          className="fs--1"
                          disabled={
                            (editPayment === true && radioValue === "auto") ||
                              !editPayment
                              ? disableFields ||
                              (radioValue === "auto" ? true : false)
                              : false
                          }
                          type="text"
                          // name='refInput'
                          ref={modalCloseFocus}
                          value={inputValue}
                          onChange={(e) =>
                            setInputValue(
                              e.target.value /* .replace(/ /g, '') */
                            )
                          }
                          maxLength={20}
                          minLength={10}
                          placeholder="Between 10 to 20 characters"
                        />
                      </Form.Group>
                      {
                        /* profileDetails.partnerType === "liner" && */
                        pmtStatus.toUpperCase() !== "PAID" &&
                        pmtStatus !== "1" &&
                        pmtStatus?.toUpperCase() != "REJECTED" &&
                        pmtStatus?.toUpperCase() != "CANCELLED" &&
                        rbac.rbacDetails.paymentApproveReject === "Y" &&
                        profileDetails.opcPartnerType === "PTO" && (
                          <Row className="mb-3 mt-2">
                            <Col>
                              <Form.Group controlId="blPayment.input4">
                                <Form.Label className="fs--1">
                                  {t("resource_1:remarks")}
                                </Form.Label>

                                <Form.Control
                                  className="fs--1"
                                  type="text"
                                  onChange={(e) => {
                                    setrejectionMsg(e.target.value);
                                    if (e.target.value.length > 0) {
                                      setrejectValidation(false);
                                    }
                                  }}
                                  disabled={
                                    pmtStatus?.toUpperCase() == "REJECTED"
                                      ? true
                                      : false
                                  }
                                  value={
                                    pmtStatus?.toUpperCase() == "REJECTED"
                                      ? linerRemarks
                                      : rejectionMsg
                                  }
                                  maxLength={20}
                                  minLength={10}
                                />
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group controlId="blPayment.input2">
                                <Form.Label className="fs--1">
                                  {t("resource_2:bankRefNo")}
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  className="fs--1"
                                  // disabled={disableFields}
                                  value={bankRefNoLiner}
                                  onChange={(e) =>
                                    setBankRefNoLiner(e.target.value)
                                  }
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        )
                      }
                      {profileDetails.partnerType === "liner" &&
                        pmtStatus.toUpperCase() !== "PAID" &&
                        pmtStatus !== "1" &&
                        rbac.rbacDetails.paymentApproveReject === "Y" && (
                          /*  profileDetails.opcPartnerType === "PTO" &&  */ <legend>
                            {" "}
                            {t("resource_2:rejectionRemark")}
                          </legend>
                        )}
                      {profileDetails.partnerType === "PTO" &&
                        pmtStatus.toUpperCase() !== "PAID" &&
                        pmtStatus !== "1" &&
                        rbac.rbacDetails.paymentApproveReject === "Y" && (
                          /*  profileDetails.opcPartnerType === "PTO" */ <Form.Group
                            className="mb-2 mt-2"
                            controlId="blPayment.input4"
                          >
                            <Form.Control
                              className="fs--1"
                              type="text"
                              onChange={(e) => {
                                setrejectionMsg(e.target.value);
                                if (e.target.value.length > 0) {
                                  setrejectValidation(false);
                                }
                              }}
                              disabled={
                                pmtStatus?.toUpperCase() == "REJECTED"
                                  ? true
                                  : false
                              }
                              value={
                                pmtStatus?.toUpperCase() == "REJECTED"
                                  ? linerRemarks
                                  : rejectionMsg
                              }
                              maxLength={20}
                              minLength={10}
                              placeholder="Enter Remark"
                            />
                          </Form.Group>
                        )}
                      {/*   {radioValue === 'generate' && !inputValue && (
                        <h6 className='text-warning m-0 fs--1'>
                          Please enter your Ref No &nbsp;&nbsp;
                        </h6>
                      )} */}

                      {radioValue === "generate" && inputValue.length < 10 && (
                        <h6 className="text-warning m-0 fs--1">
                          Please enter your Ref No Between 10 to 20 characters
                          &nbsp;&nbsp;
                        </h6>
                      )}
                    </fieldset>
                    {disableFields == false ?
                      ['O', 'M'].includes(popconfig?.[paymentMode ?? 0]?.[radioValue]) && (
                        <Form.Group controlId="blPayment.input5">
                          <Form.Label className="fs--1 d-flex flex-row mt-3">
                            { } {t("resource_2:bankProof")}
                            {paymentProofFileName &&
                              paymentProofFileName != "" && (
                                <td>
                                  <UploadIndicator
                                    style={{ marginLeft: "20px" }}
                                    progress={loadingFileState}
                                    fail={loadingFileState === -1}
                                  />
                                </td>
                              )}
                          </Form.Label>
                          <Form.Control
                            type="file"
                            disabled={disableFields}
                            onClick={() => {
                              setPaymentProofFileName("");
                              setSelectedFileServerPath([null, null]);
                            }}
                            onChange={handleFileChange}
                            style={{ marginBottom: "10px" }}
                          // value={loiUpload}
                          />
                          <>
                            {radioValue === "generate" &&
                              inputValue !== "" &&
                              popconfig?.[paymentMode ?? 0]?.[radioValue] == 'M' &&
                              paymentProofFileName === "" ? (
                              <h6 className="text-warning m-0 fs--1">
                                Please attach Payment Proof &nbsp;&nbsp;
                              </h6>
                            ) : (
                              ""
                            )}
                          </>
                        </Form.Group>
                      ) : (
                        <div>
                          <Form.Label className="fs--1">
                            {['O', 'H'].includes(popconfig?.[paymentMode ?? 0]?.[radioValue]) ? (
                              <>
                                {fileName == "" ||
                                  fileName == "undefined" ||
                                  fileName == null
                                  ? ""
                                  : t("resource_2:bankProof")}{" "}
                              </>
                            ) : (
                              ""
                            )}
                          </Form.Label>

                          <span
                            className="text-primary cursor-pointer"
                            onClick={() => {
                              window.open(
                                `${process.env.REACT_APP_CLIENT_APP_HOST
                                }/api/opc-rest-v1/v1/common/openDocument?id=${pymtId}&moduleTp=PAYMENT_PROOF&linerId=${profileDetails.partnerType === "liner"
                                  ? profileDetails?.partnerId
                                  : selectedLinerId
                                }`,
                                "_blank"
                              );
                            }}
                          >
                            {fileName}
                          </span>
                        </div>
                      )}
                  </Form>
                </>
              ) : (
                /*  paymentMode == 2 ? (
                <div>
                  <Form.Group
                    as={Row}
                    className='ml-3 mb-2'
                    controlId='payableAmount.input3'
                  >
                    <Col lg={4}>
                      <Form.Label className='fs--1'>
                        {t('resource_2:selectedAmount')}
                      </Form.Label>
                    </Col>
                    <Col>
                      <Form.Label className='fs--1'>
                        {formatNumber(selectedCreditAmount)}
                      </Form.Label>
                    </Col>
                  </Form.Group>
                  <CreditBalance />
                </div>
              ) : */ ""
              )}

              <div className="d-flex justify-content-center align-items-center">
                {paymentLoaderCheck ? (
                  <Lottie
                    animationData={infiniteLoop}
                    loop={true}
                    style={{ height: "80px", width: "80px" }}
                  />
                ) : paymentMode == 6 &&
                  pmtStatus !== "1" &&
                  pmtStatus !== "Paid" ? (
                  <>
                  </>
                ) : paymentMode == 7 &&
                  pmtStatus !== "1" &&
                  pmtStatus !== "Paid" &&
                  pmtStatus !== "Submitted" ? (
                  <>
                    <div style={{ zIndex: 500 }}>
                      <p />
                      <App
                        payload={{
                          platformChargeConfigId: platformChargeConfigId,
                        }}
                        quickPay={false}
                      />
                    </div>
                  </>
                ) : (
                  <Button
                    variant="falcon-primary"
                    size="sm"
                    className="me-2 mt-2"
                    hidden={disableFields}
                    onClick={handleSubmit}
                    type="submit"
                    disabled={
                      (selectedCreditAmount > 0 &&
                        amountDetails > 0 &&
                        selectedCreditAmount > amountDetails) ||
                        radioValue != "generate" ||
                        (inputValue.length >= 10 &&
                          (paymentProofFileName != "" ||
                            popconfig?.[paymentMode ?? 0]?.[radioValue] != 'M'))
                        ? [2, 4].includes(+paymentMode) && (creditBalance < (totalSelectedAmount.find(
                          (item: any) => item.currencyCode === selectedcurrency
                        ) || { amount: "" }
                        ).amount)
                        : true
                    }
                  >
                    <FontAwesomeIcon icon={faSave} className="me-2" />
                    {t("resource_1:submit")}
                  </Button>
                )}
                <Button
                  className="me-2 d-none"
                  variant="falcon-danger"
                  size="sm"
                  hidden={disableFields}
                >
                  <FontAwesomeIcon icon={faRedo} className="me-2" />
                  {t("resource_1:reset")}
                </Button>
              </div>
              {pmtStatus !== "1" &&
                pmtStatus !== "Paid" &&
                pmtStatus !== "Cancelled" &&
                profileDetails.opcPartnerType !== "PTO" &&
                pmtId ? (
                <div className="d-flex justify-content-center align-items-center">
                  {cancelPaymentLoader ? (
                    <Lottie
                      animationData={infiniteLoop}
                      loop={true}
                      style={{ height: "80px", width: "80px" }}
                    />
                  ) : (
                    <>
                      <Button
                        variant="falcon-primary"
                        size="sm"
                        className="me-2"
                        hidden={editPayment}
                        disabled={
                          pmtStatus == "1" || pmtStatus == "Paid" ? true : false
                        }
                        onClick={() => {
                          dispatch(setEditPayment(true));
                          setLoading(false);
                        }}
                      >
                        <FontAwesomeIcon icon={faEdit} className="me-2" />
                        {t("resource_1:edit")}
                      </Button>
                      <Button
                        variant="falcon-danger"
                        size="sm"
                        className="me-2"
                        hidden={editPayment}
                        onClick={() => setPaymentCancel(true)}
                      >
                        <FontAwesomeIcon icon={faCancel} className="me-2" />
                        {t("resource_2:cancelpayment")}
                      </Button>
                    </>
                  )}
                </div>
              ) : (
                <></>
              )}

              {
                /* profileDetails.opcPartnerType === "PTO" && */
                pmtStatus.toUpperCase() !== "PAID" &&
                pmtStatus.toUpperCase() != "REJECTED" &&
                pmtStatus?.toUpperCase() != "CANCELLED" &&
                pmtStatus !== "1" &&
                pmtMode != "2" &&
                pmtMode != "4" &&
                rbac.rbacDetails.paymentApproveReject === "Y" && (
                    /*   profileDetails.opcPartnerType === "PTO"  */ <div className="d-flex justify-content-center align-items-center mt-4">
                    {acceptPaymentLoader || rejectPaymentLoader ? (
                      <Lottie
                        animationData={infiniteLoop}
                        loop={true}
                        style={{ height: "80px", width: "80px" }}
                      />
                    ) : (
                      <>
                        <Button
                          variant="success"
                          className="me-4 approve"
                          size="sm"
                          hidden={editPayment}
                          onClick={() => {
                            /*    const payload = {
                      customerTransactionReference: inputValue,
                      bankTransactionReference: inputValue,
                      bnfAccountNumber: acName,
                      currency:
                        filteredselectedAmount?.[0]?.invcurr || 'USD',
                      countryCode: profileDetails.partnerCountry,
                      pmtSource: '',
                      amount: paymentAmount,
                      payorBankId: '',
                      payorBankName: '',
                      payorName: '',
                      payorBankAccountNumber: '',
                      transactionDateTime:
                        /* '2023-12-04 00:00:00' */ //currentDateTime,
                            //   typeCode: '',
                            //  text: '',
                            //  recordString: '',
                            //  fileName: '',
                            // otherRefNo: '',
                            //   linerId: '',
                            //  remarks: '',
                            // };
                            const payload = {
                              pmtId: pymtId,
                              // pmtRefNo:pmtRefNo,
                              bnkRefNo: bankRefNoLiner,
                              currency:
                                pymtCurr ||
                                "USD",
                              amount: paymentAmount,
                              linerId,
                            };
                            if (bankRefNoLiner.length == 0) {
                              toast.dark(
                                t("resource_message:bankRefMandatory"),
                                {
                                  toastId: 'BankRefLinerToast'
                                }

                              );
                            } else {
                              dispatch(acceptPayment(payload))
                                .unwrap()
                                .then(() => {
                                  setTimeout(() => {
                                    navigate("/opc/paymentDashboard");
                                  }, 3000);
                                });
                            }
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="me-2 approve"
                          />
                          {t("resource_1:approve")}
                        </Button>

                        <Button
                          variant="danger reject"
                          size="sm"
                          hidden={editPayment}
                          onClick={() => {
                            if (rejectionMsg.length == 0) {
                              setrejectValidation(true);
                              toast.dark(
                                t("resource_message:remarksMandatory"),
                                {
                                  toastId: 'remarksMandatoryToast'
                                }
                              );
                            } else {
                              dispatch(
                                rejectPayment({
                                  pmtId: pymtId,
                                  // blId: selectedBlId,
                                  currency: pymtCurr,
                                  rejectionMsg: rejectionMsg,
                                  linerId,
                                })
                              )
                                .unwrap()
                                .then(() => {
                                  setTimeout(() => {
                                    if (
                                      window.location.pathname.includes(
                                        "blsliderpayment"
                                      )
                                    ) {
                                      //  navigate('/payment');
                                      navigate("/opc/paymentDashboard");
                                    } else {
                                      navigate("/opc/home");
                                    }
                                  }, 3000);
                                });
                              setrejectValidation(false);
                            }
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faTimes}
                            className="me-2 reject"
                          />
                          {t("resource_1:reject")}
                        </Button>
                      </>
                    )}
                  </div>
                )
              }
              {editPayment && pmtId ? (
                loading ? (
                  <div
                    style={{
                      display: "flex",
                      alignContent: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Lottie
                      animationData={infiniteLoop}
                      loop={true}
                      style={{
                        height: "40px",
                        width: "40px",
                        alignSelf: "center",
                      }}
                    />
                  </div>
                ) : (
                  <div className="d-flex justify-content-center align-items-center">
                    <Button
                      variant="falcon-primary"
                      size="sm"
                      className="me-2"
                      hidden={!editPayment}
                      type="submit"
                      onClick={() => {
                        setLoading(true);
                        if (multiplePmtRef) {
                          setShowPaymentConfirm(true);
                          setNewPaymentRef(false);
                        } else {
                          checkValidationAndUpdate();
                          // setShowPaymentConfirm(true);
                          // setNewPaymentRef(false);
                        }
                      }}
                      disabled={
                        loadingFileState !== 0 &&
                          loadingFileState !== 100 &&
                          loadingFileState !== -1
                          ? true
                          : loading
                            ? true
                            : inputValue.length >= 10
                              ? validationFlag
                                ? true
                                : +selectedTotal < 0
                                  ? true
                                  : false
                              : true
                      }
                    >
                      <FontAwesomeIcon icon={faSave} className="me-2" />
                      {t("resource_1:submit")}
                    </Button>
                    <Button
                      className="me-2"
                      variant="falcon-danger"
                      size="sm"
                      hidden={!editPayment}
                      onClick={() =>
                      // _onCloseForm
                      {
                        setRadioValue(radioVal);
                        dispatch(setEditPayment(false));
                      }
                      }
                    >
                      {/*  <FontAwesomeIcon icon={faCancel} className='me-2' /> */}
                      <FontAwesomeIcon icon={faEraser} className="me-2" />
                      {t("resource_1:clear")}
                    </Button>
                  </div>
                )
              ) : (
                <></>
              )}
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      <Modal
        show={showPaymentConfirm}
        className="modal-dialog-centered"
        onHide={() => setShowPaymentConfirm(false)}
      >
        <Modal.Header className="p-2 pb-1" closeButton>
          <Modal.Title className="h6">
            Confirm payment Reference Number
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            overflow: "auto",
          }}
        >
          {newPaymentRef ? (
            <>
              <span className="h6 me-2 mb-2">Payment Reference Number</span>
              <input
                type="text"
                className="fs--2 form-control"
                title="payment Ref"
                placeholder="Enter Payment Reference Number"
              />
            </>
          ) : (
            <>
              <div className="mb-2">
                <span className="h6 me-2">
                  Invoice number has different payment Ref No. Do you want to
                  proceed payment with Ref No:
                </span>
                <span className="fw-bold h6">{inputValue} ?</span>
              </div>

              <div className="d-flex justify-content-center align-items-center">
                <Button
                  variant="falcon-primary"
                  size="sm"
                  className="me-2"
                  onClick={() => {
                    setShowPaymentConfirm(false);
                    handleUpdate();
                  }}
                  disabled={inputValue.length > 0 ? false : true}
                >
                  Yes
                </Button>
                <Button
                  className="me-2"
                  variant="falcon-danger"
                  size="sm"
                  onClick={() => {
                    setShowPaymentConfirm(false);
                    setLoading(false);
                  }}
                >
                  No
                </Button>
              </div>
            </>
          )}
        </Modal.Body>
        {newPaymentRef && (
          <Modal.Footer
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div className="d-flex justify-content-center align-items-center">
              <Button
                variant="falcon-primary"
                size="sm"
                className="me-2"
                onClick={() => {
                  modalCloseFocus.current.focus();
                  setShowPaymentConfirm(false);
                }}
              >
                ok
              </Button>
            </div>
          </Modal.Footer>
        )}
      </Modal>

      <Modal show={showDuplicacy} onHide={() => setshowDuplicacy(false)}>
        <Modal.Header className="p-2 pb-1" closeButton>
          <Modal.Title className="h6">
            {duplicayMsg}.Do you want to proceed with this Reference No?
          </Modal.Title>
        </Modal.Header>
        {/*       <Modal.Body
          style={{
            overflow: 'auto',
          }}
        >
         </Modal.Body> */}
        <div className="d-flex justify-content-center align-items-center p-2">
          <Button
            variant="falcon-primary"
            size="sm"
            className="me-2"
            onClick={() => {
              setshowDuplicacy(false);
              handleDuplicacy();
            }}
          // disabled={inputValue.length > 0 ? false : true}
          >
            Yes
          </Button>
          <Button
            className="me-2"
            variant="falcon-danger"
            size="sm"
            onClick={() => {
              setpaymentRefFocus(true);
              setshowDuplicacy(false);
              setLoading(false);
            }}
          >
            No
          </Button>
        </div>
      </Modal>

      <Modal
        show={showEditDuplicacy}
        onHide={() => setShowEditDuplicacy(false)}
      >
        <Modal.Header className="p-2 pb-1" closeButton>
          <Modal.Title className="h6">
            {editDuplicayMsg}.
            {methodName &&
              methodName.toString().trim() !== "" &&
              " Do you want to proceed with this Reference No?"}
          </Modal.Title>
        </Modal.Header>
        {/*       <Modal.Body
          style={{
            overflow: 'auto',
          }}
        >
         </Modal.Body> */}
        {methodName && methodName.toString().trim() !== "" ? (
          <div
            style={{ marginBottom: "1rem" }}
            className="d-flex justify-content-center align-items-center"
          >
            <Button
              variant="falcon-primary"
              size="sm"
              className="me-2"
              onClick={() => {
                setShowEditDuplicacy(false);
                if (methodName && methodName.toString().trim() !== "") {
                  if (methodName === "handleUpdate") {
                    handleUpdate();
                  }
                }
              }}
            // disabled={inputValue.length > 0 ? false : true}
            >
              Yes
            </Button>
            <Button
              className="me-2"
              variant="falcon-danger"
              size="sm"
              onClick={() => {
                setpaymentRefFocus(true);
                setShowEditDuplicacy(false);
                setLoading(false);
              }}
            >
              No
            </Button>
          </div>
        ) : (
          <div
            style={{ marginBottom: "1rem" }}
            className="d-flex justify-content-center align-items-center"
          >
            <Button
              variant="falcon-primary"
              size="sm"
              className="me-2"
              onClick={() => {
                setShowEditDuplicacy(false);
                setpaymentRefFocus(true);
              }}
            // disabled={inputValue.length > 0 ? false : true}
            >
              Okay
            </Button>
          </div>
        )}
      </Modal>

      <Modal show={showConfirmMsg} onHide={() => setShowConfirmMsg(false)}>
        <Modal.Header className="p-2 pb-1" closeButton>
          <Modal.Title className="h6">
            {/*  {pmtReftabledata.length == 0
              ? 'Do you want to cancel Payment'
              : pmtNumberdata.length > pmtReftabledata.length
              ? 'Do you want to remove Invoice from the Payment '
              : ''} */}
            Do you want to proceed with the payment
          </Modal.Title>
        </Modal.Header>

        <div
          style={{ marginBottom: "1rem" }}
          className="d-flex justify-content-center align-items-center"
        >
          <Button
            variant="falcon-primary"
            size="sm"
            className="me-2"
            onClick={() => {
              setShowConfirmMsg(false);
              dispatch(
                updatePaymentReferenceNo({
                  inputValue,
                  selectedCcy: pymtCurr,
                  selectedPymtId: pymtId,
                  pymtAmt: paymentAmount,
                })
              )
                .unwrap()
                .then(() => {
                  navigate("/opc/paymentDashboard");
                  setTimeout(() => {
                    dispatch(updateSelectedRowsInvoice({}));
                  }, 2000);
                });
              // handleUpdate();
            }}
          // disabled={inputValue.length > 0 ? false : true}
          >
            Yes
          </Button>
          <Button
            className="me-2"
            variant="falcon-danger"
            size="sm"
            onClick={() => {
              setpaymentRefFocus(true);
              setShowConfirmMsg(false);
              setLoading(false);
            }}
          >
            No
          </Button>
        </div>
      </Modal>

      <Modal show={showPaymentCancel} onHide={() => setPaymentCancel(false)}>
        <Modal.Header className="p-2 pb-1" closeButton>
          <Modal.Title className="h6">
            Do you want to Cancel the Payment?
          </Modal.Title>
        </Modal.Header>

        <div className="d-flex justify-content-center align-items-center p-2">
          <Button
            variant="falcon-primary"
            size="sm"
            className="me-2"
            onClick={() => {
              setPaymentCancel(false);
              dispatch(
                cancelPayment({
                  pmtId: pymtId,
                  // blId: selectedBlId,
                  currency: pymtCurr,
                })
              )
                .unwrap()
                .then(() => {
                  if (window.location.pathname.includes("blsliderpayment")) {
                    setTimeout(() => {
                      //  navigate('/payment');
                      navigate("/opc/paymentDashboard");
                      dispatch(updateSelectedRowsInvoice({}));
                    }, 3000);
                  } else {
                    setTimeout(() => {
                      navigate("/opc/home");
                    }, 3000);
                  }
                });
            }}
          // disabled={inputValue.length > 0 ? false : true}
          >
            Yes
          </Button>
          <Button
            className="me-2"
            variant="falcon-danger"
            size="sm"
            onClick={() => {
              setPaymentCancel(false);
              setLoading(false);
            }}
          >
            No
          </Button>
        </div>
      </Modal>
      <Modal
        show={showInsufficientCreditModal}
        onHide={() => {
          setShowInsufficientCreditModal(false);
        }}
        size="xl"
      >
        <Modal.Header className="p-2 pb-1" closeButton>
          <Modal.Title className="h6 m-2">Insufficient Credit !</Modal.Title>
        </Modal.Header>
        <div className="m-3">
          <Form.Label>
            Dear Customer,
            <br /> <br />
            <b>Prepaid Balance:</b>
            <br />
            Your account has an insufficient balance. To replenish your account,
            please use one of the following payment methods:
            <br />
            <br />
            <li>Bank Transfer</li>
            <li>Bank Deposit</li>
            <br />
            Once you've completed the transaction,
            <br />
            kindly share your SWIFT copy or deposit slip via email for
            verification.
            <br />
            <br />
            <b>Drop Email To: </b>
            <span
              style={{ color: "#2c7be5", cursor: "pointer" }}
              onClick={() => window.open("mailto:jkilongo@teagtl.com")}
            >
              jkilongo@teagtl.com
            </span>
            ,
            <span
              style={{ color: "#2c7be5", cursor: "pointer" }}
              onClick={() => window.open("mailto:bdaraja@teagtl.com")}
            >
              {" "}
              bdaraja@teagtl.com
            </span>
            ,
            <span
              style={{ color: "#2c7be5", cursor: "pointer" }}
              onClick={() => window.open("mailto:rnkya@teagtl.com")}
            >
              {" "}
              rnkya@teagtl.com
            </span>
            ,
            <span
              style={{ color: "#2c7be5", cursor: "pointer" }}
              onClick={() => window.open("mailto:llucumay@teagtl.com")}
            >
              {" "}
              llucumay@teagtl.com
            </span>
            ,
            <span
              style={{ color: "#2c7be5", cursor: "pointer" }}
              onClick={() => window.open("mailto:fkingu@teagtl.com")}
            >
              {" "}
              fkingu@teagtl.com
            </span>
            .
            <br />
            <br />
            After we process your replenishment,
            <br />
            You will receive a confirmation email, and your balance will be
            updated in Odex System.
            <br />
            <br />
            <b>Important Note:</b> Please be aware that bank transfers made over
            the weekend may not be reflected in our bank statement immediately,
            which could cause unnecessary inconvenience. We recommend using a
            bank deposit if you need immediate access to your funds.
            <br />
            If you encounter any issues, please don't hesitate to contact the
            TEAGTL Team for assistance.
            <br />
            <br />
            <b>Credit Balance:</b>
            <br />
            You will receive outstanding invoices to pay through automated
            statement.
            <br />
            Contact for more information:
            <span
              style={{ color: "#2c7be5", cursor: "pointer" }}
              onClick={() => window.open("mailto:tzhamis@teagtl.com")}
            >
              {" "}
              tzhamis@teagtl.com
            </span>
            ,
            <span
              style={{ color: "#2c7be5", cursor: "pointer" }}
              onClick={() => window.open("mailto:gshiyo@teagtl.com")}
            >
              {" "}
              gshiyo@teagtl.com
            </span>
            .
          </Form.Label>
        </div>
      </Modal>
    </div>
  );
};

export default BlPayment;
