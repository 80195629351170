import { Card } from "react-bootstrap";

import { matchPath, useLocation, useNavigate } from "react-router-dom";

import queryString from "query-string";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

import dataTableBgImg from "../../../../assets/img/illustrations/cornerRight-1.png";

import OblDetails from "../../../common/components/OblDetails";
import Header from "../../../common/components/DetailsViewHeader";
import Registration from "../../registration/Registration";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-regular-svg-icons";
import { useTranslation } from "react-i18next";
import MainTable from "./Maintable";
import DashboardContainer from "../../../../components/common/DashboardContainer";

const RegistrationDetails = () => {
  const location = useLocation();
  const { t } = useTranslation();

  const [instructionStatusNew, setInstructionStatusNew] = useState();
  const paymentStatus = useSelector(
    (state: RootState) => state.instructionDashboard.showPayment
  );

  const releaseStatus = useSelector(
    (state: RootState) => state.instructionDashboard.showRelease
  );

  const instructionStatus = useSelector(
    (state: RootState) => state.instructionDashboard.showInstruction
  );

  useEffect(() => {
    if (instructionStatus !== null) {
      setInstructionStatusNew(instructionStatus.trim());
    }
  }, [instructionStatus]);

  const rowColumns = useSelector(
    (state: RootState) => state.instructionTable.rowColumns
  );
  const navigate = useNavigate();

  const _onCloseForm = () => {
    navigate(`/opc/registrationmaster${window.location.search || ""}`);
  };

  var path = location.pathname;
  var parts = path.split("/");
  var blslider = parts[1];

  const sideWidth =
    Object.entries({
      [`/opc/registrationmaster`]: 0,
    }).find(([path, _]) =>
      matchPath(path, location.pathname.toLowerCase())
    )?.[1] ?? 9;

  return (
    <DashboardContainer
      sideWidth={sideWidth}
      middleChildren={
        <Card className="overflow-auto" style={{ height: "90vh" }}>
          <Card.Header className="d-flex align-items-center border-bottom sticky-top bg-white">
            <FontAwesomeIcon
              icon={faArrowAltCircleLeft}
              className="text-warning cursor-pointer fa-lg mb-1 me-1"
              onClick={_onCloseForm}
            />
            <h3 className="fs-0 text-primary">
              {t("resource_1:registration")}
            </h3>
          </Card.Header>

          <Card.Body className="p-3 d-flex flex-column container">
            <Registration />
          </Card.Body>
        </Card>
      }
    >
      <MainTable />
    </DashboardContainer>
  );
};

export default RegistrationDetails;
