/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { AppDispatch, RootState } from "../../../../store";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import { getUserProfile } from "../../../../common/actions/userDetails";
import {
  updateDashbaordRefresh,
  updateParams,
} from "../../../../common/components/DataTable/slices/DataTable";
import { getColumnViews } from "../../../../common/actions/dataTable";
import { getDashboardData } from "../../actions/dashboard";
import {
  updateCurrentPageInstructionDashboard,
  updatePrveDependenciesBlDashboard,
  updateSetDefaultViewBL,
} from "../../slices/dashboardSlice";
import moment from "moment";
import { AESEncrypt } from "../../../../../encrypt-util";
import TableComponent from "./TableComponent";
import { updateDashbaordName } from "../../../../common/slices/navbarTop";

const MainTable = () => {
  const [previousState, setPreviousState] = useState<{
    filterState: string | null;
    search: string | null;
    grids: any[] | null;
  }>({
    filterState: null,
    search: null,
    grids: null,
  });
  const location = useLocation();

  const instructionTopSearchState = useSelector(
    (state: RootState) => state.navbarTop.instructionTopSearchState
  );

  const tpoId = useSelector((state: RootState) => state.agentDelegation.tpoId);

  const roeGrids = useSelector((state: RootState) => state.dataTable.roeGrids);
  const dispatch = useDispatch<AppDispatch>();

  const filterState = useSelector(
    (state: RootState) => state.dataTable.filterState
  );
  const searchInputValue = useSelector(
    (state: RootState) => state.navbarTop.searchInputValue
  );
  const refresh = useSelector(
    (state: RootState) => state.dataTable.refreshDashboard
  );

  const sortState = useSelector(
    (state: RootState) => state.dataTable.sortState
  );
  const filterStateLocal = filterState?.["roeDashboard"];
  const linerId = useSelector((state: RootState) => state.navbarTop.linerId);
  let sort = (queryString.parse(location.search)?.sort as string)
    ?.split?.(",")
    ?.join?.(" ");

  const setDefaultViewBL = useSelector(
    (state: RootState) => state.instructionDashboard.setDefaultViewBL
  );
  const grids = useSelector((state: RootState) => state.dataTable.roeGrids);
  const currentPage = useSelector(
    (state: RootState) => state.dashboard.currentPageInstruction
  );

  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );
  const selectedConfigId = useSelector(
    (state: RootState) => state.dataTable.selectedConfigId
  );

  const linerDataCountry = useSelector(
    (state: RootState) => state.userProfile.linerDataCountry
  );
  const storedUserCountry = JSON.parse(
    window.localStorage.getItem("@user-selected-datacountry") || "{}"
  );
  const assignTo = useSelector((state: RootState) => state.dashboard.assignTo);
  const storedCountry =
    storedUserCountry?.[0]?.loginId === profileDetails.email
      ? storedUserCountry?.[0]?.country
      : null;

  const dataCountry = storedCountry
    ? storedCountry
    : linerDataCountry
      ? linerDataCountry
      : profileDetails.partnerCountry || null;

  function useDebouncedEffect(effect: any, deps: any, delay: any) {
    const callback = useRef<any>();

    useEffect(() => {
      callback.current = effect;
    }, [effect]);

    useEffect(() => {
      const handler = () => {
        if (callback.current) {
          callback.current();
        }
      };

      const timer = setTimeout(handler, delay);

      return () => clearTimeout(timer);
    }, [...deps, delay]);
  }
  useEffect(() => {
    if (refresh) loadRoeDashboard(false);
    // server sometimes takes time to update the view so the delay
    setTimeout(() => dispatch(updateDashbaordRefresh(false)), 2000);
  }, [refresh]);
  useEffect(() => {
    //  if (location?.pathname && dataCountry && grids.length == 0 && linerId) {

    if (dataCountry && tpoId) {
      setTimeout(() => {
        //  dispatch(getUserGridsDefinition('TZ'));

        dispatch(
          getColumnViews({
            gridName: "OPC_ROE_DASHBOARD",
            country: dataCountry,
            linerId: tpoId,
          })
        );
      }, 150);
    }

    //  }
    dispatch(updateParams({}));
    // if (datatableState != 'INSTRUCTION_DASHBOARD') {
    //   dispatch(
    //     updateSortStateDataTable({
    //       key: null,
    //     })
    //   );
    // }
  }, [dataCountry, grids, tpoId]);

  // useEffect(() => {
  //   if (dataCountry) dispatch(generateInvReferenceNo(dataCountry));
  // }, [dataCountry]);
  // useEffect(() => {
  //   {
  //     setTimeout(() => {
  //       dispatch(
  //         getDashboardData({
  //           gridName: "OPC_EXCHANGE_RATE",
  //           payload: {
  //             fields: [],

  //             filters: /*   selectedTopSearchFilter?.where === ''
  //               ? instructionTopSearchState
  //               :  */ {
  //               from_ccy: ["USD"], exchange_to: ["TZS"],
  //             },
  //             size: 20,
  //             sorts: sort ? [sort] : ["exchange_rate_date desc"],
  //             page: currentPage,
  //           },
  //           dataCountry: dataCountry,
  //           partnerId: profileDetails.partnerId,

  //           where: roeGrids?.[0]?.whereClause
  //             ? AESEncrypt(roeGrids?.[0]?.whereClause)
  //             : null,
  //           parameter:
  //             selectedTopSearchFilter?.where !== ""
  //               ? {
  //                 searchParameter: searchInputValue,
  //                 searchWhere: selectedTopSearchFilter?.where,
  //               }
  //               : {},
  //         })
  //       );
  //       // dispatch(
  //       //   /*    getDashboardData({
  //       //     gridName: 'OPC_INSTRUCTION_DASHBOARD',
  //       //     payload: {
  //       //       fields: [],

  //       //       filters: /*  !selectedTopSearchFilter?.where
  //       //         ? finalFilterApiState
  //       //         : */ //{},

  //       //   //   size: 20,
  //       //   //  sorts: '',
  //       //   /*  sortState &&
  //       //         sortState?.key != null &&
  //       //         sortState?.value != null
  //       //           ? [`${sortState?.key} ${sortState?.value}`]
  //       //           : sort
  //       //           ? [sort]
  //       //           : [''] */
  //       //   //  page: filterUpdated ? 1 : currentPage,
  //       //   //  },
  //       //   // dataCountry: dataCountry,
  //       //   // partnerId:
  //       //   //  profileDetails.partnerType === 'customer'
  //       //   //  ? profileDetails.partnerId
  //       //   //  : null,
  //       //   // where: instructionsGrids?.[0]?.whereClause
  //       //   //? AESEncrypt(instructionsGrids?.[0]?.whereClause)
  //       //   // : null,
  //       //   //  parameter:
  //       //   //  selectedTopSearchFilter?.where !== ''
  //       //   //  ? {
  //       //   //    searchParameter: searchInputValue,
  //       //   //  searchWhere: selectedTopSearchFilter?.where,
  //       //   // }
  //       //   // : {},
  //       //   //  })
  //       //   getDashboardData({
  //       //     fields: [],
  //       //     filters: {},
  //       //     size: 20,
  //       //     sorts: sort ? [sort] : ['requested_on desc'],
  //       //     /* sortState?.key != null && sortState?.value != null
  //       //         ? [`${sortState?.key} ${sortState?.value}`]
  //       //           ? [sort]
  //       //           : ['requested_on desc']
  //       //         : ['requested_on desc'], */
  //       //     page: 1,
  //       //     view: 'E58NbJpkbNRw9+y/FKnz4+ZLO9CsHv/yAz6QBCjYEpM=',
  //       //     where: '',
  //       //   })
  //       // );
  //     }, 150);
  //   }
  // }, []);

  useEffect(() => {
    const configViews = grids?.find?.(
      (g) => g?.gridName == "OPC_EXCHANGE_RATE"
    )?.configGroup;

    if (configViews && configViews?.length > 0 && setDefaultViewBL) {
      dispatch(updateSetDefaultViewBL(false));
    }
  }, []);
  const prevPageInstructionDashbaord = useSelector(
    (state: RootState) =>
      state.dashboard.prevDependencies.prevPageInstructionDashbaord
  );
  const setPrevPageInstructionDashbaord = (state: string) => {
    dispatch(
      updatePrveDependenciesBlDashboard({
        key: "prevPageInstructionDashbaord",
        value: state,
      })
    );
  };

  useEffect(()=>{
    dispatch(updateDashbaordName("OPC_ROE_DASHBOARD"))
  },[])

  useEffect(() => {
    if (refresh) loadRoeDashboard(false);
    // server sometimes takes time to update the view so the delay
    setTimeout(() => dispatch(updateDashbaordRefresh(false)), 2000);
  }, [refresh]);

  // useEffect(() => {
  //   const currentStateValue = {
  //     filterState: JSON.stringify(filterStateLocal),
  //     search: location.search,
  //     grids: grids,
  //   };
  //   if (JSON.stringify(previousState) !== JSON.stringify(currentStateValue)) {

  //     loadRoeDashboard(true);
  //     const currentState = {
  //       filterState: JSON.stringify(filterStateLocal),
  //       search: location.search,
  //       grids: grids,
  //     };
  //     setPreviousState(currentState);
  //   }

  // }, [filterStateLocal, location.search, grids, linerId]);

  useDebouncedEffect(
    () => {
      const currentStateValue = {
        filterState: JSON.stringify(filterStateLocal),
        search: location.search,
        grids: grids,
      };
      if (JSON.stringify(previousState) !== JSON.stringify(currentStateValue)) {
        loadRoeDashboard(true);
        const currentState = {
          filterState: JSON.stringify(filterStateLocal),
          search: location.search,
          grids: grids,
        };
        setPreviousState(currentState);
      }
    },
    [filterStateLocal, location.search, grids, linerId, sort],
    700
  );

  useEffect(() => {
    if (currentPage && currentPage == prevPageInstructionDashbaord) {
      //setPrevPageInstructionDashbaord(currentPage);
      loadRoeDashboard(false);
    } else {
      loadRoeDashboard(true);
    }

    const currentState = {
      filterState: JSON.stringify(filterStateLocal),
      search: location.search,
      grids: grids,
    };
    setPreviousState(currentState);
  }, [assignTo, linerId]);

  useEffect(() => {
    if (currentPage && currentPage !== prevPageInstructionDashbaord) {
      setPrevPageInstructionDashbaord(currentPage);
      loadRoeDashboard(false);
    }
  }, [currentPage]);

  const setCurrentPage = (page: number) => {
    dispatch(updateCurrentPageInstructionDashboard(page));
  };
  const selectedTopSearchFilter = useSelector(
    (state: RootState) => state.navbarTop.selectedTopSearchFilter
  );
  if (!sort) {
    sort =
      grids[0]?.configGroup?.find(
        (c: any) => c?.userGridId == selectedConfigId?.["roeDashboard"]
      )?.columnConfigGrid &&
        grids[0]?.configGroup?.find(
          (c: any) => c?.userGridId == selectedConfigId?.["roeDashboard"]
        )?.columnConfigGrid
        ? JSON.parse(
          grids[0]?.configGroup?.find(
            (c: any) => c?.userGridId == selectedConfigId?.["roeDashboard"]
          )?.columnConfigGrid
        )?.find((v: any) => v.accessor == "assigned_to")
          ? JSON.parse(
            grids[0]?.configGroup?.find(
              (c: any) => c?.userGridId == selectedConfigId?.["roeDashboard"]
            )?.columnConfigGrid
          )?.[0]?.sorts?.join(", ")
          : sort
        : sort;
  }
  const loadRoeDashboard = (filterUpdated: boolean) => {
    if (filterUpdated) {
      setCurrentPage(1);
    }

    if (filterStateLocal) {
      if (Object.keys(filterStateLocal)?.length > 0) {
        const filterApiState: any = {};

        const filterKeys = Object.keys(filterStateLocal);

        for (let k of filterKeys) {
          if (filterStateLocal?.[k]?.from) {
            filterApiState[k] = [
              filterStateLocal?.[k]?.from || "",
              filterStateLocal?.[k]?.to || "",
            ].filter((i) => i);
          } else if (filterStateLocal?.[k]?.from !== undefined) {
            filterApiState[k] = undefined;
          } else {
            filterApiState[k] = filterStateLocal?.[k]
              ? typeof filterStateLocal?.[k] === "string"
                ? [filterStateLocal?.[k]]
                : filterStateLocal?.[k]
              : undefined;
          }
        }
        if (Object.keys(filterApiState)?.length > 0) {
          const finalFilterApiState: any =
            selectedTopSearchFilter?.id &&
              searchInputValue.trim() != "" &&
              Object.keys(instructionTopSearchState).length != 0
              ? { [selectedTopSearchFilter?.id]: [searchInputValue.trim()] }
              : {};
          const currentDate = new Date();

          if (grids[0]) {
            let timeFlag = "";
            let timeDiff = 0;
            let dateRange: any = {};
            const newdata = grids[0].configGroup?.find(
              (c: any) => c.userGridId == selectedConfigId?.["roeDashboard"]
            )?.columnConfigGrid;
            if (newdata) {
              timeFlag = JSON.parse(newdata).find(
                (item: any) => item.accessor == "timestamp"
              )?.filterState;
              timeDiff = JSON.parse(newdata).find(
                (item: any) => item.accessor == "timestamp"
              )?.timeDiff;
              dateRange = JSON.parse(newdata).find(
                (item: any) => item.accessor == "timestamp"
              )?.dateRange;
            }

            if (filterApiState.timestamp && timeFlag == "day" && dateRange) {
              filterApiState.timestamp[1] = moment()
                .subtract(Math.abs(dateRange.to), "days")
                .endOf("day")
                .toISOString();
              // const newDate = new Date(
              //   new Date(currentDate.toISOString()).getTime() -
              //     timeDiff * 24 * 60 * 60 * 1000
              // );
              filterApiState.timestamp[0] = moment()
                .subtract(Math.abs(dateRange.from), "days")
                .startOf("day")
                .toISOString();
            }
          }
          for (let i of Object.keys(filterApiState)) {
            if (filterApiState[i]) {
              finalFilterApiState[i] = filterApiState[i];
            }
          }

          if (
            /*      profileDetails &&
            ((profileDetails.partnerType === 'customer' &&
              profileDetails.partnerId &&
              instructionsGrids?.[0]?.whereClause) ||
              profileDetails?.partnerType === 'liner') */
            tpoId
          ) {
            setTimeout(() => {
              dispatch(
                getDashboardData({
                  gridName: "OPC_EXCHANGE_RATE",
                  payload: {
                    fields: [],

                    filters: /*   selectedTopSearchFilter?.where === ''
                        ? instructionTopSearchState
                        :  */
                        finalFilterApiState 
                        ? finalFilterApiState
                        : {},
                    size: 20,
                    sorts: sort ? [sort] : ["exchange_rate_date desc"],
                    page: filterUpdated ? 1 : currentPage,
                  },
                  dataCountry: dataCountry,
                  partnerId: profileDetails.partnerId,

                  where: roeGrids?.[0]?.whereClause
                    ? AESEncrypt(roeGrids?.[0]?.whereClause)
                    : null,
                  parameter:
                    selectedTopSearchFilter?.where !== ""
                      ? {
                        searchParameter: searchInputValue,
                        searchWhere: selectedTopSearchFilter?.where,
                      }
                      : {},
                })
              );
              // dispatch(
              //   /*    getDashboardData({
              //     gridName: 'OPC_INSTRUCTION_DASHBOARD',
              //     payload: {
              //       fields: [],

              //       filters: /*  !selectedTopSearchFilter?.where
              //         ? finalFilterApiState
              //         : */ //{},

              //   //   size: 20,
              //   //  sorts: '',
              //   /*  sortState &&
              //         sortState?.key != null &&
              //         sortState?.value != null
              //           ? [`${sortState?.key} ${sortState?.value}`]
              //           : sort
              //           ? [sort]
              //           : [''] */
              //   //  page: filterUpdated ? 1 : currentPage,
              //   //  },
              //   // dataCountry: dataCountry,
              //   // partnerId:
              //   //  profileDetails.partnerType === 'customer'
              //   //  ? profileDetails.partnerId
              //   //  : null,
              //   // where: instructionsGrids?.[0]?.whereClause
              //   //? AESEncrypt(instructionsGrids?.[0]?.whereClause)
              //   // : null,
              //   //  parameter:
              //   //  selectedTopSearchFilter?.where !== ''
              //   //  ? {
              //   //    searchParameter: searchInputValue,
              //   //  searchWhere: selectedTopSearchFilter?.where,
              //   // }
              //   // : {},
              //   //  })
              //   getDashboardData({
              //     fields: [],
              //     filters: {},
              //     size: 20,
              //     sorts: sort ? [sort] : ['requested_on desc'],
              //     /* sortState?.key != null && sortState?.value != null
              //         ? [`${sortState?.key} ${sortState?.value}`]
              //           ? [sort]
              //           : ['requested_on desc']
              //         : ['requested_on desc'], */
              //     page: 1,
              //     view: 'E58NbJpkbNRw9+y/FKnz4+ZLO9CsHv/yAz6QBCjYEpM=',
              //     where: '',
              //   })
              // );
            }, 150);
          }
        }
      }
    }
  };
  return <TableComponent />;
};

export default MainTable;
