import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AESDecrypt } from "../../../encrypt-util";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useState, useEffect, useRef } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Button, Card, Dropdown, Form } from "react-bootstrap";
import { updateRowColumns } from "./BestPickDetailsSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { faAdd, faSave, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { getUserProfile } from "../../common/actions/userDetails";
import { profile } from "console";
import { updateDashbaordRefresh } from "../../common/components/DataTable/slices/DataTable";
import { customDropdownStyles } from "../../common/customStyles";
import { fetchVesselSuggestions } from "../blRequestInstruction/components/actions/Instruction";
import SoftBadge from "../../../components/common/SoftBadge";
import DataTable from "../../common/components/DataTable";
import { IDataTableColumn } from "../../common/types/dataTable";
import {
  bestPickCodes,
  bestPickView,
  getContainerListValues,
  getRefNo,
  ICDCode,
  submitBestPickCodes,
} from "./action/BestPickDetails";
import { bestPickCodesValChange } from "./slices/addbestPick";
import useDebounce from "../../../hooks/useDebounce";
import React from "react";
import { useLoading } from "../../../middleware/loadingMiddleware";
import CsvExport from "../../common/components/CsvExport";

const BestPick = () => {
  const dispatch = useDispatch<AppDispatch>();
  const linerDataCountry = useSelector(
    (state: RootState) => state.userProfile.linerDataCountry
  );
  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );

  const refnoVal = useSelector(
    (state: RootState) => state.addbestPickSlice.refnoVal
  );

  const bestPickViewVal = useSelector(
    (state: RootState) => state.addbestPickSlice.bestPickViewVal
  );

  const ICDCodeVal = useSelector(
    (state: RootState) => state.addbestPickSlice.ICDCodeVal
  );

  const bestPickCodesVal = useSelector(
    (state: RootState) => state.addbestPickSlice.bestPickCodesVal
  );

  const loadingVesselList = useLoading([
    "PermitRequest/fetchVesselSuggestions",
  ]);

  const tpoId = useSelector((state: RootState) => state.agentDelegation.tpoId);

  const storedUserCountry = JSON.parse(
    window.localStorage.getItem("@user-selected-datacountry") || "{}"
  );
  const assignTo = useSelector((state: RootState) => state.dashboard.assignTo);
  const storedCountry =
    storedUserCountry?.[0]?.loginId === profileDetails.email
      ? storedUserCountry?.[0]?.country
      : null;

  const dataCountry = storedCountry
    ? storedCountry
    : linerDataCountry
      ? linerDataCountry
      : profileDetails.partnerCountry || null;

  const { best_pick_request_id } = useParams();

  const dropdownRef = useRef<any>();
  const navigate = useNavigate();

  const [selectedVessel, setselectedVessel] = useState<any>("");
  const [showValues, setshowValues] = useState<any>(false);
  const [SelectedvesselName, setSelectedvesselName] = useState<any>("");
  const [refViewVal, setrefViewVal] = useState<any>("");
  const [placeOfDeliveryViewVal, setplaceOfDeliveryViewVal] = useState<any>("");
  const [bestPickCodesTableData, setbestPickCodesTableData] = useState<any>([]);
  const [selectedICDcode, setselectedICDcode] = useState<any>("");
  const [additionalInputs, setAdditionalInputs] = useState<any>([]);
  const [vesselTxt, setVesselTxt] = useState<string>("");
  const [VesselList, setVesselList] = useState<any>([]);
  const [ICDcodeList, setICDcodeList] = useState<any>([]);
  const [bestPickOptions, setbestPickOptions] = useState<any>([]);
  const [containerList, setcontainerList] = useState<any>([]);
  const [shortCodeVal, setshortCodeVal] = useState<any>("");
  const [placeOfDelivaryVal, setplaceOfDelivaryVal] = useState<any>("");
  const [vistKeyVal, setvistKeyVal] = useState<any>("");

  const resetForm = () => {
    setValue("bestPickId", "");
    clearErrors("bestPickId");
    setValue("container_numbers", "");
    clearErrors("container_numbers");
    setValue("ICDShortCode", null);
    clearErrors("ICDShortCode");
    setValue("vesselVoyage", "");
    clearErrors("vesselVoyage");
    setAdditionalInputs([]);
    setselectedVessel("");
    setVesselTxt("");
    setVesselList([]);
    setselectedICDcode(null);
    setshortCodeVal(null);
    setICDcodeList([]);
  };
  const getContainerValuesFromExcel = async (data: any[]) => {
    const firstElementRemovedArr = data;

    // Get the existing container numbers from the form state and split by comma
    const preInputedNumbers = getValues("container_numbers").split(",");

    // Helper function to sanitize the container numbers
    const sanitizeValue = (value: string) => {
      // Allow only alphanumeric characters and commas
      return value.replace(/[^a-zA-Z0-9,]/g, "");
    };

    // Sanitize and map through new container numbers from Excel upload
    const sanitizedNewValues = firstElementRemovedArr.map(({ value }: any) =>
      sanitizeValue(value)
    );

    // Combine pre-inputted numbers with new sanitized values
    const updatedContainerNumbers =
      preInputedNumbers?.length > 0
        ? [...preInputedNumbers, ...sanitizedNewValues]
        : sanitizedNewValues;

    // Filter out empty values and join the numbers with commas
    setValue(
      "container_numbers",
      updatedContainerNumbers.filter((data: any) => data).join(",")
    );

    // Optional timeout to trigger another function after updating container numbers
    setTimeout(() => {
      handleContainerNumbers();
    }, 100);
  };

  /*  const getContainerValuesFromExcel = async (data: any[]) => {
    const firstElementRemovedArr = data;
    const preInputedNumbers = getValues("container_numbers").split(",");
    const updatedContainerNumbers =
      preInputedNumbers?.length > 0
        ? [
            ...preInputedNumbers,
            firstElementRemovedArr.map(({ value }: any) => value),
          ]
        : firstElementRemovedArr.map(({ value }: any) => value);
    setValue(
      "container_numbers",
      updatedContainerNumbers.filter((data: any) => data).join(",")
    );
    setTimeout(() => {
      handleContainerNumbers();
    }, 100);
    // let customerNameList: any = [];
    // let newInputs: any = [];
    // const promises = firstElementRemovedArr?.map(
    //   async (data: any, index: number) => {
    //     try {
    //       const response: any = await dispatch(
    //         findCustomerByTaxId({ value: data?.value })
    //       ).unwrap();
    //       const { partnerNm } = response?.data;

    //       if (partnerNm) {
    //         customerNameList.push({ rowNumber: index + 1, ...response?.data });
    //         newInputs.push({
    //           value: data?.value,
    //           rowNumber: index + 1,
    //           error: null,
    //         });
    //       } else {
    //         customerNameList.push({ rowNumber: index + 1, partnerNm: "" });
    //         newInputs.push({
    //           value: data?.value,
    //           rowNumber: index + 1,
    //           error: "Error in getting partner name",
    //         });
    //       }
    //     } catch (error) {
    //       newInputs.push({
    //         value: data?.value,
    //         rowNumber: index + 1,
    //         error: "Error fetching customer data",
    //       });
    //     }
    //   }
    // );

    // Wait for all promises to resolve
    // await Promise.all(promises);

    // setAdditionalInputs(newInputs);
    // setCustomerNameList(customerNameList);
  };
 */
  type FormValues = {
    bestPickId: string;
    vesselVoyage: string;
    icd_short_code: string;
    container_numbers: string;
  };

  const handleRemoveInput = (containerNo: number) => {
    const newInputs = bestPickCodesTableData.filter(
      (i: any) => i.containerNo !== containerNo
    );
    setbestPickCodesTableData(newInputs);
  };

  const ContainerColumns: IDataTableColumn[] = [
    {
      accessor: "blNumber",
      Header: "BL Number",
      width: 150,
      maxWidth: 150,
    },
    {
      accessor: "containerNo",
      Header: "Container No",
      width: 150,
      maxWidth: 150,
    },
    {
      accessor: "groupId",
      Header: "Group ID",
      width: 150,
      maxWidth: 150,
    },
    {
      accessor: "groupDesc",
      Header: "Group Description",
      width: 270,
      maxWidth: 270,
    },
    !showValues
      ? {
        accessor: "remove",
        Header: "Remove",
        csv: false,
        width: 100,
        maxWidth: 100,
        Cell: (rowData: any) => {
          const { containerNo } = rowData.row.original;
          return (
            <Button
              variant="falcon-warning"
              style={{
                background: "transparent",
                border: "none",
                boxShadow: "none",
                padding: "0px",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                height: "100%",
              }}
              disabled={showValues}
              // disabled={showdata}
              onClick={() => handleRemoveInput(containerNo)}
            >
              <FontAwesomeIcon icon={faTrash} size="sm" />
            </Button>
          );
        },
      }
      : {},
  ];

  const VesselData: any = useSelector(
    (state: RootState) => state.permitrequest.data
  );

  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    vesselVoyage: Yup.object().required("Vessel is required").nullable(),
    ICDShortCode: Yup.object()
      .required("Place of delivery is required")
      .nullable(),
    /*   container_numbers: Yup.string().matches(
      /^[a-zA-Z0-9 ,]*$/,
      "No special characters are allowed"
    ), */
  });

  //Setup
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    getValues,
    setValue,
    control,
    clearErrors,
  } = useForm<any>({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const consumeExcelFile = (event: any, setter: Function) => {
    const file = event.target.files[0];
    if (!file) return;

    // Check for .xlsx file type
    if (
      file.type !==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      toast.error("Please upload a valid .xlsx file.", { toastId: "5410bb2" });
      return;
    }

    const reader = new FileReader();

    reader.onload = function (event: any) {
      try {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });

        // Assuming you want to read only the first sheet
        const firstSheetName = workbook.SheetNames[0];
        if (!firstSheetName) {
          console.error("No sheets found in the workbook.");
          return;
        }

        const sheet = workbook.Sheets[firstSheetName];
        const json: any = XLSX.utils.sheet_to_json(sheet, {
          header: 1,
          defval: "",
        });

        if (
          json[0][0].toUpperCase() != "CONTAINER NUMBERS" &&
          json[0][0].toUpperCase() != "CONTAINER NO"
        ) {
          toast.error(".xlsx file is invalid", { toastId: "eff8c73" });
          return;
        }

        toast.success("Added Entries Successfully!", { toastId: "350fbd2" });
        handleContainerNumbers();
        setter(
          json
            .map((i: any) => ({
              value: `${i[0]}`,
              error: null,
              rowNumber: crypto.randomUUID(),
            }))
            .filter(
              ({ value }: any, i: number) =>
                i != 0 && value && value?.trim() != ""
            )
        );
      } catch (error) {
        console.error("Error reading file:", error);
        return;
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const submitBestPickCodesSubmit = async (payload: any) => {
    try {
      const response: any = await dispatch(
        submitBestPickCodes(payload)
      ).unwrap();

      if (response.responseMsg) {
        toast.success(response.responseMsg, { toastId: "756e361" });
        setTimeout(() => {
          setbestPickCodesTableData([]);
        }, 100);
        dispatch(bestPickCodesValChange({}));
        setTimeout(() => {
          navigate("/opc/bestPick");
          dispatch(updateDashbaordRefresh(true));
        }, 1000);
      }
    } catch (error) {
      console.error("API request failed:", error);
    }
  };

  const onSubmit = handleSubmit(async (payload: any) => {
    if (bestPickCodesTableData?.length > 0) {
      const containerListData = bestPickCodesTableData.map((item: any) => ({
        blNo: item.blNo,
        containerNo: item.containerNo,
        containerSize: item.containerSize,
        groupCode: item.groupCode,
        groupDesc: item.groupDesc,
      }));

      const upadtedPayload = {
        tpoId: tpoId,
        shortCode: shortCodeVal,
        placeOfDelivery: selectedICDcode,
        referenceNo: refnoVal,
        visitKey: selectedVessel,
        vesselName: SelectedvesselName,
        containerList: containerListData,
      };

      submitBestPickCodesSubmit(upadtedPayload);

      // dispatch(submitBestPickCodes(upadtedPayload))
      // .unwrap()
      // .then((response: any) => {
      // });
    } else {
      toast.error("Container number list cannot be empty", {
        toastId: "449ce4b",
      }); //not allowed to submit with out container number
    }
  });

  useEffect(() => {
    if (best_pick_request_id) {
      resetForm();
      if (AESDecrypt(best_pick_request_id?.toString()).toString() != "0") {
        dispatch(
          bestPickView(AESDecrypt(best_pick_request_id?.toString()).toString())
        );
      }
    }
  }, [best_pick_request_id]);

  useEffect(() => {
    if (bestPickViewVal && best_pick_request_id) {
      if (AESDecrypt(best_pick_request_id?.toString()).toString() != "0") {
        setshowValues(true);
        setValue("bestPickId", bestPickViewVal.referenceNo);
        setrefViewVal(bestPickViewVal.visitKey);
        setplaceOfDeliveryViewVal(bestPickViewVal.placeOfDelivery);

        if (bestPickViewVal.containerList) {
          const updatedData = bestPickViewVal.containerList.map(
            (item: any) => ({
              ...item,
              blNumber: item.blNo,
              groupId: item.groupCode,
            })
          );
          setbestPickCodesTableData(updatedData);
        }
      } else {
        setshowValues(false);
        setValue("bestPickId", "");
        setrefViewVal("");
        setplaceOfDeliveryViewVal("");
        setTimeout(() => {
          setbestPickCodesTableData([]);
        }, 100);

        // setValue("bestPickId", refnoVal);
      }
    }
  }, [bestPickViewVal, best_pick_request_id]);

  useEffect(() => {
    if (tpoId && dataCountry) {
      if (AESDecrypt(best_pick_request_id?.toString()).toString() != "0") {
      } else {
        dispatch(getRefNo(dataCountry));
        dispatch(ICDCode(tpoId));
      }
    }
  }, [dataCountry, tpoId, showValues, best_pick_request_id]);

  useEffect(() => {
    if (bestPickCodesVal) {
      if (bestPickCodesVal.containerList) {
        const updatedData = bestPickCodesVal.containerList.map((item: any) => ({
          ...item,
          blNumber: item.blNo,
          groupId: item.groupCode,
        }));
        setbestPickCodesTableData(updatedData);
      } else {
        setbestPickCodesTableData([]);
      }
    }
  }, [bestPickCodesVal]);

  useEffect(() => {
    if (ICDCodeVal) {
      if (Array.isArray(ICDCodeVal)) {
        const formattedOptions = ICDCodeVal.map((icd: any) => ({
          value: `${icd.customsDeliveryCode}`,
          label: `${icd.customsDeliveryCode}`,
          shortCode: icd.shortCode,
        }));
        setICDcodeList(formattedOptions);

        if (formattedOptions.length === 1) {
          const singleOption = formattedOptions[0];
          // setselectedICDcode("WITZDL025");
          // setshortCodeVal('GL')
          setValue("ICDShortCode", singleOption);
          setselectedICDcode(singleOption.value);
          setshortCodeVal(singleOption.shortCode);
        }
      }
    }
  }, [ICDCodeVal]);

  useEffect(() => {
    if (refnoVal) {
      const formattedOptions = {
        value: `${refnoVal}`,
        label: `${refnoVal}`,
      };
      const singleOption = formattedOptions;
      // setbestPickOptions(singleOption.value);
      setValue("bestPickId", refnoVal);
    }
  }, [refnoVal]);

  useEffect(() => {
    if (VesselData && vesselTxt) {
      if (Array.isArray(VesselData)) {
        const formattedOptions = VesselData.map((vessel: any) => ({
          value: `${vessel.visitKey}`,
          label: `${vessel.vesselName} - ${vessel.visitKey}${vessel.ata ? " - " + new Date(vessel.ata).toLocaleString() : ""
            }`,
          visitKey: vessel.visitKey,
          vesselName: vessel.vesselName,
        }));
        setVesselList(formattedOptions);
      }
    }
  }, [VesselData]);

  const customFilterOption = (option: any, inputValue: any) => {
    // if (inputValue.length < 3) {
    //   return false;
    // }
    return option.label.toLowerCase().includes(inputValue.toLowerCase());
  };

  const handleContainerNumbers = () => {
    const input = getValues("container_numbers");

    const cleanedValues = input
      .replaceAll(/\n/g, ",")
      .replaceAll(",,", ",")
      .split(",")
      .map((val: any) => val.trim())
      .filter(Boolean);

    const containerData = cleanedValues.map((containerNo: any) => ({
      containerNo,
    }));

    setValue("container_numbers", cleanedValues.join(","));
    setcontainerList(containerData);
  };

  const handleInputChange = useDebounce((inputValue: string) => {
    setVesselTxt(inputValue);
    if (inputValue.length >= 3) {
      //   dispatch(fetchVesselSuggestions(inputValue.toUpperCase()));

      dispatch(
        fetchVesselSuggestions({
          vesselKey: inputValue.toUpperCase(),
          requestType: 0,
          permitId: 0,
        })
      );
    }
    if (inputValue.length < 3) {
      setVesselList([]);
    }
  }, 500);

  return (
    <div className="h-100">
      <Form onSubmit={onSubmit} autoComplete="off">
        <div>
          <div className="row">
            <div className="col-lg-6 mb-2">
              <Form.Group controlId="bestPickId">
                <span className="mandatory">*</span>
                <Form.Label className="fs--1">
                  {t("resource_3:bestPickId")}
                </Form.Label>
                <Controller
                  name="bestPickId"
                  control={control}
                  rules={{ required: "This field is required" }}
                  render={({ field }) => (
                    <input
                      {...field}
                      type="text"
                      disabled={true}
                      placeholder="Enter Best Pick ID"
                      className={`fs--1 form-control form-control-sm ${errors.bestPickId ? "is-invalid" : ""
                        }`}
                    // onChange={(e) => {
                    //   field.onChange(e.target.value);
                    //   clearErrors("bestPickId");
                    // }}
                    />
                  )}
                />
                <div className="invalid-feedback">
                  {errors.bestPickId?.message?.toString()}
                </div>
              </Form.Group>
            </div>
            <div className="col-lg-6 mb-2">
              <Form.Group style={{ padding: "0px" }} controlId="vesselVoyage">
                <span className="mandatory">*</span>
                <Form.Label className="fs--1">
                  {t("resource_1:vessel")}
                </Form.Label>

                {/* {!showdata && ( */}
                {!showValues && (
                  <div>
                    <Controller
                      name="vesselVoyage"
                      control={control}
                      rules={{ required: "This field is required" }}
                      render={({ field }: any) => (
                        <Select
                          {...field}
                          {...register("vesselVoyage")}
                          maxMenuHeight={150}
                          options={VesselList ?? []}
                          noOptionsMessage={() => null}
                          isClearable={true}
                          onChange={(e: any) => {
                            if (e === null) {
                              field.onChange(null);
                              setselectedVessel("");
                              setSelectedvesselName("");
                            } else {
                              field.onChange({
                                label: e.value,
                                value: e.value,
                                visitKey: e.visitKey,
                                vesselName: e.vesselName,
                              });
                              setselectedVessel(e.value);
                              setSelectedvesselName(e.vesselName);
                            }
                          }}
                          styles={customDropdownStyles}
                          filterOption={customFilterOption}
                          onInputChange={handleInputChange}
                          className={`fs--1 form-control form-control-sm ${errors.vesselVoyage ? "is-invalid" : ""
                            }`}
                        />
                      )}
                    />
                    <div className="invalid-feedback">
                      {errors.vesselVoyage?.message?.toString()}
                    </div>
                  </div>
                )}

                {showValues && (
                  <div>
                    <Form.Control
                      disabled={true}
                      value={refViewVal}
                      size="sm"
                      type="text"
                      maxLength={20}
                    />
                  </div>
                )}
              </Form.Group>
            </div>
            <div className="col-lg-6 mb-2">
              <Form.Group controlId="ICDShortCode">
                <span className="mandatory">*</span>
                <Form.Label className="fs--1">
                  {t("resource_3:place_of_delivery")}
                </Form.Label>
                {!showValues && (
                  <div>
                    <Controller
                      name="ICDShortCode"
                      control={control}
                      rules={{ required: "This field is required" }}
                      render={({ field }: any) => (
                        <Select
                          {...field}
                          //maxMenuHeight={220}
                          maxMenuHeight={Math.min(ICDcodeList.length * 40, 300)} // Dynamically adjust based on items, capped at 300px
                          menuPlacement="auto"
                          {...register("ICDShortCode")}
                          options={ICDcodeList}
                          noOptionsMessage={() => null}
                          isClearable={true}
                          onChange={(e: any) => {
                            if (e === null) {
                              field.onChange(null);
                              setselectedICDcode("");
                              setshortCodeVal("");
                            } else {
                              field.onChange({
                                label: e.value,
                                value: e.value,
                                visitKey: e.visitKey,
                              });
                              setselectedICDcode(e.value);
                              setshortCodeVal(e.shortCode);
                            }
                          }}
                          styles={customDropdownStyles}
                          className={`fs--1 form-control form-control-sm ${errors.ICDShortCode ? "is-invalid" : ""
                            }`}
                        />
                      )}
                    />
                    <div className="invalid-feedback">
                      {errors.ICDShortCode?.message?.toString()}
                    </div>
                  </div>
                )}

                {showValues && (
                  <div>
                    <Form.Control
                      disabled={true}
                      value={placeOfDeliveryViewVal}
                      size="sm"
                      type="text"
                      maxLength={20}
                    />
                  </div>
                )}
              </Form.Group>
            </div>
          </div>
          {!showValues && (
            <div className="row">
              <div className="col-lg-6 mb-2">
                <Form.Group controlId="container_numbers">
                  <span className="mandatory">*</span>
                  <Form.Label className="fs--1">
                    {t("resource_2:container_numbers_comma_seperated")}
                  </Form.Label>
                  <Form.Control
                    {...register("container_numbers")}
                    as="textarea"
                    // disabled={showValues}
                    className={`form-control ${errors.container_numbers ? "is-invalid" : ""
                      }`}
                    onBlur={() => handleContainerNumbers()}
                  />
                  <div className="invalid-feedback">
                    {errors.container_numbers?.message?.toString()}
                  </div>
                </Form.Group>
              </div>
              {!showValues && (
                <div className="d-flex">
                  <div>
                    <Button
                      onClick={() => {
                        const payload = {
                          tpoId: tpoId,
                          shortCode: shortCodeVal,
                          placeOfDelivery: selectedICDcode,
                          visitKey: selectedVessel,
                          containerList: containerList,
                        };
                        dispatch(bestPickCodes(payload))
                          .unwrap()
                          .then((response: any) => {
                            if (response.status == "FAILED" && response.responseMsg) {
                              toast.error(response.responseMsg)
                            }
                            else if (
                              response.invalidContainerList == "" &&
                              response.containerList.length == 0
                            ) {
                              toast.error(
                                ` Insert at least one container number`,
                                { toastId: "efece1b" }
                              );
                            } else {
                              if (
                                response.invalidContainerList != "" &&
                                response.invalidContainerList
                              ) {
                                /*    toast.error(
                                    `${response.invalidContainerList} container failed` ) */
                                toast.error(
                                  response?.statusDesc || response?.responseMsg,
                                  { toastId: "37be2e9" }
                                );
                              } else if (
                                response.containerList &&
                                response.containerList.length > 0
                              ) {
                                setcontainerList([]);
                                setValue("container_numbers", "");
                              } else if (
                                response.alreadyAssignedContainerList &&
                                response.alreadyAssignedContainerList != ""
                              ) {
                                toast.error(
                                  `${response?.statusDesc || response.responseMsg
                                  }`,
                                  { toastId: "8c963f3" }
                                );
                              } else if (response?.status === "FAILED") {
                                setcontainerList([]);
                                setValue("container_numbers", "");
                                toast.error(
                                  response?.statusDesc || response?.responseMsg,
                                  {
                                    toastId: "c6bca24",
                                  }
                                );
                              } else {
                              }
                            }
                          });
                      }}
                      size="sm"
                      variant="falcon-primary"
                      disabled={
                        containerList.length === 0 ||
                        !getValues("vesselVoyage") ||
                        !selectedICDcode
                      }
                    >
                      <FontAwesomeIcon className="me-1" icon={faAdd} />
                      <span>Add</span>
                    </Button>
                  </div>
                  <Dropdown navbar={true} ref={dropdownRef} style={{}}>
                    <Dropdown.Toggle
                      bsPrefix="toggle"
                      className="btn-falcon-primary btn btn-primary btn-sm mb-2 mx-2"
                    >
                      Upload .xlsx
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      className="
                          dropdown-caret dropdown-menu-card  dropdown-menu-end p-3"
                      style={{ width: "500px" }}
                    >
                      <div>
                        <input
                          type="file"
                          className="form-control mb-2"
                          accept=".xlsx"
                          onChange={(e) => {
                            consumeExcelFile(e, getContainerValuesFromExcel);
                            e.target.value = "";
                            const curr: any = dropdownRef?.current;
                            curr.click?.();
                          }}
                        />
                        <span
                          onClick={() => {
                            window.open(
                              `/api/opc-rest-v1/v1/common/openDocument?id=1&moduleTp=BEST_PICK_TEMPLATE&linerId=1`,
                              "_blank"
                            );
                          }}
                          className="cursor-pointer mt-2"
                          style={{ color: "#2C7BE5" }}
                        >
                          Click here to download the sample excel format
                        </span>
                        <span style={{ color: "#f5803e" }}>
                          <br />
                          Note: First row will be skipped as it will be the
                          column name.
                        </span>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>

                  <div>
                    <Form.Label className="fs--1">
                      Container Count :{" "}
                    </Form.Label>
                    <SoftBadge pill className="fs--2 mx-1" bg="primary">
                      {`${containerList.length}`.padStart(2, "0")}
                    </SoftBadge>
                  </div>
                </div>
              )}

              {/* <a style={{fontSize:'14px'}} href='#'>Click here to download the sample excel format</a>
            <p style={{color:'#f5822a',fontSize:'14px'}}>Note: First row will be skipped as it will be the column name</p> */}
            </div>
          )}
          <div className="row">
            <p
              className="fs-0"
              style={{
                margin: "0px",
                marginTop: "1rem",
                marginBottom: "0.7rem",
              }}
            >
              Container - Group ID Mapping
            </p>
            {bestPickCodesTableData.length > 0 && (
              <div className="d-flex justify-content-between">
                <div>
                  <Form.Label className="fs--1">
                    Passed Container Count :{" "}
                  </Form.Label>
                  <SoftBadge pill className="fs--2 mx-1" bg="primary">
                    {`${bestPickCodesTableData.length}`.padStart(2, "0")}
                  </SoftBadge>
                </div>
                <CsvExport
                  fileName="Best Pick"
                  data={bestPickCodesTableData}
                  columns={ContainerColumns}
                  className="mb-1 ms-2 col-lg-1"
                />
              </div>
            )}
            <DataTable
              data={bestPickCodesTableData}
              gridName="CONTAINER_NUMBERS"
              columns={ContainerColumns}
              sortable={false}
              searchable={false}
              showColumnFilter={false}
              selection={false}
              perPage={1000}
              pagination={false}
              showExport={false}
              uniqueFilterKey="releaseAttachment"
              autoHeight={true}
              staticHiddenCols={[]}
            />
          </div>
        </div>
        {!showValues && (
          <div className="col-lg-8 offset-lg-2 mt-2 d-flex justify-content-center align-items-center">
            <Button
              variant="falcon-primary me-2"
              size="sm"
              // disabled={bestPickCodesTableData.length == 0}
              onClick={onSubmit}
            >
              <FontAwesomeIcon icon={faSave} className="me-2" />
              {t("resource_1:submit")}
            </Button>
          </div>
        )}
      </Form>
    </div>
  );
};

export default BestPick;
