import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { axiosCacheInstance } from "../../../axiosSetup";

export const getBankListBeneficiary = createAsyncThunk(
    'beneficiaryActions/getBankListBeneficiary',
    async () => {
      try {
        const response = await axiosCacheInstance.post('/api/user-management/v1/partners/getPartnerBankAccountDetails');
        return response.data?.map(({accountNo,bankName}:{accountNo:String,bankName:String})=>({
            label:`${bankName}-${accountNo}`,
            value:bankName
        }))
      } catch (e) {
        return [];
    }
    }
  );
  