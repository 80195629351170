import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Dropdown, Form, Modal } from "react-bootstrap";
import * as XLSX from "xlsx";

import {
  // faArrowAltCircleLeft,
  faRedo,
  faSave,
  faUpload,
  faCancel,
  faDownload,
  faCheck,
  faTimes,
  faTrash,
  faAdd,
  faCross,
  faRemove,
} from "@fortawesome/free-solid-svg-icons";
import {
  faArrowAltCircleLeft,
  faCheckCircle,
  faEdit,
} from "@fortawesome/free-regular-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toggleDocumentHistoryColumnShort } from "../../blDetails/slices/documentHistorySlice";
import {
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useState, useEffect, useCallback, useRef } from "react";
import { AppDispatch, RootState } from "../../../store";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { AESDecrypt, AESEncrypt } from "../../../../encrypt-util";
import DataTable from "../../../common/components/DataTable";
import { IDataTableColumn } from "../../../common/types/dataTable";
import UploadIndicator from "../../../../components/common/UploadIndicator";
import {
  resetSSRDocLateGateIn,
  updateAttSSRGate,
  updateSelectedAttachmentFilePath,
  updateSelectedAttachmentName,
  updateSelectedDocId,
  updateSelectedFileServerPath,
  updateSSRDocLateGateIn,
  updateSSRDocumentList,
} from "../../blRequestInstruction/components/instructionSlice/DemurrageSlice";
import { toast } from "react-toastify";
import axiosInstance, { axiosCacheInstance } from "../../../../axiosSetup";
import {
  ApproveRejectSpecialServiceRequest,
  blNumbList,
  blValidation,
  cancelInvoiceRequest,
  CancelSpecialServiceRequest,
  editSSRRequest,
  fetchVesselSuggestions,
  generateInvReferenceNo,
  generateSSRReferenceNo,
  getInvoiceRequest,
  getMstCodeListVal,
  getSpecialServiceRequest,
  submitInvoiceRequest,
  submitSSRRequest,
} from "../../blRequestInstruction/components/actions/Instruction";
import { useBackNavigation } from "../../../../hooks/BackNavigation";
import { customDropdownStyles } from "../../../common/customStyles";
import { updateDashbaordRefresh } from "../../../common/components/DataTable/slices/DataTable";
import axios from "axios";
import { findCustomerByTaxId } from "../../ssrRequest/actions";
import { getRandomNumber } from "../../../../helpers/utils";
import "../../../../assets/scss/ssrRequestInstruction.scss";
import SoftBadge from "../../../../components/common/SoftBadge";
import DatePicker from "react-datepicker";
import { getUserTin } from "../../../common/actions/userDetails";
import { getSsrDocList, getSsrLateGateInDocList, getSsrLateGateInDocName } from "../actions/instruction";
import useDebounce from "../../../../hooks/useDebounce";
import React from "react";
import { useLoading } from "../../../../middleware/loadingMiddleware";
import retry from "../../../../helpers/axiosRetry";

const SSRRequestInstruction = () => {
  const { t } = useTranslation();
  const { requesttp, invoice_request_id } = useParams();
  const rbac: any = useSelector((state: RootState) => state.userProfile);

  const dropdownRef = useRef<any>();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const onBack = useBackNavigation();
  const navigate = useNavigate();
  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );
  const serverFilePath = useSelector(
    (state: RootState) => state.demurrage.selectedFileServerPath
  );

  const ssrreferenceNo = useSelector(
    (state: RootState) => state.creditBalanceBlPayment.generateSSRReferenceNo
  );
  const tpoId = useSelector((state: RootState) => state.agentDelegation.tpoId);

  const selectedAttachmentFilePath = useSelector(
    (state: RootState) => state.demurrage.selectedAttachmentFilePath
  );
  const selectedDocId = useSelector(
    (state: RootState) => state.demurrage.selectedDocId
  );
  const selectedLinerId = useSelector(
    (state: RootState) => state.userProfile.selectedLinerId
  );
  const selectedAttachmentName = useSelector(
    (state: RootState) => state.demurrage.selectedAttachmentName
  );
  const userCompanyTin = useSelector(
    (state: RootState) => state.userProfile.customerTin
  );
  const mstcodeID = useSelector(
    (state: RootState) => state.creditBalanceBlPayment.mstcodeList
  );

  /*   const generateSSRReferenceNo = useSelector(
    (state: RootState) => state.creditBalanceBlPayment.generateSSRReferenceNo
  ); */
  const [showdata, setShowData] = useState(false);
  const docExport = useSelector((state: RootState) => state.demurrage.ssrDoc);
  const docExporLategateIn = useSelector(
    (state: RootState) => state.demurrage.ssrDocLategateIn
  );

  const VesselData: any = useSelector(
    (state: RootState) => state.permitrequest.data
  );

  const blNumbListVal: any = useSelector(
    (state: RootState) => state.permitrequest.blNumbList
  );
  const docAttachment = useSelector(
    (state: RootState) => state.demurrage.attSSR
  );
  const loadingVesselList = useLoading([
    "PermitRequest/fetchVesselSuggestions",
  ]);
  const docAttachmentLateGateIn = useSelector(
    (state: RootState) => state.demurrage.attSSRGate
  );
  const decryptedId = AESDecrypt(invoice_request_id);
  const isDocAttachment = window.location.pathname.includes(decryptedId);

  const defaultCustomerTinColumns = [
    {
      accessor: "value",
      Header: "Customer TIN",
      width: 300,
      maxWidth: 400,
      Cell: (rowData: any) => {
        const { rowNumber, value, error } = rowData.row.original;
        return (
          <div className="position-relative mt-2">
            <input
              type="text"
              defaultValue={value}
              disabled={showdata}
              onBlur={(e) => {
                handleInputChangeValue(rowNumber, e.target.value);
              }}
              className={`form-control form-control-sm ${error ? "is-invalid" : ""
                }`}
            />
          </div>
        );
      },
    },
    {
      accessor: "name",
      Header: "Customer Name",
      width: 300,
      maxWidth: 400,
      Cell: (rowData: any) => {
        const { rowNumber, value, error } = rowData.row.original;
        return (
          <div className="position-relative mt-2">
            <p>
              {
                customerNameList?.find(
                  (data: any) => data?.rowNumber === rowNumber
                )?.partnerNm
              }
            </p>
          </div>
        );
      },
    },
    {
      accessor: "remove",
      Header: "",
      width: 40,
      maxWidth: 40,
      Cell: (rowData: any) => {
        const { rowNumber } = rowData.row.original;
        return (
          <Button
            variant="falcon-warning"
            style={{
              background: "transparent",
              border: "none",
              boxShadow: "none",
              padding: "0px",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              height: "100%",
            }}
            disabled={showdata}
            onClick={() => handleRemoveInput(rowNumber)}
          >
            <FontAwesomeIcon icon={faTrash} size="sm" />
          </Button>
        );
      },
    },
    {
      accessor: "error",
      Header: "",
      width: 200,
      maxWidth: 200,
      Cell: (rowData: any) => {
        const { error } = rowData.row.original;
        if (!error) return <></>;
        return (
          <div className="d-flex align-items-center h-100">
            <span style={{ color: "#E63757", fontWeight: 400 }}>{error}</span>
          </div>
        );
      },
    },
  ];

  const customerTinColumnsApprove = [
    {
      accessor: "value",
      Header: "Customer TIN",
      width: 200,
      maxWidth: 400,
      Cell: (rowData: any) => {
        const { rowNumber, value, error } = rowData.row.original;
        return (
          <div className="position-relative mt-2">
            <input
              type="text"
              defaultValue={value}
              disabled={showdata}
              onBlur={(e) => {
                handleInputChangeValue(rowNumber, e.target.value);
              }}
              className={`form-control form-control-sm ${error ? "is-invalid" : ""
                }`}
            />
          </div>
        );
      },
    },
    {
      accessor: "name",
      Header: "Customer Name",
      width: 300,
      maxWidth: 400,
      Cell: (rowData: any) => {
        const { rowNumber, value, error } = rowData.row.original;
        return (
          <div className="position-relative mt-2">
            <p>
              {
                customerNameList?.find(
                  (data: any) => data?.rowNumber === rowNumber
                )?.partnerNm
              }
            </p>
          </div>
        );
      },
    },
    {
      accessor: "remove",
      Header: "",
      width: 40,
      maxWidth: 40,
      Cell: (rowData: any) => {
        const { rowNumber } = rowData.row.original;
        return (
          <Button
            variant="falcon-warning"
            style={{
              background: "transparent",
              border: "none",
              boxShadow: "none",
              padding: "0px",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              height: "100%",
            }}
            disabled={showdata}
            onClick={() => handleRemoveInput(rowNumber)}
          >
            <FontAwesomeIcon icon={faTrash} size="sm" />
          </Button>
        );
      },
    },
    {
      accessor: "error",
      Header: "",
      width: 200,
      maxWidth: 200,
      Cell: (rowData: any) => {
        const { error } = rowData.row.original;
        if (!error) return <></>;
        return (
          <div className="d-flex align-items-center h-100">
            <span style={{ color: "#E63757", fontWeight: 400 }}>{error}</span>
          </div>
        );
      },
    },
    {
      accessor: "approval",
      Header: "Approval",
      width: 50,
      maxWidth: 50,
      Cell: (rowData: any) => {
        const { value } = rowData.row.original;
        return (
          <input
            type="checkbox"
            disabled={status != "Submitted"}
            onChange={(e) => {
              if (e?.target?.checked) {
                setApprovedCustomerTin((prevState) => [...prevState, value]);
              } else {
                setApprovedCustomerTin((prevState) =>
                  prevState.filter((id: string) => id != value)
                );
              }
            }}
            checked={approvedCustomerTin.includes(value)}
          />
        );
      },
    },
  ];
  const [specialRequestId, setSpecialRequestId] = useState<number | null>();
  const [newcutoffData, setnewcutoffData] = useState<any>(null);
  const [uploadProgress, setUploadProgress] = useState<number | null>(null);
  const [uploadedFile, setUploadedFile] = useState<any>(null);
  const [approveState, setapproveState] = useState<any>(null);
  const [cutOffDateVal, setcutOffDateVal] = useState<any>("");
  const [showTooltip, setShowTooltip] = useState<number | null>();
  const [isUnderlined, setIsUnderlined] = useState(false);
  const linkStyle = {
    cursor: "pointer",
    fontSize: "16px",
    fontStyle: "italic",
    textDecoration: isUnderlined ? "underline" : "none",
  };
  const [requestedPartner, setrequestedPartner] = useState<any>("");
  const [approvalEmailFileName, setapprovalEmailFileName] = useState<any>("");
  const [specialServiceReqAccessIdList, setSpecialServiceReqAccessIdList] =
    useState<any>([]);
  const [uploadStatus, setuploadStatus] = useState<number>(0);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [loadingFileState, setLoadingFileState] = useState<any>();
  const [approvedCustomerTin, setApprovedCustomerTin] = useState<string[]>([]);
  const [customerTinColumn, setCustomerTinColumn] = useState(
    defaultCustomerTinColumns
  );
  const handleOtherFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (!file) return; // If no file, exit the function

    // File size validation
    if (file.size > 10 * 1024 * 1024) {
      toast.error(
        "File size is greater than 10MB. Please upload a file below 10MB"
        , {
          toastId: 'c07072f'
        }
      );
      e.target.value = ""; // Reset file input
      return;
    }

    // File extension validation
    const allowedExtensions =
      /(\.pdf|\.doc|\.docx|\.jpg|\.jpeg|\.png|\.xls|\.xlsx|\.eml)$/i;
    if (!allowedExtensions.exec(file.name)) {
      toast.error("Please upload a valid file type", {
        toastId: 'b33e14f'
      });
      e.target.value = ""; // Reset file input
      return;
    }

    // Create FormData object to send file to server
    const formData = new FormData();
    formData.append("file", file);

    // File upload process
    axiosInstance
      .post("/api/opc-management/v1/view/file", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / (progressEvent.total ?? 1)
          );
          setUploadProgress(percentCompleted);
        },
      })
      .then((response) => {
        const { data } = response;
        setUploadProgress(100); // Set upload progress to 100% when done
        setUploadedFile(data);
      })
      .catch(() => {
        setUploadProgress(-1); // Set upload progress to error state
        setUploadedFile(null);
      });
  };
  const uploadDoument = (rowData: any) => {
    const { rowNumber, dndInstructionAttId, fileName } = rowData.row.original;
    const charLimit = (text: string, maxLength: number) => {
      // Check if the text exceeds the max length
      const isTextCropped = text?.length > maxLength;
      const croppedText = isTextCropped
        ? text.slice(0, maxLength) + "..."
        : text;
      return (
        <div>
          <span
            onMouseEnter={() => setShowTooltip(rowNumber)}
            onMouseLeave={() => setShowTooltip(null)}
          >
            {croppedText}
          </span>
        </div>
      );
    };

    if (!showdata) {
      return (
        <div className="d-flex justify-content-start align-items-center">
          <label
            className="btn btn-sm btn-falcon-primary m-0 me-2"
            htmlFor={`${rowNumber}-input`}
          >
            <FontAwesomeIcon icon={faUpload} />
          </label>
          <input
            id={`${rowNumber}-input`}
            title="Input"
            type="file"
            className="fs--2 form-control ms-1"
            // disabled={disableFields}
            accept=".pdf,.doc,.docx,.jpg,.jpeg,.png,.xls,.xlsx"
            onClick={() => {
              updateSelectedFileServerPath({
                ...serverFilePath,
                [rowNumber]: [null, null],
              });
            }}
            onChange={(e) => {
              handleFileChange(e, rowNumber, serverFilePath);
            }}
            hidden
          />
          {selectedAttachmentFilePath?.[rowNumber] ? (
            <span
              title={fileName}
              className="text-primary cursor-pointer text-truncate"
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_CLIENT_APP_HOST
                  }/api/opc-rest-v1/v1/common/openDocument?id=${dndInstructionAttId}&moduleTp=DND&linerId=${profileDetails.partnerType === "liner"
                    ? profileDetails?.partnerId
                    : selectedLinerId
                  }`,
                  "_blank"
                );
              }}
            >
              {fileName}
            </span>
          ) : (
            <>
              <span title={selectedAttachmentName?.[rowNumber]?.name}>
                {charLimit(selectedAttachmentName?.[rowNumber]?.name, 45)}
              </span>
              {loadingFileState[rowNumber] != 0 && (
                <UploadIndicator
                  style={{ marginLeft: "20px" }}
                  progress={loadingFileState?.[rowNumber]}
                  fail={loadingFileState?.[rowNumber] === -1}
                />
              )}
            </>
          )}
        </div>
      );
    } else {
      return (
        <div className="d-flex justify-content-start align-items-center">
          {/*  <label
            className='btn btn-sm btn-dark m-0 me-2'
            htmlFor={`${docId}-input`}>
            Choose
          </label>
          <input
            id={`${docId}-input`}
            title='Input'
            type='file'
            className='fs--2 form-control ms-1'
            disabled={disableFields}
            onChange={(e) => {
              handleFileChange(e, docId);
            }}
            hidden
          /> */}

          {/* <span
            className='text-primary cursor-pointer'
            onClick={() => {
              window.open(
                `${
                  process.env.REACT_APP_CLIENT_APP_HOST
                }/api/invoice-management/v1/common/openDocument?id=${dndInstructionAttId}&moduleTp=DND&linerId=${
                  profileDetails.partnerType === 'liner'
                    ? profileDetails?.partnerId
                    : selectedLinerId
                }`,
                '_blank'
              );
            }}
          >
            {charLimit(fileName, 50)}
          </span> */}
          <span
            className="text-primary text-truncate cursor-pointer"
            onClick={() => {
              window.open(
                `/api/opc-rest-v1/v1/common/openDocument?id=${selectedAttachmentName?.[rowNumber].specialServiceReqAttId
                }&moduleTp=SSR_REQUEST&linerId=${tpoId}`,
                "_blank"
              );
            }}
            title={selectedAttachmentName?.[rowNumber]?.name}
          >
            {selectedAttachmentName?.[rowNumber]?.name}
          </span>
        </div>
      );
    }
  };

  const toUTCString = (localDateTime: any) => {
    const localDate = new Date(localDateTime);
    return new Date(
      localDate.getTime() - localDate.getTimezoneOffset() * 60000
    ).toISOString();
  };

  const removeAttachment = (rowData: any) => {
    const { rowNumber, docId, m } = rowData.row.original;

    return (
      <>
        {docId == 12 && !showdata && (
          <Button
            variant="falcon-warning"
            style={{
              background: "transparent",
              border: "none",
              boxShadow: "none",
              padding: "0px",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              height: "100%",
            }}
            onClick={() => {
              dispatch(
                updateSSRDocLateGateIn(
                  docExporLategateIn.filter(
                    (data: any, id: any) => data?.rowNumber != rowNumber
                  )
                )
              );
              dispatch(
                updateAttSSRGate(
                  docAttachmentLateGateIn.list.filter(
                    (data: any, id: any) => data?.rowNumber != rowNumber
                  )
                )
              );
            }}
          >
            <FontAwesomeIcon icon={faTrash} size="sm" />
          </Button>
        )}
      </>
    );
  };

  const downloadDoc = (rowData: any) => {
    const { docId, dndInstructionAttId, fileName } = rowData.row.original;

    const charLimit = (name: string, limit: number) =>
      name && name?.length >= limit ? name?.substring(0, limit) + "..." : name;

    return (
      <div className="d-flex justify-content-start align-items-center">
        <span
          className="text-primary cursor-pointer"
          onClick={() => {
            window.open(
              `/api/opc-rest-v1/v1/common/openDocument?id=${selectedAttachmentName?.[docId]?.specialServiceReqAttId}&moduleTp=SSR_REQUEST&linerId=1`,
              "_blank"
            );
          }}
          title={selectedAttachmentName?.[docId]?.name}
        >
          {charLimit(selectedAttachmentName?.[docId]?.name, 45)}
        </span>
      </div>
    );
  };

  const downloadDocApprovalEmail = () => {
    // const { docId, dndInstructionAttId, fileName } = rowData.row.original;
    const docId: any = "";
    const charLimit = (name: string, limit: number) =>
      name && name?.length >= limit ? name?.substring(0, limit) + "..." : name;

    return (
      <div className="d-flex justify-content-start align-items-center">
        <span
          style={{ fontSize: "13.33px" }}
          className="text-primary cursor-pointer"
          onClick={() => {
            window.open(
              `/api/opc-rest-v1/v1/common/openDocument?id=${selectedAttachmentName?.[docId]?.specialServiceReqAttId}&moduleTp=SSR_REQUEST&linerId=1`,
              "_blank"
            );
          }}
          title={approvalEmailFileName}
        >
          {charLimit(approvalEmailFileName, 45)}
        </span>
      </div>
    );
  };

  const showFileName = (rowData: any) => {
    const { docId, dndInstructionAttId, fileName } = rowData.row.original;
    const charLimit = (name: string, limit: number) =>
      name && name?.length >= limit ? name?.substring(0, limit) + "..." : name;

    return <span>{charLimit(fileName, 45)}</span>;
  };

  const docAttachmentColumns: IDataTableColumn[] = [
    {
      accessor: "dndInstructionAttId",
      Header: "dndInstructionAttId",
    },
    {
      accessor: "docId",
      Header: "Document",
      Cell: (rowData: any) => {
        const { docId } = rowData.row.original;
        if (
          profileDetails?.opcPartnerType === "CFA" ||
          getValues("invoiceType") != 2
        ) {
          return (
            <>
              {docExport?.find((v: any) => v?.id === docId)?.m === true ? (
                <span className="mandatory">*</span>
              ) : (
                ""
              )}
              {docExport?.find((v: any) => v?.id === docId)?.name || ""}
            </>
          );
        } else {
          return (
            <>
              {docExporLategateIn?.find((v: any) => v?.id === docId)?.m ? (
                <span className="mandatory">*</span>
              ) : (
                ""
              )}
              {docExporLategateIn?.find((v: any) => v?.id === docId)?.name || ""}
            </>
          );
        }
      },
      width: 250,
      maxWidth: 250,
    },
    {
      accessor: "byteArray",
      Header: showdata ? "Download" : "Upload",
      Cell: uploadDoument,
      width: 450,
      maxWidth: 450,
    },
    {
      accessor: "delete",
      Header: "",
      Cell: removeAttachment,
      width: 150,
      maxWidth: 150,
    },
    {
      accessor: "fileName",
      Header: "fileName",
    },
  ];
  // const docId = AESDecrypt(encryptdoc);
  const [additionalInputs, setAdditionalInputs] = useState<any[]>([]);
  const [customerNameList, setCustomerNameList] = useState<any[]>([]);
  const [attachedFileSize, setAttachedFileSize] = useState(1000);
  const [invoiceCancel, setinvoiceCancel] = useState(false);
  const [ssrRefNo, setssrRefNo] = useState("");
  const [localTransitOptions, setLocalTransitOptions] = useState<any>([]);

  const [columns, setColumns] = useState(docAttachmentColumns);
  const [trafficVal, setTrafficVal] = useState<Number>(0);
  const [selectedVessel, setselectedVessel] = useState<any>("");
  const [selectedPartnerType, setselectedPartnerType] = useState<string>("");
  const [status, setStatus] = useState<any>("");
  const [vesselTxt, setVesselTxt] = useState<string>("");
  const [approverRemarks, setapproverRemarks] = useState<string>("");
  const [approverRemarksVal, setapproverRemarksVal] = useState<string>("");
  const [approverRemarksError, setapproverRemarksError] = useState<string>("");

  const [newCutOffDate, setnewCutOffDate] = useState<any>("");
  const [newCutOffDateError, setNewCutOffDateError] = useState<any>("");
  const [requestedOn, setRequestedOn] = useState<any>("");

  const [VesselList, setVesselList] = useState<any>([]);
  const [trafficOptions, setTrafficOptions] = useState<any>([]);
  const [invoiceOptions, setInvoiceOptions] = useState<any>([]);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedInvoiceOption, setSelectedInvoiceOptions] =
    useState<string>("");
  const [companyTin, setCompanyTin] = useState<any>("");
  const [partnerName, setPartnerName] = useState<any>("");

  const linerDataCountry = useSelector(
    (state: RootState) => state.userProfile.linerDataCountry
  );
  const storedUserCountry = JSON.parse(
    window.localStorage.getItem("@user-selected-datacountry") || "{}"
  );
  const storedCountry =
    storedUserCountry?.[0]?.loginId === profileDetails.email
      ? storedUserCountry?.[0]?.country
      : null;

  const dataCountry = storedCountry
    ? storedCountry
    : linerDataCountry
      ? linerDataCountry
      : profileDetails.partnerCountry || null;

  useEffect(() => {
    if (dataCountry)
      dispatch(generateSSRReferenceNo(dataCountry)).then((response: any) => {
        setssrRefNo(response.data);
      });
  }, [dataCountry]);

  useEffect(() => {
    setCustomerNameList([]);
    // Clear the form state when the component mounts
    setselectedVessel("");
    setValue("blno", "");
    setVesselList([]);
    clearErrors("vesselVoyage");
    clearErrors("blno");
    dispatch(updateSelectedAttachmentName({}));
    dispatch(resetSSRDocLateGateIn());
    setLoadingFileState({});
    dispatch(updateSelectedFileServerPath({}));
  }, []);

  useEffect(() => {
    if (profileDetails?.opcPartnerType === "CFA") {
      setValue("invoiceType", 1);
      setPartnerName(profileDetails.partnerName);
    }
    if (profileDetails?.opcPartnerType === "SLA") {
      setValue("invoiceType", 2);
      setPartnerName(profileDetails.partnerName);
    }
  }, [profileDetails]);

  useEffect(() => {
    if (!showdata) {
      setCompanyTin(userCompanyTin);
    }
  }, [userCompanyTin]);

  useEffect(() => {
    if (profileDetails?.opcPartnerType === "PTO") {
      const approvalColumn = {
        accessor: "approval",
        Header: "Approval",
        width: 50,
        maxWidth: 50,
        Cell: (rowData: any) => {
          const { value } = rowData.row.original;
          return (
            <input
              type="checkbox"
              onChange={(e) => {
                if (e?.target?.checked) {
                  setApprovedCustomerTin((prevState) => [...prevState, value]);
                } else {
                  setApprovedCustomerTin((prevState) =>
                    prevState.filter((id: string) => id != value)
                  );
                }
              }}
              checked={approvedCustomerTin.includes(value)}
            />
          );
        },
      };

      setCustomerTinColumn((prevState) => [...prevState, approvalColumn]);
    }
  }, [profileDetails, showdata, approvedCustomerTin, customerNameList]);

  const handleAddInput = () => {
    setAdditionalInputs((state) => [
      ...state,
      { value: "", rowNumber: crypto.randomUUID(), error: null },
    ]);
  };

  const formatDateTime = (dateString: string) => {
    const date = new Date(dateString);
    const localISOTime =
      date.getFullYear() +
      "-" +
      String(date.getMonth() + 1).padStart(2, "0") +
      "-" +
      String(date.getDate()).padStart(2, "0") +
      "T" +
      String(date.getHours()).padStart(2, "0") +
      ":" +
      String(date.getMinutes()).padStart(2, "0");
    return localISOTime;
  };

  const fetchInvoiceOptions = async () => {
    try {
      if (tpoId) {
        const response = await retry(
          async () =>
            await axiosCacheInstance.get(
              `${process.env.REACT_APP_API_GATEWAY_HOST}/api/opc-rest-v1/v1/mstCode/getMstCodeListByTypeAndLanguage?language=EN&codeType=SSR_OPTIONS&linerId=${tpoId}&countryCode=TZ`,
              {
                cache: {
                  ttl: 10 * 60 * 1000,
                },
              }
            ),
          2
        );
        setInvoiceOptions(response.data ?? []);
      }
      /*      setSelectedInvoiceOptions(
        response?.data?.[defaultLastGateIn === "Y" ? 1 : 0]?.codeId
      ); */
      if (profileDetails?.opcPartnerType === "CFA") {
        // setValue("invoiceType", 1);
        setSelectedInvoiceOptions("1");
      }
      if (profileDetails?.opcPartnerType === "SLA") {
        // setValue("invoiceType", 2);
        setSelectedInvoiceOptions("2");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchTrafficOptions = async () => {
    try {
      const response = await retry(
        async () =>
          await axiosCacheInstance.get(
            `${process.env.REACT_APP_API_GATEWAY_HOST}/api/opc-rest-v1/v1/mstCode/getMstCodeListByTypeAndLanguage?language=EN&codeType=TRAFFIC_OPTIONS&linerId=${tpoId}&countryCode=TZ`,
            {
              cache: {
                ttl: 10 * 60 * 1000,
              },
            }
          ),
        2
      );

      setTrafficOptions(response.data.filter((item: any) => item.codeId == 2));

      // (response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchLocalTransitOptions = async () => {
    try {
      const response = await retry(
        async () =>
          await axiosCacheInstance.get(
            `${process.env.REACT_APP_API_GATEWAY_HOST}/api/opc-rest-v1/v1/mstCode/getMstCodeListByTypeAndLanguage?language=EN&codeType=LOCAL/TRANSIT_OPTIONS&linerId=${tpoId}&countryCode=TZ`,
            {
              cache: {
                ttl: 10 * 60 * 1000,
              },
            }
          ),
        2
      );
      setLocalTransitOptions(response.data ?? []);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // dispatch(generateInvReferenceNo(''));
    dispatch(getMstCodeListVal());
    if (tpoId) {
      fetchTrafficOptions();
      fetchLocalTransitOptions();
      fetchInvoiceOptions();
    }
    setTrafficVal(2);

    // dispatch(
    //   fetchVesselSuggestions({
    //     shipmentTp: 'import',
    //   })
    // );
  }, [tpoId]);

  useEffect(() => {
    if (VesselData && vesselTxt) {
      if (Array.isArray(VesselData)) {
        const formattedOptions = VesselData.map((vessel: any) => ({
          value: `${vessel.visitKey}`,
          label: `${vessel.vesselName} - ${vessel.visitKey}${vessel.ata ? " - " + new Date(vessel.ata).toLocaleString() : ""
            }`,
          visitKey: vessel.visitKey,
        }));
        setValue("shippingLine", VesselData?.[0]?.line);
        setVesselList(formattedOptions);
      }
    }
  }, [VesselData]);

  useEffect(() => {
    if (requesttp && invoice_request_id) {
      if (
        matchPath(
          "/opc/instruction/:requesttp/:invoice_request_id",
          location.pathname
        )
      ) {
        dispatch(getSpecialServiceRequest(AESDecrypt(invoice_request_id)))
          .then((response: any) => {
            let tempSelectedAttachmentName = {};
            let tempDocList: Array<any> = [];
            let tempAttList: Array<any> = [];
            for (var i = 0; i < response.payload.data.documents.length; i++) {
              // row number for SLA is specialServiceReqAttId
              // row number for CFA is docId
              tempSelectedAttachmentName = {
                ...tempSelectedAttachmentName,
                [profileDetails.opcPartnerType == "SLA" ||
                  response?.payload?.data?.ssrType == 2
                  ? response.payload.data.documents[i]?.specialServiceReqAttId
                  : response.payload.data.documents[i]?.docId]: {
                  name: response.payload.data.documents[i]?.fileName || null,
                  invoiceRequestAttId:
                    response.payload.data.documents[i]?.invoiceRequestAttId ||
                    null,
                  specialServiceReqAttId:
                    response.payload.data.documents[i]
                      ?.specialServiceReqAttId || null,
                },
              };

              tempDocList = [
                ...tempDocList,
                {
                  id: response.payload.data.documents[i].docId,
                  name:
                    response.payload.data.documents[i].docId == 11
                      ? "Loading permission"
                      : "Others",
                  m: true,
                  rowNumber:
                    profileDetails.opcPartnerType == "SLA" ||
                      response?.payload?.data?.ssrType == 2
                      ? response.payload.data.documents[i]
                        ?.specialServiceReqAttId
                      : response.payload.data.documents[i]?.docId,
                },
              ];

              tempAttList = [
                ...tempAttList,
                {
                  dndInstructionAttId:
                    response.payload.data.documents[i]?.specialServiceReqAttId,
                  docId: response.payload.data.documents[i].docId,
                  fileName: response.payload.data.documents[i]?.fileName,
                  byteArray: "hdavdddjhcvhjvdj",
                  downloadBtn: true,
                  m: true,
                  rowNumber:
                    profileDetails.opcPartnerType == "SLA" ||
                      response?.payload?.data?.ssrType == 2
                      ? response.payload.data.documents[i]
                        ?.specialServiceReqAttId
                      : response.payload.data.documents[i]?.docId,
                  filePath: response.payload.data.documents[i]?.filePath,
                },
              ];

              dispatch(
                updateSSRDocumentList({
                  rowNumber: response.payload.data.documents[i]?.docId,
                  filePath: response.payload.data.documents[i]?.filePath,
                  fileName: response.payload.data.documents[i]?.fileName,
                })
              );
            }
            setTimeout(() => {
              dispatch(updateAttSSRGate(tempAttList));
              dispatch(updateSSRDocLateGateIn(tempDocList));
              dispatch(
                updateSelectedAttachmentName(tempSelectedAttachmentName)
              );
            }, 100);
            setTimeout(() => {
              setValidationObject(
                Yup.object().shape({
                  ...validationObject.fields,
                  approvalRemarksValidation: Yup.string().required(
                    "Approver Remarks is required"
                  ),
                  newCutOffDateValidation: Yup.date()
                    .nullable()
                    .transform((curr, orig) => (orig === "" ? null : curr))
                    .required("New Cut Off Date&Time is required"),
                })
              );
            }, 100);
            setShowData(true);
            setTimeout(() => {

              setselectedPartnerType(response?.payload?.data?.partnerType);
              setValue("invoiceNumber", response?.payload?.data?.odexRefNo);
              setapprovalEmailFileName(response?.payload?.data?.fileName);
              setValue("invoiceType", response?.payload?.data?.ssrType);
              setSelectedInvoiceOptions(response?.payload?.data?.ssrType);
              // setValue("customerTin", response?.payload?.data?.identityNo);
              setCompanyTin(response?.payload?.data?.identityNo);
              /*      setValue(
                "shipperConsigneeName",
                response?.payload?.data?.requestedPartnerNm
              ); */

              setValue(
                "newCutOffDateValidation",
                response?.payload?.data?.[
                getValues("invoiceType") == 1
                  ? "extInvoiceCutOff"
                  : "extGateIn"
                ]
              );

              response?.paylaod?.data?.documents?.forEach(
                (data: any, index: number) => {
                  uploadDoument({
                    rowNumber: index,
                    dndInstructionAttId: data?.docId,
                    fileName: data?.fileName,
                  });
                }
              );
              setCustomerNameList(
                response?.payload?.data?.customerDetails?.map(
                  (data: any, id: number) => ({
                    mstPartnerId: data?.accessPartnerId,
                    specialServiceReqAccessId: data?.specialServiceReqAccessId,
                    specialServiceReqId: data?.specialServiceReqId,
                    partnerNm: data?.accessPartnerNm,
                    identityNo: data?.identityNo,
                    rowNumber: id,
                  })
                )
              );
              setSpecialServiceReqAccessIdList(
                response?.payload?.data?.customerDetails?.map(
                  (data: any, id: number) => ({
                    specialServiceReqAccessId: data?.specialServiceReqAccessId,
                    specialServiceReqId: data?.specialServiceReqId,
                  })
                )
              );
              setApprovedCustomerTin(
                response?.payload?.data?.status == 303
                  ? response?.payload?.data?.customerDetails?.map(
                    ({ identityNo }: any) => identityNo
                  )
                  : response?.payload?.data?.customerDetails
                    ?.filter(({ isAccess }: any) => isAccess == "Y")
                    ?.map(({ identityNo }: any) => identityNo)
              );
              setAdditionalInputs(
                response?.payload?.data?.customerDetails?.map(
                  (data: any, id: number) => ({
                    value: data?.identityNo,
                    rowNumber: id,
                    error: null,
                  })
                )
              );
              setValue("shippingLine", response?.payload?.data?.shippingLine);
              setValue(
                "numberOfContainers",
                response?.payload?.data?.noOfContainer
              );
              setPartnerName(response?.payload?.data?.requestedPartnerNm);
              setrequestedPartner(
                response?.payload?.data?.requestedPartnerType
              );
              setValue("traffic", response?.payload?.data?.traffic);
              setTrafficVal(response?.payload?.data?.traffic);
              setValue("deliveryType", response?.payload?.data?.deliveryType);
              setValue("delayReason", response?.payload?.data?.reasonToDelay);
              if (response?.payload?.data?.extGateIn) {
                // setnewcutoffData(formatDateTime(response?.payload?.data?.extGateIn))
                setValue(
                  "newCutOffDateValidationVal",
                  response?.payload?.data?.extGateIn
                    ? formatDateTime(response?.payload?.data?.extGateIn)
                    : ""
                );
              } else if (response?.payload?.data?.extInvoiceCutOff) {
                // setnewcutoffData(formatDateTime(response?.payload?.data?.extInvoiceCutOff))
                setValue(
                  "newCutOffDateValidationVal",
                  response?.payload?.data?.extInvoiceCutOff
                    ? formatDateTime(response?.payload?.data?.extInvoiceCutOff)
                    : ""
                );
              } else {
                setValue("newCutOffDateValidationVal", null);
              }
              setValue("vesselVoyage", {
                value: response?.payload?.data?.vesselVoyage,
                label: response?.payload?.data?.vesselVoyage,
              });
              setselectedVessel(response?.payload?.data?.vesselVoyage);
              setcutOffDateVal(response?.payload?.data?.vesselVoyage);
              if (response?.payload?.data?.approvalRemarks) {
                setapproverRemarksVal(response?.payload?.data?.approvalRemarks);
              }
              if (response?.payload?.data?.approvedOn) {
                setapproveState(response?.payload?.data?.approvedOn);
              } else {
                setapproveState(null);
              }
              dispatch(
                blNumbList({
                  visit: response?.payload?.data?.vesselVoyage,
                  permit: response?.payload?.data?.traffic,
                })
              );
              setValue(
                "vesselVoyageShow",
                response?.payload?.data?.vesselVoyage
              );
              setValue("blno", response?.payload?.data?.blNo);
              setValue("remarks", response?.payload?.data?.remarks);
              setRequestedOn(response?.payload?.data?.requestedOn);
              const statusMap: any = {
                "307": "Cancelled",
                "311": "Approved",
                "312": "Partial Approved",
                "304": "Rejected",
                "303": "Submitted",
              };

              const statusValue = response?.payload?.data?.status;
              setStatus(statusMap[statusValue] || "");
              setSelectedFile({
                tempFileName: response?.payload?.data?.fileName,
                path: response?.payload?.data?.filePath,
              });
              setSpecialRequestId(
                response?.payload?.data?.specialServiceReqId ?? null
              );
            }, 100);
          })
          .catch((e) => {
            console.error(e, "<<errrrror");
          });
      }
    }
  }, [requesttp, invoice_request_id]);

  // useEffect(() => {
  //   const decryptedId = AESDecrypt(invoice_request_id);
  //   const isDocAttachment = window.location.pathname.includes(decryptedId);

  //   setColumns(isDocAttachment ? docAttachmentColumns : docDownloadColumns);
  // }, [invoice_request_id, selectedAttachmentName]);

  useEffect(() => {
    if (ssrreferenceNo) {
      setValue("invoiceNumber", ssrreferenceNo);
    }
  }, [ssrreferenceNo]);

  useEffect(() => {
    setUploadedFile(null);
  }, [invoice_request_id]);

  const validationSchemaLastGateIn = Yup.object().shape({
    invoiceNumber: Yup.string().required(
      "Invoice Reference Number is required"
    ),

    blno: Yup.string().required("so number is required"),
    // .test("validate-bl", "BL/SO number is invalid", (val) => {
    //   if (!val) return false; // if val is undefined or null, fail the test
    //   if (val.length > 0) {
    //     if (
    //       val
    //         .split(",")
    //         .every((data: string) =>
    //           blNumbListVal.some(({ blNumber }: any) => blNumber == data)
    //         )
    //     ) {
    //       return true; // Pass the validation
    //     } else {
    //       return false; // Fail the validation
    //     }
    //   }
    //   return true; // Pass the validation if no issues
    // }),
    /*    customerTin: Yup.string()
      .required("Requester Company TIN is required")
      .matches(/^[0-9-]+$/, "Shipper/Consignee TIN must be a number"),
    shipperConsigneeName: Yup.string().required(
      "Requester Comapny Name is required"
    ), */
    //traffic: Yup.string().required("Traffic is required"),
    invoiceType: Yup.string().required("Invoice Type is required"),
    delayReason: Yup.string()
      .required("Reason for Delay is required")
      .max(500, "Reason for Delay cannot be more than 500 characters"),
    // deliveryType: Yup.string().required("Local/Transit is required"),
    vesselVoyageShow: Yup.string(),
    vesselVoyage: Yup.object().required("Vessel is required").nullable(),
    // vesselVoyage: Yup.object().required('Vessel is required').nullable(),
    numberOfContainers: Yup.string().required(
      "Total number of containers is required"
    ),
    shippingLine: Yup.string().required("Shipping Line is required"),
    remarks: Yup.string().max(
      500,
      "Remarks cannot be more than 250 characters"
    ),
  });

  const validationSchemaCFA = Yup.object().shape({
    invoiceNumber: Yup.string().required(
      "Invoice Reference Number is required"
    ),

    // approvalRemarksValidation: Yup.string().required("Approver Remarks is required"),

    // newCutOffDateValidation: Yup.date()
    // .nullable()
    // .transform((curr, orig) => (orig === "" ? null : curr))
    // .required("New Cut Off Date&Time is required"),

    blno: Yup.string().required("so number is required"),
    // .test("validate-bl", "BL/SO number is invalid", function (val) {
    //   if (!val) return false; // if val is undefined or null, fail the test
    //   if (val.length > 0) {
    //     if (
    //       val
    //         .split(",")
    //         .every((data: string) =>
    //           blNumbListVal.some(({ blNumber }: any) => blNumber === data)
    //         )
    //     ) {
    //       return true; // Pass the validation
    //     } else {
    //       return false; // Fail the validation
    //     }
    //   }
    //   return true; // Pass the validation if no issues
    // })
    /*    customerTin: Yup.string()
      .required("Requester Company TIN is required")
      .matches(/^[0-9-]+$/, "Shipper/Consignee TIN must be a number"),
    shipperConsigneeName: Yup.string().required(
      "Requester Comapny Name is required"
    ), */
    //traffic: Yup.string().required("Traffic is required"),
    invoiceType: Yup.string().required("Invoice Type is required"),
    delayReason: Yup.string()
      .required("Reason for Delay is required")
      .max(500, "Reason for Delay cannot be more than 500 characters"),
    deliveryType: Yup.string().required("Local/Transit is required"),
    vesselVoyageShow: Yup.string(),
    vesselVoyage: Yup.object().required("Vessel is required").nullable(),
    // vesselVoyage: Yup.object().required('Vessel is required').nullable(),
    remarks: Yup.string().max(
      500,
      "Remarks cannot be more than 250 characters"
    ),
  });

  const [validationObject, setValidationObject] =
    useState<any>(validationSchemaCFA);

  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
    control,
    clearErrors,
    setError,
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(validationObject),
  });
  const customFilterOption = (option: any, inputValue: any) => {
    // if (inputValue.length < 3) {
    //   return false;
    // }
    return option.label.toLowerCase().includes(inputValue.toLowerCase());
  };

  useEffect(() => {
    setValidationObject(
      getValues("invoiceType") == 2
        ? validationSchemaLastGateIn
        : validationSchemaCFA
    );
  }, [getValues("invoiceType")]);

  const onApproveReject = (payload: any) => {
    // if (getValues("invoiceType") == 2) {
    let isErr = false;
    if (!newCutOffDate && payload.status == 311) {
      isErr = true;
      setNewCutOffDateError("Cut off date is required");
    } else {
      setNewCutOffDateError("");
    }

    if (!approverRemarks && payload.status == 304) {
      isErr = true;
      setapproverRemarksError("Approval remark is required");
    } else {
      setapproverRemarksError("");
    }
    if (isErr) {
      return;
    }
    // }
    dispatch(ApproveRejectSpecialServiceRequest(payload))
      .unwrap()
      .then((response) => {
        if (response?.data?.responseCode) {
          onBack();
          toast.success(response?.data?.responseMsg, {
            toastId: '935ed09'
          });
          setTimeout(() => {
            dispatch(updateDashbaordRefresh(true));
          }, 3000);
        }
      });
  };

  const handleFileChange = (e: any, fileDocId: any, filePaths: any) => {
    if (e.target.files) {
      const file = e.target.files[0];
      if (file.size > 10 * 1024 * 1024) {
        toast.error(
          "File size is greater than 10MB. Please upload file below 10MB"
          , {
            toastId: 'b729c1e'
          }
        );
        e.target.value = null;
        return;
      }
      const allowedExtensions = [
        ".pdf",
        ".doc",
        ".docx",
        ".jpg",
        ".jpeg",
        ".png",
        ".xls",
        ".xlsx",
        ".eml",
      ];
      const fileExt = file.name.slice(-4).toLowerCase();
      const fileExtLong = file.name.slice(-5).toLowerCase();

      if (
        !allowedExtensions.includes(fileExt) &&
        !allowedExtensions.includes(fileExtLong)
      ) {
        toast.error("Please upload a valid file (PDF,JPEG,PNG,WORD,EXCEL)", {
          toastId: 'd959016'
        });
        e.target.value = null;
        return;
      }
      const formData = new FormData();
      formData.append("file", file);
      try {
        setLoadingFileState((state: any) => {
          return { ...state, [fileDocId]: 0 };
        });
        axiosInstance
          .post("/api/opc-management/v1/view/file", formData, {
            headers: {
              accept: "*/*",
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress(progressEvent) {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / (progressEvent.total ?? 1)
              );
              setLoadingFileState((state: any) => {
                return { ...state, [fileDocId]: percentCompleted };
              });
            },
          })
          .then((response) => {
            setLoadingFileState((state: any) => {
              return { ...state, [fileDocId]: 100 };
            });
            dispatch(
              updateSelectedFileServerPath({
                ...filePaths,
                [fileDocId]: [response.data.path, response.data.tempFileName],
              })
            );
            dispatch(
              updateSelectedAttachmentName({
                ...selectedAttachmentName,
                [fileDocId]: file,
              })
            );

            dispatch(
              updateSelectedAttachmentFilePath({
                ...selectedAttachmentFilePath,
                [fileDocId]: null,
              })
            );

            dispatch(
              updateSelectedDocId({
                ...selectedDocId,
                [fileDocId]: file[0],
              })
            );
          })
          .catch(() => {
            setLoadingFileState((state: any) => {
              return { ...state, [fileDocId]: -1 };
            });
            if (getValues("invoiceType") == 2) {
              const row = {
                ...docAttachmentLateGateIn.list?.find(
                  (data: any) => data?.rowNumber == fileDocId
                ),
              };
              row.fileName = "";
              dispatch(
                updateAttSSRGate(
                  docAttachmentLateGateIn.list.map((data: any) =>
                    data?.rowNumber != fileDocId ? data : row
                  )
                )
              );
            }
            dispatch(
              updateSelectedFileServerPath({
                ...filePaths,
                [fileDocId]: null,
              })
            );
            const emptyFile = {
              name: null,
              invoiceRequestAttId: null,
              specialServiceReqAttId: null,
            };

            dispatch(
              updateSelectedAttachmentName({
                ...selectedAttachmentName,
                [fileDocId]: emptyFile,
              })
            );
            dispatch(
              updateSelectedAttachmentFilePath({
                ...selectedAttachmentFilePath,
                [fileDocId]: null,
              })
            );
            dispatch(
              updateSelectedDocId({
                ...selectedDocId,
                [fileDocId]: emptyFile,
              })
            );
          });
        // add server path to redux
      } catch (error) {
        console.error("File Upload Error: ", error);
        console.error("File Upload Error: ", error);
      }
    }
  };

  const handleExcelFileChange = (e: any) => {
    if (e.target.files) {
      const file = e.target.files[0];
      if (file.size > 10 * 1024 * 1024) {
        toast.error(
          "File size is greater than 10MB. Please upload file below 10MB"
          , {
            toastId: 'd01b776'
          });
        e.target.value = null;
        return;
      }
      const allowedExtensions = [".xls", ".xlsx", ".csv"];
      const fileExt = file.name.slice(-4).toLowerCase();
      const fileExtLong = file.name.slice(-5).toLowerCase();

      if (
        !allowedExtensions.includes(fileExt) &&
        !allowedExtensions.includes(fileExtLong)
      ) {
        toast.error("Please upload a valid EXCEL file", {
          toastId: '20a03ae'
        });
        e.target.value = null;
        return;
      }
      const formData = new FormData();
      formData.append("file", file);
      try {
        axiosInstance
          .post("/api/opc-management/v1/view/file", formData, {
            headers: {
              accept: "*/*",
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress(progressEvent) {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / (progressEvent.total ?? 1)
              );
              setuploadStatus(percentCompleted);
            },
          })
          .then((response) => {
            const { data } = response;
            setuploadStatus(100);
            setSelectedFile(data);
          })
          .catch(() => {
            setuploadStatus(-1);
            setSelectedFile(null);
          });
        // add server path to redux
      } catch (error) {
        console.error("File Upload Error: ", error);
        console.error("File Upload Error: ", error);
      }
    }
  };

  const resetForm = () => {
    /*     setValue('invoiceNumber', '');
    setValue('invoiceType', ''); */
    clearErrors("customerTin");
    clearErrors("shipperConsigneeName");
    clearErrors("blno");
    clearErrors("traffic");
    clearErrors("vesselVoyage");
    clearErrors("deliveryType");
    clearErrors("delayReason");
    clearErrors("companyTin");
    setValue("customerTin", "");
    setValue("shipperConsigneeName", "");
    setValue("deliveryType", "");
    setValue("vesselVoyage", "");
    setValue("vesselVoyageShow", "");
    setValue("blno", "");
    setValue("delayReason", "");
    setValue("remarks", "");
    setValue("numberOfContainers", "");
    dispatch(updateSelectedAttachmentName({}));
    setCustomerNameList([]);
    setAdditionalInputs([]);
    dispatch(
      updateSSRDocLateGateIn([
        { id: 11, name: "Loading permission", m: true, rowNumber: 11 },
        { id: 12, name: "Others", m: false, rowNumber: 12 },
      ])
    );
    dispatch(
      updateAttSSRGate([
        {
          dndInstructionAttId: null,
          docId: 11,
          fileName: "",
          byteArray: "hdavdddjhcvhjvdj",
          downloadBtn: true,
          m: true,
          rowNumber: crypto.randomUUID(),
        },
        {
          dndInstructionAttId: null,
          docId: 12,
          fileName: "",
          byteArray: "hdavjhcvddddhjvdj",
          downloadBtn: true,
          rowNumber: crypto.randomUUID(),
          m: true,
        },
      ])
    );

    setLoadingFileState({});
    dispatch(updateSelectedFileServerPath({}));
  };
  const charLimit = (name: string, limit: number) =>
    name && name?.length >= limit ? name?.substring(0, limit) + "..." : name;
  const downloadDoument = (rowData: any) => {
    const { docId, dndInstructionAttId, fileName, permitNumber } =
      rowData.row.original;
    return (
      <FontAwesomeIcon
        onClick={() => {
          window.open(
            `${process.env.REACT_APP_CLIENT_APP_HOST
            }/api/opc-rest-v1/v1/common/openDocument?id=${dndInstructionAttId}&moduleTp=DND&linerId=${profileDetails.partnerType === "liner"
              ? profileDetails?.partnerId
              : selectedLinerId
            }`,
            "_blank"
          );
        }}
        icon={faDownload}
        style={{ color: "orange" }}
      />
    );
  };

  const docDownloadColumns: IDataTableColumn[] = [
    {
      accessor: "dndInstructionAttId",
      Header: "dndInstructionAttId",
    },
    {
      accessor: "docId",
      Header: "Document",
      Cell: (rowData: any) => {
        const { docId } = rowData.row.original;
        if (requestedPartner === "CFA") {
          return (
            <>
              {docExport?.find((v: any) => v?.id === docId)?.m === true ? (
                <span className="mandatory">*</span>
              ) : (
                ""
              )}
              {docExport?.find((v: any) => v?.id === docId)?.name || ""}
            </>
          );
        } else {
          return (
            <>
              {docExporLategateIn?.find((v: any) => v?.id === docId)?.m ===
                true ? (
                <span className="mandatory">*</span>
              ) : (
                ""
              )}
              {docExporLategateIn?.find((v: any) => v?.id === docId)?.name || ""}
            </>
          );
        }
      },
      width: 250,
      maxWidth: 250,
    },
    // {
    //   accessor: "upload",
    //   Header: "Attachment",
    //   Cell: showFileName,
    //   width: 250,
    //   maxWidth: 450,
    // },
    {
      accessor: "byteArray",
      Header: "Download",
      Cell: downloadDoc,
      width: 450,
      maxWidth: 450,
    },
    // {
    //   accessor: "download",
    //   Header: t("resource_1:download"),
    //   Cell: downloadDoument,
    //   width: 250,
    //   maxWidth: 450,
    // },
    {
      accessor: "fileName",
      Header: "fileName",
    },
  ];

  const consumeExcelFile = (event: any, setter: Function) => {
    const file = event.target.files[0];
    if (!file) return;

    // Check for .xlsx file type
    if (
      file.type !==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      toast.error("Please upload a valid .xlsx file.", {
        toastId: '823d96e'
      });
      return;
    }

    const reader = new FileReader();

    reader.onload = function (event: any) {
      try {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });

        // Assuming you want to read only the first sheet
        const firstSheetName = workbook.SheetNames[0];
        if (!firstSheetName) {
          console.error("No sheets found in the workbook.");
          return;
        }

        const sheet = workbook.Sheets[firstSheetName];
        const json: any = XLSX.utils.sheet_to_json(sheet, {
          header: 1,
          defval: "",
        });

        //const customerTinNumbers = json.filter(({ value }: any, i: number) => i != 0 && value && value?.trim() != '').slice(1, json.length);
        const output = json
          .map((i: any) => ({
            value: `${i[0]}`,
            error: null,
            rowNumber: crypto.randomUUID(),
          }))
          .filter(
            ({ value }: any, i: number) =>
              i != 0 && value && value?.trim() != ""
          );

        if (output.length == 0) {
          toast.error(
            "Blank data file cannot be uploaded, Kindly insert at least 1 data entry and retry"
            ,
            {
              toastId: '0047d31'
            }
          );
          return;
        }
        toast.success("Added Entries Successfully!",
          {
            toastId: 'a800c86'
          }
        );
        setter(
          json
            .map((i: any) => ({
              value: `${i[0]}`,
              error: null,
              rowNumber: crypto.randomUUID(),
            }))
            .filter(
              ({ value }: any, i: number) =>
                i != 0 && value && value?.trim() != ""
            )
        );
      } catch (error) {
        console.error("Error reading file:", error);
        return;
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const handleCancel = () => {
    if (requesttp && invoice_request_id) {
      dispatch(
        CancelSpecialServiceRequest({
          //  request_id: AESDecrypt(invoice_request_id),
          // tpoId: tpoId,
          specialServiceReqId: AESDecrypt(invoice_request_id),
          status: "307",
          approvalRemarks: "test ",
          requestedOn: requestedOn,
          extInvoiceCutOff: "2024-08-30T10:09:53",
          extGateIn: "2024-08-30T10:09:53",
          customerDetails: customerNameList.map((data) => ({
            accessPartnerId: data?.mstPartnerId,
            accessPartnerNm: data?.partnerNm,
            identityNo: data?.identityNo,
            isAccess: approvedCustomerTin.includes(data?.identityNo)
              ? "Y"
              : "N",
          })),
          serverFilePath: selectedFile?.path,
          tempFileName: selectedFile?.tempFileName,
        })
      )
        .then((response: any) => {
          toast.success(response.payload.data.responseMsg, {
            theme: "colored",
            toastId: '47c5f44'
          });
          setinvoiceCancel(false);

          resetForm();

          onBack();
          setTimeout(() => {
            dispatch(updateDashbaordRefresh(true));
          }, 500);
        })
        .catch(() => { });
    }
  };

  const cfaTinListFieldError = (rowNumber: string, error: string) => {
    const newInputs = [...additionalInputs];
    const row = newInputs?.find((i) => i.rowNumber == rowNumber);
    if (!row) return;
    row.error = error;
    setAdditionalInputs(newInputs);
  };

  useEffect(() => {
    if (!showdata) {
      dispatch(getUserTin());
    }
  }, [showdata]);

  const getConsumerTin = async (data: any[]) => {
    const firstElementRemovedArr = data;
    let customerNameList: any = [];
    let newInputs: any = [];
    const promises = firstElementRemovedArr?.map(
      async (data: any, index: number) => {
        try {
          const response: any = await dispatch(
            findCustomerByTaxId({ value: data?.value })
          ).unwrap();
          const { partnerNm } = response?.data;

          if (partnerNm) {
            customerNameList.push({ rowNumber: index + 1, ...response?.data });
            newInputs.push({
              value: data?.value,
              rowNumber: index + 1,
              error: null,
            });
          } else {
            customerNameList.push({ rowNumber: index + 1, partnerNm: "" });
            newInputs.push({
              value: data?.value,
              rowNumber: index + 1,
              error: "Error in getting partner name",
            });
          }
        } catch (error) {
          newInputs.push({
            value: data?.value,
            rowNumber: index + 1,
            error: "Error fetching customer data",
          });
        }
      }
    );

    // Wait for all promises to resolve
    await Promise.all(promises);

    setAdditionalInputs(newInputs);
    setCustomerNameList(customerNameList);
  };

  const handleInputChangeValue = useCallback(
    (rowNumber: string, value: string) => {
      const newInputs = [...additionalInputs];
      const formattedTin = formatTIN(value);
      const row = newInputs?.find((i) => i.rowNumber == rowNumber);
      if (!row) return;
      row.value = formattedTin;
      row.error = null;
      setAdditionalInputs(newInputs);
      setCustomerNameList((prevState) => [
        ...prevState.filter((data) => data?.rowNumber != rowNumber),
      ]);

      if (value.trim() != "")
        dispatch(findCustomerByTaxId({ value: formattedTin }))
          .unwrap()
          .then((response: any) => {
            const { partnerNm } = response?.data;
            if (response?.data?.partnerNm) {
              const selectedCustomer = customerNameList?.filter(
                (data) => data?.rowNumber != rowNumber
              );
              setCustomerNameList((prevState) => [
                ...prevState,
                { rowNumber, ...selectedCustomer, ...response?.data },
              ]);
            } else {
              const selectedCustomer = customerNameList?.filter(
                (data) => data?.rowNumber != rowNumber
              );
              setCustomerNameList((prevState) => [
                ...prevState.filter((data) => data?.rowNumber != rowNumber),
                { ...selectedCustomer, rowNumber, partnerNm: "" },
              ]);
              cfaTinListFieldError(rowNumber, "Error in getting partner name");
            }
          })
          .catch((error) => {
            cfaTinListFieldError(rowNumber, "Error in getting partner name");
          });
    },
    [additionalInputs]
  );

  const onSubmit = handleSubmit(
    async (data: any) => {
      const blData = blNumbListVal?.find(
        (bl: any) => bl.blNumber === data.blno.trim()
      );
      if (
        (!companyTin || companyTin == "") &&
        profileDetails.opcPartnerType != "PTO"
      ) {
        setError("companyTin", { message: "Company TIN is required" });
        return;
      }
      if (
        !getValues("blno")
          .split(",")
          .every((data: string) =>
            blNumbListVal.some(({ blNumber }: any) => blNumber == data)
          )
      ) {
        setError("blno", {
          type: "manual",
          message: "BL/SO number is invalid",
        });
        return;
      }

      // Check if there's an error in the 'blno' field or if the DO number is missing
      /*   if (trafficVal == 1 && (errors.blno || (blData && !blData.doNumber))) { */
      if (
        trafficVal == 1 &&
        profileDetails?.opcPartnerType !== "ICD" && // Ensure profileDetails.opcPartnerType is not "ICD"
        (errors.blno || (blData && !blData.doNumber))
      ) {
        setError("blno", {
          type: "manual",
          message: "DO Number is not present for this BL/SO Number",
        });

        // Safely extract and display the error message
        const errorMessage =
          typeof errors.blno?.message === "string"
            ? errors.blno.message
            : "Please correct the BL/SO Number before submitting.";

        toast.error(errorMessage, {
          toastId: '91c4238'
        });
        return; // Stop form submission
      }

      if (getValues("invoiceType") == 2) {
        if (additionalInputs?.length < 1) {
          toast.error("Atlease one Customer tin is required", {
            toastId: '0dc47d7'
          });
          return;
        }
        let errorFound = false;
        additionalInputs.forEach(({ value, rowNumber }, i) => {
          // blank or null error
          if (!value) {
            errorFound = true;
            cfaTinListFieldError(rowNumber, "CFA TIN is required");
            return;
          }
          // duplicate error
          if (
            additionalInputs
              .map((i) => i.value)
              .slice(i + 1)
              .includes(value)
          ) {
            errorFound = true;
            cfaTinListFieldError(rowNumber, "Duplicate CFA TIN Entry");
            return;
          }

          // //invalid error
          // if (value.match(/^[0-9-]+$/)) {
          //   return;
          // } else {
          //   errorFound = true;
          //   cfaTinListFieldError(rowNumber, "CFA TIN should be a number");
          // }
          if (
            !customerNameList?.find((data) => data?.rowNumber === rowNumber)
              ?.partnerNm
          ) {
            errorFound = true;
            cfaTinListFieldError(rowNumber, "Error in getting partner name");
          }
        });

        if (errorFound) return;
      }
      if (
        profileDetails?.opcPartnerType === "CFA"
          ? Object.values(selectedAttachmentName).filter(
            (data: any) => data?.name
          ).length >= 1
          : profileDetails?.opcPartnerType === "SLA"
            ? docAttachmentLateGateIn?.list?.every(
              (d: any) =>
                (serverFilePath?.[d.rowNumber]?.[0] &&
                  serverFilePath?.[d.rowNumber]?.[0].trim() != "") ||
                (d?.fileName && d?.fileName?.trim() != "")
            )
            : true
      ) {
        if (isEdit) {
          //const selectedDocAttachment = docAttachment;
          const selectedDocAttachment =
            profileDetails?.opcPartnerType === "CFA"
              ? docAttachment
              : docAttachmentLateGateIn;

          const {
            invoiceNumber,
            blno,
            invoiceType,
            deliveryType,
            remarks,
            delayReason,
          } = data;
          let formattedData: any = {
            specialServiceReqId: AESDecrypt(invoice_request_id),
            odexRefNo: invoiceNumber,
            blNo: blno,
            tpoId,
            ssrType: invoiceType,
            identityNo: companyTin,
            vesselVoyage: selectedVessel,
            deliveryType: getValues("invoiceType") == 1 ? deliveryType : "",
            traffic: "Export",
            remarks: remarks,
            reasonToDelay: delayReason,
            documents: selectedDocAttachment?.list.map?.(
              (d: any, index: any) => ({
                docId: d?.docId,
                serverFilePath: d?.rowNumber
                  ? serverFilePath?.[d.rowNumber]?.[0]
                  : null,
                tempFileName: d?.rowNumber
                  ? serverFilePath?.[d.rowNumber]?.[1]
                  : null,
                fileName: serverFilePath?.[d.rowNumber]?.[0]
                  ? undefined
                  : d?.fileName ?? undefined,
                filePath: serverFilePath?.[d.rowNumber]?.[0]
                  ? undefined
                  : d?.filePath ?? undefined,
              })
            ),
          };

          if (getValues("invoiceType") == 2) {
            formattedData = {
              ...formattedData,
              customerDetails: customerNameList?.map((data, id) => ({
                specialServiceReqAccessId:
                  specialServiceReqAccessIdList[id]?.specialServiceReqAccessId,
                specialServiceReqId:
                  specialServiceReqAccessIdList[id]?.specialServiceReqId,
                accessPartnerId: data?.mstPartnerId,
                accessPartnerNm: data?.partnerNm,
                identityNo: data?.identityNo,
              })),
              noOfContainer: data?.numberOfContainers,
              shippingLine: data?.shippingLine,
            };
          }
          try {
            dispatch(editSSRRequest(formattedData))
              .unwrap()
              .then((response: any) => {
                if (response?.data?.responseCode === "SUCCESS") {
                  toast.success(response?.data?.responseMsg, {
                    theme: "colored",
                    toastId: '8f8d65e'
                  });
                  setTimeout(() => {
                    onBack();
                    setTimeout(() => {
                      dispatch(updateDashbaordRefresh(true));
                    }, 2000);
                  }, 500);
                } else {
                  toast.error(response?.data?.responseMsg, {
                    theme: "colored",
                    toastId: '1d77a3f'
                  });
                  resetForm();
                }
              });

            /*     toast.success('Invoice Request Sumbitted Successfully', {
              theme: 'colored',
            }); */
            // resetForm();
          } catch (err: any) {
            console.error(err.message);
          }
        } else {
          const selectedDocAttachment =
            getValues("invoiceType") == 2
              ? docAttachmentLateGateIn
              : docAttachment;
          let formattedData: any = {
            odexRefNo: data.invoiceNumber,
            blNo: data.blno,
            tpoId,
            ssrType: data.invoiceType,
            vesselVoyage: selectedVessel,
            traffic: "Export",
            deliveryType:
              getValues("invoiceType") == 1 ? data.deliveryType : "",
            remarks: data.remarks || "",
            identityNo: companyTin,
            requestedPartnerName: profileDetails.partnerName,
            reasonToDelay: data?.delayReason,
            documents: selectedDocAttachment?.list.map?.(
              (d: any, index: any) => ({
                docId: d?.docId,
                serverFilePath: d?.rowNumber
                  ? serverFilePath?.[d.rowNumber]?.[0]
                  : null,
                tempFileName: d?.docId
                  ? serverFilePath?.[d.rowNumber]?.[1]
                  : null,
              })
            ),
            customerDetails: [
              {
                accessPartnerId: profileDetails.partnerId,
                accessPartnerNm: profileDetails.partnerName,
                identityNo: profileDetails.userIdentityNo,
              },
            ],
          };

          if (getValues("invoiceType") == 2) {
            formattedData = {
              ...formattedData,
              customerDetails: customerNameList?.map((data) => ({
                accessPartnerId: data?.mstPartnerId,
                accessPartnerNm: data?.partnerNm,
                identityNo: data?.identityNo,
              })),
              noOfContainer: data?.numberOfContainers,
              shippingLine: data?.shippingLine,
            };
          }
          try {
            dispatch(submitSSRRequest(formattedData))
              .unwrap()
              .then((response: any) => {
                if (response?.data?.responseCode === "SUCCESS") {
                  toast.success(response?.data?.responseMsg, {
                    theme: "colored",
                    toastId: 'ad58b3b'
                  });
                  setTimeout(() => {
                    onBack();
                    setTimeout(() => {
                      dispatch(updateDashbaordRefresh(true));
                    }, 2000);
                  }, 500);
                } else {
                  toast.error(response?.data?.responseMsg, {
                    theme: "colored",
                    toastId: '8ed1782'
                  });
                  resetForm();
                }
              });

            /*     toast.success('Invoice Request Sumbitted Successfully', {
              theme: 'colored',
            }); */
            // resetForm();
          } catch (err: any) {
            console.error(err.message);
          }
        }
      } else {
        toast.error("Upload all required documents", {
          toastId: '0be693e'
        });
      }
    },
    (error: any) => {
      console.error("Handle submit error: ", error);
    }
  );

  // const _onCloseForm = () => {
  //   dispatch(toggleDocumentHistoryColumnShort(false));
  //   navigate('/instruction');
  // };

  const handleRemoveInput = (rowNumber: number) => {
    const newInputs = additionalInputs.filter((i) => i.rowNumber !== rowNumber);
    setAdditionalInputs(newInputs);
    setCustomerNameList((prevState) =>
      prevState.filter((data: any) => data?.rowNumber != rowNumber)
    );
  };

  const _onCloseForm = () => {
    dispatch(toggleDocumentHistoryColumnShort(false));
    setTimeout(() => {
      onBack();
    }, 500);
  };

  const formatTIN = (value: string) => {
    const cleaned = value.replace(/[^\w]/g, "");
    const match = cleaned.match(/^(\w{0,3})(\w{0,3})(\w{0,3})$/);

    if (match) {
      return [match[1], match[2], match[3]].filter((part) => part).join("-");
    }

    return value;
  };

  const handleOnClickManagerApprovalMail = () => {
    specialRequestId &&
      window.open(
        `${process.env.REACT_APP_CLIENT_APP_HOST
        }/api/opc-rest-v1/v1/common/openDocument?id=${specialRequestId}&moduleTp=SSR_APPROVAL&linerId=${profileDetails.partnerType === "liner"
          ? profileDetails?.partnerId
          : selectedLinerId
        }`,
        "_blank"
      );
  };

  const validateBl = (val: any) => {
    var containerList: any = [];
    const isValid = val
      .split(",")
      .every((data: string) =>
        blNumbListVal.some(({ blNumber }: any) => blNumber === data)
      );
    if (val.length > 0) {
      if (isValid) {
        clearErrors("blno");
      } else {
        setError("blno", {
          type: "manual",
          message: "BL/SO number is invalid",
        });
      }

      /*   axios
        .get(
          //`${process.env.REACT_APP_API_GATEWAY_HOST}/api/opc-management/v1/common/getVesselVoyageFromTicts?shipmentTp=Import`
          `${process.env.REACT_APP_API_GATEWAY_HOST}/api/opc-management/v1/common/getDoDataFromTicts?blNo=${val}`
        )
        .then((response: any) => {
        });
  */
      // dispatch(blValidation({ blnumber: val, shipmentType: trafficVal })).then(
      //   (response: any) => {
      //
      //     if(response.payload.data.length > 0){
      //       const transformedData = response.payload.data.map((item:any) => ({
      //         containerNo: item.containerNumber
      //     }));

      //     }else{

      //     }

      //   }
      // );
    }
  };

  const handleTINChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const formattedValue = formatTIN(value);
    setValue("customerTin", formattedValue, { shouldValidate: true });
  };

  const handleInputChange = useDebounce((inputValue: string) => {
    setVesselTxt(inputValue);
    if (inputValue.length >= 3) {
      //dispatch(fetchVesselSuggestions(inputValue.toUpperCase()));
      dispatch(
        fetchVesselSuggestions({
          vesselKey: inputValue.toUpperCase(),
          requestType: 903,
          permitId: 0,
        })
      );
    }
    if (inputValue.length < 3) {
      setVesselList([]);
    }
  }, 500);


  useEffect(() => {
    const catchResponseFunc = () => {
      toast.error('Failed to fetch documents due to network error', {
        toastId: '77f3858'
      });
      if (requesttp && invoice_request_id) {
        return;
      }
      navigate('/opc/instruction')
    }
    if (getValues('invoiceType') && tpoId && profileDetails?.opcPartnerType && dataCountry) {
      if (getValues('invoiceType') == 1) {
        dispatch(getSsrDocList({
          dataCountry,
          partner: profileDetails.opcPartnerType,
          tpoId
        })).unwrap().catch(catchResponseFunc);
      } else {
        if (showdata) {
          dispatch(getSsrLateGateInDocName({
            dataCountry,
            partner: profileDetails.opcPartnerType,
            tpoId
          })).unwrap().catch(catchResponseFunc);
        } else {
          dispatch(getSsrLateGateInDocList({
            dataCountry,
            partner: profileDetails.opcPartnerType,
            tpoId
          })).unwrap().catch(catchResponseFunc);
        }
      }
    }
  }, [getValues('invoiceType'), tpoId, profileDetails?.opcPartnerType, dataCountry]);


  return (
    <div>
      <Card style={{ minHeight: "100%" }}>
        <Card.Header className="d-flex align-items-center border-bottom sticky-top bg-white">
          <FontAwesomeIcon
            icon={faArrowAltCircleLeft}
            className="text-warning cursor-pointer fa-lg mb-1 me-1"
            onClick={_onCloseForm}
          />
          <h2 className="fs-0 text-primary mb-1">Special Service Request</h2>
        </Card.Header>
        <Card.Body>
          <div className="container">
            <Form onSubmit={onSubmit} autoComplete="off">
              <div className="row">
                <div className="col-lg-6 mb-3">
                  <Form.Group controlId="invoiceNumber">
                    <span className="mandatory">*</span>

                    <Form.Label className="fs--1">
                      {getValues("invoiceType") == 1
                        ? t("resource_4:invoiceCutOffExtension")
                        : t("resource_4:lateGateInExtension")}
                    </Form.Label>

                    <Form.Control
                      size="sm"
                      {...register("invoiceNumber")}
                      className={`fs--1 form-control form-control-sm ${errors.invoiceNumber ? "is-invalid" : ""
                        }`}
                      type="text"
                      maxLength={20}
                      disabled={true}
                    />
                    <div className="invalid-feedback">
                      {errors.invoiceNumber?.message?.toString()}
                    </div>
                  </Form.Group>
                </div>

                <div className="col-lg-6 mb-3">
                  <Form.Group controlId="traffic">
                    <span className="mandatory">*</span>
                    <Form.Label className="fs--1">
                      {t("resource_4:specialServiceRequestType")}
                    </Form.Label>
                    <Form.Select
                      disabled={true}
                      {...register("invoiceType")}
                      className={`fs--1 form-control form-control-sm ${errors.invoiceType ? "is-invalid" : ""
                        }`}
                      value={selectedInvoiceOption}
                    >
                      <option value="">Select Invoice Type</option>
                      {invoiceOptions.map(({ codeId, codeDesc }: any) => (
                        <option value={codeId}>{codeDesc}</option>
                      ))}
                    </Form.Select>
                    <div className="invalid-feedback">
                      {errors.invoiceType?.message?.toString()}
                    </div>
                  </Form.Group>
                </div>

                <div className="col-lg-6 mb-3">
                  <Form.Group controlId="customerTin">
                    <span className="mandatory">*</span>
                    <Form.Label className="fs--1">
                      {t("resource_3:requesterCompanyTin")}
                    </Form.Label>
                    <Form.Control
                      disabled={true}
                      className={`fs--1 form-control form-control-sm ${errors.companyTin ? "is-invalid" : ""
                        }`}
                      size="sm"
                      type="text"
                      value={companyTin}
                      maxLength={20}
                    />
                    <div className="invalid-feedback">
                      {errors.companyTin?.message?.toString()}
                    </div>
                  </Form.Group>
                </div>

                <div className="col-lg-6 mb-3">
                  <Form.Group controlId="shipperConsigneeName">
                    <span className="mandatory">*</span>
                    <Form.Label className="fs--1">
                      {t("resource_3:requesterCompanyName")}
                    </Form.Label>
                    <Form.Control
                      disabled={true}
                      size="sm"
                      value={partnerName}
                      type="text"
                      maxLength={20}
                    />
                  </Form.Group>
                </div>

                <div className="col-lg-6 mb-3">
                  <Form.Group controlId="traffic">
                    <span className="mandatory">*</span>
                    <Form.Label className="fs--1">
                      {t("resource_1:traffic")}
                    </Form.Label>
                    <Controller
                      name="traffic"
                      control={control}
                      render={({ field }) => (
                        <Form.Select
                          {...field}
                          disabled={true}
                          className={`fs--1 form-control form-control-sm`}
                          onChange={(e) => {
                            field.onChange(e);
                            //  setTrafficVal(parseInt(e.target.value));
                            clearErrors();
                            //   dispatch(updateSelectedAttachmentName({}));
                            //   setLoadingFileState({});
                            //   dispatch(updateSelectedFileServerPath({}));
                          }}
                        >
                          {/*     <option value="">Select Traffic</option> */}
                          {trafficOptions.map(({ codeId, codeDesc }: any) => (
                            <option value={codeId}>{codeDesc}</option>
                          ))}
                        </Form.Select>
                      )}
                    />
                    <div className="invalid-feedback">
                      {errors.traffic?.message?.toString()}
                    </div>
                  </Form.Group>
                </div>
                {getValues("invoiceType") != 2 && (
                  <div className="col-lg-6 mb-3">
                    <Form.Group controlId="deliveryType">
                      <span className="mandatory">*</span>
                      <Form.Label className="fs--1">
                        {t("resource_2:local_transit")}
                      </Form.Label>
                      <Form.Select
                        disabled={showdata}
                        {...register("deliveryType")}
                        className={`fs--1 form-control form-control-sm ${errors.deliveryType ? "is-invalid" : ""
                          }`}
                      >
                        <option value="">Select Local/Transit</option>
                        {localTransitOptions.map(
                          ({ codeId, codeDesc }: any) => (
                            <option value={codeId}>{codeDesc}</option>
                          )
                        )}
                      </Form.Select>
                      <div className="invalid-feedback">
                        {errors.deliveryType?.message?.toString()}
                      </div>
                    </Form.Group>
                  </div>
                )}

                <div className="col-lg-6 mb-3">
                  <Form.Group
                    style={{ padding: "0px" }}
                    controlId="vesselVoyage"
                  >
                    <span className="mandatory">*</span>
                    <Form.Label className="fs--1">
                      {t("resource_1:vessel")}
                    </Form.Label>

                    {!showdata && (
                      <div>
                        <Controller
                          name="vesselVoyage"
                          control={control}
                          rules={{ required: "This field is required" }}
                          render={({ field }: any) => (
                            <Select
                              {...field}
                              {...register("vesselVoyage")}
                              options={VesselList}
                              maxMenuHeight={150}
                              noOptionsMessage={() => null}
                              isDisabled={showdata}
                              isClearable={true}
                              onChange={(e: any) => {
                                if (!trafficVal) {
                                  setValue("vesselVoyage", "");
                                  setselectedVessel(" ");
                                  setError("vesselVoyage", {
                                    type: "manual",
                                    message: "Please select traffic first.",
                                  });
                                } else if (e === null) {
                                  field.onChange(null);
                                  setselectedVessel("");
                                  setValue("shippingLine", "");
                                  setValue("blno", ""); // Clear the BL Number field when vessel is cleared
                                  clearErrors("blno");
                                } else {
                                  field.onChange({
                                    label: e.label,
                                    value: e.value,
                                    visitKey: e.visitKey,
                                  });
                                  setselectedVessel(e.value);
                                  dispatch(
                                    blNumbList({
                                      visit: e.visitKey,
                                      permit:
                                        trafficVal == 1 ? "Import" : "Export",
                                    })
                                  );
                                  setValue("blno", ""); // Clear the BL Number field when vessel is cleared
                                  clearErrors("blno");
                                }
                              }}
                              styles={customDropdownStyles}
                              filterOption={customFilterOption}
                              onInputChange={handleInputChange}
                              className={`fs--1 form-control form-control-sm ${errors.vesselVoyage ? "is-invalid" : ""
                                } ${loadingVesselList ? "loadingPulse" : ""}`}
                            />
                          )}
                        />
                        <div className="invalid-feedback">
                          {errors.vesselVoyage?.message?.toString()}
                        </div>
                      </div>
                    )}

                    {showdata && (
                      <div>
                        <Form.Control
                          disabled={showdata}
                          size="sm"
                          {...register("vesselVoyageShow")}
                          className={`fs--1 form-control form-control-sm ${errors.vesselVoyageShow ? "is-invalid" : ""
                            }`}
                          type="text"
                          maxLength={20}
                        />
                      </div>
                    )}
                  </Form.Group>
                </div>

                <div className="col-lg-6 mb-3">
                  <Form.Group controlId="blno">
                    <span className="mandatory">*</span>
                    <Form.Label className="fs--1">
                      {t("resource_3:soNumberComma")}
                    </Form.Label>
                    <Form.Control
                      disabled={showdata}
                      size="sm"
                      {...register("blno")}
                      className={`fs--1 form-control form-control-sm ${errors.blno ? "is-invalid" : ""
                        }`}
                      type="text"
                      // maxLength={20}
                      //    onBlur={(e) => validateBl(e.target.value.trim())}
                      onBlur={(e) => {
                        const blNumber = e.target.value.trim();
                        if (!blNumber) {
                          setError("blno", {
                            type: "manual",
                            message: "so number is required",
                          });
                          return;
                        }
                        // Case 1: Validate the BL number using validateBl function
                        validateBl(blNumber);

                        // Case 2 & 3: Handle traffic-specific checks
                        const validateBlNumbers = (
                          blData: any[],
                          blNumber: string,
                          checkDoNumber: boolean = false
                        ) => {
                          const blCount = blNumber.split(",").length;
                          const validBlNumbers =
                            blData.length > 0 && blData.length === blCount;
                          const allDoNumbersPresent = blData.every(
                            ({ doNumber }: any) => !!doNumber
                          );
                          if (!validBlNumbers) {
                            setError("blno", {
                              type: "manual",
                              message: "SO number is invalid",
                            });
                          } else if (checkDoNumber && !allDoNumbersPresent) {
                            setError("blno", {
                              type: "manual",
                              message:
                                "DO Number is not present for this SO/BL Number",
                            });
                          } else {
                            clearErrors("blno");
                          }
                        };

                        const blData = blNumbListVal.filter((bl: any) =>
                          blNumber.split(",").includes(bl.blNumber)
                        );
                        if (
                          trafficVal === 1 &&
                          profileDetails.opcPartnerType === "CFA"
                        ) {
                          validateBlNumbers(blData, blNumber, true);
                        } else if (trafficVal == 2) {
                          validateBlNumbers(blData, blNumber);
                        }
                      }}
                      onChange={(e) => {
                        if (!selectedVessel) {
                          setError("blno", {
                            message: "Please select vessel first",
                          });
                          setValue("blno", "");
                        }
                        setValue("blno", e.target.value.trim());
                      }}
                    />
                    <div className="invalid-feedback">
                      {errors.blno?.message?.toString()}
                    </div>
                  </Form.Group>
                </div>
                {(profileDetails.opcPartnerType === "SLA" ||
                  getValues("invoiceType") == 2) && (
                    <div className="col-lg-6 mb-3">
                      <Form.Group controlId="shippingLine">
                        <span className="mandatory">*</span>
                        <Form.Label className="fs--1">
                          {t("resource_2:shipping_line")}
                        </Form.Label>
                        <Form.Control
                          disabled={true}
                          size="sm"
                          {...register("shippingLine")}
                          className={`fs--1 form-control form-control-sm ${errors.shippingLine ? "is-invalid" : ""
                            }`}
                          type="text"
                        />
                        <div className="invalid-feedback">
                          {errors.shippingLine?.message?.toString()}
                        </div>
                      </Form.Group>
                    </div>
                  )}
                {(profileDetails.opcPartnerType === "SLA" ||
                  getValues("invoiceType") == 2) && (
                    <div className="col-lg-6 mb-3">
                      <Form.Group controlId="shippingLine">
                        <span className="mandatory">*</span>
                        <Form.Label className="fs--1">
                          {t("resource_4:totalNumberOfContainers")}
                        </Form.Label>
                        <Form.Control
                          disabled={showdata}
                          size="sm"
                          {...register("numberOfContainers")}
                          className={`fs--1 form-control form-control-sm ${errors.numberOfContainers ? "is-invalid" : ""
                            }`}
                          type="text"
                        />
                        <div className="invalid-feedback">
                          {errors.numberOfContainers?.message?.toString()}
                        </div>
                      </Form.Group>
                    </div>
                  )}

                {requesttp && invoice_request_id && showdata && (
                  <div className="col-lg-6 mb-3">
                    <Form.Group controlId="blno">
                      <Form.Label>Request Status</Form.Label>
                      <Form.Control
                        disabled={showdata}
                        size="sm"
                        className={`fs--1 form-control form-control-sm ${errors.blno ? "is-invalid" : ""
                          }`}
                        type="text"
                        value={status}
                      />
                      <div className="invalid-feedback">
                        {errors.blno?.message?.toString()}
                      </div>
                    </Form.Group>
                  </div>
                )}
                {getValues("invoiceType") == 2 && (
                  <>
                    {!showdata && (
                      <div className="d-flex">
                        <button
                          type="button"
                          onClick={handleAddInput}
                          className="btn-falcon-primary btn btn-primary btn-sm mb-2"
                          style={{ width: "80px" }}
                        >
                          <FontAwesomeIcon className="me-1" icon={faAdd} /> Row
                        </button>
                        <Dropdown navbar={true} ref={dropdownRef} style={{}}>
                          <Dropdown.Toggle
                            bsPrefix="toggle"
                            className="btn-falcon-primary btn btn-primary btn-sm mb-2 mx-2"
                          >
                            Upload .xlsx
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            className="
                          dropdown-caret dropdown-menu-card  dropdown-menu-end p-3"
                            style={{ width: "500px" }}
                          >
                            <div>
                              <input
                                type="file"
                                className="form-control mb-2"
                                accept=".xlsx"
                                onChange={(e) => {
                                  consumeExcelFile(e, getConsumerTin);
                                  e.target.value = "";
                                  const curr: any = dropdownRef?.current;
                                  curr.click?.();
                                }}
                              />
                              <span
                                onClick={() => {
                                  window.open(
                                    `/api/opc-rest-v1/v1/common/openDocument?id=1&moduleTp=SSR_TEMPLETE&linerId=1`,
                                    "_blank"
                                  );
                                }}
                                className="cursor-pointer mt-2"
                                style={{ color: "#2C7BE5" }}
                              >
                                Click here to download the sample excel format
                              </span>
                              <span style={{ color: "#f5803e" }}>
                                <br />
                                Note: First row will be skipped as it will be
                                the column name.
                              </span>
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                        <div>
                          <Form.Label className="fs--1">
                            Customer Count :{" "}
                          </Form.Label>
                          <SoftBadge pill className="fs--2 mx-1" bg="primary">
                            {`${additionalInputs.length}`.padStart(2, "0")}
                          </SoftBadge>
                        </div>
                      </div>
                    )}

                    <DataTable
                      gridName="customer_details"
                      columns={customerTinColumnsApprove}
                      data={additionalInputs}
                      shortPrepend="qp_"
                      perPage={100}
                      sortable={false}
                      searchable={false}
                      showColumnFilter={false}
                      selection={false}
                      pagination={false}
                      seekPagination={false}
                      showExport={false}
                      localFilter={true}
                      uniqueFilterKey="invoicereq"
                      autoHeight={true}
                      tableMinHeight={100}
                      staticHiddenCols={[
                        showdata ? "remove" : "",
                        profileDetails?.opcPartnerType === "PTO" ||
                          status == "Partial Approved" ||
                          status == "Approved"
                          ? ""
                          : "approval",
                      ]}
                    />
                  </>
                )}

                <div className="mt-3 d-flex flex-column gap-2">
                  {getValues("invoiceType") == 2 && !showdata ? (
                    <>
                      <span style={{ color: "#f5803e" }} className="fs--1">
                        Note: Upload Loading Permission for the Late Gate In
                        containers only
                      </span>
                      <button
                        type="button"
                        onClick={(e: any) => {
                          const uuid = crypto.randomUUID();
                          dispatch(
                            updateSSRDocLateGateIn([
                              ...docExporLategateIn,
                              {
                                rowNumber: uuid,
                                id: 12,
                                name: "Others",
                                m: true,
                              },
                            ])
                          );
                          dispatch(
                            updateAttSSRGate([
                              ...docAttachmentLateGateIn.list,
                              {
                                dndInstructionAttId: null,
                                docId: 12,
                                rowNumber: uuid,
                                fileName: "",
                                byteArray: "hdavdddjhcvhjvdj",
                                downloadBtn: true,
                                m: true,
                              },
                            ])
                          );
                        }}
                        className="btn-falcon-primary btn btn-primary btn-sm mb-2"
                        style={{ width: "max-content" }}
                      >
                        <FontAwesomeIcon className="me-1" icon={faAdd} />{" "}
                        Documents
                      </button>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <DataTable
                  data={
                    isDocAttachment
                      ? profileDetails?.opcPartnerType === "CFA"
                        ? docAttachment?.list ?? []
                        : docAttachmentLateGateIn?.list ?? []
                      : requestedPartner === "CFA"
                        ? docAttachment?.list ?? []
                        : docAttachmentLateGateIn?.list ?? []
                  }
                  gridName="RELEASE_ATTACHMENT"
                  columns={
                    isDocAttachment
                      ? docAttachmentColumns
                      : docAttachmentColumns
                  }
                  sortable={false}
                  searchable={false}
                  showColumnFilter={false}
                  selection={false}
                  perPage={10}
                  pagination={false}
                  showExport={false}
                  uniqueFilterKey="releaseAttachment"
                  autoHeight={true}
                  staticHiddenCols={["dndInstructionAttId", "fileName"]}
                />
                {profileDetails.opcPartnerType === "PTO" &&
                  getValues("invoiceType") == 2 && (
                    <div className="container">
                      <div
                        className="row mb-0"
                        style={{
                          padding: 0,
                          marginTop: "0.25rem",
                          marginBottom: 0,
                        }}
                      >
                        <div className="col-lg-6">
                          <Form.Group
                            controlId="managementApprovalEmail"
                            style={{ marginBottom: "0.1rem", marginTop: 0 }}
                          >
                            <span className="mandatory">*</span>
                            <Form.Label className="fs--1" style={{ margin: 0 }}>
                              Management Approval Email
                            </Form.Label>
                          </Form.Group>
                        </div>
                      </div>

                      <div
                        style={{
                          marginTop: "0.05rem",
                          marginBottom: "0.3rem",
                          color: "#00BFFF",
                        }}
                      >
                        (Approver to attach the management approval email.)
                      </div>

                      {approveState == null ? (
                        <div className="row mb-2">
                          <div className="col-lg-6 d-flex align-items-center">
                            <Form.Group
                              controlId="uploadFile"
                              className="mb-0 d-flex align-items-center"
                              style={{ marginRight: "0.25rem" }}
                            >
                              <Form.Label
                                className="btn btn-primary mb-0"
                                style={{
                                  width: "8rem",
                                  whiteSpace: "nowrap",
                                  padding: "0.2rem 0.5rem",
                                  fontSize: "0.875rem",
                                  lineHeight: "1.5",
                                }}
                              >
                                Choose File
                                <Form.Control
                                  type="file"
                                  className="d-none"
                                  onChange={handleOtherFileChange}
                                  disabled={false} // Adjust as necessary
                                />
                              </Form.Label>
                            </Form.Group>
                            {uploadedFile?.tempFileName ? (
                              <div
                                onClick={() => {
                                  /* handle click for file actions */
                                }}
                                className="ssrRequest_excelFileUploaded"
                                style={{ fontSize: "13.33px", display: "flex" }}
                              >
                                <span style={{ width: "max-content" }}>
                                  {uploadedFile?.tempFileName}
                                </span>
                                {uploadProgress != null && (
                                  <UploadIndicator
                                    style={{ marginLeft: "10px" }}
                                    progress={uploadProgress}
                                    fail={uploadProgress === -1}
                                  />
                                )}
                              </div>
                            ) : (
                              <Form.Control
                                type="text"
                                readOnly
                                className="form-control-sm"
                                placeholder={
                                  "Attach .pdf, .jpeg, .png, etc., as approval proof."
                                }
                                style={{
                                  width: "22rem",
                                  color: "#6c757d",
                                  backgroundColor: "#e9ecef",
                                }}
                              />
                            )}
                          </div>
                        </div>
                      ) : (
                        downloadDocApprovalEmail()
                      )}
                    </div>
                  )}
                <div className="col-lg-6 mb-2">
                  <Form.Group controlId="delayReason">
                    <span className="mandatory">*</span>
                    <Form.Label className="fs--1">
                      {t("resource_3:reasonForDelay")}
                    </Form.Label>

                    <Form.Control
                      disabled={showdata}
                      {...register("delayReason")}
                      className={`fs--1 form-control form-control-sm ${errors.delayReason ? "is-invalid" : ""
                        }`}
                      as="textarea"
                      onChange={(e) => {
                        const value = e.target.value;
                        setValue("delayReason", value.substring(0, 250));
                      }}
                      onBlur={(e) => {
                        const reasonForDelay = e?.target?.value?.trim();
                        if (!reasonForDelay) {
                          setError("delayReason", {
                            type: "manual",
                            message: "Reason for Delay is required",
                          });
                          return;
                        } else {
                          clearErrors("delayReason");
                        }
                        setValue(
                          "delayReason",
                          e?.target?.value.substring(0, 250)
                        );
                      }}
                    />
                    <div className="invalid-feedback">
                      {errors.delayReason?.message?.toString()}
                    </div>
                  </Form.Group>
                </div>

                <div className="col-lg-6 mb-2">
                  <Form.Group controlId="remarks">
                    <Form.Label className="fs--1">
                      {t("resource_2:user_remarks")}
                    </Form.Label>
                    <Form.Control
                      disabled={showdata}
                      className={`fs--1 form-control form-control-sm ${errors.remarks ? "is-invalid" : ""
                        }`}
                      {...register("remarks")}
                      as="textarea"
                      onChange={(e) => {
                        const value = e.target.value;
                        // Trim the value to 250 characters
                        e.target.value = value.substring(0, 250);
                      }}
                    />
                    <div className="invalid-feedback">
                      {errors.remarks?.message?.toString()}
                    </div>
                  </Form.Group>
                </div>

                {/* <Button variant='falcon-primary me-2' size='sm' onClick={onSubmit}>
                <FontAwesomeIcon icon={faSave} className='me-2' />
                {t('resource_1:submit')}
              </Button> */}
                {!showdata && profileDetails.opcPartnerType !== "PTO" && (
                  <div className="col-lg-8 offset-lg-2 mt-2 d-flex justify-content-center align-items-center">
                    <Button
                      variant="falcon-primary me-2"
                      size="sm"
                      disabled={
                        loadingFileState &&
                        Object.values(loadingFileState).some(
                          (data) => data !== 100 && data !== -1 && data !== 0
                        )
                      }
                      onClick={onSubmit}
                    >
                      <FontAwesomeIcon icon={faSave} className="me-2" />
                      {t("resource_1:submit")}
                    </Button>

                    <Button
                      variant="falcon-danger"
                      onClick={() => {
                        resetForm();
                      }}
                      size="sm"
                    >
                      <FontAwesomeIcon icon={faRedo} className="me-2" />
                      Reset
                    </Button>
                  </div>
                )}
                {(rbac.rbacDetails.ssrApproveReject === "Y" &&
                  status == "Submitted" &&
                  profileDetails.opcPartnerType === "PTO") ||
                  status == "Approved" ||
                  status == "Rejected" ||
                  status == "Partial Approved" ? (
                  <>
                    <div className="col-lg-6 mb-3">
                      <Form.Group controlId="newCutOffDateValidation">
                        <span className="mandatory">*</span>
                        <Form.Label>New Cut Off Date & Time</Form.Label>
                        {approveState == null && status == "Submitted" ? (
                          <div>
                            <Controller
                              name="newCutOffDateValidation"
                              control={control}
                              render={({ field }) => (
                                <>
                                  <DatePicker
                                    selected={
                                      field.value ? new Date(field.value) : null
                                    }
                                    onChange={(date) => {
                                      field.onChange(date);
                                      setnewCutOffDate(toUTCString(date));
                                    }}
                                    className={`fs--1 form-control form-control-sm ${newCutOffDateError ? "is-invalid" : ""
                                      }`}
                                    showTimeSelect
                                    dateFormat="dd/MM/yyyy h:mm aa"
                                    timeIntervals={15}
                                  />
                                  {newCutOffDateError && (
                                    <div className="invalid-feedback d-block">
                                      {newCutOffDateError}
                                    </div>
                                  )}
                                </>
                              )}
                            />
                          </div>
                        ) : (
                          <div>
                            <Controller
                              name="newCutOffDateValidationVal"
                              control={control}
                              render={({ field }) => (
                                <>
                                  <DatePicker
                                    selected={
                                      field.value ? new Date(field.value) : null
                                    }
                                    disabled={true}
                                    onChange={(date) => {
                                      field.onChange(date);
                                      setnewCutOffDate(toUTCString(date));
                                    }}
                                    className={`fs--1 form-control form-control-sm ${errors.newCutOffDateValidationVal
                                      ? "is-invalid"
                                      : ""
                                      }`}
                                    showTimeSelect
                                    timeIntervals={15}
                                    dateFormat="dd/MM/yyyy h:mm aa"
                                  />
                                </>
                              )}
                            />
                          </div>
                        )}
                      </Form.Group>
                    </div>
                    <div className="col-lg-6 mb-3">
                      <Form.Group controlId="approvalRemarksValidation">
                        <span className="mandatory">*</span>
                        <Form.Label>Approver Remarks</Form.Label>
                        {approveState == null && status == "Submitted" && (
                          <Form.Control
                            disabled={false}
                            size="sm"
                            className={`fs--1 form-control form-control-sm ${errors.approvalRemarksValidation
                              ? "is-invalid"
                              : ""
                              }`}
                            type="text"
                            // value={approverRemarks}
                            onChange={(e) => {
                              // field.onChange(e);
                              trigger("approvalRemarksValidation");
                              setapproverRemarks(e.target.value);
                            }}
                          />
                        )}
                        {(approveState != null || status != "Submitted") && (
                          <Form.Control
                            type="text"
                            as="textarea"
                            disabled={true}
                            value={approverRemarksVal}
                            className="form-control form-control-sm"
                          />
                        )}
                        {approverRemarksError && (
                          <div className="invalid-feedback d-block">
                            {approverRemarksError}
                          </div>
                        )}
                      </Form.Group>
                    </div>
                  </>
                ) : (
                  ""
                )}
                {
                  /* profileDetails.opcPartnerType === "PTO" && */

                  rbac.rbacDetails.ssrApproveReject === "Y" &&
                  approveState == null &&
                  status == "Submitted" &&
                  profileDetails.opcPartnerType === "PTO" && (
                      /*   profileDetails.opcPartnerType === "PTO"  */ <div className="d-flex justify-content-center align-items-center mt-4">
                      <>
                        <Button
                          variant="success"
                          className="me-4 approve"
                          size="sm"
                          onClick={() => {
                            if (
                              approvedCustomerTin.length > 0 ||
                              getValues("invoiceType") == 1
                            ) {
                              if (
                                uploadedFile == null &&
                                getValues("invoiceType") == 2
                              ) {
                                toast.error(
                                  "Please attach the management approval email", {
                                  toastId: '76b3c94'
                                }
                                );
                              } else {
                                const approveData = {
                                  specialServiceReqId:
                                    AESDecrypt(invoice_request_id),
                                  status: "311",
                                  approvalRemarks: approverRemarks,
                                  requestedOn: requestedOn,
                                  extInvoiceCutOff:
                                    getValues("invoiceType") == 2
                                      ? null
                                      : newCutOffDate,
                                  extGateIn:
                                    getValues("invoiceType") == 1
                                      ? null
                                      : newCutOffDate,
                                  customerDetails: customerNameList.map(
                                    (data) => ({
                                      accessPartnerId: data?.mstPartnerId,
                                      accessPartnerNm: data?.partnerNm,
                                      identityNo: data?.identityNo,
                                      isAccess: approvedCustomerTin.includes(
                                        data?.identityNo
                                      )
                                        ? "Y"
                                        : "N",
                                    })
                                  ),
                                  serverFilePath: uploadedFile?.path ?? "",
                                  tempFileName:
                                    uploadedFile?.tempFileName ?? "",
                                };
                                onApproveReject(approveData);
                              }
                            } else {
                              toast.error("No Customer Tin Selected", {
                                toastId: '68b6d39'
                              });
                            }
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="me-2 approve"
                          />
                          {t("resource_1:approve")}
                        </Button>

                        <Button
                          variant="danger reject"
                          size="sm"
                          onClick={() => {
                            onApproveReject({
                              specialServiceReqId:
                                AESDecrypt(invoice_request_id),
                              status: 304,
                              approvalRemarks: approverRemarks,
                              /*  requestedOn: "2024-09-02T06:34:58.168307Z",
              extInvoiceCutOff: "2024-09-02T06:34:58Z", */
                              requestedOn: requestedOn,
                              extInvoiceCutOff: "2024-09-02T06:34:58Z",
                              extGateIn: "2024-08-30T10:09:53",
                              customerDetails: customerNameList.map(
                                (data) => ({
                                  accessPartnerId: data?.mstPartnerId,
                                  accessPartnerNm: data?.partnerNm,
                                  identityNo: data?.identityNo,
                                  isAccess: approvedCustomerTin.includes(
                                    data?.identityNo
                                  )
                                    ? "Y"
                                    : "N",
                                })
                              ),
                              serverFilePath: selectedFile?.path,
                              tempFileName: selectedFile?.tempFileName,
                            });
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faTimes}
                            className="me-2 reject"
                          />
                          {t("resource_1:reject")}
                        </Button>
                      </>
                    </div>
                  )
                }
                {showdata &&
                  status == "Submitted" &&
                  profileDetails.opcPartnerType !== "PTO" && (
                    <div className="col-lg-8 offset-lg-2 mt-2 d-flex justify-content-center align-items-center">
                      <Button
                        className="me-2"
                        variant="falcon-danger"
                        size="sm"
                        type="button"
                        onClick={() => {
                          setIsEdit(true);
                          setShowData(false);
                        }}
                      >
                        <FontAwesomeIcon icon={faEdit} className="me-2" />
                        Edit
                      </Button>
                      <Button
                        className="me-2"
                        variant="falcon-danger"
                        size="sm"
                        type="button"
                        onClick={() => setinvoiceCancel(true)}
                      >
                        <FontAwesomeIcon icon={faCancel} className="me-2" />
                        Cancel Request
                      </Button>
                    </div>
                  )}
              </div>
            </Form>
          </div>
        </Card.Body>
      </Card>

      <Modal show={invoiceCancel} onHide={() => setinvoiceCancel(false)}>
        <Modal.Header className="p-2 pb-1" closeButton>
          <Modal.Title className="h6">
            {getValues("invoiceType") == 2
              ? "Do you want to cancel the SSR Late Gate In?"
              : "Do you want to cancel the SSR invoice cut off extension?"}
          </Modal.Title>
        </Modal.Header>

        <div className="d-flex justify-content-center align-items-center p-2">
          <Button
            variant="falcon-primary"
            onClick={handleCancel}
            size="sm"
            className="me-2"
          >
            Yes
          </Button>
          <Button
            className="me-2"
            onClick={() => setinvoiceCancel(false)}
            variant="falcon-danger"
            size="sm"
          >
            No
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default SSRRequestInstruction;
