/* eslint-disable react-hooks/exhaustive-deps */
import DataTable from '../../../common/components/DataTable';
import { useDispatch } from 'react-redux';

import {
  toggleAddAgent,
  toggleAddAgentButton,
  toggleColumnSettingsDashboard,
  updateColumnDefinition,
  updateColumnDefinitionConfigs,
} from '../slices/dashboardSlice';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store';

import NominationReq from '../../nominationReq/NominationReq';
import {
  Link,
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import OdexSpinner from '../../../common/components/OdexSpinner';
import {
  IConfigsDefinition,
  generateColumnConfig,
} from '../../../utils/columnConfig';
import React, { useEffect, useRef, useState } from 'react';
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AESDecrypt, AESEncrypt } from '../../../../encrypt-util';
import { IDataTableColumn } from '../../../common/types/dataTable';
import DashboardRoot from './DashboardRoot';
import { graphql } from 'msw';
import {
  updateCurrentPageInstructionDashboard,
  updateSelectedTableData,
} from '../../dashboard/slices/dashboardSlice';
import { getOBLDetails } from '../../dashboard/actions/instruction';
import { updateInstructionID } from '../../blDetails/slices/documentHistorySlice';
import { Card } from 'react-bootstrap';
import { updateSelectedInstRow } from '../../dashboard/slices/dashboardSlice';
import { updateColsDataTable } from '../../../common/components/DataTable/slices/DataTable';
import { updateSelectedRowsInvoice } from '../../multiBlPayment/slices/multiBlPaymentSlice';

const TableComponent = () => {
  const tableRef = useRef<any>(null);
  const { t } = useTranslation();
  const { encryptdoc, blNum, encrypt, doc_id } = useParams();
  const blDocId = encryptdoc;
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();

  const grids = useSelector(
    (state: RootState) => state.dataTable.invoiceDataGrids
  );
  const currentPage = useSelector(
    (state: RootState) => state.dashboard.currentPageInstruction
  );

  const rbac = useSelector((state: RootState) => state.userProfile);
  const selectionInitialState = useSelector(
    (state: RootState) => state.dashboard.selectedInstDataRows
  );
  // const configs = useSelector(
  //   (state: RootState) => state.instructionDashboard.configs
  // );
  const tableData = useSelector(
    (state: RootState) => state.invoiceChange.invoiceDataList
  );
  // const columns = useSelector((state: RootState) => state.dashboard.columns);
  const [columns, setColumns] = useState<IDataTableColumn[]>([]);
  const [modifiedData, setmodifiedData] = useState<any>({});
  const [configs, setConfigs] = useState<IConfigsDefinition>({
    columns: [],
    staticHiddenCols: [],
    defaultHiddenCols: [],
  });

  const data = useSelector(
    (state: RootState) => state.dashboard.instructionDashboardList
  );
  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );
  const selection = useSelector(
    (state: RootState) => state.multiBlPayment.selectedRowsInvoice
  );

  const selectedInvoiceType = useSelector(
    (state: RootState) => state.multiBlPayment.selectedInvoiceType
  );

  const linerDataCountry = useSelector(
    (state: RootState) => state.userProfile.linerDataCountry
  );
  // const dataCountry = linerDataCountry || profileDetails.partnerCountry || '';
  const storedUserCountry = JSON.parse(
    window.localStorage.getItem('@user-selected-datacountry') || '{}'
  );
  const storedCountry =
    storedUserCountry?.[0]?.loginId === profileDetails.email
      ? storedUserCountry?.[0]?.country
      : null;
  const dataCountry: any = storedCountry
    ? storedCountry
    : linerDataCountry
      ? linerDataCountry
      : profileDetails.partnerCountry || null;
  const dataLoading = useSelector(
    (state: RootState) => state.dashboard.dataLoading
  );
  const selectedLinerId = useSelector(
    (state: RootState) => state.userProfile.selectedLinerId
  );

  const columnsCustom: any[] = [
    {
      accessor: 'draftNo',
      Header: t('resource_2:draftNo'),
      minWidth: 190,
      width: 150,
      maxWidth: 250,
      columnFilterType: 'search',
    },
    {
      accessor: 'finalNo',
      Header: t('resource_2:finalNo'),
      minWidth: 190,
      width: 150,
      maxWidth: 250,
      columnFilterType: 'search',
    },
    {
      accessor: 'status',
      Header: t('resource_2:status'),
      minWidth: 90,
      width: 150,
      maxWidth: 250,
      columnFilterType: 'search',
    },
    {
      accessor: 'effectiveDate',
      Header: t('resource_2:effectiveDate'),
      minWidth: 90,
      width: 150,
      maxWidth: 250,
      columnFilterType: 'date-range',
    },
    {
      accessor: 'currencyExchangeDate',
      Header: t('resource_2:currencyExchangeDate'),
      minWidth: 210,
      width: 150,
      maxWidth: 250,
      columnFilterType: 'date-range',
    },
    {
      accessor: 'payee',
      Header: t('resource_2:payee'),
      minWidth: 90,
      width: 100,
      maxWidth: 250,
      columnFilterType: 'search',
    },
    {
      accessor: 'payeeName',
      Header: t('resource_2:payeeName'),
      minWidth: 60,
      width: 150,
      maxWidth: 250,
      columnFilterType: 'search',
    },
    {
      accessor: 'invoiceType',
      Header: t('resource_2:invoiceType'),
      minWidth: 90,
      width: 150,
      maxWidth: 250,
      columnFilterType: 'search',
    },
    {
      accessor: 'totalCharges',
      Header: t('resource_2:totalCharges'),
      minWidth: 90,
      width: 150,
      maxWidth: 250,
      columnFilterType: 'search',
    },
    {
      accessor: 'ccy',
      Header: t('resource_2:ccy'),
      minWidth: 90,
      width: 150,
      maxWidth: 250,
      columnFilterType: 'search',
    },
    {
      accessor: 'taxes',
      Header: t('resource_2:taxes'),
      minWidth: 90,
      width: 150,
      maxWidth: 250,
      columnFilterType: 'search',
    },
    {
      accessor: 'discounts',
      Header: t('resource_2:discounts'),
      minWidth: 90,
      width: 150,
      maxWidth: 250,
      columnFilterType: 'search',
    },
    {
      accessor: 'credits',
      Header: t('resource_2:credits'),
      minWidth: 90,
      width: 150,
      maxWidth: 250,
      columnFilterType: 'search',
    },
    {
      accessor: 'creditTaxes',
      Header: t('resource_2:creditTaxes'),
      minWidth: 90,
      width: 150,
      maxWidth: 250,
      columnFilterType: 'search',
    },
    {
      accessor: 'paid',
      Header: t('resource_2:paid'),
      minWidth: 90,
      width: 150,
      maxWidth: 250,
      columnFilterType: 'search',
    },
    {
      accessor: 'owed',
      Header: t('resource_2:owed'),
      minWidth: 90,
      width: 150,
      maxWidth: 250,
      columnFilterType: 'search',
    },
    {
      accessor: 'amountLocal',
      Header: t('resource_2:amountLocal'),
      minWidth: 90,
      width: 150,
      maxWidth: 250,
      columnFilterType: 'search',
    },
  ];

  useEffect(() => {
    if (tableData.length > 0) {
      setmodifiedData({
        hasNext: false,
        result: tableData,
        size: 20,
      });
    } else {
      setmodifiedData({
        hasNext: false,
        result: [],
        size: 20,
      });
    }
  }, [tableData]);
  useEffect(() => {
    if (blNum) {
      dispatch(
        getOBLDetails({
          blNumber: AESDecrypt(blNum) || null,
          selectedLinerId:
            profileDetails.partnerType === 'liner'
              ? profileDetails?.partnerId
              : selectedLinerId,
        })
      );
    }
  }, [blNum]);
  const instructionIdurl = useSelector(
    (state: RootState) => state.documentHistory.instructionIdurl
  );

  const getParams = () => {
    dispatch(
      updateInstructionID(AESDecrypt(location?.pathname?.split?.('/')?.[3]))
    );
    return {
      bl_id: blNum ? AESDecrypt(blNum) : '',
      doc_id: encryptdoc ? AESDecrypt(encryptdoc) : '',
      // inst_id: parseInt(location?.pathname?.split?.('/')?.[3]) || '',
      inst_id: encrypt ? AESDecrypt(encrypt) : '',
    };
  };

  /*  const userGridDefinitions = useSelector(
    (state: RootState) => state.userProfile.userGridDefinitions
  ); */
  const userGridDefinitions = useSelector(
    (state: RootState) => state.dataTable.grids
  );

  const showColumnSettings = useSelector(
    (state: RootState) => state.instructionDashboard.showColumnSettings
  );

  const showAddAgent = useSelector(
    (state: RootState) => state.instructionDashboard.showAddAgent
  );

  const showNominaationReq = useSelector(
    (state: RootState) => state.instructionDashboard.showNonimationReq
  );

  const rowColumns = useSelector(
    (state: RootState) => state.instructionTable.rowColumns
  );

  const onHideColumnSettings = () => {
    dispatch(toggleColumnSettingsDashboard(false));
  };
  const loadingGridConfigs = useSelector(
    (state: RootState) => state.dataTable.loadingGridConfigs
  );

  const accessorEtd = () => (rowData: any) => {
    const { etd } = rowData.row.original;

    try {
      const value = etd
        .split('-')
        .map((v: string, index: number) => (index === 0 ? v.slice(2, 4) : v))
        .join('-');

      return (
        <div className='text-truncate' data-toggle='tooltip' title={value}>
          {value}
        </div>
      );
    } catch (e) {
      return (
        <div className='text-truncate' data-toggle='tooltip' title={etd}>
          {etd}
        </div>
      );
    }
  };

  const accessorEta = () => (rowData: any) => {
    const { eta } = rowData.row.original;

    try {
      const value = eta
        .split('-')
        .map((v: string, index: number) => (index === 0 ? v.slice(2, 4) : v))
        .join('-');

      return (
        <div className='text-truncate' data-toggle='tooltip' title={value}>
          {value}
        </div>
      );
    } catch (e) {
      return (
        <div className='text-truncate' data-toggle='tooltip' title={eta}>
          {eta}
        </div>
      );
    }
  };
  const selectedData = useSelector(
    (state: RootState) => state.dashboard.selectedData
  );
  useEffect(() => {
    let selections: any = {};
    data.result?.forEach((d: any, i: any) => {
      if (
        selectedData?.map((v: any) => v?.bl_event_id).includes(d?.bl_event_id)
      )
        selections[i] = true;
    });

    dispatch(updateSelectedInstRow(selections));
  }, [selectedData, data]);

  // useEffect(()=>{
  //   dispatch(updateSelectedTableData([]));
  // },[dataCountry])

  // const columns = columnConfig(AESDecrypt(blNum) || null);

  // useEffect(() => {
  //   const blDashboardDefinition = grids?.[0]?.columnConfigGrid || '';

  //   if (!blDashboardDefinition) return;

  //   const configs = generateColumnConfig(JSON.parse(blDashboardDefinition));

  //   const columns = configs?.columns?.map((c) =>
  //     c.accessor === 'eta'
  //       ? { ...c, Cell: accessorEta() }
  //       : c.accessor === 'etd'
  //       ? { ...c, Cell: accessorEtd() }
  //       : c
  //   );

  //   dispatch(updateColumnDefinitionConfigs(configs));
  //   dispatch(updateColumnDefinition(columns));
  // }, [grids, location]);

  useEffect(() => {
    const blDashboardDefinition = grids?.[0]?.columnConfigGrid || '';

    if (!blDashboardDefinition) return;

    try {
      const configs = generateColumnConfig(
        JSON.parse(JSON.parse(blDashboardDefinition)),
        getParams,
        navigate,
        selectedData,
        dispatch
      );

      const columns = configs?.columns?.map((c) => c);
      if (selectedInvoiceType) {
        // if (selectedInvoiceType === 'TAXINVOICES') {
        //   const newCols: any = configs?.columns.map((column) =>
        //     column.accessor === 'finalNo' ? { ...column, hide: false } : column
        //   );
        //   /*  if (selectedInvoiceType === 'TAXINVOICES') {
        //     newCols[0].hyperlink = null;
        //   } */
        //   const newColswithHyper: any = newCols.map((column: any) =>
        //     column.accessor === 'finalNo'
        //       ? {
        //           ...column,
        //           hyperlink:
        //             '/api/opc-rest-v1/v1/invoice/getInvoiceReportFromNavis?draftNbr=:draftNo',
        //         }
        //       : column
        //   );
        //   const newconfigs = generateColumnConfig(
        //     newColswithHyper,
        //     getParams,
        //     navigate,
        //     selectedData,
        //     dispatch
        //   );
        //   setConfigs(newconfigs);
        //   setColumns(newconfigs?.columns);
        // } else {
        //   const newCols: any = configs?.columns.map((column) =>
        //     column.accessor === 'finalNo' ? { ...column, hide: true } : column
        //   );
        //   const newColswithHyper: any = newCols.map((column: any) =>
        //     column.accessor === 'draftNo'
        //       ? {
        //           ...column,
        //           hyperlink:
        //             '/api/opc-rest-v1/v1/invoice/getInvoiceReportFromNavis?draftNbr=:draftNo',
        //         }
        //       : column
        //   );
        //   const newconfigs = generateColumnConfig(
        //     newColswithHyper,
        //     getParams,
        //     navigate,
        //     selectedData,
        //     dispatch
        //   );
        //   setConfigs(newconfigs);
        //   setColumns(newconfigs?.columns);
        // }
      }

      setConfigs(configs);
      setColumns(configs?.columns);
    } catch (e) {
      console.error('Column Error: ', e);
    }
  }, [grids, location, selectedData]);
  const setCurrentPage = (page: number) => {
    dispatch(updateCurrentPageInstructionDashboard(page));
  };
  const invGridName = useSelector((state: RootState) =>
    state.multiBlPayment.selectedInvoiceType == 'CWPDRAFTINVOICES'
      ? 'OPC_INV_DASHBOARD'
      : 'OPC_INV_DASHBOARD_TAX'
  );

  useEffect(() => {
    if (selectedInvoiceType && configs)
      if (selectedInvoiceType === 'TAXINVOICES') {
        const newCols: any = configs?.columns.map((column) =>
          column.accessor === 'finalNo' ? { ...column, hide: false } : column
        );
        const newColswithHyper: any = newCols.map((column: any) =>
          column.accessor === 'finalNo'
            ? {
              ...column,
              hyperlink:
                '/api/opc-rest-v1/v1/invoice/getInvoiceReportFromNavis?draftNbr=:draftNo',
            }
            : column
        );
        /*  if (newCols[1])
          newCols[1].hyperlink =
            '/api/opc-rest-v1/v1/invoice/getInvoiceReportFromNavis?draftNbr=:draftNo';
        */
        /*      var link = newCols[0].hyperlink;
        if (selectedInvoiceType === 'TAXINVOICES') {
          newCols[0].hyperlink = null;
        } */
        const newconfigs = generateColumnConfig(
          newColswithHyper,
          getParams,
          navigate,
          selectedData,
          dispatch
        );

        setConfigs(newconfigs);
        setColumns(newconfigs?.columns);

        dispatch(
          updateColsDataTable({
            [invGridName]: newconfigs?.columns
              .map((i) => i.accessor)
              .filter((c: any) => !newconfigs.staticHiddenCols.includes(c)),
          })
        );
      } else {
        const newCols: any = configs?.columns.map((column) =>
          column.accessor === 'finalNo' ? { ...column, hide: true } : column
        );
        const newColswithHyper: any = newCols.map((column: any) =>
          column.accessor === 'draftNo'
            ? {
              ...column,
              hyperlink:
                '/api/opc-rest-v1/v1/invoice/getInvoiceReportFromNavis?draftNbr=:draftNo',
            }
            : column
        );
        /*         if (newCols)
          newCols[0].hyperlink =
            '/api/opc-rest-v1/v1/invoice/getInvoiceReportFromNavis?draftNbr=:draftNo'; */
        const newconfigs = generateColumnConfig(
          newColswithHyper,
          getParams,
          navigate,
          selectedData,
          dispatch
        );

        setConfigs(newconfigs);
        setColumns(newconfigs?.columns);

        dispatch(
          updateColsDataTable({
            [invGridName]: newconfigs?.columns
              .map((i) => i.accessor)
              .filter((c: any) => !newconfigs.staticHiddenCols.includes(c)),
          })
        );
      }
  }, [selectedInvoiceType]);

  useEffect(() => {
    const sliderOpened = showAddAgent || showNominaationReq;

    if (sliderOpened && tableRef.current) {
      const tableElement = tableRef.current;
      const rowIndexToShow = 0;

      const rows = tableElement.getElementsByTagName('tr');
      const rowToScroll = rows[rowIndexToShow];

      if (rowToScroll) {
        const tableBounds = tableElement.getBoundingClientRect();
        const rowBounds = rowToScroll.getBoundingClientRect();

        if (
          rowBounds.bottom > tableBounds.bottom ||
          rowBounds.top < tableBounds.top
        ) {
          // Scroll the row into view smoothly
          rowToScroll.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }
    }
  }, [showAddAgent, showNominaationReq]);

  return (
    <div
      className={`row g-0 col-transition-animation h-100 w-100 ${rowColumns.mainTableClass === 'col-lg-12'
        ? 'w-100 '
        : 'odex-width-14rem '
        }`}
    >
      <div
        className={`d-flex flex-column ${showAddAgent
          ? 'col-sm-0 col-md-7 col-lg-7'
          : showNominaationReq
            ? 'col-sm-0 col-md-6 col-lg-6'
            : 'col-md-12 col-lg-12'
          }`}
      >
        {columns?.length > 0 ? (
          <>
            <DashboardRoot />
            <DataTable
              data={modifiedData?.result || []}
              columns={columns}
              sortable={true}
              searchable={false}
              showColumnFilter={false}
              // selection={rbac.rbacDetails.allowInstructionAssignment == 'Y'}
              selectionInitialState={selectionInitialState}
              // perPage={20}
              configGroup={
                //  grids?.find?.((g) => g?.gridId === 2)?.configGroup || []
                grids?.find?.((g: any) => g?.gridName === invGridName)
                  ?.configGroup || []
              }
              perPage={20}
              pagination={true}
              showExport={false}
              isChecked={(row) => {
                return selection?.[row?.draftNo] ? 'Y' : 'N'
              }}
              ///  currentPage={currentPage}
              localFilter={true}
              selectedRows={selection}
              isAllChecked={(rows) => {
                const matchAll = rows?.every?.((x: any) => !!selection?.[x?.draftNo]);
                const matchSome = rows?.some?.((x: any) => !!selection?.[x?.draftNo])
                if (matchAll && matchSome) return 'Y'
                if (matchSome) return 'S'
                return 'N'
              }}
              showColumnSettings={showColumnSettings}
              gridName={invGridName}
              onHide={onHideColumnSettings}

              columnSettingsPersistKey='@odex/maintable/v6'
              onCustomSelectChange={(d) => {
                if (d?.list?.length > 0) {
                  dispatch(toggleAddAgentButton(true));
                } else {
                  dispatch(toggleAddAgentButton(false));
                  dispatch(toggleAddAgent(false));
                }
              }}
              customSort={false}
              selection={
                selectedInvoiceType === 'CWPDRAFTINVOICES'
                && profileDetails.opcPartnerType != "PTO"
              }
              onSelectRow={(dat) => {

                if (dat.action == 'add') {
                  dispatch(updateSelectedRowsInvoice({ ...selection, [dat.row.draftNo]: dat.row }))
                }
                if (dat.action == 'remove') {
                  dispatch(updateSelectedRowsInvoice(({ ...selection, [dat.row.draftNo]: undefined })))
                }
                if (dat.action == 'addAll') {
                  dispatch(updateSelectedRowsInvoice(({
                    ...selection, ...dat?.rows?.reduce((a: any, b: any) => ({
                      ...a, [b.draftNo]: b
                    }), {})
                  })))
                }
                if (dat.action == 'removeAll') {
                  dispatch(updateSelectedRowsInvoice(({
                    ...selection, ...dat?.rows?.reduce((a: any, b: any) => ({
                      ...a, [b.draftNo]: undefined
                    }), {})
                  })))
                }

                // if (dat.action == 'add') {
                //   dispatch(
                //     updateSelectedRowsInvoice([
                //       ...selectedRowsInvoice,
                //       dat.data,
                //     ])
                //   );
                // } else if (dat.action == 'remove') {
                //   const filteredItems = selectedRowsInvoice.filter(
                //     (item: any) => item.draftNo != dat.data.draftNo
                //   );
                //   dispatch(updateSelectedRowsInvoice(filteredItems));
                // } else if (dat.action == 'addHeader') {
                //   dispatch(updateSelectedRowsInvoice(dat.data));
                // } else if (dat.action == 'removeHeader') {
                //   dispatch(updateSelectedRowsInvoice([]));
                // }
              }}
              shortPrepend='bl_'
              uniqueFilterKey='invDashboard'
              staticHiddenCols={configs?.staticHiddenCols || []}
              defaultHiddenCols={configs?.defaultHiddenCols || []}
              autoHeight={false}
              tableMinHeight={400}
              dataCountry={dataCountry}
              gridId={grids?.[0]?.gridId || 0}
              dataLoading={false}
              manualFilter={true}
            />

            {/* <DataTable
              data={modifiedData?.result || []}
              columns={columnsCustom}
              sortable={true}
              // customSort={true}
              searchable={false}
              showColumnFilter={false}
              selection={rbac.rbacDetails.allowInstructionAssignment == 'Y'}
              selectionInitialState={selectionInitialState}
              perPage={20}
              // localFilter={true}
              pagination={false}

              configGroup={
                //  grids?.find?.((g) => g?.gridId === 2)?.configGroup || []
                grids?.find?.((g:any) => g?.gridName === 'OPC_INV_DASHBOARD')
                  ?.configGroup || []
              }
              onChangePageNumber={(pageNumber) => setCurrentPage(pageNumber)}
              numberPagination={true}
              seekPagination={false}
              maxPage={parseInt(modifiedData?.maxPage || '100')}
              hasNext={modifiedData?.hasNext}
              showExport={false}
              currentPage={currentPage}
              localFilter={true}
              showColumnSettings={showColumnSettings}
              gridName='OPC_INV_DASHBOARD'
              onHide={onHideColumnSettings}
              columnSettingsPersistKey='@odex/maintable/v6'
              onCustomSelectChange={(d) => {
                if (d?.list?.length > 0) {
                  dispatch(toggleAddAgentButton(true));
                } else {
                  dispatch(toggleAddAgentButton(false));
                  dispatch(toggleAddAgent(false));
                }
              }}
              customSort={true}
              shortPrepend='bl_'
              uniqueFilterKey='invDashboard'
              staticHiddenCols={configs?.staticHiddenCols || []}
              defaultHiddenCols={configs?.defaultHiddenCols || []}
              autoHeight={false}
              tableMinHeight={400}
              dataCountry={dataCountry}
              gridId={grids?.[0]?.gridId || 0}
              dataLoading={false}
            /> */}
          </>
        ) : (
          ''
        )}
      </div>
      <div
        className={`${showAddAgent
          ? 'col-sm-12 col-md-5 col-lg-5 odex-overlap-card h-100'
          : showNominaationReq
            ? 'col-sm-12 col-md-6 col-lg-6 odex-overlap-card h-100'
            : 'd-none'
          }`}
      ></div>
    </div>
  );
};

export default TableComponent;
