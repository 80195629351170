import { useContext, useEffect, useMemo, useState } from "react";
import { Navbar, Nav, FormControl, Card } from "react-bootstrap";
import classNames from "classnames";
import AppContext from "../../../context/Context";
import Logo from "../../common/Logo";
import { navbarBreakPoint, topNavbarBreakpoint } from "../../../config";
import TopNavRightSideNavItem from "./TopNavRightSideNavItem";
import { useLocation, matchPath } from "react-router";
import { Form, InputGroup, Dropdown } from "react-bootstrap";
import queryString from "query-string";
import { getCodeList } from "../../../app/features/blRequestInstruction/components/actions/Instruction";
import { useDispatch } from "react-redux";
import { getDashboardData } from "../../../app/features/dashboard/actions/dashboard";

import { useSelector } from "react-redux";
import {
  updateBlTopSearchState,
  updateInvoiceTopSearchState,
  updateSearchInputValueNT,
  updateTopSearchFilter,
  updatePrevSearchInput,
  updateInstructionTopSearchState,
  updateUserTopSearchState,
  updateLinerId,
  updateReportTopSearchState,
  updatePaymentTopSearchState,
  updatePortOptions,
} from "../../../app/common/slices/navbarTop";
import {
  getCreditLimit,
  getExchangeRate,
  getSearchFiltter,
} from "../../../app/features/blPayment/actions/payments";

import { updateLinerDataCountry } from "../../../app/common/slices/userDetails";
import i18next from "i18next";
import {
  AESEncrypt,
  AESEncryptforDashboard,
  Base64Encode,
} from "../../../encrypt-util";
import {
  updateCurrentPageBlDashboard,
  updateCurrentPageInvoiceDashboard,
  updateCurrentPageInstructionDashboard,
  updateCurrentPageUserDashboard,
  updateCurrentPagePayment,
  updateCurrentPageReportDashboard,
} from "../../../app/features/dashboard/slices/dashboardSlice";
import { updateCookie } from "../../../app/common/actions/userDetails";
import useReportGridName from "../../../app/features/reportDashboard/useReportGridName";
import { updateDashbaordRefresh } from "../../../app/common/components/DataTable/slices/DataTable";
import { tpoId } from "../../../app/features/blRequestInstruction/components/instructionSlice/AgentNominationSlice";

const NavbarTop = () => {
  const dispatch = useDispatch();

  const searchInputValue = useSelector(
    (state) => state.navbarTop.searchInputValue
  );
  const { reportGridName } = useReportGridName();
  const prevSearchInput = useSelector(
    (state) => state.navbarTop.prevSearchInput
  );
  const selectedDashbaordName = useSelector(
    (state) => state.navbarTop.selectedDashbaordName
  );
  const location = useLocation();
  const selectedTopSearchFilter = useSelector(
    (state) => state.navbarTop.selectedTopSearchFilter
  );

  const [prevPathName, setPrevPathName] = useState("opc/home");

  const linerCountryList = useSelector(
    (state) => state.userProfile.countryList
  );
  const grids = useSelector((state) => state.dataTable.grids);
  const tpoId = useSelector((state) => state.agentDelegation.tpoId);
  const invoiceGrids = useSelector((state) => state.dataTable.invoiceGrids);
  const instructionsGrids = useSelector(
    (state) => state.dataTable.instructionsGrids
  );
  const userGrids = useSelector((state) => state.dataTable.userGrids);
  const paymentGrids = useSelector((state) => state.dataTable.paymentGrids);
  const reportGrids = useSelector((state) => state.dataTable.reportGrids);
  const getGirdList = (gridName, gridWhere, sorts) => {
    dispatch(
      getDashboardData({
        gridName: gridName,
        payload: {
          fields: [],
          filters: {},
          size: 20,
          sorts: [`${sorts}`],
          page: 1,
        },
        dataCountry: dataCountry,

        partnerId:
          profileDetails.partnerType === "customer"
            ? profileDetails.partnerId
            : null,
        parameter:
          selectedTopSearchFilter?.where !== ""
            ? {
                searchParameter: searchInputValue.trim(),
                searchWhere: selectedTopSearchFilter?.where,
              }
            : {},
        where: AESEncrypt(gridWhere) || {},
      })
    );
  };

  const getSearchFilterList = (values) => {
    const searchFilter = values?.[0]?.searchFilter;
    let searchFilterList = [];

    if (searchFilter) {
      searchFilterList = JSON.parse(searchFilter);
    }
    return searchFilterList;
  };

  const searchFilterList = getSearchFilterList(
    selectedDashbaordName === "BL_DASHBOARD"
      ? grids
      : selectedDashbaordName === "INVOICE_DASHBOARD"
      ? invoiceGrids
      : selectedDashbaordName === "INSTRUCTION_DASHBOARD"
      ? instructionsGrids
      : selectedDashbaordName === "OPC_USER_DASHBOARD"
      ? userGrids
      : selectedDashbaordName === reportGridName
      ? reportGrids
      : selectedDashbaordName === "PAYMENT_DASHBOARD"
      ? paymentGrids
      : []
  );

  const param =
    '{"' +
    selectedTopSearchFilter?.id +
    '": ["' +
    searchInputValue.trim() +
    '"]}';

  const getGridName = () => {
    if (location.pathname === "opc/home") {
      return "BL_DASHBOARD";
    } else if (location.pathname === "/payment") {
      return "INVOICE_DASHBOARD";
    } else if (location.pathname === "opc/instruction") {
      return "OPC_INSTRUCTION_DASHBOARD";
    } else if (location.pathname === "opc/doupload") {
      return "OPC_DO_DASHBOARD";
    } else if (location.pathname === "/report") {
      return reportGridName;
    } else if (location.pathname === "/paymentDashboard") {
      return "PAYMENT_DASHBOARD";
    } else return null;
  };
  const linerData = useSelector((state)=>state.navbarTop.portOptions)

  const getSortOrder = () => {
    if (location.pathname === "opc/home") return "lastupdateddate desc";
    if (location.pathname === "/payment") return "blid asc";
    if (location.pathname === "/instruction") return "timestamp desc";
    if (location.pathname === "/users") return "user_id desc";
    if (location.pathname === "/report") return "bl_no desc";
    if (location.pathname === "/paymentDashboard") return "pmtmode desc";

    return null;
  };

  const getGridWhere = (
    grids,
    invoiceGrids,
    instructionsGrids,
    userGrids,
    reportGrids,
    paymentGrids
  ) => {
    if (location.pathname === "opc/home") return grids?.[0]?.whereClause;
    if (location.pathname === "/payment") return invoiceGrids?.[0]?.whereClause;
    if (location.pathname === "/instruction")
      return instructionsGrids?.[0]?.whereClause;
    if (location.pathname === "/users") return userGrids?.[0]?.whereClause;
    if (location.pathname === "/report") return reportGrids?.[0]?.whereClause;
    if (location.pathname === "/paymentDashboard")
      return paymentGrids?.[0]?.whereClause;
    // Add other paths if necessary
    return null;
  };
  const gridName = getGridName();

  const gridWhere = getGridWhere(
    grids,
    invoiceGrids,
    instructionsGrids,
    userGrids,
    reportGrids,
    paymentGrids
  );
  const gridSort = getSortOrder();

  useEffect(() => {
    const isPathValid =
      location.pathname === "opc/home" ||
      location.pathname === "/payment" ||
      location.pathname === "/instruction" ||
      location.pathname === "/report" ||
      location.pathname === "paymentDashboard";
    const isPrevPathValid =
      prevPathName === "opc/home" ||
      prevPathName === "/payment" ||
      prevPathName === "/instruction" ||
      prevPathName === "/report" ||
      prevPathName === "/paymentDashboard";

    if (isPathValid && isPrevPathValid) {
      if (gridName /* && gridWhere && gridSort */) {
        if (prevSearchInput) getGirdList(gridName, gridWhere, gridSort);
        dispatch(updateBlTopSearchState({}));
        dispatch(updateInvoiceTopSearchState({}));
        dispatch(updateInstructionTopSearchState({}));
        dispatch(updateUserTopSearchState({}));
        dispatch(updateSearchInputValueNT(""));
      }
    }
    dispatch(updatePrevSearchInput(searchInputValue.trim()));
    setPrevPathName(location.pathname);
  }, [location.pathname]);
  useEffect(() => {
    dispatch(updateTopSearchFilter(searchFilterList[0]));
  }, [JSON.stringify(searchFilterList)]);

  const handleFilterChange = (e) => {
    const selectedId = e.target.value;
    const selectedFilter = searchFilterList.find(
      (option) => option.id === selectedId
    );

    if (selectedFilter) {
      dispatch(updateTopSearchFilter(selectedFilter));
    }
  };

  const _updateSearchInputValue = (e) => {
    if (!e.target.value) {
      if (location.pathname === "opc/home") {
        dispatch(updateBlTopSearchState({}));
      } else if (location.pathname === "/payment") {
        dispatch(updateInvoiceTopSearchState({}));
      } else if (location.pathname === "/instruction") {
        dispatch(updateInstructionTopSearchState({}));
      } else if (location.pathname === "/users") {
        dispatch(updateUserTopSearchState({}));
      } else if (location.pathname === "/report") {
        dispatch(updateReportTopSearchState({}));
      } else if (location.pathname === "/paymentDashboard") {
        dispatch(updatePaymentTopSearchState({}));
      }
    }
    if (
      searchInputValue.trim() &&
      !e.currentTarget.value &&
      gridName /*  && gridWhere */
    ) {
      dispatch(updateDashbaordRefresh(true));
    }
    dispatch(updateSearchInputValueNT(e.target.value));
  };

  const {
    config: { showBurgerMenu, navbarPosition, navbarCollapsed },
    setConfig,
  } = useContext(AppContext);

  const { pathname } = useLocation();
  const isChat = pathname.includes("chat");
  const profileDetails = useSelector((state) => state.userProfile.profile);
  const [showDropShadow, setShowDropShadow] = useState(false);

  const storedUserCountry = JSON.parse(
    window.localStorage.getItem("@user-selected-datacountry") || "{}"
  );
  const storedCountry =
    storedUserCountry?.[0]?.loginId === profileDetails.email
      ? storedUserCountry?.[0]?.country
      : null;

  const handleBurgerMenu = () => {
    navbarPosition === "top" && setConfig("navbarCollapsed", !navbarCollapsed);

    (navbarPosition === "vertical" || navbarPosition === "combo") &&
      setConfig("showBurgerMenu", !showBurgerMenu);
  };

  const setDropShadow = () => {
    const el = document.documentElement;
    if (el.scrollTop > 0) {
      setShowDropShadow(true);
    } else {
      setShowDropShadow(false);
    }
  };

  const partnerCountry = profileDetails.partnerCountry;
  const linerDataCountry = useSelector(
    (state) => state.userProfile.linerDataCountry
  );

  const linerId = 1; /* useSelector((state) => state.navbarTop.linerId); */

  // useEffect(() => {
  //   dispatch(updateLinerId('1'));
  // }, []);

  const dataCountry = storedCountry
    ? storedCountry
    : linerDataCountry
    ? linerDataCountry
    : profileDetails.partnerCountry || null;
  
  

useEffect(() => {
  if(tpoId)
  {
    dispatch(getCreditLimit(tpoId));
}
}, [tpoId]);
    
    useEffect(() => {
    window.addEventListener("scroll", setDropShadow);
    return () => window.removeEventListener("scroll", setDropShadow);
  }, []);

  useEffect(() => {
    if (partnerCountry !== null) {
      dispatch(getSearchFiltter(partnerCountry));
    }
  }, [partnerCountry]);

  useEffect(() => {
    if (linerCountryList && linerCountryList.length == 1 && !storedCountry) {
      dispatch(
        updateLinerDataCountry(
          storedCountry ||
            linerCountryList[0]?.countryCode ||
            profileDetails.partnerCountry
        )
      );
      const userSelectedCountry = [
        {
          loginId: profileDetails.email,
          country:
            storedCountry ||
            linerCountryList[0]?.countryCode ||
            profileDetails.partnerCountry ||
            "",
        },
      ];
      window.localStorage.setItem(
        "@user-selected-datacountry",
        JSON.stringify(userSelectedCountry)
      );
      i18next.changeLanguage(
        storedCountry ||
          linerCountryList[0]?.countryCode ||
          profileDetails.partnerCountry
      );
    }
  }, [linerCountryList]);
  const handleSearch = (event) => {
    if (event.key === "Enter") {
      if (searchInputValue.trim().length > 0) {
        if (matchPath(location.pathname, "opc/home")) {
          dispatch(updateCurrentPageBlDashboard(1));
          dispatch(updateBlTopSearchState(JSON.parse(param)));
          if (
            (profileDetails?.partnerType === "customer" &&
              profileDetails?.partnerId &&
              grids?.[0]?.whereClause) ||
            profileDetails.partnerType === "liner"
          ) {
            dispatch(
              getDashboardData({
                gridName: "BL_DASHBOARD",
                payload: {
                  //fields: SelectedViewFields?.fields || [],
                  fields: [],
                  filters:
                    selectedTopSearchFilter?.where == ""
                      ? JSON.parse(param)
                      : {},
                  size: 20,
                  sorts: ["lastupdateddate desc"],
                  page: 1,
                },
                dataCountry: dataCountry,
                parameter:
                  selectedTopSearchFilter?.where !== ""
                    ? {
                        searchParameter: searchInputValue.trim(),
                        searchWhere: selectedTopSearchFilter?.where,
                      }
                    : {},
                where: AESEncrypt(grids?.[0]?.whereClause) || {},
                partnerId:
                  profileDetails.partnerType === "customer"
                    ? profileDetails.partnerId
                    : null,
              })
            );
          }
        } else if (matchPath(location.pathname, "/report")) {
          dispatch(updateCurrentPageReportDashboard(1));
          dispatch(updateReportTopSearchState(JSON.parse(param)));
          if (
            (profileDetails?.partnerType === "customer" &&
              profileDetails?.partnerId &&
              reportGrids?.[0]?.whereClause) ||
            profileDetails.partnerType === "liner"
          ) {
            dispatch(
              getDashboardData({
                gridName: reportGridName,
                payload: {
                  //fields: SelectedViewFields?.fields || [],
                  fields: [],
                  filters:
                    selectedTopSearchFilter?.where == ""
                      ? JSON.parse(param)
                      : {},
                  size: 20,
                  sorts: ["bl_no desc"],
                  page: 1,
                },
                dataCountry: dataCountry,
                parameter:
                  selectedTopSearchFilter?.where !== ""
                    ? {
                        searchParameter: searchInputValue.trim(),
                        searchWhere: selectedTopSearchFilter?.where,
                      }
                    : {},
                where: AESEncrypt(reportGrids?.[0]?.whereClause) || {},
                partnerId:
                  profileDetails.partnerType === "customer"
                    ? profileDetails.partnerId
                    : null,
              })
            );
          }
        } else if (matchPath(location.pathname, "/payment")) {
          dispatch(updateCurrentPageInvoiceDashboard(1));
          dispatch(updateInvoiceTopSearchState(JSON.parse(param)));
          if (
            (profileDetails?.partnerType === "customer" &&
              profileDetails?.partnerId &&
              invoiceGrids?.[0]?.whereClause) ||
            profileDetails.partnerType === "liner"
          ) {
            dispatch(
              getDashboardData({
                gridName: "INVOICE_DASHBOARD",
                payload: {
                  //fields: SelectedViewFields?.fields || [],
                  fields: [],
                  filters:
                    selectedTopSearchFilter?.where == ""
                      ? JSON.parse(param)
                      : {},
                  size: 20,
                  sorts: ["blid asc"],
                  page: 1,
                },
                dataCountry: dataCountry,
                parameter:
                  selectedTopSearchFilter?.where !== ""
                    ? {
                        searchParameter: searchInputValue.trim(),
                        searchWhere: selectedTopSearchFilter?.where,
                      }
                    : {},
                where: AESEncrypt(invoiceGrids?.[0]?.whereClause) || {},
                partnerId:
                  profileDetails.partnerType === "customer"
                    ? profileDetails.partnerId
                    : null,
              })
            );
          }
        } else if (matchPath(location.pathname, "/instruction")) {
          dispatch(updateCurrentPageInstructionDashboard(1));
          dispatch(updateInstructionTopSearchState(JSON.parse(param)));
          if (
            (profileDetails?.partnerType === "customer" &&
              profileDetails?.partnerId &&
              instructionsGrids?.[0]?.whereClause) ||
            profileDetails.partnerType === "liner"
          ) {
            dispatch(
              getDashboardData({
                gridName: "OPC_INSTRUCTION_DASHBOARD",
                payload: {
                  fields: [],
                  filters:
                    selectedTopSearchFilter?.where == ""
                      ? JSON.parse(param)
                      : {},
                  size: 20,
                  sorts: ["timestamp desc"],
                  page: 1,
                },
                dataCountry: dataCountry,
                parameter:
                  selectedTopSearchFilter?.where !== ""
                    ? {
                        searchParameter: searchInputValue.trim(),
                        searchWhere: selectedTopSearchFilter?.where,
                      }
                    : {},
                where: AESEncrypt(instructionsGrids?.[0]?.whereClause) || {},
                partnerId:
                  profileDetails.partnerType === "customer"
                    ? profileDetails.partnerId
                    : null,
              })
            );
          }
        } else if (matchPath(location.pathname, "/users")) {
          dispatch(updateCurrentPageUserDashboard(1));
          dispatch(updateUserTopSearchState(JSON.parse(param)));
          if (
            (profileDetails?.partnerType === "customer" &&
              profileDetails?.partnerId &&
              userGrids?.[0]?.whereClause) ||
            profileDetails.partnerType === "liner"
          ) {
            dispatch(
              getDashboardData({
                gridName: "OPC_USER_DASHBOARD",
                payload: {
                  fields: [],
                  filters:
                    selectedTopSearchFilter?.where == ""
                      ? JSON.parse(param)
                      : {},
                  size: 20,
                  sorts: ["user_id desc"],
                  page: 1,
                },
                dataCountry: dataCountry,
                parameter:
                  selectedTopSearchFilter?.where !== ""
                    ? {
                        searchParameter: searchInputValue.trim(),
                        searchWhere: selectedTopSearchFilter?.where,
                      }
                    : {},
                where: AESEncrypt(userGrids?.[0]?.whereClause) || {},
                partnerId:
                  profileDetails.partnerType === "customer"
                    ? profileDetails.partnerId
                    : null,
              })
            );
          }
        } else if (matchPath(location.pathname, "/paymentDashboard")) {
          dispatch(updateCurrentPagePayment(1));
          dispatch(updateUserTopSearchState(JSON.parse(param)));
          if (
            (profileDetails?.partnerType === "customer" &&
              profileDetails?.partnerId &&
              paymentGrids?.[0]?.whereClause) ||
            profileDetails.partnerType === "liner"
          ) {
            dispatch(
              getDashboardData({
                gridName: "PAYMENT_DASHBOARD",
                payload: {
                  fields: [],
                  filters:
                    selectedTopSearchFilter?.where == ""
                      ? JSON.parse(param)
                      : {},
                  size: 20,
                  sorts: ["pmtmode desc"],
                  page: 1,
                },
                dataCountry: dataCountry,
                parameter:
                  selectedTopSearchFilter?.where !== ""
                    ? {
                        searchParameter: searchInputValue.trim(),
                        searchWhere: selectedTopSearchFilter?.where,
                      }
                    : {},
                where: AESEncrypt(paymentGrids?.[0]?.whereClause) || {},
                partnerId:
                  profileDetails.partnerType === "customer"
                    ? profileDetails.partnerId
                    : null,
              })
            );
          }
        }
      } else {
        dispatch(updateDashbaordRefresh(true));
      }
    }
  };
  /*   useEffect(() => {
    if (tpoId)
      dispatch(getExchangeRate({ tpoIdval: tpoId, selectedCcy: "usd" }))
        .then((response) => {
          const rate = response.payload.exchangeRate;
          setExchangeRate(rate);
        })
        .catch((error) =>
          console.error("Error fetching the exchange rate:", error)
        );
  }, [tpoId, location]); */
  useEffect(() => {
    if (tpoId)
      dispatch(getExchangeRate({ tpoIdval: tpoId, selectedCcy: "usd" }));
  }, [tpoId]);
  useEffect(() => {
    /*   dispatch(
      updateCookie({
        key: 'TjVRRXh5RVdPV2tOTmpHbFBKWE5xUT09',
        value: 'ZjdxeTZMYkt0RHlkZlAzUEp1UU1IZz09',
      })
    ); */

    dispatch(
      getCodeList({
        // codeType: 'ACTIVE_LINERS',
        codeType: "ACTIVE_PORT",
        linerId: 1,
        partnerCountry: profileDetails?.partnerCountry,
      })
    ).then((returnedData) => {
      dispatch(updatePortOptions(returnedData?.payload));
      dispatch(
        updateLinerId(
          window.localStorage.getItem("tpo_id")
            ? window.localStorage.getItem("tpo_id")
            : returnedData?.payload[0]?.value.toString()
        )
      );
    });
    if (tpoId)
      dispatch(
        updateCookie({
          key: Base64Encode(AESEncryptforDashboard("liner_id")),
          value: Base64Encode(
            AESEncryptforDashboard(
              window.localStorage.getItem("tpo_id")
                ? window.localStorage.getItem("tpo_id")
                : tpoId.toString() /* returnedData?.payload[0]?.value.toString() */
            )
          ),
        })
      );
    /*   });
    } else { */
    /*       dispatch(
        updateLinerId(
          window.localStorage.getItem('liner_id')
            ? window.localStorage.getItem('liner_id')
            : profileDetails?.partnerId
        )
      ); */
    /*     dispatch(
        updateCookie({
          key: Base64Encode(AESEncryptforDashboard('liner_id')),
          value: Base64Encode(
            AESEncryptforDashboard(
              window.localStorage.getItem('liner_id')
                ? window.localStorage.getItem('liner_id')
                : profileDetails?.partnerId?.toString()
            )
          ),
        })
      ); */
    // }
  }, [profileDetails?.partnerCountry, tpoId]);
  return (
    <Navbar
      className={classNames("navbar-glass  fs--1 navbar-top sticky-kit", {
        "navbar-glass-shadow": showDropShadow && !isChat,
      })}
      expand={
        navbarPosition === "top" || navbarPosition === "combo"
          ? topNavbarBreakpoint
          : true
      }
    >
      <Navbar.Toggle
        className={classNames("toggle-icon-wrapper me-md-3 me-2", {
          "d-lg-none": navbarPosition === "top",
          [`d-${navbarBreakPoint}-none`]:
            navbarPosition === "vertical" || navbarPosition === "combo",
        })}
        as="div"
      >
        <button
          className="navbar-toggler-humburger-icon btn btn-link d-flex flex-center"
          onClick={handleBurgerMenu}
          id="burgerMenu"
        >
          <span className="navbar-toggle-icon">
            <span className="toggle-line" />
          </span>
        </button>
      </Navbar.Toggle>

      <Logo at="navbar-top" width={100} id="topLogo" />

      <Nav navbar className={`flex-row align-items-center`} as="ul">
        {!(profileDetails?.opcPartnerType == "PTO") ? (
          <Nav.Item
            as="li"
            className="flex-row align-items-center justify-content-start"
          >
            <InputGroup>
              <InputGroup.Text
                className="rounded-pill-l bg-100 fs--1"
                style={{ fontWeight: 450 }}
              >
                Terminal
              </InputGroup.Text>
              {linerData.length > 1 ? (
                <Form.Select
                  aria-label="Carrier"
                  className="rounded-pill-r text-primary fs--1"
                  size="md"
                  value={linerId}
                  onChange={(e) => {
                    dispatch(updateLinerId(e.target.value));

                    dispatch(
                      updateCookie({
                        key: Base64Encode(AESEncryptforDashboard("liner_id")),
                        value: Base64Encode(
                          AESEncryptforDashboard(e.target.value)
                        ),
                      }),
                      localStorage.setItem(`tpo_id`, e.target.value)
                      //  window.location.reload()
                    );
                  }}
                >
                  {linerData.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.text}
                    </option>
                  ))}
                </Form.Select>
              ) : (
                <InputGroup.Text
                  className="rounded-pill-r text-primary fs--1"
                  style={{
                    fontWeight: 450,
                    backgroundColor: "rgb(249, 250, 253)",
                  }}
                >
                  {linerData.length === 1 && linerData[0].text}
                </InputGroup.Text>
              )}
            </InputGroup>
          </Nav.Item>
        ) : (
          <Nav.Item
            as="li"
            className="flex-row align-items-center justify-content-start"
          >
            {linerCountryList?.length > 1 ? (
              <InputGroup>
                <Form.Select
                  aria-label="Country"
                  className="rounded-pill text-primary fs-0"
                  size="md"
                  onChange={(e) => {
                    dispatch(updateLinerDataCountry(e.target.value));
                    dispatch(
                      updateCookie({
                        key: Base64Encode(
                          AESEncryptforDashboard("partner_country")
                        ),
                        value: Base64Encode(
                          AESEncryptforDashboard(e.target.value)
                        ),
                      })
                    );
                    const userSelectedCountry = [
                      {
                        loginId: profileDetails.email,
                        country: e.target.value || "",
                      },
                    ];
                    window.localStorage.setItem(
                      "@user-selected-datacountry",
                      JSON.stringify(userSelectedCountry)
                    );
                    i18next.changeLanguage(e.target.value);
                  }}
                  value={dataCountry}
                >
                  {linerCountryList?.map((option) => (
                    <option
                      key={option.countryCode}
                      value={option?.countryCode}
                    >
                      {option?.countryName}
                    </option>
                  ))}
                </Form.Select>
              </InputGroup>
            ) : (
              <></>
            )}
          </Nav.Item>
        )}

        <Nav.Item
          as="li"
          className="ps-4 flex-row align-items-center justify-content-start"
        >
          {searchFilterList?.length > 0 ? (
            <InputGroup className="row g-0">
              <div className="col-5">
                <Form.Select
                  aria-label="Carrier"
                  className="rounded-pill-l bg-100 fs--1 h-100"
                  onChange={handleFilterChange}
                  value={selectedTopSearchFilter?.id}
                >
                  {searchFilterList?.map((option) => (
                    <option
                      className="text-dark"
                      key={option.id}
                      value={option.id}
                    >
                      {option.desc}
                    </option>
                  ))}
                </Form.Select>
              </div>

              <div className="col-7">
                <FormControl
                  type="search"
                  className="rounded-pill-r text-primary fs-0 shadow-none"
                  placeholder="Search..."
                  onChange={_updateSearchInputValue}
                  onKeyDown={handleSearch}
                  value={searchInputValue}
                />
              </div>
            </InputGroup>
          ) : (
            <></>
          )}
        </Nav.Item>
      </Nav>
        <Nav navbar>             
        </Nav>
      <TopNavRightSideNavItem />
    </Navbar>
  );
};

export default NavbarTop;
