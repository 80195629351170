import FilterView from '../../../common/components/FilterView';
import { matchPath, useLocation } from 'react-router-dom';
import { toggleColumnSettingsDashboard } from '../slices/dashboardSlice';
import { RootState } from '../../../store';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

const DashboardRoot = () => {
  const location = useLocation();
  const [tabelData, settabelData] = useState<any>({});
  const dataLoading = useSelector(
    (state: RootState) => state.dashboard.dataLoading
  );
  const grids = useSelector((state: RootState) => state.dataTable.permitGrids);
  const permitTrackingState = useSelector((state: RootState) => state.dataTable.permitTrackingState)

  const data = useSelector(
    (state: RootState) => state.invoiceChange.permitDashboardData
  );

  useEffect(() => {
    if (data) {
      settabelData({ hasNext: true, result: data, size: 20 });
    }
  }, [data]);

  return (
    <FilterView
      showColumnSettings={permitTrackingState != ''}
      //  showPayButton={true}
      toggleColumnSettings={toggleColumnSettingsDashboard}
      showExport
      columnSettingsPersistKey='@odex/maintable/v6'
      uniqueFilterKey='permitDashboard'
      dataLoading={dataLoading}
      //  configViews={grids?.find?.((g) => g?.gridId === 1)?.configGroup || []}
      configViews={
        grids?.find?.((g: any) => g?.gridName === 'OPC_PERMIT_DASHBOARD')
          ?.configGroup || []
      }
      showRightDiv={
        !!matchPath(location.pathname, '/opc/permit-tracking') &&
        permitTrackingState != ''
      }
      manualFilter
      dashboardName='OPC_PERMIT_DASHBOARD'
      data={tabelData}
      gridDef={grids}
      csvByMail={false}
    />
  );
};

export default DashboardRoot;
