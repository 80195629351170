import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { boolean } from "yup";
import { getBankListBeneficiary } from "./beneficiaryReportAction";

interface BankList{
  label:String;
  value:String;
}

interface IPaymentDashSlice {
  showColumnSettings: boolean;
  bankList:Array<BankList>;
}

const initialState: IPaymentDashSlice = {
  showColumnSettings: false,
  bankList:[]
};

export const BeneficiaryPaymentSlice = createSlice({
  name: "beneficiaryPaymentSlice",
  initialState,
  reducers: {
    toggleColumnSettingsPayDash: (state, action) => {
      state.showColumnSettings = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getBankListBeneficiary.fulfilled, (state, action) => {
      state.bankList = action.payload??[];
    });
  }
});

export const { toggleColumnSettingsPayDash } = BeneficiaryPaymentSlice.actions;
export default BeneficiaryPaymentSlice.reducer;
