import { useSelector } from 'react-redux';
import DataTable from '../../common/components/DataTable';
import { AppDispatch, RootState } from '../../store';
import { CreditBalanceBlPaymentDetails } from './slices/creditBalance';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useRef, useState } from 'react';
import { getCreditBalance} from '../blRequestInstruction/components/actions/Instruction';
import { useDispatch } from 'react-redux';
import { selectedCredit } from '../../common/slices/creditBalanceSlice';
import { getInvoiceDetailsByPmtId } from '../blRequestInstruction/components/actions/Instruction';
import {
  InvoicePmtDetails,
  PaymentBlPaymentDetails,
  flagPmtNumber,
  multiplePmtRef,
  pmtReftabledata,
  selectedPayment,
} from './slices/PaymentInvoiceSlice';
import {
  faAdd,
  faCheckCircle,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import React from 'react';
import { useAsyncDebounce } from 'react-table';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router';
import { setEditPayment } from './slices/blPaymentSlice';
import {
  exportDashboardData,
  getDashboardData,
} from '../dashboard/actions/dashboard';
import { AESDecrypt, AESEncrypt } from '../../../encrypt-util';
import { useLoading } from '../../../middleware/loadingMiddleware';

interface ChildComponentProps {
  pmtID: any;
  inputValue: any;
  selectedCcy: any;
}

const PaymentInvoiceList: React.FC<ChildComponentProps> = ({
  pmtID,
  inputValue,
  selectedCcy,
}) => {
  var newArray: any;
  const { t } = useTranslation();
  // const data = useSelector((state: RootState) => state.paymentBlPayment.data);
  const dispatch = useDispatch<AppDispatch>();
  const invoiceDetails = useSelector(
    (state: RootState) => state.paymentBlPayment.invoiceDetailsPmtId
  );

  const mstCodeList = useSelector(
    (state: RootState) => state.paymentBlPayment.mstCodeList
  );


  const validationFlag = useSelector(
    (state: RootState) => state.paymentBlPayment.validationFlag
  );

  const editPayment = useSelector(
    (state: RootState) => state.blPayment.editPayment
  );
  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    };

    const date = new Date(dateString);
    return date.toLocaleString('en-US', options).replace(/,/, '');
  };
  const [addButtonDisable, setAddButtonDisable] = useState(false);
  const [newArr, setNewArr] = useState<any>([]);
  const selecteCredData = useSelector(
    (state: RootState) => state.creditBalance.selectedCreditData
  );
  const dataLoading = useLoading(['instruction/getInvoiceDetailsByPmtId'])
  const location = useLocation();
  const pmtNumberdata = useSelector(
    (state: RootState) => state.paymentBlPayment.pmtNumberdata
  );
  const invoiceDashboardData = useSelector(
    (state: RootState) => state.dashboard.invoiceDashboardList
  );

  function removeDuplicates(arr: any[], prop: string) {
    const uniqueMap = new Map();

    const uniqueArray = arr.filter((obj: { [x: string]: any }) => {
      const key = prop ? obj[prop] : JSON.stringify(obj);
      if (!uniqueMap.has(key)) {
        uniqueMap.set(key, obj);
        return true;
      }
      return false;
    });

    return uniqueArray;
  }

  const uniqueArray = removeDuplicates(invoiceDashboardData.result, 'invno');

  const selectedCreditAmount = selecteCredData
    .map((i: any) => Number(i.unAllocatedAmount))
    .reduce((partialSum: any, a: any) => partialSum + a, 0);
  const amountDetails = useSelector(
    (state: RootState) => state.blPayment.amountDetails
  );

  const _onSelect = (data: any) => {
    dispatch(pmtReftabledata(data.list));
    // dispatch(selectedPayment(data.list));
  };


  useEffect(() => {
    dispatch(getInvoiceDetailsByPmtId(pmtID));
    dispatch(setEditPayment(false));
  }, [pmtID]);

  function checkForNullAndEmptyStrings(arr: any) {
    if (arr.length > 0) {
      const firstObject = arr[0];

      Object.entries(firstObject).forEach(([key, value]) => {
        if (value === null || value === '') {
          // debugger;
          dispatch(flagPmtNumber(true));
        } else {
          // debugger;
          dispatch(flagPmtNumber(false));
        }
      });
    }
  }

  useEffect(() => {
    if (pmtNumberdata) {
      newArrRef.current = pmtNumberdata;
    }
  }, [pmtNumberdata]);

  useEffect(() => {
    if (pmtNumberdata) {
      checkForNullAndEmptyStrings(pmtNumberdata);
      newArrRef.current = pmtNumberdata;
    }
  }, [pmtNumberdata]);


  const inputRefAmt = useRef<HTMLInputElement>(null);
  const inputRefInv = useRef<HTMLInputElement>(null);

  const [invAmt, setInvAmt] = useState<any>('');
  const [invNo, setInvNo] = useState<any>('');

  const [valFlag, setvalFlag] = useState(false);
  // const [ invNoVal ,setinvNoVal] = useState(false)

  const currentPageInvoice = useSelector(
    (state: RootState) => state.dashboard.currentPageInvoice
  );
  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );
  const storedUserCountry = JSON.parse(
    window.localStorage.getItem('@user-selected-datacountry') || '{}'
  );
  const storedCountry =
    storedUserCountry?.[0]?.loginId === profileDetails.email
      ? storedUserCountry?.[0]?.country
      : null;
  const linerDataCountry = useSelector(
    (state: RootState) => state.userProfile.linerDataCountry
  );

  const invoiceGrids = useSelector(
    (state: RootState) => state.dataTable.invoiceGrids
  );
  const dataCountry = storedCountry
    ? storedCountry
    : linerDataCountry
      ? linerDataCountry
      : profileDetails.partnerCountry || '';

  const newArrRef = useRef<any>(pmtNumberdata);


  useEffect(() => {
    if (inputRefAmt.current) {
      inputRefAmt.current.focus();
    }
  }, [invAmt]);

  useEffect(() => {
    if (inputRefInv.current) {
      inputRefInv.current.focus();
    }
  }, [invNo]);

  function checkKeyValue(objects: any, key: string | number) {
    let firstValue;
    // debugger
    for (const obj of objects) {
      const value = obj[key];

      if (firstValue === undefined) {
        firstValue = value;
      } else if (value !== inputValue) {
        dispatch(multiplePmtRef(true));
        return;
      }
    }
    dispatch(multiplePmtRef(false));
  }

  const _onHandleInputChange = useAsyncDebounce(
    async (index: any, type: any, value: string) => {
      if (value != null && value != '') {
        newArray = newArrRef.current.map((item: any, i: any) =>
          i === index ? { ...item, [type]: value } : item
        );
        newArrRef.current = newArray;
        //   setNewArr(pmnewArraytNumberdata.map((item: any, i: any) =>
        //   i === index ? { ...item, [type]: value } : item
        // ))
        if (type == 'invNo') {
          const existingInvoice: any = await dispatch(
            exportDashboardData({
              gridName: 'INVOICE_DASHBOARD',
              payload: {
                fields: /* SelectedViewFields?.fields || */[],
                filters: { invno: [value] },
                size: 20,
                sorts: ['invid desc'],
                //page: currentPageInvoice,
                page: 1,
              },

              dataCountry: dataCountry,
              partnerId:
                profileDetails.partnerType === 'customer'
                  ? profileDetails.partnerId
                  : null,
              where: invoiceGrids?.[0]?.whereClause
                ? AESEncrypt(invoiceGrids?.[0]?.whereClause)
                : null,
              parameter: {},
            })
          );

          if (
            existingInvoice.payload?.find(
              (invoicedata: any) => invoicedata.invno == value
            )?.pmtstatus === '1'
          ) {
            toast.error('Invoice is already paid', { toastId: 'invoicePaid' });
          } else if (existingInvoice.payload?.length == 0) {
            toast.error('Invoice is not available', { toastId: 'invoicePaidError' });
          } else if (
            existingInvoice.payload &&
            existingInvoice.payload !== undefined &&
            existingInvoice.payload.length > 0
          ) {
            if (
              existingInvoice.payload?.find(
                (invoicedata: any) => invoicedata.invno == value
              ) &&
              !pmtNumberdata.find(
                (invoicedata: { invNo: any }) => invoicedata.invNo == value
              )
            ) {
              const uniquePmtArray = removeDuplicates(
                existingInvoice.payload,
                'invno'
              );
              // setvalFlag(false)
              dispatch(flagPmtNumber(false));

              if (inputRefAmt.current) {
                uniquePmtArray.map((invoicedata: any) => {
                  if (invoicedata.invno == value) {
                    if (inputRefAmt.current!.value != null) {
                      // inputRefAmt.current!.value = invoicedata.invamt;
                      if (invoicedata.invcurr == selectedCcy) {
                        const updatedArray = newArrRef.current.map(
                          (obj: any) => {
                            if (obj.invNo == value) {
                              setInvAmt(invoicedata.invamt);

                              return {
                                ...obj,
                                invAmt: invoicedata.invamt,
                                pmtRefNo: invoicedata.pmtreference,
                                invcurr: invoicedata.invcurr,
                                invid: invoicedata.invid,
                              };
                            }
                            return obj;
                          }
                        );
                        checkKeyValue(updatedArray, 'pmtRefNo');
                        dispatch(pmtReftabledata(updatedArray));
                        newArrRef.current = updatedArray;
                      } else {
                        dispatch(flagPmtNumber(true));
                        toast.error(
                          'Invoice Currency is different. Please Select invoices with same Currency.', {
                          toastId: 'invoiceCurrencyToast'
                        }
                        );
                      }

                      /*     var filteredArray: any = [];
                      filteredArray = invoiceDashboardData.result?.filter(
                        (d: any) => {
                          return d.invno === value;
                        }
                      ); */

                      // newArrRef.current = [{...newArrRef.current,invAmt:invoicedata.invamt }]
                      //  pmtNumberdata[index].invAmt = invoicedata.invamt
                    }
                  }
                });
              }
            } else {
              if (
                pmtNumberdata.find(
                  (invoicedata: { invNo: any }) => invoicedata.invNo == value
                )
              ) {
                // setvalFlag(true)
                dispatch(flagPmtNumber(true));
                toast.error('Invoice already present', { toastId: 'invoicePresentAlready' });
              } else {
                dispatch(flagPmtNumber(true));
                // setvalFlag(true)
                toast.error('Invoice not available', { toastId: 'invoiceNotPresent' });
              }
            }
          }
        }
      }
    },
    100
  );

  const columns: {
    accessor: keyof InvoicePmtDetails;
    Header: string;
    minWidth: number;
    width: number;
    maxWidth: number;
    columnFilterType?:
    | 'search'
    | 'single'
    | 'multiple'
    | 'date-range'
    | 'number-range';
    Cell?: any;
  }[] = [
      {
        accessor: 'invNo',
        Header: 'Invoice No',
        minWidth: 80,
        width: 120,
        maxWidth: 150,
        columnFilterType: 'search',
        Cell: (rowData: any) => {
          const {
            cell: { value },
            row: { index },
          } = rowData;
          return rowData.cell.row.original.invNo ? (
            <div>{rowData.cell.row.original.invNo}</div>
          ) : (
            editPayment && (
              <input
                placeholder='Enter Invoice'
                key={index}
                value={invNo}
                ref={inputRefInv}
                onChange={(e) => setInvNo(e.target.value.trim())}
                onBlur={(e) =>
                  _onHandleInputChange(index, 'invNo', e.target.value)
                }
                style={{ border: 'none', backgroundColor: 'transparent' }}
              //  className='fs--1'
              />
            )
          );
        },
      },
      {
        accessor: 'invAmt',
        Header: 'Amount',
        minWidth: 80,
        width: 100,
        maxWidth: 150,
        columnFilterType: 'search',
        Cell: (rowData: any) => {
          const {
            cell: { value },
            row: { index },
          } = rowData;
          const formatNumber = (value: string): string => {
            const numericValue = parseFloat(value);

            if (isNaN(numericValue)) {
              return 'Invalid Number';
            }

            const trimmedValue = numericValue.toFixed(2);

            const numberWithCommas = trimmedValue.replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ','
            );

            return numberWithCommas;
          };
          return (
            // <input type="text" ref={inputReftest} />

            rowData.cell.row.original.invAmt ? (
              <div>{formatNumber(rowData.cell.row.original.invAmt)}</div>
            ) : (
              editPayment && (
                <input
                  key={index}
                  placeholder='Enter Amount'
                  // ref={(el) => (inputRefAmt.current[index] = el as HTMLInputElement)}
                  ref={inputRefAmt}
                  onBlur={(e) =>
                    _onHandleInputChange(index, 'invAmt', e.target.value)
                  }
                  type='text'
                  style={{ border: 'none', backgroundColor: 'transparent' }}
                  // value={inputRefAmt.current[index].value}
                  value={invAmt}
                  onChange={(e) => setInvAmt(e.target.value)}
                // value={rowData.cell.row.original.invAmt}
                />
              )
            )
          );
        },
      },
      {
        accessor: 'invType',
        Header: 'Invoice Type',
        columnFilterType: 'search',
        minWidth: 140,
        width: 100,
        maxWidth: 150,
        Cell: (rowData: any) => {
          const {
            cell: { value },
            row: { index },
          } = rowData;

          const invTypesSet = new Set(pmtNumberdata?.map((item: any) => item.invType));
          const invoiceTypeVal = mstCodeList?.data?.filter((item: any) => invTypesSet?.has(item?.codeId.toString()));
          return rowData.cell.row.original.invType ? (
            <>{invoiceTypeVal?.find((item: any) => item.codeId.toString() === rowData.cell.row.original.invType.toString())?.codeDesc}</>
          ) : (
            editPayment && (
              <input
                placeholder='Enter Invoice'
                key={index}
                value={invNo}
                ref={inputRefInv}
                onChange={(e) => setInvNo(e.target.value.trim())}
                onBlur={(e) =>
                  _onHandleInputChange(index, 'invNo', e.target.value)
                }
                style={{ border: 'none', backgroundColor: 'transparent' }}
              //  className='fs--1'
              />
            )
          );
        },
      },
      {
        accessor: 'invStatus',
        Header: 'Paid',
        minWidth: 80,
        width: 80,
        maxWidth: 150,
        Cell: (rowData: any) => {
          const {
            cell: { value },
            row: { index },
          } = rowData;
          return rowData.cell.row.original.invStatus === 1 ? (
            /*  <div>{rowData.cell.row.original.invStatus}</div> */

            <FontAwesomeIcon
              icon={faCheckCircle}
              className='text-primary ps-1'
              size='lg'
            />
          ) : (
            ''
          ); /* (
          editPayment && (
            <input
              placeholder='Enter Invoice'
              key={index}
              value={invNo}
              ref={inputRefInv}
              onChange={(e) => setInvNo(e.target.value.trim())}
              onBlur={(e) =>
                _onHandleInputChange(index, 'invNo', e.target.value)
              }
              style={{ border: 'none', backgroundColor: 'transparent' }}
              //  className='fs--1'
            />
          ) */
          //);
        },
      },
    ];

  return (
    <>
      {editPayment && addButtonDisable && (
        <div>
          <Button
            disabled={validationFlag}
            className='filters-header-odex-md btn btn-falcon-warning p-0'
            variant='falcon-warning'
            style={{ width: '30px', height: '30px' }}
            size='sm'
            onClick={() => {
              // if(newArr)

              setTimeout(() => {
                setInvAmt(null);
                setInvNo(null);
              }, 100);
            }}
          >
            <FontAwesomeIcon icon={faAdd} size='sm' />
          </Button>

          {validationFlag && (
            <Button
              onClick={() => {
                const pmtArray = [...pmtNumberdata];
                pmtArray.shift();
              }}
              className='filters-header-odex-md btn btn-falcon-warning p-0 ml-1'
              variant='falcon-warning'
              style={{ width: '30px', height: '30px', marginLeft: '0.5rem' }}
              size='sm'
            >
              <FontAwesomeIcon icon={faTrash} size='sm' />
            </Button>
          )}
        </div>
      )}
      {invoiceDetails?.data !== undefined &&
        invoiceDetails?.data.length >= 0 &&
        pmtNumberdata && (
          /*  data?.length > 0 && */ <DataTable
            dataLoading={dataLoading}
            columns={columns}
            data={pmtNumberdata || []}
            gridName='PAYMENT_INVOICE'
            selection={editPayment && addButtonDisable}
            searchable={false}
            autoHeight={true}
            tableMinHeight={120}
            onCustomSelectChange={_onSelect}
            sortable
            pagination={false}
            perPage={100}
            showColumnFilter={false}
            showExport={false}
            localFilter={true}
            uniqueFilterKey='paymentinvoice'
            maxHeight='30vh'
          />
        )}
    </>
  );
};

export default PaymentInvoiceList;
