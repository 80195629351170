import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { BLDetailsList } from "../types";

import {
  // getBLListWithBlnumber,
  // getBlDashboardDefinition,
  getBlsList,
  // getBlsListFilter,
  getDashboardData,
  //todo bllist
  // getDashboardData,
  exportDashboardData,
  searchWithBlno,
  searchWithContainerno,
  searchWithInternalVoyageNo,
  searchWithInternalVoyageNoPaymentDashboard,
  searchWithShipmentNoBlDashboard,
  searchWithShipmentno,
  getInvoiceTableData,
  getVesselTableData,
} from "../actions/dashboard";
import { IDataTableColumn } from "../../../common/types/dataTable";
import { IConfigsDefinition } from "../../../utils/columnConfig";
import { PaymentBlDetails } from "../../payment/paymentSlice";
import { string } from "yup";

export interface PayeeOption {
  value: any;
  label: any;
}

interface IBlList {
  list: BLDetailsList[];
  next: string | null;
  previous: string | null;
}

interface IBlDependency {
  prevLocation: string;
  prevFilterState: string;
  prevDataCountry: string;
  prevPage: number;
  prevPageInstructionDashbaord: number;
  prevPageDoDashbaord: number;
  prevPageRegistrationDashbaord: number;
  prevPageUserDashbaord: number;
  prevselectedConfigId: string;
  prevPartnerType: string;
}

interface DashboardSlice {
  result: any[];
  data: IBlList;
  showPayment: any;
  showInstruction: any;
  showRelease: any;
  dataLoading: boolean;
  showColumnSettings: boolean;
  showBlExists: boolean;
  showAddAgent: boolean;
  showNonimationReq: boolean;
  enableDeliveryOrderEntry: boolean;
  enableAddAgentButton: boolean;
  blDashboardDefinition: any[];
  blDashboardDefinitionLoading: boolean;
  columns: IDataTableColumn[];
  configs: IConfigsDefinition;
  setDefaultViewBL: boolean;
  setDefaultViewInvoice: boolean;
  currentGridName: string;
  blDashboardList: {
    maxPage: number | null;
    hasNext: boolean;
    size: number | null;
    result: BLDetailsList[];
  };
  invoiceDashboardList: {
    [x: string]: any;
    maxPage: string | null;
    hasNext: boolean;
    size: string | null;
    result: PaymentBlDetails[];
  };
  instructionDashboardList: {
    maxPage: string | null;
    hasNext: boolean;
    size: string | null;
    result: PaymentBlDetails[];
  };
  permitDashboardList: {
    maxPage: string | null;
    hasNext: boolean;
    size: string | null;
    result: PaymentBlDetails[];
  };
  doDashboardList: {
    maxPage: string | null;
    hasNext: boolean;
    size: string | null;
    result: PaymentBlDetails[];
  };
  roeDashboardList: {
    maxPage: string | null;
    hasNext: boolean;
    size: string | null;
    result: PaymentBlDetails[];
  };
  registrationDashboardList: {
    maxPage: string | null;
    hasNext: boolean;
    size: string | null;
    result: any[];
  };

  bestPickDashboardList: {
    maxPage: string | null;
    hasNext: boolean;
    size: string | null;
    result: PaymentBlDetails[];
  };

  paymentFundList: {
    maxPage: string | null;
    hasNext: boolean;
    size: string | null;
    result: PaymentBlDetails[];
  };

  userDashboardList: {
    maxPage: string | null;
    hasNext: boolean;
    size: string | null;
    result: PaymentBlDetails[];
  };

  vesselDashboardList: {
    maxPage: string | null;
    hasNext: boolean;
    size: string | null;
    result: PaymentBlDetails[];
  };
  paymentDashboardList: {
    maxPage: string | null;
    hasNext: boolean;
    size: string | null;
    result: PaymentBlDetails[];
  };
  beneficiaryRptDashboardList: {
    maxPage: string | null;
    hasNext: boolean;
    size: string | null;
    result: PaymentBlDetails[];
  };
  reportDashboardList: {
    maxPage: string | null;
    hasNext: boolean;
    size: string | null;
    result: PaymentBlDetails[];
  };
  standingInstructionList: {
    maxPage: string | null;
    hasNext: boolean;
    size: string | null;
    result: PaymentBlDetails[];
  };

  currentPage: number | any;
  currentPageInvoice: number | any;
  currentPageVessel: number | any;
  currentPagePayment: number | any;
  currentPageReport: number | any;
  currentPageInstruction: number | any;
  currentPagePermit: number | any;
  currentPageDo: number | any;
  currentPageRegistration: number | any;
  currentPageUser: number | any;
  currentPageStandingInst: number | any;
  prevDependencies: IBlDependency;
  selectedData: any[];
  selectedBlDataRows: any;
  selectedInstDataRows: any;
  assignTo: any;
  selectedPayee: PayeeOption | null;
}

const initialState: DashboardSlice = {
  data: {
    next: null,
    previous: null,
    list: [],
  },
  dataLoading: true,
  showPayment: "",
  showInstruction: "",
  showRelease: "",
  showColumnSettings: false,
  showAddAgent: false,
  showBlExists: false,
  enableAddAgentButton: false,
  enableDeliveryOrderEntry: false,
  showNonimationReq: false,
  blDashboardDefinition: [],
  blDashboardDefinitionLoading: false,
  columns: [],
  configs: {
    columns: [],
    staticHiddenCols: [],
    defaultHiddenCols: [],
  },
  setDefaultViewBL: true,
  setDefaultViewInvoice: true,
  blDashboardList: {
    maxPage: null,
    hasNext: false,
    size: null,
    result: [],
  },

  invoiceDashboardList: {
    maxPage: null,
    hasNext: false,
    size: null,
    result: [],
  },

  bestPickDashboardList: {
    maxPage: null,
    hasNext: false,
    size: null,
    result: [],
  },

  permitDashboardList: {
    maxPage: null,
    hasNext: false,
    size: null,
    result: [],
  },
  instructionDashboardList: {
    maxPage: null,
    hasNext: false,
    size: null,
    result: [],
  },
  doDashboardList: {
    maxPage: null,
    hasNext: false,
    size: null,
    result: [],
  },
  roeDashboardList: {
    maxPage: null,
    hasNext: false,
    size: null,
    result: [],
  },
  registrationDashboardList: {
    maxPage: null,
    hasNext: false,
    size: null,
    result: [],
  },
  reportDashboardList: {
    maxPage: null,
    hasNext: false,
    size: null,
    result: [],
  },
  paymentFundList: {
    maxPage: null,
    hasNext: false,
    size: null,
    result: [],
  },

  userDashboardList: {
    maxPage: null,
    hasNext: false,
    size: null,
    result: [],
  },
  vesselDashboardList: {
    maxPage: null,
    hasNext: false,
    size: null,
    result: [],
  },
  paymentDashboardList: {
    maxPage: null,
    hasNext: false,
    size: null,
    result: [],
  },
  beneficiaryRptDashboardList: {
    maxPage: null,
    hasNext: false,
    size: null,
    result: [],
  },
  standingInstructionList: {
    maxPage: null,
    hasNext: false,
    size: null,
    result: [],
  },
  currentPage: 1,
  currentPagePayment: 1,
  currentPageInvoice: 1,
  currentPageInstruction: 1,
  currentPagePermit: 1,
  currentPageDo: 1,
  currentPageReport: 1,
  currentPageRegistration: 1,
  currentPageUser: 1,
  currentPageStandingInst: 1,
  currentPageVessel: 1,
  prevDependencies: {
    prevLocation: "",
    prevFilterState: "",
    prevDataCountry: "",
    prevPage: 0,
    prevselectedConfigId: "",
    prevPartnerType: "",
    prevPageInstructionDashbaord: 0,
    prevPageDoDashbaord: 0,
    prevPageRegistrationDashbaord: 0,
    prevPageUserDashbaord: 0,
  },
  result: [],
  selectedData: [],
  selectedBlDataRows: {},
  currentGridName: "",
  assignTo: "",
  selectedInstDataRows: {},
  selectedPayee: null,
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    updateCurrentPageBlDashboard: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    updateCurrentPagePayment: (state, action: PayloadAction<number>) => {
      state.currentPagePayment = action.payload;
    },
    updateCurrentPageInvoiceDashboard: (
      state,
      action: PayloadAction<number>
    ) => {
      state.currentPageInvoice = action.payload;
    },
    updateCurrentPageInstructionDashboard: (
      state,
      action: PayloadAction<number>
    ) => {
      state.currentPageInstruction = action.payload;
    },

    updateCurrentPageDoDashboard: (state, action: PayloadAction<number>) => {
      state.currentPageDo = action.payload;
    },
    updateCurrentPagePermitDashboard: (
      state,
      action: PayloadAction<number>
    ) => {
      state.currentPagePermit = action.payload;
    },
    updateCurrentPageUserDashboard: (state, action: PayloadAction<number>) => {
      state.currentPageUser = action.payload;
    },
    updateCurrentPageReportDashboard: (
      state,
      action: PayloadAction<number>
    ) => {
      state.currentPageReport = action.payload;
    },
    updateCurrentPageStandingInst: (state, action: PayloadAction<number>) => {
      state.currentPageStandingInst = action.payload;
    },
    updateCurrentPageVessel: (state, action: PayloadAction<number>) => {
      state.currentPageVessel = action.payload;
    },
    updateCurrentPageRegistrationDashboard: (
      state,
      action: PayloadAction<number>
    ) => {
      state.currentPageRegistration = action.payload;
    },
    updatePrveDependenciesBlDashboard: (
      state,
      action: PayloadAction<{
        key: keyof IBlDependency;
        value: string | number | any;
      }>
    ) => {
      if (action.payload.key === "prevLocation") {
        state.prevDependencies.prevLocation = action.payload.value;
      } else if (action.payload.key === "prevFilterState") {
        state.prevDependencies.prevFilterState = action.payload.value;
      } else if (action.payload.key === "prevDataCountry") {
        state.prevDependencies.prevDataCountry = action.payload.value;
      } else if (action.payload.key === "prevPage") {
        state.prevDependencies.prevPage = action.payload.value;
      } else if (action.payload.key === "prevselectedConfigId") {
        state.prevDependencies.prevselectedConfigId = action.payload.value;
      } else if (action.payload.key === "prevPartnerType") {
        state.prevDependencies.prevPartnerType = action.payload.value;
      } else if (action.payload.key === "prevPageInstructionDashbaord") {
        state.prevDependencies.prevPageInstructionDashbaord =
          action.payload.value;
      } else if (action.payload.key === "prevPageUserDashbaord") {
        state.prevDependencies.prevPageUserDashbaord = action.payload.value;
      } else if (action.payload.key === "prevPageDoDashbaord") {
        state.prevDependencies.prevPageDoDashbaord = action.payload.value;
      } else if (action.payload.key === "prevPageRegistrationDashbaord") {
        state.prevDependencies.prevPageRegistrationDashbaord =
          action.payload.value;
      }
    },
    updateSetDefaultViewBL: (state, action: PayloadAction<boolean>) => {
      state.setDefaultViewBL = action.payload;
    },
    updateSetDefaultViewInvoice: (state, action: PayloadAction<boolean>) => {
      state.setDefaultViewInvoice = action.payload;
    },
    updateDashboardBlList: (state, action: PayloadAction<IBlList>) => {
      state.data = action.payload;
    },
    updateColumnDefinitionConfigs: (
      state,
      action: PayloadAction<IConfigsDefinition>
    ) => {
      state.configs = action.payload;
    },
    updateColumnDefinition: (
      state,
      action: PayloadAction<IDataTableColumn[]>
    ) => {
      state.columns = action.payload;
    },
    getPaymentStatus: (state, action: PayloadAction<IBlList>) => {
      state.showPayment = action.payload;
    },
    getInstructionStatus: (state, action: PayloadAction<IBlList>) => {
      state.showInstruction = action.payload;
    },
    getReleaseStatus: (state, action: PayloadAction<IBlList>) => {
      state.showRelease = action.payload;
    },
    toggleColumnSettingsDashboard: (state, action: PayloadAction<boolean>) => {
      state.showColumnSettings = action.payload;
    },
    toggleAddAgent: (state, action: PayloadAction<boolean>) => {
      state.showAddAgent = action.payload;
    },
    toggleIsBlExists: (state, action: PayloadAction<boolean>) => {
      state.showBlExists = action.payload;
    },
    toggleAddAgentButton: (state, action: PayloadAction<boolean>) => {
      state.enableAddAgentButton = action.payload;
    },
    toggleDeliveryOrderEntry: (state, action: PayloadAction<boolean>) => {
      state.enableDeliveryOrderEntry = action.payload;
    },
    toggleNominationReq: (state, action: PayloadAction<boolean>) => {
      state.showNonimationReq = action.payload;
    },
    updateSelectedTableData: (state, action: PayloadAction<any[]>) => {
      state.selectedData = action.payload;
    },
    addSelectionToTableData: (state, action: PayloadAction<any[]>) => {
      if (state.selectedData.length >= 1) {
        state.selectedData = [...state.selectedData, ...action.payload];
      } else {
        state.selectedData = action.payload;
      }
    },
    removeSelectionfromTable: (state, action: PayloadAction<any[]>) => {
      state.selectedData = state.selectedData.filter(
        (B) => action.payload.findIndex((A) => A.blid === B.blid) == -1
      );
    },
    removeSelectionfromInstTable: (state, action: PayloadAction<any[]>) => {
      state.selectedData = state.selectedData.filter(
        (B) =>
          action.payload.findIndex((A) => A.bl_event_id === B.bl_event_id) == -1
      );
    },
    updateSelectedRow: (state, action: PayloadAction<any>) => {
      state.selectedBlDataRows = action.payload;
    },
    updateSelectedInstRow: (state, action: PayloadAction<any>) => {
      state.selectedInstDataRows = action.payload;
    },
    updateAssigneTo: (state, action: PayloadAction<any>) => {
      state.assignTo = state.assignTo + action.payload;
    },
    updateSelectedPayee: (state, action: PayloadAction<PayeeOption | null>) => {
      state.selectedPayee = action.payload;
    },
    clearBeneficiaryDashboardList:(state) => {
      state.beneficiaryRptDashboardList = initialState.beneficiaryRptDashboardList;
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(getBlDashboardDefinition.pending, (state, action) => {
    //   state.blDashboardDefinitionLoading = true;
    // });
    // builder.addCase(getBlDashboardDefinition.fulfilled, (state, action) => {
    //   state.blDashboardDefinitionLoading = false;
    //   state.blDashboardDefinition = action.payload;
    // });
    builder.addCase(getBlsList.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(getBlsList.fulfilled, (state: any, action) => {
      state.dataLoading = false;
      state.data = action.payload;
    });
    // builder.addCase(getBlsListFilter.pending, (state, action) => {
    //   state.dataLoading = true;
    // });
    // builder.addCase(getBlsListFilter.fulfilled, (state, action) => {
    //   state.dataLoading = false;

    //   state.data = action.payload;
    // });
    //todo bllist

    builder.addCase(getInvoiceTableData.pending, (state, action) => {
      // state.dataLoading = true;
    });
    builder.addCase(getInvoiceTableData.fulfilled, (state, action) => {
      state.currentGridName = "OPC_INV_DASHBOARD";
    });

    builder.addCase(getDashboardData.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(getDashboardData.fulfilled, (state, action) => {
      state.currentGridName = action?.payload?.gridName;
      if (
        action?.payload?.data?.asCsv &&
        action?.payload?.data?.asCsv === true
      ) {
      } else {
        switch (action?.payload?.gridName) {
          case "BL_DASHBOARD":
            state.blDashboardList = action.payload.data;
            break;
          case "INVOICE_DASHBOARD":
            state.invoiceDashboardList = action.payload.data;
            break;
          case "OPC_INSTRUCTION_DASHBOARD":
            state.instructionDashboardList = action.payload.data;
            break;
          case "OPC_DO_DASHBOARD":
            state.doDashboardList = action.payload.data;
            break;
          case "OPC_BESTPICK_DASHBOARD":
            state.bestPickDashboardList = action.payload.data;
            break;
          case "OPC_EXCHANGE_RATE":
            state.roeDashboardList = action.payload.data;
            break;
          case "OPC_PERMIT_DASHBOARD":
            state.permitDashboardList = action.payload.data;
            break;
          case "OPC_PAYMENT_FUNDING":
            state.paymentFundList = action.payload.data;
            break;
          case "OPC_USER_DASHBOARD":
            state.userDashboardList = action.payload.data;
            break;

          case "STANDING_INSTRUCTION":
            state.standingInstructionList = action.payload.data;
            break;
          case "OPC_REGISTRATION_DASHBOARD":
            state.registrationDashboardList = action.payload.data;
            break;

          case "OPC_PAYMENT_DASHBOARD":
            state.paymentDashboardList = action.payload.data;
            break;
          case "OPC_INVOICE_PMT_DASHBOARD":
            state.beneficiaryRptDashboardList = action.payload.data;
            break;
          case "INVOICE_PMT_DASHBOARD":
            state.paymentDashboardList = action.payload.data;
            break;
          case "OPC_VESSEL_MASTER":
            state.vesselDashboardList = action.payload.data;
            break;
          case "PICKUP_LOC_INSTRACT_REPORT":
            state.reportDashboardList = action.payload.data;
            break;
          default:
            break;
        }
      }

      state.dataLoading = false;
    });

    builder.addCase(searchWithBlno.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(searchWithBlno.fulfilled, (state, action) => {
      state.dataLoading = false;
      state.data = action.payload;
    });
    builder.addCase(
      searchWithShipmentNoBlDashboard.pending,
      (state, action) => {
        state.dataLoading = true;
      }
    );
    builder.addCase(
      searchWithShipmentNoBlDashboard.fulfilled,
      (state, action) => {
        state.dataLoading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(searchWithInternalVoyageNo.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(searchWithInternalVoyageNo.fulfilled, (state, action) => {
      state.dataLoading = false;
      state.data = action.payload;
    });

    builder.addCase(searchWithShipmentno.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(searchWithShipmentno.fulfilled, (state, action) => {
      state.dataLoading = false;
      state.data = action.payload;
    });
    builder.addCase(searchWithContainerno.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(searchWithContainerno.fulfilled, (state, action) => {
      state.dataLoading = false;
      state.data = action.payload;
    });
    builder.addCase(
      searchWithInternalVoyageNoPaymentDashboard.pending,
      (state, action) => {
        state.dataLoading = true;
      }
    );
    builder.addCase(
      searchWithInternalVoyageNoPaymentDashboard.fulfilled,
      (state, action) => {
        state.dataLoading = false;
        state.data = action.payload;
      }
    );
    // todo bllist
    // builder.addCase(getBLListWithBlnumber.pending, (state, action) => {
    //   state.dataLoading = true;
    // });
    // builder.addCase(getBLListWithBlnumber.fulfilled, (state, action) => {
    //   state.dataLoading = false;
    //   state.data = action.payload;
    // });
  },
});

export const {
  updateCurrentPageBlDashboard,
  updateCurrentPageInvoiceDashboard,
  updatePrveDependenciesBlDashboard,
  updateCurrentPageInstructionDashboard,
  updateCurrentPageDoDashboard,
  updateCurrentPageUserDashboard,
  updateCurrentPageReportDashboard,
  updateCurrentPageRegistrationDashboard,
  updateCurrentPageStandingInst,
  updateSetDefaultViewBL,
  updateDashboardBlList,
  updateColumnDefinitionConfigs,
  updateColumnDefinition,
  updateSetDefaultViewInvoice,
  toggleColumnSettingsDashboard,
  toggleAddAgent,
  toggleAddAgentButton,
  toggleNominationReq,
  toggleIsBlExists,
  getPaymentStatus,
  getInstructionStatus,
  toggleDeliveryOrderEntry,
  getReleaseStatus,
  updateSelectedTableData,
  updateSelectedRow,
  addSelectionToTableData,
  removeSelectionfromTable,
  updateCurrentPagePayment,
  updateSelectedInstRow,
  updateAssigneTo,
  removeSelectionfromInstTable,
  updateCurrentPageVessel,
  updateSelectedPayee,
  clearBeneficiaryDashboardList
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
