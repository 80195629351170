import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetQuickPayTable } from "../quickpay/quickPaySlice";
function App({payload, quickPay}) {
  
  const paypalRef = useRef();

  const navigate = useNavigate()
  const dispatch = useDispatch()
  useEffect(() => {
    let orderId = '';
    window.paypal.Buttons({
      createOrder: function(data, actions) {
        return axios.post('/api/payment-gateway/v1/gateway/paypal/order/initiation', payload, {
          headers: {
            'Accept': '*/*'
          },
        }).then(function(response) {
      //window.open(response?.data?.links[1]?.href, '_blank', 'height=500,width=600');
      orderId = response?.data?.id;
      return response?.data?.id
        }).catch(function(error) {
          console.error('Error creating order:', error);
          throw error; 
        });
      },
      
      onApprove: function (data, actions) {
        
              return axios.post(
                `/api/payment-gateway/v1/gateway/paypal/order/${orderId}/capture`,
                {
                  headers: {
                    'accept': '*/*',
                    'Content-Type': 'application/json'
                  },
                }
              ).then(function(response) {
                if(response?.data?.error){
                  alert(response.data.error)
                  throw new Error("Payment unsuccessful")
                }
                if(quickPay){
                  dispatch(resetQuickPayTable())
                  navigate('/quickpay')
                  toast.success(`Payment successful, details will be mailed to ${payload.email}`, {
                    theme: 'colored',
                    toastId:'paymentSuccessToastQuickPay'
                  });
                  return
                }
                toast.success('Payment successful', {
                  theme: 'colored',
                    toastId:'paymentSuccessToast'
                });

              }).catch(function(error) {
                toast.error('Payment unsuccessful',{toastId:'paymentUnsuccessful'});
                console.error('Error creating order:', error);
                throw error; 
              });
            }
    }).render(paypalRef.current);
  }, []);
  return (
      <div className="container">
        <div className="paypal" ref={paypalRef}></div>
      </div>
  );
}

export default App;