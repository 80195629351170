import i18n from "i18next";
import { initReactI18next } from "react-i18next";
export default i18n.use(initReactI18next).init({
  lng: window?.localStorage?.getItem?.("@odex-user-lng") || "TZ",
  debug: false,
  fallbackLng: "TZ",
  resources: {
    TZ: {
      resource_1: require("./resources/TZ/resource-1.json"),
      resource_2: require("./resources/TZ/resource-2.json"),
      resource_3: require("./resources/TZ/resource-3.json"),
      resource_4: require("./resources/TZ/resource-4.json"),
      resource_5: require("./resources/TZ/resource-5.json"),
      resource_7: require("./resources/TZ/resource-7.json"),
      resource_12: require("./resources/TZ/resource-12.json"),
      resource_message: require("./resources/TZ/resource-message.json"),
    },
    US: {
      resource_1: require("./resources/US/resource-1.json"),
      resource_2: require("./resources/US/resource-2.json"),
      resource_3: require("./resources/US/resource-3.json"),
      resource_4: require("./resources/US/resource-4.json"),
      resource_5: require("./resources/US/resource-5.json"),
      resource_7: require("./resources/US/resource-7.json"),
      resource_12: require("./resources/US/resource-12.json"),
      resource_message: require("./resources/US/resource-message.json"),
    },
    SG: {
      resource_1: require("./resources/SG/resource-1.json"),
      resource_2: require("./resources/SG/resource-2.json"),
      resource_3: require("./resources/SG/resource-3.json"),
      resource_4: require("./resources/SG/resource-4.json"),
      resource_5: require("./resources/SG/resource-5.json"),
      resource_7: require("./resources/SG/resource-7.json"),
      resource_12: require("./resources/SG/resource-12.json"),
      resource_message: require("./resources/SG/resource-message.json"),
    },
    ZA: {
      resource_1: require("./resources/ZA/resource-1.json"),
      resource_2: require("./resources/ZA/resource-2.json"),
      resource_3: require("./resources/ZA/resource-3.json"),
      resource_4: require("./resources/ZA/resource-4.json"),
      resource_5: require("./resources/ZA/resource-5.json"),
      resource_7: require("./resources/ZA/resource-7.json"),
      resource_12: require("./resources/ZA/resource-12.json"),
      resource_message: require("./resources/ZA/resource-message.json"),
    },
    MY: {
      resource_1: require("./resources/MY/resource-1.json"),
      resource_2: require("./resources/MY/resource-2.json"),
      resource_3: require("./resources/MY/resource-3.json"),
      resource_4: require("./resources/MY/resource-4.json"),
      resource_5: require("./resources/MY/resource-5.json"),
      resource_7: require("./resources/MY/resource-7.json"),
      resource_12: require("./resources/MY/resource-12.json"),
      resource_message: require("./resources/MY/resource-message.json"),
    },
    KE: {
      resource_1: require("./resources/KE/resource-1.json"),
      resource_2: require("./resources/KE/resource-2.json"),
      resource_3: require("./resources/KE/resource-3.json"),
      resource_4: require("./resources/KE/resource-4.json"),
      resource_5: require("./resources/KE/resource-5.json"),
      resource_7: require("./resources/KE/resource-7.json"),
      resource_12: require("./resources/KE/resource-12.json"),
      resource_message: require("./resources/KE/resource-message.json"),
    },
    JO: {
      resource_1: require("./resources/JO/resource-1.json"),
      resource_2: require("./resources/JO/resource-2.json"),
      resource_3: require("./resources/JO/resource-3.json"),
      resource_4: require("./resources/JO/resource-4.json"),
      resource_5: require("./resources/JO/resource-5.json"),
      resource_7: require("./resources/JO/resource-7.json"),
      resource_12: require("./resources/JO/resource-12.json"),
      resource_message: require("./resources/JO/resource-message.json"),
    },
    UG: {
      resource_1: require("./resources/UG/resource-1.json"),
      resource_2: require("./resources/UG/resource-2.json"),
      resource_3: require("./resources/UG/resource-3.json"),
      resource_4: require("./resources/UG/resource-4.json"),
      resource_5: require("./resources/UG/resource-5.json"),
      resource_7: require("./resources/UG/resource-7.json"),
      resource_12: require("./resources/UG/resource-12.json"),
      resource_message: require("./resources/UG/resource-message.json"),
    },
    RW: {
      resource_1: require("./resources/RW/resource-1.json"),
      resource_2: require("./resources/RW/resource-2.json"),
      resource_3: require("./resources/RW/resource-3.json"),
      resource_4: require("./resources/RW/resource-4.json"),
      resource_5: require("./resources/RW/resource-5.json"),
      resource_7: require("./resources/RW/resource-7.json"),
      resource_12: require("./resources/RW/resource-12.json"),
      resource_message: require("./resources/RW/resource-message.json"),
    },
    BI: {
      resource_1: require("./resources/BI/resource-1.json"),
      resource_2: require("./resources/BI/resource-2.json"),
      resource_3: require("./resources/BI/resource-3.json"),
      resource_4: require("./resources/BI/resource-4.json"),
      resource_5: require("./resources/BI/resource-5.json"),
      resource_7: require("./resources/BI/resource-7.json"),
      resource_12: require("./resources/BI/resource-12.json"),
      resource_message: require("./resources/BI/resource-message.json"),
    },
    CD: {
      resource_1: require("./resources/CD/resource-1.json"),
      resource_2: require("./resources/CD/resource-2.json"),
      resource_3: require("./resources/CD/resource-3.json"),
      resource_4: require("./resources/CD/resource-4.json"),
      resource_5: require("./resources/CD/resource-5.json"),
      resource_7: require("./resources/CD/resource-7.json"),
      resource_12: require("./resources/CD/resource-12.json"),
      resource_message: require("./resources/CD/resource-message.json"),
    },
    SS: {
      resource_1: require("./resources/SS/resource-1.json"),
      resource_2: require("./resources/SS/resource-2.json"),
      resource_3: require("./resources/SS/resource-3.json"),
      resource_4: require("./resources/SS/resource-4.json"),
      resource_5: require("./resources/SS/resource-5.json"),
      resource_7: require("./resources/SS/resource-7.json"),
      resource_12: require("./resources/SS/resource-12.json"),
      resource_message: require("./resources/SS/resource-message.json"),
    },
    PK: {
      resource_1: require("./resources/PK/resource-1.json"),
      resource_2: require("./resources/PK/resource-2.json"),
      resource_3: require("./resources/PK/resource-3.json"),
      resource_4: require("./resources/PK/resource-4.json"),
      resource_5: require("./resources/PK/resource-5.json"),
      resource_7: require("./resources/PK/resource-7.json"),
      resource_12: require("./resources/PK/resource-12.json"),
      resource_message: require("./resources/PK/resource-message.json"),
    },
    HK: {
      resource_1: require("./resources/HK/resource-1.json"),
      resource_2: require("./resources/HK/resource-2.json"),
      resource_3: require("./resources/HK/resource-3.json"),
      resource_4: require("./resources/HK/resource-4.json"),
      resource_5: require("./resources/HK/resource-5.json"),
      resource_7: require("./resources/HK/resource-7.json"),
      resource_12: require("./resources/HK/resource-12.json"),
      resource_message: require("./resources/HK/resource-message.json"),
    },
  },
});
