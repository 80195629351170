/* eslint-disable react-hooks/exhaustive-deps */
import DataTable from "../../../common/components/DataTable";
import { useDispatch } from "react-redux";

import {
  toggleAddAgent,
  toggleAddAgentButton,
  toggleColumnSettingsDashboard,
} from "../slices/dashboardSlice";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";

import {
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  IConfigsDefinition,
  generateColumnConfig,
} from "../../../utils/columnConfig";
import { useEffect, useRef, useState } from "react";
import {
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AESDecrypt } from "../../../../encrypt-util";
import { IDataTableColumn } from "../../../common/types/dataTable";
import DashboardRoot from "./DashboardRoot";
import {
  updateCurrentPageInstructionDashboard,
} from "../../dashboard/slices/dashboardSlice";
import { getOBLDetails } from "../../dashboard/actions/instruction";
import { updateInstructionID } from "../../blDetails/slices/documentHistorySlice";
import { Card } from "react-bootstrap";
import { updateSelectedInstRow } from "../../dashboard/slices/dashboardSlice";

const TableComponent = () => {
  const tableRef = useRef<any>(null);
  const { t } = useTranslation();
  const { encryptdoc, blNum, encrypt, doc_id } = useParams();
  const blDocId = encryptdoc;
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();

  const grids = useSelector((state: RootState) => state.dataTable.permitGrids);
  const permitDashboardData = useSelector(
    (state: RootState) => state.invoiceChange.permitDashboardData
  );

  const currentPage = useSelector(
    (state: RootState) => state.dashboard.currentPagePermit
  );

  const rbac = useSelector((state: RootState) => state.userProfile);
  const selectionInitialState = useSelector(
    (state: RootState) => state.dashboard.selectedInstDataRows
  );
  // const configs = useSelector(
  //   (state: RootState) => state.instructionDashboard.configs
  // );
  /*   const tableData = useSelector(
    (state: RootState) => state.invoiceChange.invoiceDataList
  ); */
  // const columns = useSelector((state: RootState) => state.dashboard.columns);
  const [columns, setColumns] = useState<IDataTableColumn[]>([]);

  const [modifiedData, setmodifiedData] = useState<any>({});
  const [tableData, setTableData] = useState<any>([]);
  const [configs, setConfigs] = useState<IConfigsDefinition>({
    columns: [],
    staticHiddenCols: [],
    defaultHiddenCols: [],
  });

  const [isUnderlined, setIsUnderlined] = useState(false);
  const data = useSelector(
    (state: RootState) => state.dashboard.permitDashboardList
  );
  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );

  const linerDataCountry = useSelector(
    (state: RootState) => state.userProfile.linerDataCountry
  );
  // const dataCountry = linerDataCountry || profileDetails.partnerCountry || '';
  const storedUserCountry = JSON.parse(
    window.localStorage.getItem("@user-selected-datacountry") || "{}"
  );
  const handleDownload = () => {
    const blob = new Blob([""], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "permit_request.pdf";

    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };
  const linkStyle = {
    cursor: "pointer",
    fontSize: "13px",
    fontStyle: "italic",
    textDecoration: isUnderlined ? "underline" : "none",
  };
  const uploadDoument = (rowData: any) => {
    const { docId, dndInstructionAttId, fileName } = rowData.row.original;
    const charLimit = (name: string, limit: number) =>
      name && name?.length >= limit ? name?.substring(0, limit) + "..." : name;

    return (
      <div className="d-flex justify-content-start align-items-center">
        <span
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
          className="fs-0  text-primary"
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {/* <label onClick={exportDataAsCSV} className='btn btn-sm btn-falcon-primary' style={{marginBottom:'0rem',margin:'0.5rem'}}>
                                  <FontAwesomeIcon
                                    icon={faDownload}
                                    className='me-2 '
                                    style={{
                                      fontSize: '10px !important',
                                      color: '#f5822a',
                                      marginLeft:'0.5rem',
                                      
                                    }}
                                    title='Download'
                                  />
                                  </label> */}
            <span
              onClick={() => handleDownload()}
              onMouseEnter={() => setIsUnderlined(true)}
              onMouseLeave={() => setIsUnderlined(false)}
              style={{ ...linkStyle, marginTop: "5px" }}
            >
              <FontAwesomeIcon icon={faDownload} />
              <span> Download </span>
            </span>
          </div>
        </span>
      </div>
    );
  };
  const mockData = [
    {
      permitNo: "31201",
      permitDate: "24-jun-30 0000",
      slotStartTime: "24-jun-30 0000",
      slotEndTime: "24-jun-30 2359",
      state: "Used",
      cwpReference: "HASU4291699",
    },
    {
      permitNo: "31202",
      permitDate: "24-jun-30 0000",
      slotStartTime: "24-jun-30 0000",
      slotEndTime: "24-jun-30 2359",
      state: "Used",
      cwpReference: "MRKU5356718",
    },
    {
      permitNo: "51188",
      permitDate: "24-jul-13 0000",
      slotStartTime: "24-jul-13 0000",
      slotEndTime: "24-jul-13 2359",
      state: "Used",
      cwpReference: "SUDU5846454",
    },
    {
      permitNo: "51189",
      permitDate: "24-jul-13 0000",
      slotStartTime: "24-jul-13 0000",
      slotEndTime: "24-jul-13 2359",
      state: "Used",
      cwpReference: "SUDU5848312",
    },
    {
      permitNo: "61091",
      permitDate: "24-jul-20 0000",
      slotStartTime: "24-jul-20 0000",
      slotEndTime: "24-jul-20 2359",
      state: "Used",
      cwpReference: "CAAU5203648",
    },
    {
      permitNo: "61092",
      permitDate: "24-jul-20 0000",
      slotStartTime: "24-jul-20 0000",
      slotEndTime: "24-jul-20 2359",
      state: "Used",
      cwpReference: "SUDU8931400",
    },
  ];
  const storedCountry =
    storedUserCountry?.[0]?.loginId === profileDetails.email
      ? storedUserCountry?.[0]?.country
      : null;
  const dataCountry: any = storedCountry
    ? storedCountry
    : linerDataCountry
      ? linerDataCountry
      : profileDetails.partnerCountry || null;
  const dataLoading = useSelector(
    (state: RootState) => state.dashboard.dataLoading
  );
  const selectedLinerId = useSelector(
    (state: RootState) => state.userProfile.selectedLinerId
  );

  const columnsCustom: any[] = [
    {
      accessor: "permitNo",
      Header: t("resource_2:permitNumber"),
      minWidth: 190,
      width: 150,
      maxWidth: 250,
      columnFilterType: "search",
    },
    {
      accessor: "permitDate",
      Header: t("resource_2:permitDate"),
      minWidth: 90,
      width: 150,
      maxWidth: 250,
      columnFilterType: "date-range",
    },
    {
      accessor: "slotStartTime",
      Header: t("resource_3:slotStartTime"),
      minWidth: 90,
      width: 150,
      maxWidth: 250,
      columnFilterType: "date-range",
    },
    {
      accessor: "slotEndTime",
      Header: t("resource_3:slotEndTime"),
      minWidth: 90,
      width: 150,
      maxWidth: 250,
      columnFilterType: "date-range",
    },
    {
      accessor: "state",
      Header: "State",
      minWidth: 90,
      width: 150,
      maxWidth: 250,
      columnFilterType: "search",
    },
    {
      accessor: "cwpReference",
      Header: "CWP Reference",
      minWidth: 190,
      width: 150,
      maxWidth: 250,
      columnFilterType: "search",
    },
    {
      accessor: "byteArray",
      Header: "",

      disableSort: true,
      Cell: uploadDoument,
      width: 450,
      maxWidth: 450,
    },
  ];

  /*   useEffect(() => {
    if (tableData.length > 0) {
      setmodifiedData({
        hasNext: false,
        result: tableData,
        size: 20,
      });
    }
  }, [tableData]); */
  const permitTrackingMode = useSelector((state: RootState) => state.dataTable.permitTrackingState);

  useEffect(() => {
    if (permitDashboardData && permitTrackingMode != "") {
      setTableData(permitDashboardData);
    } else {
      setTableData([]);
    }
  }, [permitDashboardData, permitTrackingMode]);

  useEffect(() => {
    if (blNum) {
      dispatch(
        getOBLDetails({
          blNumber: AESDecrypt(blNum) || null,
          selectedLinerId:
            profileDetails.partnerType === "liner"
              ? profileDetails?.partnerId
              : selectedLinerId,
        })
      );
    }
  }, [blNum]);
  const instructionIdurl = useSelector(
    (state: RootState) => state.documentHistory.instructionIdurl
  );

  const getParams = () => {
    dispatch(
      updateInstructionID(AESDecrypt(location?.pathname?.split?.("/")?.[3]))
    );
    return {
      bl_id: blNum ? AESDecrypt(blNum) : "",
      doc_id: encryptdoc ? AESDecrypt(encryptdoc) : "",
      // inst_id: parseInt(location?.pathname?.split?.('/')?.[3]) || '',
      inst_id: encrypt ? AESDecrypt(encrypt) : "",
    };
  };

  /*  const userGridDefinitions = useSelector(
    (state: RootState) => state.userProfile.userGridDefinitions
  ); */
  const userGridDefinitions = useSelector(
    (state: RootState) => state.dataTable.grids
  );

  const showColumnSettings = useSelector(
    (state: RootState) => state.instructionDashboard.showColumnSettings
  );

  const showAddAgent = useSelector(
    (state: RootState) => state.instructionDashboard.showAddAgent
  );

  const showNominaationReq = useSelector(
    (state: RootState) => state.instructionDashboard.showNonimationReq
  );

  const rowColumns = useSelector(
    (state: RootState) => state.instructionTable.rowColumns
  );

  const onHideColumnSettings = () => {
    dispatch(toggleColumnSettingsDashboard(false));
  };
  const loadingGridConfigs = useSelector(
    (state: RootState) => state.dataTable.loadingGridConfigs
  );

  const accessorEtd = () => (rowData: any) => {
    const { etd } = rowData.row.original;

    try {
      const value = etd
        .split("-")
        .map((v: string, index: number) => (index === 0 ? v.slice(2, 4) : v))
        .join("-");

      return (
        <div className="text-truncate" data-toggle="tooltip" title={value}>
          {value}
        </div>
      );
    } catch (e) {
      return (
        <div className="text-truncate" data-toggle="tooltip" title={etd}>
          {etd}
        </div>
      );
    }
  };

  const accessorEta = () => (rowData: any) => {
    const { eta } = rowData.row.original;

    try {
      const value = eta
        .split("-")
        .map((v: string, index: number) => (index === 0 ? v.slice(2, 4) : v))
        .join("-");

      return (
        <div className="text-truncate" data-toggle="tooltip" title={value}>
          {value}
        </div>
      );
    } catch (e) {
      return (
        <div className="text-truncate" data-toggle="tooltip" title={eta}>
          {eta}
        </div>
      );
    }
  };
  const selectedData = useSelector(
    (state: RootState) => state.dashboard.selectedData
  );
  useEffect(() => {
    let selections: any = {};
    data.result?.forEach((d: any, i: any) => {
      if (
        selectedData?.map((v: any) => v?.bl_event_id).includes(d?.bl_event_id)
      )
        selections[i] = true;
    });

    dispatch(updateSelectedInstRow(selections));
  }, [selectedData, data]);

  // useEffect(()=>{
  //   dispatch(updateSelectedTableData([]));
  // },[dataCountry])

  // const columns = columnConfig(AESDecrypt(blNum) || null);

  // useEffect(() => {
  //   const blDashboardDefinition = grids?.[0]?.columnConfigGrid || '';

  //   if (!blDashboardDefinition) return;

  //   const configs = generateColumnConfig(JSON.parse(blDashboardDefinition));

  //   const columns = configs?.columns?.map((c) =>
  //     c.accessor === 'eta'
  //       ? { ...c, Cell: accessorEta() }
  //       : c.accessor === 'etd'
  //       ? { ...c, Cell: accessorEtd() }
  //       : c
  //   );

  //   dispatch(updateColumnDefinitionConfigs(configs));
  //   dispatch(updateColumnDefinition(columns));
  // }, [grids, location]);

  useEffect(() => {
    const blDashboardDefinition = grids?.[0]?.columnConfigGrid || "";

    if (!blDashboardDefinition) return;

    try {
      const configs = generateColumnConfig(
        JSON.parse(JSON.parse(blDashboardDefinition)),
        getParams,
        navigate,
        selectedData,
        dispatch
      );

      const columns = configs?.columns?.map((c) => c);
      setConfigs(configs);
      setColumns(configs?.columns);
    } catch (e) {
      console.error("Column Error: ", e);
    }
  }, [grids, location, selectedData]);
  const setCurrentPage = (page: number) => {
    dispatch(updateCurrentPageInstructionDashboard(page));
  };

  useEffect(() => {
    const sliderOpened = showAddAgent || showNominaationReq;

    if (sliderOpened && tableRef.current) {
      const tableElement = tableRef.current;
      const rowIndexToShow = 0;

      const rows = tableElement.getElementsByTagName("tr");
      const rowToScroll = rows[rowIndexToShow];

      if (rowToScroll) {
        const tableBounds = tableElement.getBoundingClientRect();
        const rowBounds = rowToScroll.getBoundingClientRect();

        if (
          rowBounds.bottom > tableBounds.bottom ||
          rowBounds.top < tableBounds.top
        ) {
          // Scroll the row into view smoothly
          rowToScroll.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }
    }
  }, [showAddAgent, showNominaationReq]);

  return (
    <div
      className={`row g-0 col-transition-animation h-100 w-100 ${rowColumns.mainTableClass === 'col-lg-12'
        ? 'w-100 '
        : 'odex-width-14rem '
        }`}
    >
      <div
        className={`d-flex flex-column ${showAddAgent
          ? 'col-sm-0 col-md-7 col-lg-7'
          : showNominaationReq
            ? 'col-sm-0 col-md-6 col-lg-6'
            : 'col-md-12 col-lg-12'
          }`}
      >
        {columns?.length > 0 ? (
          <>
            {location.pathname !== "/opc/permit-tracking" /*  &&
            location.pathname !== '/opc/home' */ ? (
                <Card.Header className="p-0 ps-2 pe-2 d-flex justify-content-start align-items-center odex-mh-60px" />
              ) : (
                <DashboardRoot />
              )}

            <DataTable
              data={tableData || []}
              columns={columns}
              sortable={true}
              searchable={false}
              showColumnFilter={false}
              // selection={rbac.rbacDetails.allowInstructionAssignment == 'Y'}
              selectionInitialState={selectionInitialState}
              // perPage={20}
              configGroup={
                //  grids?.find?.((g) => g?.gridId === 2)?.configGroup || []
                grids?.find?.(
                  (g: any) => g?.gridName === "OPC_PERMIT_DASHBOARD"
                )?.configGroup || []
              }
              perPage={20}
              pagination={true}
              showExport={false}
              localFilter={true}
              showColumnSettings={showColumnSettings}
              gridName="OPC_PERMIT_DASHBOARD"
              onHide={onHideColumnSettings}
              columnSettingsPersistKey="@odex/maintable/v6"
              onCustomSelectChange={(d) => {
                if (d?.list?.length > 0) {
                  dispatch(toggleAddAgentButton(true));
                } else {
                  dispatch(toggleAddAgentButton(false));
                  dispatch(toggleAddAgent(false));
                }
              }}
              customSort={false}
              selection={false}
              shortPrepend="bl_"
              uniqueFilterKey="permitDashboard"
              staticHiddenCols={configs?.staticHiddenCols || []}
              defaultHiddenCols={configs?.defaultHiddenCols || []}
              autoHeight={false}
              tableMinHeight={400}
              dataCountry={dataCountry}
              gridId={grids?.[0]?.gridId || 0}
              dataLoading={false}
              manualFilter={true}
            />

            {/* <DataTable
              data={modifiedData?.result || []}
              columns={columnsCustom}
              sortable={true}
              // customSort={true}
              searchable={false}
              showColumnFilter={false}
              selection={rbac.rbacDetails.allowInstructionAssignment == 'Y'}
              selectionInitialState={selectionInitialState}
              perPage={20}
              // localFilter={true}
              pagination={false}

              configGroup={
                //  grids?.find?.((g) => g?.gridId === 2)?.configGroup || []
                grids?.find?.((g:any) => g?.gridName === 'OPC_INV_DASHBOARD')
                  ?.configGroup || []
              }
              onChangePageNumber={(pageNumber) => setCurrentPage(pageNumber)}
              numberPagination={true}
              seekPagination={false}
              maxPage={parseInt(modifiedData?.maxPage || '100')}
              hasNext={modifiedData?.hasNext}
              showExport={false}
              currentPage={currentPage}
              localFilter={true}
              showColumnSettings={showColumnSettings}
              gridName='OPC_INV_DASHBOARD'
              onHide={onHideColumnSettings}
              columnSettingsPersistKey='@odex/maintable/v6'
              onCustomSelectChange={(d) => {
                if (d?.list?.length > 0) {
                  dispatch(toggleAddAgentButton(true));
                } else {
                  dispatch(toggleAddAgentButton(false));
                  dispatch(toggleAddAgent(false));
                }
              }}
              customSort={true}
              shortPrepend='bl_'
              uniqueFilterKey='invDashboard'
              staticHiddenCols={configs?.staticHiddenCols || []}
              defaultHiddenCols={configs?.defaultHiddenCols || []}
              autoHeight={false}
              tableMinHeight={400}
              dataCountry={dataCountry}
              gridId={grids?.[0]?.gridId || 0}
              dataLoading={false}
            /> */}
          </>
        ) : (
          ""
        )}
      </div>
      <div
        className={`${showAddAgent
          ? "col-sm-12 col-md-5 col-lg-5 odex-overlap-card h-100"
          : showNominaationReq
            ? "col-sm-12 col-md-6 col-lg-6 odex-overlap-card h-100"
            : "d-none"
          }`}
      ></div>
    </div>
  );
};

export default TableComponent;
