import { useEffect, useMemo, useState } from "react"

import * as Yup from 'yup';
import moment from "moment";
import Select from "react-select";
import { toast } from "react-toastify";
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import DatePicker from "react-datepicker";
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Controller, useForm } from "react-hook-form"
import { Button, ButtonGroup, Card, Form, Modal } from "react-bootstrap"

import { yupResolver } from "@hookform/resolvers/yup"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faRedo, faTimes } from "@fortawesome/free-solid-svg-icons"
import { faCancel, faFolder, faSave } from "@fortawesome/free-solid-svg-icons"
import { faArrowAltCircleLeft, faEdit } from "@fortawesome/free-regular-svg-icons"

import { clearDocumentList } from "./portIdSlice"
import { AESDecrypt } from "../../../encrypt-util"
import { AppDispatch, RootState } from "../../store"
import useDebounce from "../../../hooks/useDebounce"
import PortIdDocumentTable from "./PortIdDocumentTable"
import useDataCountry from "../../../hooks/useDataCountry"
import { getUserTin } from "../../common/actions/userDetails"
import { customDropdownStyles } from "../../common/customStyles"
import { useBackNavigation } from "../../../hooks/BackNavigation"
import { updateDashbaordRefresh } from "../../common/components/DataTable/slices/DataTable"
import { fetchUserSuggestions, generatePortIDReferenceNo } from "../blRequestInstruction/components/actions/Instruction"
import { cancelPortIdRequest, getDurationOptions, getNationalIdOptions, getPortIDRequest, getPortIdTypeOptions, getStatusOptions, PortIdApproveReject, saveOrDraftPortId, validateUserEmail } from "./portIdActions"
import { c } from "msw/lib/glossary-de6278a9";

const PortIDRequest = ({ isNew = true }) => {
    const [backendValues, setBackendValues] = useState<any>({});
    const [isInvalidEmail, setIsInvalidEmail] = useState<boolean>(false);
    const [cancel, setCancel] = useState(false)
    const [hideEditCancelBtn, setHideEditCancelBtn] = useState(false);
    const [isDisable, setIsDisable] = useState(false);
    const [maxDateValidTo, setMaxDateValidTo] = useState<any>();
    const [userList, setUserList] = useState<any>();
    const [userEmailTxt, setUserEmailTxt] = useState("");
    const [status, setStatus] = useState<any>();
    const [selfBtnDisabled, setSelfBtnDisabled] = useState(false);
    const [mailId, setMailId] = useState('');
    const [selectedUserEmail, setSelectedUserEmail] = useState<any>({
        value: '',
        label: ''
    });


    const profileDetails = useSelector((state: RootState) => state.userProfile.profile);
    const rbac = useSelector((state: RootState) => state.userProfile);
    const UserData: any = useSelector((state: RootState) => state.portId.userSuggestions);
    const tpoId = useSelector((state: RootState) => state.agentDelegation.tpoId)
    const portOptions = useSelector((state: RootState) => state.navbarTop.portOptions)
    const documentData = useSelector((state: RootState) => state.portId.documentData)
    const companyTin = useSelector((state: RootState) => state.userProfile.customerTin)
    const nationalIdOptions = useSelector((state: RootState) => state.portId.nationalIdOptions)
    const durationOptions = useSelector((state: RootState) => state.portId.durationOptions)
    const portIdOptions = useSelector((state: RootState) => state.portId.portIdTypeOptions)
    const statusOptions = useSelector((state: RootState) => state.mps.statusOptions);

    const dispatch = useDispatch<AppDispatch>()
    const { t } = useTranslation();
    const onBack = useBackNavigation()
    const { invoice_request_id } = useParams();
    const dataCountry = useDataCountry()

    const isLoading = useMemo(() => { return documentData?.some(({ loading }: { loading: number }) => loading !== 100 && loading !== -1 && loading !== 0) }, [documentData])
    const optionsPayload = useMemo(() => ({ tpoId, dataCountry }), [tpoId, dataCountry]);
    const portId = useMemo(() => {
        const dec = AESDecrypt(invoice_request_id);
        return dec && dec != '' ? dec : null;
    }, [invoice_request_id]);

    interface formState {
        For: number,
        validFrom: string | Date,
        validTo: any,
        ref_no: string,
        remarks: string,
        approvalRemarks: string | undefined,
        terminalOperator: string,
        companyTin: string,
        companyName: string,
        userEmail: string,
        firstName: string,
        middleName: string,
        lastName: string,
        phoneNo: string,
        requestDuration: string,
        nationalIdType: string,
        nationalIdNumber: number,
        emergencyContactNumber: string,
        navisDraftId: string,
        portIdReqId: number
    }

    const validationSchema = Yup.object().shape({
        For: Yup.string().required('For is required'),
        validFrom: Yup.string().typeError('Valid from date is required').required('Valid from date is required'),
        validTo: Yup.string().typeError('Valid to date is required').required('Valid to date is required'),
        ref_no: Yup.string().required('Reference number is required'),
        terminalOperator: Yup.string().required('Terminal operator is required'),
        companyTin: Yup.string().required('Company tin is required'),
        companyName: Yup.string().required('Company name is required'),
        firstName: Yup.string().required('First name is required'),
        middleName: Yup.string().required('Middle name is required'),
        lastName: Yup.string().required('Last name is required'),
        phoneNo: Yup.string().required('Phone number is required'),
        requestDuration: Yup.string().required('Request duration is required'),
        nationalIdType: Yup.string().required('National ID type is required'),
        nationalIdNumber: Yup.string().required('National ID number is required'),
        userEmail: Yup.string().required('User email is required'),
    });

    const {
        handleSubmit,
        setValue,
        clearErrors,
        control,
        register,
        watch,
        setError,
        trigger,
        formState: { errors },
    } = useForm<formState>({
        resolver: yupResolver(validationSchema),
        mode: 'onChange',
    });

    const resetForm = () => {
        const fields =
        {
            validFrom: new Date(),
            validTo: '',
            remarks: '',
            firstName: '',
            middleName: '',
            lastName: '',
            phoneNo: '',
            requestDuration: '',
            nationalIdType: '',
            nationalIdNumber: '',
            emergencyContactNumber: '',
            For: 1,
            userEmail: profileDetails.email ?? ''
        }
        Object.entries(fields).forEach(([key, val]: any) => {
            setValue(key, val)
            clearErrors(key);
        })
        setSelectedUserEmail({
            value: '',
            label: ''
        })
        dispatch(clearDocumentList())
    }

    const onChangeNumericValues = (field: any, value: string, limit: number) => {
        if (!limit || (limit && value?.length <= limit)) {
            setValue(field, value.replace(/[^0-9]/g, ''))
        }
        if (value) {
            clearErrors(field);
        }
        else {
            trigger(field)
        }
    }

    const getMaxDateForValidTo = (setValidToDate = false, validFromDate?: any) => {
        const DURATION_MAPPINGS: Record<number, { unit: 'days' | 'years', value: number }> = {
            1: { unit: 'days', value: 7 },
            2: { unit: 'days', value: 31 },
            3: { unit: 'days', value: 92 },
            4: { unit: 'years', value: 1 }
        };

        const requestDuration = watch('requestDuration');
        const validFrom = validFromDate || watch('validFrom');

        if (!requestDuration || !validFrom) return;

        const duration = DURATION_MAPPINGS[parseInt(requestDuration, 10)];
        if (!duration) return;

        const parsedValidFrom = moment(validFrom);
        //set max date according to duration
        const maxDate = parsedValidFrom.clone().add(duration.value, duration.unit).toDate();

        //check if the validfrom date is endof the year
        const endOfYear = moment(validFrom).endOf('year').toDate();
        const isValidFromEndOfYear = moment(validFrom).isSame(endOfYear, 'day');

        // function to set valid to date
        const handleSetValidTo = (date: Date, isYearEnd: boolean = false) => {
            if (setValidToDate) {
                setValue('validTo', date);
                clearErrors('validTo');
            }
            if (isYearEnd) {
                setMaxDateValidTo(date);
            }
        };


        //check if year is select then setting valid to date and max date as end of year
        if (parseInt(requestDuration, 10) === 4) {
            const yearEndDate = moment(validFrom).endOf('year').toDate();
            handleSetValidTo(yearEndDate, true);
            return;
        }

        //condifiton for other duration
        if (setValidToDate || (!isValidFromEndOfYear && isNew)) {
            //check if the max date is after end of the year then setting thg valid to as end of the year date
            const shouldSetDecember31st = moment(maxDate).isSameOrAfter(endOfYear);
            //checking if valid from date is after valid to date
            if (moment(validFrom).isAfter(watch('validTo'))) {
                setValue('validTo', null);
            }
            //setting valid to date according to condition
            if (shouldSetDecember31st) {
                handleSetValidTo(endOfYear);
            } else {
                setValue('validTo', null)
            }
        }
        setMaxDateValidTo(maxDate);
    };


    const customFilterOption = (option: any, inputValue: any) => {
        return option.label.toLowerCase().includes(inputValue.toLowerCase());
    };

    const onSubmit = handleSubmit(async (payload) => {
        try {
            if (documentData.some(doc => !doc.fileName)) {
                toast.error('Upload all required documents', { toastId: '2Gsd213' });
                return;
            }
            if (isInvalidEmail) {
                toast.error('Port ID card request is already submitted in the system for the selected user, Kindly Review/Edit the same to avoid multiple requests', {
                    toastId: 'sdjgsd223'
                })
                return;
            }
            if (new Date(watch('validFrom')).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) {
                toast.error("Valid From date must be greater than today's date.", { toastId: "j1hg5dsad" })
                return;
            }
            if (new Date(watch('validTo')).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) {
                toast.error("Valid To date must be greater than today's date.", { toastId: 'nhj2fd15s0g' })
                return;
            }
            if (moment(watch('validFrom')).isAfter(moment(watch('validTo')))) {
                toast.error('Valid From date must be less than Valid To date.', { toastId: '02ovt' })
                return;
            }
            if (status === 314) {
                await dispatch(saveOrDraftPortId({
                    ...payload,
                    isDraft: false,
                    tpoId,
                    requestId: portId,
                })).unwrap();
            }
            else {
                if (new Date().setHours(0, 0, 0, 0) <= new Date(watch('validFrom')).setHours(0, 0, 0, 0)) {
                    await dispatch(saveOrDraftPortId({
                        ...payload,
                        isDraft: false,
                        tpoId,
                        requestId: portId,
                    }));
                }
                else {
                    toast.error('Cannot select past dates while submission', { toastId: "cD9kY" });
                    return;
                }
            }
            onBack();
            requestAnimationFrame(() => {
                dispatch(updateDashbaordRefresh(true));
            });
        } catch (error) {
            toast.error("Failed to submit form. Please try again.", { toastId: 'wBY4G' });
        }
    }, (errors) => {
        console.error("Validation errors:", errors);
    });

    const validateUserEmailId = (email?: string, type?: string) => {
        setIsInvalidEmail(false);

        // Return early if no email is provided
        if (!email) return;

        // If email is the same as the current one, set value and return early
        if (email === mailId) {
            setValue('userEmail', email);
            if (email !== profileDetails?.email) {
                setSelectedUserEmail({ value: email, label: email });
            }
            return;
        }

        // Dispatch email validation action
        dispatch(validateUserEmail(email))
            .unwrap()
            .then(response => {
                const { responseMsg } = response || {};
                const isAdminAccess = rbac.rbacDetails.adminAccessPortId === 'Y';

                if (responseMsg === "new request") {
                    setIsInvalidEmail(false);
                    setValue('userEmail', email);
                    return;
                }

                setIsInvalidEmail(true);

                // Handle specific conditions for error response
                const shouldShowError = !isNew || type !== 'self' || !isAdminAccess;
                if (shouldShowError) {
                    toast.error(responseMsg, { toastId: 'JmH32Tg' });
                }

                // Reset email value for invalid responses
                setValue('userEmail', isAdminAccess ? "" : email);

                // Adjust UI if 'For' value is 1
                if (watch('For') === 1) {
                    setSelfBtnDisabled(true);
                    if (isAdminAccess) {
                        setValue('For', 2);
                    }
                } else {
                    setSelectedUserEmail({ value: '', label: '' });
                }
            })
            .catch(() => {
                setValue('userEmail', "");
            });
    };

    const renderButtons = () => {
        return portIdOptions?.length > 0 && portIdOptions?.map(({ text, value }: any) => {
            if (text === 'Team' && rbac.rbacDetails.adminAccessPortId !== 'Y' && !isDisable) {
                return null;
            }

            return (
                <Button
                    key={value}
                    className={`${watch('For') === value
                        ? isDisable
                            ? 'bg-secondary'
                            : 'bg-primary'
                        : isDisable
                            ? 'bg-white text-secondary'
                            : 'bg-white text-primary'
                        }`}
                    onClick={() => {
                        if (!isDisable) {
                            setValue('For', value)
                            clearErrors('userEmail');
                            if (value == 1) {
                                validateUserEmailId(profileDetails.email ?? '', 'self')
                            }
                            else {
                                setValue('userEmail', '')
                            }
                        }
                    }}
                    disabled={isDisable || selfBtnDisabled}
                >
                    {text}
                </Button>
            );
        });
    };

    const saveDraft = async () => {
        if (!watch('userEmail')) {
            setError("userEmail", {
                type: "manual",
                message: "User email is required",
            });
            return
        }
        if (isInvalidEmail) {
            toast.error('Port ID card request is already submitted in the system for the selected user, Kindly Review/Edit the same to avoid multiple requests', {
                toastId: 'sdjgsd223'
            })
            return;
        }
        const payload = { ...watch(), isDraft: true, tpoId, requestId: portId };
        await dispatch(saveOrDraftPortId(payload)).unwrap();
        onBack();
        dispatch(updateDashbaordRefresh(true));
    }

    const approveRejectValidation = (status: any) => {
        let isError = false;
        if (
            status == 304 &&
            (!watch("approvalRemarks") || watch("approvalRemarks") == "")
        ) {
            isError = true;
            setError("approvalRemarks", {
                message: "Remarks are required for rejection",
            });
        }
        return isError;
    };

    const onApproveReject = ({ status }: any) => {
        if (approveRejectValidation(status)) return;
        dispatch(
            PortIdApproveReject({
                ...backendValues,
                tpoId,
                requestId: portId ?? null,
                ptoRemarks: watch("approvalRemarks"),
                status,
                validTo: watch('validTo'),
                validFrom: watch('validFrom')
            })
        );
        onBack();
        setTimeout(() => {
            dispatch(updateDashbaordRefresh(true));
        }, 1000);
    };

    const handleGetPortIdRequest = () => {
        dispatch(getPortIDRequest({ tpoId, id: portId, dataCountry, opcPartnerType: profileDetails.opcPartnerType })).unwrap()
            .then((data: any) => {
                ["ref_no",
                    "companyTin",
                    "companyName",
                    "firstName",
                    "middleName",
                    "lastName",
                    "nationalIdNumber",
                    "remarks",
                    "validFrom",
                    "validTo",
                    "terminalOperator",
                    "userEmail",
                    "phoneNo",
                    "requestDuration",
                    "nationalIdType",
                    "emergencyContactNumber",
                    "tpoId",
                    "portIdReqId",
                    "approvalRemarks",
                    "navisDraftId"
                ].forEach((s: any) => {
                    setValue(s, data?.[s] ?? '')
                });
                setMailId(data?.userEmail);
                const isAdminAccessRestricted = rbac.rbacDetails.adminAccessPortId !== 'Y';
                let forValue;
                if (isAdminAccessRestricted && profileDetails.email === data.userEmail) {
                    forValue = 1;
                }
                else {
                    forValue = data?.For;
                }
                setValue('For', forValue);
                if (forValue === 1) {
                    if (isAdminAccessRestricted && profileDetails.email != data.userEmail) {
                        setHideEditCancelBtn(true);
                    }
                    else {
                        setHideEditCancelBtn(false);
                    }
                }
                else {
                    if (isAdminAccessRestricted) {
                        setHideEditCancelBtn(true);
                    }
                    else {
                        setHideEditCancelBtn(false);
                    }
                    setSelectedUserEmail({ value: data.userEmail, label: data.userEmail });
                }
                getMaxDateForValidTo();
                setBackendValues({
                    requestedOn: data?.requestedOn,
                    // nextLevel: data?.nextLevel,
                    // level: data?.level,
                    requestedPartnerType: data?.requestedPartnerType,
                });
                setValue('userEmail', data.userEmail ?? '')
                setStatus(data?.status);
            })
    }

    const handleGetUserList = () => {
        const formattedOptions = UserData.map(({ login_id, usr_nm }: { login_id: string, usr_nm: string }) => ({
            value: `${login_id}`,
            label: `${usr_nm} - ${login_id}`,
            visitKey: login_id,
        }));
        setUserList(formattedOptions);
    }

    const handleGetDropdownOptions = () => {
        const actions = [
            getNationalIdOptions(optionsPayload),
            getDurationOptions(optionsPayload),
            getPortIdTypeOptions(optionsPayload)
        ];

        if (!isNew) {
            actions.push(getStatusOptions({
                tpoId: optionsPayload.tpoId,
                country: optionsPayload.dataCountry
            }));
        }

        Promise.all(actions.map(action => dispatch(action)))
            .catch(error => toast.error('Error fetching options:', { toastId: '9jprQD' }));
    }

    useEffect(() => {
        if (profileDetails.partnerId) {
            dispatch(
                fetchUserSuggestions({
                    partnerId: profileDetails.partnerId,
                    selfUserEmail: profileDetails.email
                })
            )
        }
    }, [profileDetails.partnerId]);

    useEffect(() => {
        if (!isNew && tpoId && portId && portOptions?.length > 0 && durationOptions?.length > 0 && nationalIdOptions?.length > 0 && dataCountry && profileDetails?.opcPartnerType) {
            handleGetPortIdRequest()
        }
    }, [isNew, tpoId, portId, portOptions, durationOptions, nationalIdOptions])

    useEffect(() => {
        if (UserData) {
            if (Array.isArray(UserData)) {
                handleGetUserList();
            }
        }
    }, [UserData, userEmailTxt]);

    useEffect(() => {
        if (invoice_request_id) setIsDisable(true)
    }, [invoice_request_id])

    useEffect(() => {
        if (isNew) {
            setValue('validFrom', new Date());
            clearErrors();
            setValue('For', 1)
            dispatch(getUserTin())
        }
    }, [isNew]);

    useEffect(() => {
        if (dataCountry && isNew)
            dispatch(generatePortIDReferenceNo(dataCountry)).unwrap().then((data) => {
                setValue('ref_no', data)
            })
    }, [dataCountry, isNew]);

    useEffect(() => {
        if (!optionsPayload.dataCountry || !optionsPayload.tpoId) return;
        handleGetDropdownOptions();
    }, [optionsPayload, isNew])

    useEffect(() => {
        if (isNew) {
            setValue('companyTin', companyTin)
            setValue('companyName', profileDetails.partnerName ?? '')
            setValue('terminalOperator', tpoId)
        }
    }, [profileDetails, companyTin, tpoId, isNew])

    useEffect(() => {
        if (!isNew) return
        if (watch('For') == 1) {
            validateUserEmailId(profileDetails.email ?? '', 'self')
        }
        else {
            setValue('userEmail', '')
        }
    }, [watch('For'), profileDetails.email, isNew])

    return (
        <>
            <Card style={{ minHeight: "100%" }}>
                <Card.Header className="d-flex align-items-center border-bottom sticky-top bg-white">
                    <FontAwesomeIcon
                        icon={faArrowAltCircleLeft}
                        className="text-warning cursor-pointer fa-lg mb-1 me-1"
                        onClick={onBack}
                    />
                    <h2 className="fs-0 text-primary mb-1">Port ID Card Request</h2>
                </Card.Header>
                <Card.Body>
                    <Form className="row" autoComplete="off">
                        <div className="col-lg-6 mb-3">
                            <Form.Group controlId="idRequestReferenceNumber">
                                <span className="mandatory">*</span>
                                <Form.Label>
                                    {t("resource_4:idRequestReferenceNumber")}
                                </Form.Label>
                                <Form.Control
                                    size="sm"
                                    {...register("ref_no")}
                                    className={`fs--1 form-control form-control-sm ${errors?.ref_no ? "is-invalid" : ''}`}
                                    type="text"
                                    disabled={true}
                                />
                                <div className="invalid-feedback d-block">
                                    {errors.ref_no?.message?.toString()}
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-lg-6 mb-3">
                            <Form.Group controlId="invoiceNumber">
                                <Form.Label>
                                    <span className="mandatory">*</span>
                                    {t("resource_2:terminalOperator")}
                                </Form.Label>
                                <Form.Select
                                    size="sm"
                                    className={`fs--1 form-control form-control-sm ${errors?.terminalOperator ? 'is-invalid' : ''}`}
                                    onChange={(e) => {
                                        setValue('terminalOperator', e.target.value)
                                    }}
                                    disabled={true}
                                    value={watch('terminalOperator')}
                                >
                                    {portOptions.map(({ value, text }) => <option value={value} key={value}>
                                        {text}
                                    </option>
                                    )}
                                </Form.Select>
                                <div className="invalid-feedback d-block">
                                    {errors.terminalOperator?.message?.toString()}
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-lg-6 mb-3">
                            <Form.Group controlId="requestor_company_tin">
                                <span className="mandatory">*</span>
                                <Form.Label>
                                    {t("resource_3:requestor_company_tin")}
                                </Form.Label>
                                <Form.Control
                                    disabled={true}
                                    size="sm"
                                    {...register("companyTin")}
                                    // onChange={handleTINChange}
                                    className={`fs--1 form-control form-control-sm ${errors?.companyTin ? 'is-invalid' : ''}`}
                                    type="text"
                                    maxLength={20}
                                />
                                <div className="invalid-feedback d-block">
                                    {errors.companyTin?.message?.toString()}
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-lg-6 mb-3">
                            <Form.Group controlId="">
                                <span className="mandatory">*</span>
                                <Form.Label>
                                    {t("resource_3:requestor_company_name")}
                                </Form.Label>
                                <Form.Control
                                    disabled={true}
                                    size="sm"
                                    {...register("companyName")}
                                    className={`fs--1 form-control form-control-sm ${errors?.companyName ? 'is-invalid' : ''}`}
                                    type="text"
                                />
                                <div className="invalid-feedback d-block">
                                    {errors.companyName?.message?.toString()}
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-lg-6 mb-3">
                            <Form.Group controlId="">
                                <div className="d-flex align-items-center flex-row gap-3">
                                    <Form.Label>
                                        <span className="mandatory">*</span>
                                        {t("resource_1:FOR")}
                                    </Form.Label>
                                    <ButtonGroup size="sm" className={`mb-2 ${isDisable && 'border-secondary'}`}>
                                        {renderButtons()}
                                    </ButtonGroup>
                                </div>
                                <div className="invalid-feedback d-block">
                                    {errors.For?.message?.toString()}
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-lg-6 mb-3">
                            <Form.Group controlId="">
                                <div className="d-flex w-full w-100 flex-row gap-3">
                                    <Form.Label style={{ width: '30%' }} className="mb-0 mt-2">
                                        <span className="mandatory">*</span>
                                        {t("resource_3:user_email_id")}
                                    </Form.Label>
                                    <div style={{ width: '70%' }}>
                                        {
                                            (watch('For') === 1 || isDisable) ?
                                                <Form.Control
                                                    disabled={!isNew || isDisable || watch('For') == 1}
                                                    size="sm"
                                                    {...register("userEmail")}
                                                    // onChange={handleTINChange}
                                                    className={`fs--1 form-control form-control-sm ${errors?.userEmail ? 'is-invalid' : ''} `}
                                                    type="text"
                                                />
                                                :
                                                <Select
                                                    options={userList}
                                                    value={selectedUserEmail}
                                                    isDisabled={isDisable}
                                                    noOptionsMessage={() => null}
                                                    isClearable={true}
                                                    maxMenuHeight={150}
                                                    onChange={(e: any) => {
                                                        if (e === null) {
                                                            setSelectedUserEmail({
                                                                value: '',
                                                                label: ''
                                                            })
                                                            setError("userEmail", {
                                                                type: "manual",
                                                                message: "User email is required",
                                                            });
                                                            setValue('userEmail', "")
                                                        } else {
                                                            setSelectedUserEmail({
                                                                value: e?.label,
                                                                label: e?.value
                                                            })
                                                            clearErrors('userEmail');
                                                            validateUserEmailId(e?.value, 'team')
                                                        }
                                                    }}
                                                    classNames={{
                                                        input: () => 'fs--1'
                                                    }}
                                                    styles={customDropdownStyles}
                                                    filterOption={customFilterOption}
                                                    onInputChange={(inputValue) => setUserEmailTxt(inputValue)}
                                                    className={`form-control form-control-sm ${errors.userEmail ? "is-invalid" : ""}`}
                                                />
                                        }
                                        {
                                            errors.userEmail &&
                                            <div className="invalid-feedback d-block">
                                                {errors.userEmail?.message?.toString()}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-lg-4 mb-3">
                            <Form.Group controlId="">
                                <span className="mandatory">*</span>
                                <Form.Label>
                                    {t("resource_2:firstName")}
                                </Form.Label>
                                <Form.Control
                                    disabled={isDisable}
                                    size="sm"
                                    {...register("firstName")}
                                    maxLength={32}
                                    className={`fs--1 form-control form-control-sm ${errors?.firstName ? 'is-invalid' : ''} `}
                                    type="text"
                                />
                                <div className="invalid-feedback d-block">
                                    {errors.firstName?.message?.toString()}
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-lg-4 mb-3">
                            <Form.Group controlId="">
                                <span className="mandatory">*</span>
                                <Form.Label>
                                    {t("resource_2:middleName")}
                                </Form.Label>
                                <Form.Control
                                    disabled={isDisable}
                                    size="sm"
                                    {...register("middleName")}
                                    maxLength={32}
                                    className={`fs--1 form-control form-control-sm ${errors?.middleName ? 'is-invalid' : ''} `}
                                    type="text"
                                />
                                <div className="invalid-feedback d-block">
                                    {errors.middleName?.message?.toString()}
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-lg-4 mb-0">
                            <Form.Group controlId="">
                                <span className="mandatory">*</span>
                                <Form.Label>
                                    {t("resource_2:lastName")}
                                </Form.Label>
                                <Form.Control
                                    disabled={isDisable}
                                    size="sm"
                                    {...register("lastName")}
                                    maxLength={32}
                                    className={`fs--1 form-control form-control-sm ${errors?.lastName ? 'is-invalid' : ''}`}
                                    type="text"
                                />
                                <div className="invalid-feedback d-block">
                                    {errors.lastName?.message?.toString()}
                                </div>
                            </Form.Group>
                        </div>
                        <span style={{ color: '#F5803E', fontSize: '15px' }} className="mb-3">(Enter the name as per the attached National Id)</span>
                        <div className="col-lg-4 mb-3">
                            <Form.Group controlId="">
                                <span className="mandatory">*</span>
                                <Form.Label>
                                    {t("resource_2:phoneNo")}
                                </Form.Label>
                                <Form.Control
                                    disabled={isDisable}
                                    size="sm"
                                    onChange={(e) => onChangeNumericValues('phoneNo', e?.target?.value, 20)}
                                    value={watch('phoneNo')}
                                    className={`fs--1 form-control form-control-sm ${errors?.phoneNo ? 'is-invalid' : ''} `}
                                    type="text"
                                />
                                <div className="invalid-feedback d-block">
                                    {errors.phoneNo?.message?.toString()}
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-lg-4 mb-3">
                            <Form.Group controlId="invoiceNumber">
                                <Form.Label>
                                    <span className="mandatory">*</span>
                                    {t("resource_3:requesting_for_duration")}
                                </Form.Label>
                                <Form.Select
                                    disabled={isDisable}
                                    size="sm"
                                    className={`fs--1 form-control form-control-sm ${errors?.requestDuration ? 'is-invalid' : ''}`}
                                    onChange={(e) => {
                                        setValue('requestDuration', e.target.value)
                                        if (e.target.value) {
                                            clearErrors('requestDuration')
                                            getMaxDateForValidTo(true);
                                        }
                                        else {
                                            setError('requestDuration', {
                                                type: "manual",
                                                message: "Request duration is required",
                                            })
                                        }
                                    }}
                                    value={watch('requestDuration')}
                                >
                                    <option value={''}>{isDisable ? '' : 'Select Duration'}</option>
                                    {durationOptions?.length > 0 && durationOptions?.map(({ value, text }) => <option value={value} key={value}>
                                        {text}
                                    </option>
                                    )}
                                </Form.Select>
                                <div className="invalid-feedback d-block">
                                    {errors.requestDuration?.message?.toString()}
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-lg-2 mb-3">
                            <Form.Group controlId="">
                                <span className="mandatory">*</span>
                                <Form.Label>
                                    {t("resource_2:validForm")}
                                </Form.Label>
                                <Controller
                                    name="validFrom"
                                    control={control}
                                    render={({ field }) => (
                                        <DatePicker
                                            selected={
                                                field.value ? new Date(field.value) : null
                                            }
                                            disabled={(isDisable && profileDetails?.opcPartnerType != 'PTO') || status == 311 || status == 304}
                                            minDate={moment().toDate()}
                                            onChange={(date) => {
                                                const validToDate = watch('validTo');
                                                if (moment(date).isAfter(moment(validToDate))) {
                                                    setValue('validTo', '');
                                                    toast.error('Valid From date must be less than Valid To date.', { toastId: 'ZiH1L' });
                                                }
                                                getMaxDateForValidTo(true, date);
                                                field.onChange(date);
                                            }}
                                            className={`fs--1 form-control form-control-sm ${errors?.validFrom ? 'is-invalid' : ''}`}
                                            dateFormat="dd/MM/yyyy"
                                            timeIntervals={15}
                                        />
                                    )}
                                />
                                <div className="invalid-feedback d-block">
                                    {errors.validFrom?.message?.toString()}
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-lg-2 mb-3">
                            <Form.Group controlId="">
                                <span className="mandatory">*</span>
                                <Form.Label>
                                    {t("resource_2:validTo")}
                                </Form.Label>
                                <Controller
                                    name="validTo"
                                    control={control}
                                    render={({ field }) => (
                                        <DatePicker
                                            selected={
                                                field.value ? new Date(field.value) : null
                                            }
                                            disabled={(isDisable && profileDetails?.opcPartnerType != 'PTO') || status == 311 || status == 304}
                                            minDate={moment(watch('validFrom')).add(1, 'days').toDate()}
                                            maxDate={maxDateValidTo}
                                            onChange={(date) => {
                                                if (moment(watch('validFrom')).isAfter(moment(date))) {
                                                    setValue('validFrom', '');
                                                    toast.error('Valid From date must be less than Valid To date.', { toastId: 'EAc25' })
                                                }
                                                field.onChange(date);
                                            }}
                                            className={`fs--1 form-control form-control-sm ${errors?.validTo ? 'is-invalid' : ''}`}
                                            dateFormat="dd/MM/yyyy"
                                            timeIntervals={15}
                                        />
                                    )}
                                />
                                <div className="invalid-feedback d-block">
                                    {errors.validTo?.message?.toString()}
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-lg-4 mb-3">
                            <Form.Group controlId="invoiceNumber">
                                <Form.Label>
                                    <span className="mandatory">*</span>
                                    {t("resource_3:national_id_type")}
                                </Form.Label>
                                <Form.Select
                                    disabled={isDisable}
                                    size="sm"
                                    className={`fs--1 form-control form-control-sm ${errors?.nationalIdType ? 'is-invalid' : ''}`}
                                    onChange={(e) => {
                                        if (e.target.value) {
                                            clearErrors('nationalIdType')
                                        }
                                        else {
                                            setError('nationalIdType', {
                                                type: "manual",
                                                message: "National ID type is required",
                                            })
                                        }
                                        setValue('nationalIdType', e.target.value)
                                    }}
                                    value={watch('nationalIdType')}
                                >
                                    <option value={''}>{isDisable ? '' : 'Select National Id Type'}</option>
                                    {nationalIdOptions?.length > 0 && nationalIdOptions.map(({ value, text }) => <option value={value} key={value}>
                                        {text}
                                    </option>
                                    )}
                                </Form.Select>
                                <div className="invalid-feedback d-block">
                                    {errors.nationalIdType?.message?.toString()}
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-lg-4 mb-3">
                            <Form.Group controlId="">
                                <span className="mandatory">*</span>
                                <Form.Label>
                                    {t("resource_3:national_id_number")}
                                </Form.Label>
                                <Form.Control
                                    disabled={isDisable}
                                    size="sm"
                                    maxLength={32}
                                    {...register("nationalIdNumber")}
                                    className={`fs--1 form-control form-control-sm ${errors?.nationalIdNumber ? 'is-invalid' : ''} `}
                                    type="text"
                                />
                                <div className="invalid-feedback d-block">
                                    {errors.nationalIdNumber?.message?.toString()}
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-lg-4 mb-3">
                            <Form.Group controlId="">
                                <Form.Label>
                                    {t("resource_3:emergency_contact_number")}
                                </Form.Label>
                                <Form.Control
                                    disabled={isDisable}
                                    size="sm"
                                    onChange={(e) => onChangeNumericValues('emergencyContactNumber', e?.target?.value, 20)}
                                    value={watch('emergencyContactNumber')}
                                    className={`fs--1 form-control form-control-sm ${errors?.emergencyContactNumber ? 'is-invalid' : ''} `}
                                    type="text"
                                />
                                <div className="invalid-feedback d-block">
                                    {errors.emergencyContactNumber?.message?.toString()}
                                </div>
                            </Form.Group>
                        </div>
                        <PortIdDocumentTable status={status} type={1} requestPartner={profileDetails.opcPartnerType} showdata={isDisable} />
                        <div className="col-lg-6 mb-2">
                            <Form.Group controlId="remarks">
                                <Form.Label>{t("resource_2:user_remarks")}</Form.Label>
                                <Form.Control
                                    disabled={isDisable}
                                    className={`fs--1 form-control form-control-sm ${errors?.remarks ? 'is-invalid' : ''}`}
                                    {...register("remarks")}
                                    maxLength={1000}
                                    as="textarea"
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        e.target.value = value.substring(0, 250);
                                    }}
                                />
                                <div className="invalid-feedback d-block">
                                    {errors.remarks?.message?.toString()}
                                </div>
                            </Form.Group>
                        </div>
                        {
                            (
                                (profileDetails?.opcPartnerType == "PTO" && status == 303)
                                || (status != 303 && status != 307 && status != 314 && !isNew)
                            ) &&
                            <div className="col-lg-6 mb-2">
                                <Form.Group controlId="remarks">
                                    {profileDetails.opcPartnerType === "PTO" && <span className="mandatory">*</span>}
                                    <Form.Label>{t("resource_2:approval_remarks")}</Form.Label>
                                    <Form.Control
                                        disabled={
                                            !(profileDetails.opcPartnerType === "PTO" && !['311', '307', '304'].includes(`${status}`))
                                        }
                                        className={`fs--1 form-control form-control-sm ${errors?.approvalRemarks ? 'is-invalid' : ''}`}
                                        {...register("approvalRemarks")}
                                        maxLength={1000}
                                        as="textarea"
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            e.target.value = value.substring(0, 250);
                                        }}
                                    />
                                    <div className="invalid-feedback d-block">
                                        {errors.approvalRemarks?.message?.toString()}
                                    </div>
                                </Form.Group>
                            </div>
                        }
                        {(status === 311 && watch('navisDraftId')) &&
                            <>
                                <div className="col-lg-6 mb-3">
                                    <Form.Group>
                                        {profileDetails.opcPartnerType === "PTO" && <span className="mandatory">*</span>}
                                        <Form.Label>{t("resource_4:portIdDraftNumber")}</Form.Label>
                                        <Form.Control
                                            size="sm"
                                            className={`fs--1 form-control form-control-sm ${errors.navisDraftId ? "is-invalid" : ""}`}
                                            type="text"
                                            maxLength={20}
                                            disabled={true}
                                            {...register("navisDraftId")}
                                        />
                                    </Form.Group>
                                </div>
                            </>
                        }
                        {!isNew && <div className="col-lg-6 mb-3"><Form.Group>
                            <Form.Label>{t("resource_2:requestStatus")}</Form.Label>
                            <Form.Select
                                size="sm"
                                className={`fs--1 form-control form-control-sm }`}
                                disabled={true}
                                value={status}
                            >
                                <option></option>
                                {statusOptions?.length > 0 && statusOptions.map(({ value, text }) => <option value={value} key={value}>
                                    {text}
                                </option>
                                )}
                            </Form.Select>
                        </Form.Group>
                        </div>
                        }
                        {
                            [304, 307, 311].includes(status)
                                ? ''
                                : isDisable && profileDetails?.opcPartnerType !== 'PTO' && [314, 303].includes(status)
                                    ? (
                                        <div className="col-lg-8 offset-lg-2 mt-2 d-flex justify-content-center align-items-center">
                                            {!hideEditCancelBtn && (
                                                <>
                                                    <Button
                                                        className="me-2"
                                                        variant="falcon-danger"
                                                        size="sm"
                                                        type="button"
                                                        onClick={() => {
                                                            setIsDisable(false);
                                                        }}
                                                    >
                                                        <FontAwesomeIcon icon={faEdit} className="me-2" />
                                                        {t("resource_1:edit")}
                                                    </Button>
                                                    <Button
                                                        className="me-2"
                                                        variant="falcon-danger"
                                                        size="sm"
                                                        type="button"
                                                        onClick={() => setCancel(true)}
                                                    >
                                                        <FontAwesomeIcon icon={faCancel} className="me-2" />
                                                        {t("resource_2:cancelRequest")}
                                                    </Button>
                                                </>
                                            )}
                                        </div>
                                    )
                                    : isDisable && profileDetails?.opcPartnerType === 'PTO'
                                        ? (
                                            <div className="d-flex justify-content-center align-items-center mt-4">
                                                <Button
                                                    variant="success"
                                                    className="me-4 approve"
                                                    size="sm"
                                                    onClick={() => {
                                                        clearErrors();
                                                        onApproveReject({ status: 311 });
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faCheck} className="me-2 approve" />
                                                    {t("resource_1:approve")}
                                                </Button>

                                                <Button
                                                    variant="danger reject"
                                                    size="sm"
                                                    onClick={() => {
                                                        clearErrors();
                                                        onApproveReject({ status: 304 });
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faTimes} className="me-2 reject" />
                                                    {t("resource_1:reject")}
                                                </Button>
                                            </div>
                                        )
                                        : (
                                            <div className="col-lg-8 offset-lg-2 mt-2 d-flex justify-content-center align-items-center">
                                                {/* {status !== 303 && ( */}
                                                <Button
                                                    variant="falcon-primary me-2"
                                                    size="sm"
                                                    disabled={isLoading}
                                                    onClick={saveDraft}
                                                >
                                                    <FontAwesomeIcon icon={faFolder} className='me-2' color='#f5822a' />
                                                    {t("resource_2:saveDraft")}
                                                </Button>
                                                <Button
                                                    variant="falcon-primary me-2"
                                                    size="sm"
                                                    type="submit"
                                                    disabled={isLoading}
                                                    onClick={onSubmit}
                                                >
                                                    <FontAwesomeIcon icon={faSave} className="me-2" />
                                                    {t("resource_1:submit")}
                                                </Button>
                                                <Button
                                                    variant="falcon-danger"
                                                    onClick={() => {
                                                        resetForm();
                                                    }}
                                                    size="sm"
                                                >
                                                    <FontAwesomeIcon icon={faRedo} className="me-2" />
                                                    {t("resource_1:reset")}
                                                </Button>
                                            </div>
                                        )
                        }
                    </Form>
                </Card.Body>
            </Card >
            <Modal show={cancel} onHide={() => setCancel(false)}>
                <Modal.Header className="p-2 pb-1" closeButton>
                    <Modal.Title className="h6">
                        Do you want to Cancel the Port ID request?
                    </Modal.Title>
                </Modal.Header>

                <div className="d-flex justify-content-center align-items-center p-2">
                    <Button
                        variant="falcon-primary"
                        onClick={() => {
                            dispatch(cancelPortIdRequest({ id: watch('portIdReqId') }))
                            setCancel(false)
                            onBack()
                            dispatch(updateDashbaordRefresh(true))
                        }}
                        size="sm"
                        className="me-2"
                    >
                        {t("resource_1:yes")}
                    </Button>
                    <Button
                        className="me-2"
                        onClick={() => setCancel(false)}
                        variant="falcon-danger"
                        size="sm"
                    >
                        {t("resource_1:no")}
                    </Button>
                </div>
            </Modal>
        </>
    )
}

export default PortIDRequest