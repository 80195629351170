import { useSelector } from "react-redux";
import DataTable from "../../common/components/DataTable";
import { AppDispatch, RootState } from "../../store";
import { faAdd, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";

import { useDispatch } from "react-redux";
import {
  addDocumentTableRow,
  removeDocumentTableRow,
  updateDocumentTableRow,
} from "./mpsSlice";
import { useEffect } from "react";
import { getMpsDocList, getMPSDocumentNames } from "./mpsActions";
import useDataCountry from "../../../hooks/useDataCountry";
import { useParams } from "react-router-dom";
import { UploadThing } from "../../../components/common/UploadThing";
export default function MpsDocumentTable({
  newData = false,
  showdata = false,
  type = null,
  requestPartner,
}: any) {
  const data = useSelector((state: RootState) => state.mps.documentData);
  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );
  const dataCountry = useDataCountry();
  const tpoId = useSelector((state: RootState) => state.agentDelegation.tpoId);
  const { invoice_request_id } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    if (tpoId && profileDetails?.opcPartnerType && dataCountry && type)
      if (!showdata) {
        dispatch(
          getMpsDocList({
            dataCountry,
            partner: profileDetails?.opcPartnerType,
            tpoId,
            type,
          })
        );
      } else if (requestPartner) {
        dispatch(
          getMPSDocumentNames({
            dataCountry,
            partner: requestPartner,
            tpoId,
            type,
          })
        );
      }
  }, [
    dataCountry,
    profileDetails?.opcPartnerType,
    tpoId,
    type,
    newData,
    invoice_request_id,
    requestPartner,
  ]);

  const docAttachmentColumns = [
    {
      accessor: "dndInstructionAttId",
      Header: "dndInstructionAttId",
    },
    {
      accessor: "docName",
      Header: "Document",
      width: 400,
      maxWidth: 450,
      Cell: (rowData: any) => {
        const { docName, m } = rowData.row.original;
        return (
          <>
            {m && <span className="mandatory">*</span>}
            <span title={docName}>{docName}</span>
          </>
        );
      },
    },
    {
      accessor: "byteArray",
      Header: showdata ? "Download" : "Upload",
      width: 450,
      maxWidth: 450,
      Cell: (rowData: any) => {
        const { fileName, loading, rowNumber, attId } = rowData.row.original;

        if (showdata)
          return (
            <span
              className="text-primary cursor-pointer"
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_CLIENT_APP_HOST}/api/opc-rest-v1/v1/common/openDocument?id=${attId}&moduleTp=INV_REQUEST&linerId=${tpoId}`,
                  "_blank"
                );
              }}
            >
              {fileName}
            </span>
          );

        return (
          <UploadThing
            name={fileName}
            loading={loading}
            fulfilled={function (
              name: string,
              serverPath: string,
              serverName: string
            ): void {
              dispatch(
                updateDocumentTableRow({
                  rowNumber: rowNumber,
                  fileName: name,
                  serverFilePath: serverPath,
                  tempName: serverName,
                  loading: 100,
                })
              );
            }}
            failed={function (): void {
              dispatch(
                updateDocumentTableRow({
                  rowNumber: rowNumber,
                  loading: -1,
                  fileName: "",
                })
              );
            }}
            pending={function (loading: number): void {
              dispatch(
                updateDocumentTableRow({
                  rowNumber: rowNumber,
                  loading: loading,
                })
              );
            }}
            clear={function (): void { }}
            selected={function (name: string): void {
              dispatch(
                updateDocumentTableRow({
                  rowNumber: rowNumber,
                  fileName: name,
                  serverFilePath: "",
                  filePath: "",
                  tempName: "",
                  loading: 0,
                })
              );
            }}
            fileLimit={5}
            fileType={[
              "pdf",
              "doc",
              "docx",
              "jpg",
              "jpeg",
              "png",
              "xls",
              "xlsx",
              "eml",
            ]}
            allowRemove={false}
          />
        );
      },
    },
    {
      accessor: "delete",
      Header: "",
      width: 150,
      maxWidth: 150,
      Cell: (rowData: any) => {
        const { rowNumber, m, docId } = rowData.row.original;
        if (docId != 12 || showdata) return <></>;
        return (
          <Button
            variant="falcon-warning"
            style={{
              background: "transparent",
              border: "none",
              boxShadow: "none",
              padding: "0px",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              height: "100%",
            }}
            disabled={showdata}
            onClick={() => {
              dispatch(removeDocumentTableRow(rowNumber));
            }}
          >
            <FontAwesomeIcon icon={faTrash} size="sm" />
          </Button>
        );
      },
    },
  ];

  return (
    <>
      {!showdata && (
        <div>
          <button
            type="button"
            onClick={(e: any) => {
              const rowNumber = crypto.randomUUID();
              dispatch(addDocumentTableRow(rowNumber));
            }}
            className="btn-falcon-primary btn btn-primary btn-sm mb-2"
            style={{ width: "max-content" }}
          >
            <FontAwesomeIcon className="me-1" icon={faAdd} /> Documents
          </button>
        </div>
      )}
      <DataTable
        columns={docAttachmentColumns}
        data={data}
        pagination={false}
        selection={false}
        sortable={false}
        perPage={100}
        searchable={false}
        showExport={false}
        showColumnFilter={undefined}
        uniqueFilterKey={"mpsDocument"}
        autoHeight={true}
        tableMinHeight={100}
        staticHiddenCols={["dndInstructionAttId"]}
      />
    </>
  );
}
