import { createAsyncThunk } from "@reduxjs/toolkit"
import axiosInstance, { axiosCacheInstance } from "../../../axiosSetup"
import { toast } from "react-toastify";
import { RootState } from "../../store";
import { AxiosError } from "axios";
import retry from "../../../helpers/axiosRetry";

export const getTrafficOptions = createAsyncThunk(
    'mps/getTrafficOptions',
    async (payload: { tpoId: any, country: any }) => {
        try {
            const data = (await retry( async ()=> await axiosCacheInstance.get(
                `${process.env.REACT_APP_API_GATEWAY_HOST}/api/opc-rest-v1/v1/mstCode/getMstCodeListByTypeAndLanguage?language=EN&codeType=TRAFFIC_OPTIONS&linerId=${payload.tpoId}&countryCode=${payload.country}`,
                {
                    cache: {
                        ttl: 10 * 60 * 1000
                    }
                }
            ),2))?.data;
            return data.map(
                (d: any) => ({
                    text: d.codeDesc,
                    value: d.codeId,
                })
            )
        } catch {
            return []
        }
    })

export const getTransitOptions = createAsyncThunk(
    'mps/getTransitOptions',
    async (payload: { tpoId: any, country: any }) => {
        try {
            const data = (await retry( async ()=> await axiosCacheInstance.get(
                `${process.env.REACT_APP_API_GATEWAY_HOST}/api/opc-rest-v1/v1/mstCode/getMstCodeListByTypeAndLanguage?language=EN&codeType=LOCAL/TRANSIT_OPTIONS&linerId=${payload.tpoId}&countryCode=${payload.country}`,
                {
                    cache: {
                        ttl: 10 * 60 * 1000
                    }
                }
            ),2))?.data;
            return data.map(
                (d: any) => ({
                    text: d.codeDesc,
                    value: d.codeId,
                })
            )
        } catch {
            return []
        }

    })

export const getStatusOptions = createAsyncThunk(
        'opc/getStatusOptions',
        async (payload: { tpoId: any, country: any }) => {
            try {
                const data = (await retry( async ()=> await axiosCacheInstance.get(
                    `${process.env.REACT_APP_API_GATEWAY_HOST}/api/opc-rest-v1/v1/mstCode/getMstCodeListByTypeAndLanguage?language=EN&codeType=EVENT_TYPE&linerId=${payload.tpoId}&countryCode=${payload.country}`,
                    {
                        cache: {
                            ttl: 10 * 60 * 1000
                        }
                    }
                ),2))?.data;
                return data.map(
                    (d: any) => ({
                        text: d.codeDesc,
                        value: d.codeId,
                    })
                )
            } catch {
                return []
            }
    
        })

export const getInstructionConfig = createAsyncThunk(
    'mps/instructionConfig',
    async ({dataCountry, partner, tpoId, type, category}:any) => {

        // default state for all fields is hidden
        const config: Record<string, { s: boolean, m: boolean }> = {
            referenceNumber: { s: false, m: false },
            companyTin: { s: false, m: false },
            companyName: { s: false, m: false },
            traffic: { s: false, m: false },
            vessel: { s: false, m: false },
            transit: { s: false, m: false },
            bl_so: { s: false, m: false },
            containerCount: { s: false, m: false },
            containerList: { s: false, m: false },
            documentList: { s: false, m: false },
            remarks: { s: false, m: false },
            type: { s: true, m: true }, // always true since we need it for config
            category: { s: true, m: true } // always true since we need it for config
        }

        try {
            const data = JSON.parse((await axiosInstance.post(
                `${process.env.REACT_APP_API_GATEWAY_HOST}/api/opc-rest-v1/v1/common/getInstructionConfig?countryCode=${dataCountry
                }&instructionTy=904&tpoId=${tpoId}&partnerType=${partner}&isOtherConditionAllow=1&type=${category}&category=${type}`
            )).data?.columnConfig??'')

            Object.entries(data).forEach(([key, val]) => {
                config[key] = { s: val == 'M' || val == 'O', m: val == 'M' }
            })
    
            // bl_so validation has dependency on vessel and traffic
            config.traffic.s ||= config.bl_so.s
            config.vessel.s ||= config.bl_so.s
            config.traffic.m ||= config.bl_so.m
            config.vessel.m ||= config.bl_so.m

            // count is autogenerated so doesn't need state
            config.containerCount.m = false
    
            return config
           
        } catch {
            return config
        }

    })

export const getMpsCategory = createAsyncThunk(
    'mps/getCategory',
    async ({dataCountry, partner, tpoId}:any) => {
        try {
            const data = (await axiosInstance.post(
                `${process.env.REACT_APP_API_GATEWAY_HOST}/api/opc-rest-v1/v1/common/getInstructionConfig?countryCode=${dataCountry}&instructionTy=904&tpoId=${tpoId}&partnerType=${partner}&isOtherConditionAllow=1`
            )).data
            return data.map(
                (d: any) => ({
                    text: d.typeNm,
                    value: d.type
                })
            )
        } catch {
            return []
        }

    })

export const getMpsTypes = createAsyncThunk(
    'mps/getTypes',
    async ({dataCountry, partner, tpoId, type}:any) => {
        try {
            const data = (await axiosInstance.post(
                `${process.env.REACT_APP_API_GATEWAY_HOST}/api/opc-rest-v1/v1/common/getInstructionConfig?countryCode=${dataCountry
                }&instructionTy=904&tpoId=${tpoId}&partnerType=${partner}&isOtherConditionAllow=1&type=${type}`
            )).data
            return data.map(
                (d: any) => ({
                    text: d.categoryNm,
                    value: d.category
                })
            )
        } catch {
            return []
        }

    })

export const getMpsDocList = createAsyncThunk(
    'mps/getMpsDocList',
    async ({dataCountry, partner, tpoId, type}:any) => {
        try {
            const data = (await axiosCacheInstance.post(
                `${process.env.REACT_APP_API_GATEWAY_HOST}/api/opc-rest-v1/v1/common/getAttachmentConfig?countryCode=${dataCountry
                }&instructionTy=904&tpoId=${tpoId}&partnerType=${partner}&category=${type}`,
                undefined,
                {cache:{
                    ttl: 10 * 60 * 1000
                  }}
            )).data
            //const data = [{ docName: 'test', docId: 1, m: false }, { docName: 'test', docId: 2, m: true }]

            return data.map((d:any) => ({
                rowNumber: crypto.randomUUID(),
                attId: null,
                docId: d.docId,
                docName: d.docName,
                fileName: '',
                filePath: '',
                tempName: '',
                serverFilePath: '',
                m: d.isMandatory == 'Y',
                loading: 0
            }))
        } catch {
            return []
        }

    })

    export const getMPSDocumentNames = createAsyncThunk(
        'mps/getMpsDocNames',
        async ({dataCountry, partner, tpoId, type}:any) => {
            try {
                const data = (await axiosCacheInstance.post(
                    `${process.env.REACT_APP_API_GATEWAY_HOST}/api/opc-rest-v1/v1/common/getAttachmentConfig?countryCode=${dataCountry
                    }&instructionTy=904&tpoId=${tpoId}&partnerType=${partner}&category=${type}`,
                    undefined,
                    {cache:{
                        ttl: 10 * 60 * 1000
                      }}
                )).data
                return [...data.map((d:any) => ({
                    docId: d?.docId,
                    docName: d?.docName,
                    m: d?.isMandatory == 'Y',
                })), {docId: 12, docName: 'Others', m:true}]
            } catch(e) {
                console.error('mpsDocConfigError',e)
                return []
            }
    
        })

export const saveMPSRequest = createAsyncThunk(
    'mps/saveMPSReques',
    async (data: any, { getState }: any) => {
        const state: RootState = getState();
        try {
            const payload = {
                "requestId": data.requestId??null,
                "odexRefNo": data.referenceNumber,
                "customerTin": data.companyTin,
                "requestedPartnerNm": data.companyName,
                "vesselVoyage": data.vessel,
                "traffic": state.mps.trafficOptions.find((d)=>d.value == data.traffic)?.text??null,
                "deliveryType": state.mps.transitOptions.find((d)=>d.value == data.transit)?.text??null,
                "remarks": data.remarks??'',
                "documents": state.mps.documentData.map((d) => ({
                    docId: d.docId,
                    filePath: d.filePath,
                    fileName: d.fileName,
                    serverFilePath: d.serverFilePath,
                    tempFileName: d.tempName
                })),
                "blno": data.bl_so,
                "tpoId": data.tpoId,
                "type": data.type,
                "category": data.category,
                "typeNm": state.mps.typeOptions.find((d) => d.value == data.type)?.text,
                "categoryNm": state.mps.categoryOptions.find((d) => d.value == data.category)?.text,
                "containerList": state.mps.containerData.map((d) =>
                     ({ containerNo: d.containerNumber, isChecked: d.selected?'Y':'N' })), 
                "requestedOn": data?.requestedOn??undefined
            }
            const response = await axiosInstance.post(
                `${process.env.REACT_APP_API_GATEWAY_HOST}/api/opc-management/v1/invoice/submitMiscInvoiceRequest`,
                payload
            )
            if(response?.data?.responseCode == 'SUCCESS'){
                toast.success(response?.data?.responseMsg,{
                    toastId:'996e94a'
                })
            }else{
                toast.error(response?.data?.responseMsg??'Error while saving MPS request',{
                    toastId:'bf6eeb7'
                });    
            }
        } catch {
            toast.error('Error while saving MPS request',{
                toastId:'c3dac08'
            });
        }
    })

    export const getMPSRequest = createAsyncThunk(
        'mps/getMPSReques',
        async ({tpoId, id}:any, {getState}:any) => {
            const state :RootState = getState()
            try {
                const data = (await axiosInstance.get(`${process.env.REACT_APP_API_GATEWAY_HOST}/api/opc-management/v1/invoice/getInvoiceRequest?id=${id}&instType=904&tpoId=${tpoId}`)).data
                const response = {
                    requestId: data.requestId,
                    referenceNumber: data.odexRefNo??'',
                    companyTin: data.customerTin??'',
                    companyName: data.requestedPartnerNm??'',
                    vessel: data.vesselVoyage??'',
                    traffic: state.mps.trafficOptions.find((x) => x.text == data.traffic)?.value??0,
                    transit: state.mps.transitOptions.find((x) => x.text == data.deliveryType)?.value??0,
                    remarks: data.remarks??'',
                    documents: data.documents?.map?.((d:any) => ({
                        rowNumber: crypto.randomUUID(),
                        attId: d.invoiceRequestAttId,
                        docId: d.docId,
                        filePath: d.filePath,
                        fileName: d.fileName,
                        serverFilePath: '',
                        tempName: '',
                        loading: 0
                    }))??[],
                    bl_so: data.blno??'',
                    tpoId: data.tpoId,
                    type: data.type,
                    category: data.category,
                    typeNm: data.typeNm,
                    categoryNm: data.categoryNm,
                    containerData: data.containerList?.map?.((d:any) =>
                         ({ 
                            rowNumber: crypto.randomUUID(),
                            containerNumber: d.containerNo, 
                            selected: d.isChecked == 'Y' }))??[],
                    status: data.status,
                    nextLevel: data?.nextApprovalLevel,
                    level: data?.approvalLevel,
                    requestedOn: data?.requestedOn,
                    linerRemarks: data?.approvalRemarks,
                    navisDraftId: data?.navisDraftId,
                    requestedPartnerType: data?.requestedPartnerType
                }
                return response
            } catch {
            }
    
        })

    export const getContainerByBl = createAsyncThunk(
        "mps/containerByBl",
        async (payload: any) => {
            try {
            const response = await axiosInstance.get(
                `/api/opc-rest-v1/v1/common/getCntnrByBlNo?blNo=${payload.blnumber}&&shipmentType=${payload.shipmentType}`
            );
            return response.data.map((d:any)=>({
                rowNumber: crypto.randomUUID(),
                containerNumber: d.containerNumber,
                selected:true
            })) ;
            } catch (e) {
            const error = e as AxiosError<any>;
            console.error(error);
            return [];
            }
        }
        );

    export const cancelMPSRequest = createAsyncThunk(
        'mps/cancelrequest',
        async (payload:any)=>{
            try{
                const response = await axiosInstance.post(`${process.env.REACT_APP_API_GATEWAY_HOST}/api/opc-management/v1/invoice/cancelMiscInvoiceRequest?id=${payload.id}`)
                if(response?.data?.responseCode == 'CANCELLED'){
                    toast.success(response?.data?.responseMsg,{
                        toastId:'06f5157'
                    })
                }else{
                    toast.error(response?.data?.responseMsg??'Error while canceling MPS request',{
                        toastId:'21f2f83'
                    });    
                }
            }catch(e){

            }
        })

export const MpsApproveReject = createAsyncThunk(
    'mps/MpsApproveReject',
    async ({nextLevel, requestedOn, ptoRemarks, 
        tpoId, requestId, navisDraftId, status, level}: any, { getState }: any) => {
        const state: RootState = getState();
        try {
            const payload = {
                "requestId": requestId,
                "status": status, //311,
                "tpoId": tpoId,
                "instType": 904,
                "approvalRemarks": ptoRemarks,
                "navisDraftId": navisDraftId, // null for all approval level, only on final 311 set this field
                "requestedOn": requestedOn,// from get api
                "approvalLevel":level //approval level from get api
            }
            const response = await axiosInstance.post(
                `${process.env.REACT_APP_API_GATEWAY_HOST}/api/opc-management/v1/invoice/approveRejectMiscInvoiceRequest`,
                payload
            )
            if(response?.data?.responseCode == 'APPROVED' ||
                response?.data?.responseCode == 'REJECTED' ||
                response?.data?.responseCode == 'SUCCESS'
            ){
                toast.success(response?.data?.responseMsg,{
                    toastId:'4b4de93'
                })
            }else{
                toast.error(response?.data?.responseMsg??'Error while saving MPS request',{
                    toastId:'51db340'
                });    
            }
        } catch {
            toast.error('Error while saving MPS request',{
                toastId:'dd7bb3d'
            });
        }
    })