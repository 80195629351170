import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  generateDeliveryReferenceNo,
  generateInvReferenceNo,
  generateMPSReferenceNo,
  generatePermitReferenceNo,
  generatePortIDReferenceNo,
  generateSSRReferenceNo,
  getCreditBalance,
  getMstCodeListVal,
} from "../../blRequestInstruction/components/actions/Instruction";

export interface CreditBalanceBlPaymentDetails {
  pmtReference: string;
  pmtTime: string;
  pmtAmount: string;
  allocatedAmount: any;
  unAllocatedAmount: any;
}

interface CreditBalanceSllice {
  data: CreditBalanceBlPaymentDetails[];
  mstcodeList: any;
  generateInvReferenceNo: any;
  generatePermitReferenceNo: any;
  generateDoReferenceNo: any;
  generateSSRReferenceNo: any;
  generateMPSReferenceNo: any;
  generatePortIDReferenceNo:any;
}

const initialState: CreditBalanceSllice = {
  mstcodeList: [],
  generateInvReferenceNo: null,
  generatePermitReferenceNo: null,
  generateDoReferenceNo: null,
  generateSSRReferenceNo: null,
  generateMPSReferenceNo: null,
  generatePortIDReferenceNo: null,
  data: [
    /*  {
      paymentRef: 'PTMNO2366',
      time: '18-02-2023  5:30',
      amount: 'USD 100',
    },
    {
      paymentRef: 'PTMNO2366',
      time: '10-02-2023  1:00',
      amount: 'USD 200',
    },
    {
      paymentRef: 'PTMNO2366',
      time: '11-02-2023  3:30',
      amount: 'USD 800',
    },
    {
      paymentRef: 'PTMNO2366',
      time: '12-02-2023  5:20',
      amount: 'USD 200',
    }, */
  ],
};

export const creditBalanceBlPayment = createSlice({
  name: "creditBalanceBlPayment",
  initialState,
  reducers: {
    updateCreditBalanceList: (
      state,
      action: PayloadAction<CreditBalanceBlPaymentDetails[]>
    ) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCreditBalance.fulfilled, (state: any, action) => {
      state.dataLoading = false;
      state.data = action.payload;
    });
    builder.addCase(generateInvReferenceNo.fulfilled, (state: any, action) => {
      state.dataLoading = false;
      state.generateInvReferenceNo = action.payload;
    });
    builder.addCase(generateInvReferenceNo.pending,(state, action)=>{
      state.generateInvReferenceNo = null
    })
    builder.addCase(generateSSRReferenceNo.fulfilled, (state: any, action) => {
      state.dataLoading = false;
      state.generateSSRReferenceNo = action.payload;
    });
    builder.addCase(generateSSRReferenceNo.pending,(state, action)=>{
      state.generateSSRReferenceNo = null
    })
    builder.addCase(generateMPSReferenceNo.pending,(state, action)=>{
      state.generateMPSReferenceNo = null
    })
    builder.addCase(generateMPSReferenceNo.fulfilled,(state, action)=>{
      state.generateMPSReferenceNo = action.payload
    })
    builder.addCase(generatePortIDReferenceNo.pending, (state)=>{
      state.generatePortIDReferenceNo = null
    })
    builder.addCase(generatePortIDReferenceNo.fulfilled,(state,action)=>{
      state.generatePortIDReferenceNo = action.payload
    })

    builder.addCase(
      generatePermitReferenceNo.fulfilled,
      (state: any, action) => {
        state.dataLoading = false;
        state.generatePermitReferenceNo = action.payload;
      }
    );
    builder.addCase(generatePermitReferenceNo.pending,(state)=>{
      state.generatePermitReferenceNo = null
    })
    builder.addCase(
      generateDeliveryReferenceNo.pending,
      (state: any) => {
        state.generateDoReferenceNo = null;
      }
    );
    builder.addCase(
      generateDeliveryReferenceNo.fulfilled,
      (state: any, action) => {
        state.dataLoading = false;
        state.generateDoReferenceNo = action.payload;
      }
    );
    builder.addCase(getMstCodeListVal.pending, (state: any, action) => {
      state.dataLoading = true;
      state.mstcodeList = action.payload;
    });
    builder.addCase(getMstCodeListVal.fulfilled, (state: any, action) => {
      state.dataLoading = false;
      state.mstcodeList = action.payload;
    });
  },
});

export const { updateCreditBalanceList } = creditBalanceBlPayment.actions;

export default creditBalanceBlPayment.reducer;
