import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";

export const getCurrencyDDList = createAsyncThunk(
    'quickpay/getCurrencyDDList',
    async (payload:{country:string}) => {
      try {
        const response = await axios.get(
          `/api/user-management/v1/country/getCurrencyDDListWithoutSession?country=${payload.country}`
        );
        return response?.data;
      } catch (e) {
        const error = e as AxiosError;

        return error;
      }
    }
  );


  export const validateQuickPayInvoice = createAsyncThunk(
    'quickpay/validateInvoice',
    async (payload: any, {getState}:any) => {
      const {invoiceAmount, invoiceCurrency, invoiceNo}:any = getState()?.quickpay?.data?.filter((x:any)=>x?.rowNumber == payload.rowNumber)[0]
      if( invoiceAmount == null 
          || invoiceAmount == 0
          || invoiceNo == '' 
          || invoiceCurrency == ""
      )return null


      try {
        const response = await axios.post(
          `/api/invoice-management/v1/payment/validateInvoiceForQuickPay?country=${payload.country}`,
          {
            invoiceId: null,
            invoiceNo: invoiceNo || null,
            invoiceAmount: invoiceAmount || null,
            invoiceCurrency: invoiceCurrency || null,
          }
        );
        return {
          ...response?.data,
          rowNumber: payload.rowNumber,
        };
      } catch (e) {
        const error = e as AxiosError;
  
        console.error( error.message);
        toast.error(error.message, {
          theme: 'colored',
          toastId:'aa5b41c'
        });
        return error;
      }
    }
  );

  export const getPlatformFees = createAsyncThunk(
    'quickPay/getPaymentFees',
    async (payload: any) => {
      try {
        const response = await axios.get( 
          `/api/payment-gateway/v1/platform/charges?pmtAmount=${payload.amount}&countryCode=US&currency=${payload.ccy}&linerId=1&pmtMode=7`,
        );
  
        return response.data
      } catch (e) {
        const error = e as AxiosError;
        return error;
      }
    }
  );