import { createSlice } from "@reduxjs/toolkit";
import { getCurrencyDDList, getPlatformFees, validateQuickPayInvoice } from "./actions/quickPayActions";

interface IQuickPayTableData{
    rowNumber: any;
    invoiceId?: number | null;
    invoiceNo: string;
    invoiceAmount?: number | null;
    invoiceCurrency: string;
    error: { invoiceNo: boolean, invoiceAmount: boolean, invoiceCurrency: boolean },
    remove: boolean,
    invDt: string;
    eximIndicator: string;
    shipmentNumber: string;
    blNo: string;
    valid: boolean;
}

interface IQuickPay{
    data: Array<IQuickPayTableData>,
    countryList:Array<any>,
    pymtModeList:Array<any>,
    currencyList:Array<any>,
    linerList:Array<any>,
    pymtMode:string|null,
    platformFees:string,
    platformChargeConfigId:string
}

const initialState:IQuickPay = {
  data: [
    {
      rowNumber: crypto.randomUUID(),
      invoiceId: null,
      invoiceNo: "",
      invoiceAmount: null,
      invoiceCurrency: "",
      error: { invoiceNo: false, invoiceAmount: false, invoiceCurrency: false },
      remove: true,
      invDt: "",
      eximIndicator: "",
      shipmentNumber: "",
      blNo: "",
      valid: false
    },
  ],
  countryList:[],
  pymtModeList:[],
  currencyList:[],
  linerList:[],
  pymtMode:null,
  platformFees:'',
  platformChargeConfigId:''

};

const quickPaySlice = createSlice({
  initialState,
  name: "quickpay",
  reducers: {
    addQuickPayRow: (state) => {
      state.data.push({
        rowNumber: crypto.randomUUID(),
        invoiceId: null,
        invoiceNo: "",
        invoiceAmount: null,
        invoiceCurrency: state.currencyList[0]?.currencyCode??'',
        error: {
          invoiceNo: false,
          invoiceAmount: false,
          invoiceCurrency: false,
        },
        remove: true,
        invDt: "",
        eximIndicator: "",
        shipmentNumber: "",
        blNo: "",
        valid: false
      });
    },
    removeQuickPayRow: (state, action) => {
      if (state.data.length <= 1) {
        state.data = [
          {
            rowNumber: crypto.randomUUID(),
            invoiceId: null,
            invoiceNo: "",
            invoiceAmount: null,
            invoiceCurrency: state.currencyList[0]?.currencyCode??'',
            error: {
              invoiceNo: false,
              invoiceAmount: false,
              invoiceCurrency: false,
            },
            remove: true,
            invDt: "",
            eximIndicator: "",
            shipmentNumber: "",
            blNo: "",
            valid: false
          },
        ];
        return;
      }
      state.data = state.data.filter(
        (i) => i.rowNumber !== action.payload.rowNumber
      );
    },
    resetQuickPayTable:(state)=>{
      state.data = [
        {
          rowNumber: crypto.randomUUID(),
          invoiceId: null,
          invoiceNo: "",
          invoiceAmount: null,
          invoiceCurrency: state.currencyList[0]?.currencyCode??'',
          error: {
            invoiceNo: false,
            invoiceAmount: false,
            invoiceCurrency: false,
          },
          remove: true,
          invDt: "",
          eximIndicator: "",
          shipmentNumber: "",
          blNo: "",
          valid: false
        },
      ];
    },
    setQuickPayRow: (state, action) => {
      const row: any = state.data.find(
        (x) => x.rowNumber == action.payload.rowNumber
      );
      row.error = {
        invoiceNo: false,
        invoiceAmount: false,
        invoiceCurrency: false,
      };
      
      row.valid = false
      Object.entries(action.payload).forEach(([key, val]) => {
        row[key] = val;
      });

    },
    quickPayCurrencyError: (state, action) => {
      state.data.forEach((item)=>
        {
          if(item.invoiceCurrency != action.payload.ccy)item.error.invoiceCurrency = true
    })

    },
    quickPayNumberError: (state, action) => {
      action.payload.forEach((i:IQuickPayTableData)=>{
        const x = state.data.find((j)=>j.rowNumber == i.rowNumber)
        if(x)x.error.invoiceNo = true
      })
    },
    setQuickPaymentMode: (state, action)=>{
      state.pymtMode = action.payload
    }
  },
  extraReducers:(builder)=>{
    builder.addCase(getCurrencyDDList.fulfilled, (state,action)=>{
      state.currencyList = action.payload??[]
      state.data.forEach((x)=>{x.invoiceCurrency = action.payload?.[0]?.currencyCode??''})
    })
    builder.addCase(getPlatformFees.fulfilled, (state,action)=>{
      state.platformFees = action.payload.totalPlatformCharge??''
    })
    builder.addCase(validateQuickPayInvoice.fulfilled, (state,action)=>{
      if(!action.payload) return
      const row: any = state.data.find(
        (x) => x.rowNumber == action.payload.rowNumber
      );
      if(action.payload.message){
        row.error = {
          invoiceNo: true,
          invoiceAmount: true,
          invoiceCurrency: true,
        }
        row.valid = false
      }else{
        row.error = {
          invoiceNo: false,
          invoiceAmount: false,
          invoiceCurrency: false,
        }
        row.valid = true
        Object.entries(action.payload).forEach(([key, val]) => {
          if(Object.hasOwn(row,key))row[key] = val;
        });
      }
      
    })
  }
  

});
export const { addQuickPayRow, removeQuickPayRow, setQuickPayRow, quickPayCurrencyError, setQuickPaymentMode, resetQuickPayTable, quickPayNumberError} =
  quickPaySlice.actions;
export default quickPaySlice.reducer;
