import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  addInvoiceChange,
  approveRejectInvoiceChange,
} from "../actions/Instruction";
import { getInvoiceChange } from "../../../dashboard/actions/instruction";
import {
  getEbsPermitsData,
  getEirInPermitsData,
  getEnquiryTableData,
  getEnquiryTableDataForContainer,
  getInvoiceTableData,
  getPermitTracking,
  getPermitTrackingData,
  getVesselTableData,
} from "../../../dashboard/actions/dashboard";

export interface InvoiceDetails {
  userRemarks: string;
  blId: number | string | null;
  email: string | null;
  taxId: string;
  contactNo: string;
  address: string;
  freightCharges: boolean;
  invoiceCharges: boolean;
  masterPartnerId: string;
  standingInstructionId: string;
  status: string;
  requestedpartnernm: string;
  //loiUpload: string;
  remarks: string;
  timePendingCharges: boolean;
  poReference: string;
  returnType: string;
  releaseType: string;
  country: string;
  matchCodeList: any[];
  matchCode: string;
  customerCode: string;
}
interface IInvoiceList {
  list: InvoiceDetails[];
  /*   next: string | null;
  previous: string | null; */
}

export interface IRelease {
  data: IInvoiceList;
  invoiceData: InvoiceDetails;
  dataLoading: boolean;
  invoiceCreateRes: any;
  isCompleteLoading: boolean;
  isRejectLoading: boolean;
  returnTypeList: any;
  invoiceDataList: any;
  enquiryDashboardList: any;
  enquiryDashboardListForContainer: any;
  vesselDataList: any;
  eirOutPermitsData: any;
  ebsPermitsData: any;
  eirInPermitsData: any;
  permitDashboardData: any;
  releaseTypeList: any;
}

const initialState: IRelease = {
  data: {
    list: [],
    /*   next: null,
    previous: null, */
  },
  dataLoading: true,
  invoiceDataList: [],
  vesselDataList: [],
  eirOutPermitsData: [],
  ebsPermitsData: [],
  eirInPermitsData: [],
  permitDashboardData: [],
  enquiryDashboardList: [],
  enquiryDashboardListForContainer: [],
  invoiceCreateRes: {},
  invoiceData: {
    blId: null,
    email: null,
    taxId: "",
    contactNo: "",
    address: "",
    freightCharges: false,
    invoiceCharges: true,
    masterPartnerId: "",
    standingInstructionId: "",
    status: "",
    requestedpartnernm: "",
    //loiUpload: '',
    remarks: "",
    timePendingCharges: false,
    poReference: "",
    returnType: "",
    releaseType: "",
    country: "",
    matchCodeList: [],
    matchCode: "",
    customerCode: "",
    userRemarks: "",
  },
  isCompleteLoading: false,
  isRejectLoading: false,
  returnTypeList: [],
  releaseTypeList: [],
};

export const InvoiceChangeSlice = createSlice({
  name: "InvoiceChange",
  initialState,
  reducers: {
    updateReturnTypeList: (state, action: PayloadAction<any>) => {
      state.returnTypeList = action.payload;
    },
    updateReleaseTypeList: (state, action: PayloadAction<any>) => {
      state.releaseTypeList = action.payload;
    },
    clearEnquirtDashboard: (state) => {
      state.enquiryDashboardList = [];
    },
    clearInvoiceDashboard:(state)=>{
      state.invoiceDataList = []
    }
  },
  extraReducers: (builder) => {
    builder.addCase(addInvoiceChange.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(addInvoiceChange.fulfilled, (state: any, action) => {
      state.dataLoading = false;
      state.data = action.payload;
    });
    builder.addCase(getInvoiceTableData.pending, (state, action) => {
      state.dataLoading = true;
      state.invoiceDataList = []
    });
    builder.addCase(getInvoiceTableData.fulfilled, (state, action) => {
      state.dataLoading = false;
      state.invoiceDataList = action.payload;
    });
    builder.addCase(getEnquiryTableData.pending,(state,action)=>{
      state.enquiryDashboardList = []
    })
    builder.addCase(getEnquiryTableData.fulfilled, (state, action) => {
      state.dataLoading = false;
      state.enquiryDashboardList = action.payload;
    });
    builder.addCase(
      getEnquiryTableDataForContainer.fulfilled,
      (state, action) => {
        state.dataLoading = false;
        state.enquiryDashboardListForContainer = action.payload;
      }
    );
    builder.addCase(getVesselTableData.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(getVesselTableData.fulfilled, (state, action) => {
      state.dataLoading = false;
      state.vesselDataList = action.payload;
    });
    builder.addCase(getPermitTrackingData.pending, (state, action) => {
      state.dataLoading = true;
      state.permitDashboardData = [];
    });
    builder.addCase(getPermitTrackingData.fulfilled, (state, action) => {
      state.dataLoading = false;
      state.permitDashboardData = action.payload;
    });
    builder.addCase(getPermitTracking.pending, (state, action) => {
      state.dataLoading = true;
      state.permitDashboardData = [];
    });
    builder.addCase(getPermitTracking.fulfilled, (state, action) => {
      state.dataLoading = false;
      state.permitDashboardData = action.payload;
    });
    builder.addCase(getEbsPermitsData.pending, (state, action) => {
      state.permitDashboardData = [];
      state.dataLoading = true;
    });
    builder.addCase(getEbsPermitsData.fulfilled, (state, action) => {
      state.dataLoading = false;
      state.permitDashboardData = action.payload;
    });
    builder.addCase(getEirInPermitsData.pending, (state, action) => {
      state.permitDashboardData = [];
      state.dataLoading = true;
    });
    builder.addCase(getEirInPermitsData.fulfilled, (state, action) => {
      state.dataLoading = false;
      state.permitDashboardData = action.payload;
    });
    builder.addCase(getInvoiceChange.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(getInvoiceChange.fulfilled, (state: any, action) => {
      state.dataLoading = false;
      state.invoiceData = action.payload;
    });

    builder.addCase(approveRejectInvoiceChange.pending, (state, action) => {
      state.isCompleteLoading = true;
    });
    builder.addCase(approveRejectInvoiceChange.fulfilled, (state, action) => {
      state.isCompleteLoading = false;
    });
  },
});

export const {
  updateReturnTypeList,
  updateReleaseTypeList,
  clearEnquirtDashboard,
  clearInvoiceDashboard
} = InvoiceChangeSlice.actions;

export default InvoiceChangeSlice.reducer;
