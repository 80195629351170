import PropTypes from "prop-types";
import { Outlet } from "react-router-dom";

import bg1 from "../assets/images/landing-bg.jpeg";
import { Card } from "react-bootstrap";

const AuthCardLayoutNew = () => {
  return (
    <div
      className="d-flex overflow-hidden justify-content-center 
    align-content-center flex-row"
      style={{ height: "100vh" }}
    >
      <div
        className="col-lg-12"
        style={{
          height: "100vh",
          backgroundImage: `url(${bg1})`,
          backgroundSize: "100%",
          backgroundPosition: "45% 100%",
          filter: "brightness(75%)",
          position: "absolute",
          zIndex: -1,
        }}
      />
      <Card className="col-md-9 my-3 overflow-hidden">
        <div className="overflow-auto p-3 pt-3">
          <Outlet />
        </div>
        <div
          className="w-100 sticky-bottom"
          style={{
            fontSize: "14.5px",
            backgroundColor: "rgba(80, 80, 80)",
            display: "flex",
            justifyContent: "center",
            color: "white",
          }}
        >
          © Copyright 2018-2024 EA Technologies. All rights reserved
        </div>
      </Card>
    </div>
  );
};
AuthCardLayoutNew.propTypes = {
  leftSideContent: PropTypes.node,
  children: PropTypes.node.isRequired,
  footer: PropTypes.bool,
};

export default AuthCardLayoutNew;
