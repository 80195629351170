import { useDispatch, useSelector } from "react-redux";
import { Badge, Button, Card, Dropdown, Form, Modal } from "react-bootstrap";
import * as XLSX from "xlsx";
import {
  // faArrowAltCircleLeft,
  faRedo,
  faSave,
  faUpload,
  faCancel,
  faTrash,
  faAdd,
} from "@fortawesome/free-solid-svg-icons";
import {
  faArrowAltCircleLeft,
  faCheckCircle,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toggleDocumentHistoryColumnShort } from "../../blDetails/slices/documentHistorySlice";
import {
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { AppDispatch, RootState } from "../../../store";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
//import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { AESDecrypt, AESDecryptforDashboard } from "../../../../encrypt-util";
import DataTable from "../../../common/components/DataTable";
import { IDataTableColumn } from "../../../common/types/dataTable";
import UploadIndicator from "../../../../components/common/UploadIndicator";
import {
  updateSelectedAttachmentFilePath,
  updateSelectedAttachmentName,
  updateSelectedDocId,
  updateSelectedFileServerPath,
} from "../../blRequestInstruction/components/instructionSlice/DemurrageSlice";
import { toast } from "react-toastify";
import axiosInstance from "../../../../axiosSetup";
import {
  generateInvReferenceNo,
  getInvoiceRequestByReferenceNo,
  getMstCodeListVal,

} from "../../blRequestInstruction/components/actions/Instruction";
import { useBackNavigation } from "../../../../hooks/BackNavigation";
import axios from "axios";


const InvoiceRequestDocList = () => {
  const { t } = useTranslation();
  const invReqId = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const onBack = useBackNavigation();
  const navigate = useNavigate();
  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );
  const docList = useSelector((state: RootState) => state.demurrage.docList);
  const docExport = useSelector(
    (state: RootState) => state.demurrage.docExport
  );

  const [trafficVal, setTrafficVal] = useState<Number>(0);

  const linerDataCountry = useSelector(
    (state: RootState) => state.userProfile.linerDataCountry
  );
  const storedUserCountry = JSON.parse(
    window.localStorage.getItem("@user-selected-datacountry") || "{}"
  );
  const storedCountry =
    storedUserCountry?.[0]?.loginId === profileDetails.email
      ? storedUserCountry?.[0]?.country
      : null;

  const dataCountry = storedCountry
    ? storedCountry
    : linerDataCountry
      ? linerDataCountry
      : profileDetails.partnerCountry || null;
  useEffect(() => {
    if (dataCountry) dispatch(generateInvReferenceNo(dataCountry));
  }, [dataCountry]);

  const [InvDocList, setInvDocList] = useState([])
  useEffect(() => {
    dispatch(getInvoiceRequestByReferenceNo(invReqId.invReqId)).unwrap().then((response) => {
      setInvDocList(response.data.documents)
    })
  }, [invReqId]);

  return (
    <>
      <Card style={{ minHeight: "100%" }}>
        <Card.Header className="d-flex align-items-center border-bottom sticky-top bg-white">
          <h2 className="fs-0 text-primary mb-1">{t("Invoice Document List")}</h2>
        </Card.Header>
        <Card.Body>
          <div className="container">
            <>

              {InvDocList.map((item: any, i) => {
                return (
                  <div className="row g-3 timeline timeline-primary timeline-past pb-card">
                    <div className="col-3">
                      <div className="row gx-0 border-bottom pb-card">
                        <div className="col">

                          {trafficVal == 2 ? docExport.find((v: any) => v?.id === i + 1)?.name || "" : docList?.find((v: any) => v?.id === i + 1)?.name || ""}

                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="row gx-0 border-bottom pb-card">
                        <div className="col">
                          <h6
                            onClick={() => {
                              window.open(
                                `${process.env.REACT_APP_CLIENT_APP_HOST
                                }/api/opc-rest-v1/v1/common/openDocument?id=${item.invoiceRequestAttId}&moduleTp=DND&linerId=${29990
                                }`,
                                "_blank"
                              );
                            }}
                            className=" text-primary cursor-pointer">{item.fileName}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                )

              })}

            </>
          </div>
        </Card.Body>
      </Card>


    </>
  );
};
export default InvoiceRequestDocList;
